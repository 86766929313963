import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./Experience.fulfillment";
import OptionsList from "../../../components/OptionsList/OptionsList";
import NumberField from "../../../components/NumberField/NumberField";
import SimpleTextField from "../../../components/SimpleTextField/SimpleTextField";
import TableSelect from "../../../components/TableSelect/TableSelect";
import SimpleCheckbox from "../../../components/SimpleCheckbox/SimpleCheckbox";
import Table from "../../../../../components/Table/Table";
import i18n from "../../../../../helpers/i18n";
import TextView from "../../../../../components/TextView/TextView";

export default class Experience extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) {
				callbackFn();
			}
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {}
			}
		});
	}

	setTableData(fields, callbackFn) {
		this.setCustomerState({tableData: {...this.state.customerState.tableData, ...fields}}, callbackFn);
	}

	render() {
		const {customerState} = this.state;
		const classes = createClassName("Cob-view Cob-view2", {});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>{i18n("experience", "background_experience")}</h2>

					<div className="Cob-view2-fields">

						<h3>{i18n("experience", "general_information")}</h3>

						<h4>{i18n("experience", "financial_investments_knowledge")}*</h4>

						<TextView
							name="exp_is_professional"
							label={`${i18n("general", "free_text")} (${200 - customerState.exp_is_professional.length} ${i18n("general", "remaining_chars")})`}
							value={customerState.exp_is_professional}
							maxLength={200}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({exp_is_professional: target.value})}
							fullWidth
							minHeight={90}
							required={true}
						/>

						<h4>{i18n("experience", "how_long_trading_securities")}?*</h4>
						<div style={{maxWidth: "150px"}}>
							<NumberField label={i18n("general", "provide_number")}
										 unit={i18n("general", "years", true)}
										 name="exp_years_traded"
										 value={customerState.exp_years_traded}
										 showSeparators={false}
										 validation={value => (value.length > 0 && value >= 0 && value <= 99)}
										 onChange={({target}) => {
											 this.setCustomerState({exp_years_traded: target.value});
										 }}/>
						</div>

						<div className="h-line spacing"/>

						<h3 style={{marginBottom: "25px"}}>{i18n("experience", "different_asset_classes_knowledge")}*</h3>
						<Table
							className="Cob-Table"
							columns={_getTableColumns.call(this)}
							rows={_getTableRows.call(this)}
						/>

						<h4 style={{marginTop: "20px"}}>{i18n("experience", "different_asset_classes_knowledge_comment")}</h4>

						<TextView
							name="exp_comment"
							label={`${i18n("general", "free_text")} (${400 - customerState.exp_comment.length} ${i18n("general", "remaining_chars")})`}
							value={customerState.exp_comment}
							maxLength={400}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({exp_comment: target.value})}
							fullWidth
							minHeight={90}
						/>

						<div className="h-line spacing"/>

						<h3 style={{marginBottom: "25px"}}>{i18n("experience", "portfolio_management_knowledge")}*</h3>
						<Table
							className="Cob-Table"
							columns={_getTableColumnsOther.call(this)}
							rows={_getTableRowsOther.call(this)}
						/>

						<h4 style={{marginTop: "20px"}}>{i18n("experience", "portfolio_management_knowledge_comment")}</h4>

						<TextView
							name="exp_comment_portfoljforvaltning"
							label={`${i18n("general", "free_text")} (${400 - customerState.exp_comment_portfoljforvaltning.length} ${i18n("general", "remaining_chars")})`}
							value={customerState.exp_comment_portfoljforvaltning}
							maxLength={400}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({exp_comment_portfoljforvaltning: target.value})}
							fullWidth
							minHeight={90}
						/>

						<div className="h-line spacing"/>

						<h3>{i18n("experience", "risk_of_loss")}</h3>

						<h4>{i18n("experience", "financial_instruments_risk_awareness")}?*
						</h4>

						<OptionsList
							type="radio"
							orientation="horizontal"
							name="exp_understands_total_loss"
							options={[
								{
									label: i18n("general", "no"),
									value: "Nej",
									checked: customerState.exp_understands_total_loss === "Nej"
								},
								{
									label: i18n("general", "yes"),
									value: "Ja",
									checked: customerState.exp_understands_total_loss === "Ja"
								}
							]}
							onChange={(checked, exp_understands_total_loss) => this.setCustomerState({exp_understands_total_loss})}
						/>

						{customerState.exp_understands_total_loss === "Nej" && (
							<p style={{
								borderRadius: "5px",
								border: "1px solid #df4c43",
								width: "100%",
								padding: "15px"
							}}>
                {i18n("experience", "risk_understand_confirmation")}
							</p>
						)}

					</div>
				</ContentBox>
			</div>
		);
	}
}

// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer} = this.props;
	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

function getTableData(customer) {
	return {
		exp_no_of_trades_shares: customer.getData("exp_no_of_trades_shares") || "",
		exp_no_of_trades_shares_unlisted: customer.getData("exp_no_of_trades_shares_unlisted") || "",
		exp_no_of_trades_funds: customer.getData("exp_no_of_trades_funds") || "",
		exp_no_of_trades_funds_special: customer.getData("exp_no_of_trades_funds_special") || "",
		exp_no_of_trades_bonds: customer.getData("exp_no_of_trades_bonds") || "",
		exp_no_of_trades_bonds_unlisted: customer.getData("exp_no_of_trades_bonds_unlisted") || "",
		exp_no_of_trades_eln: customer.getData("exp_no_of_trades_eln") || "",
		exp_no_of_trades_hedgefunds: customer.getData("exp_no_of_trades_hedgefunds") || "",
		exp_no_of_trades_credit_certs: customer.getData("exp_no_of_trades_credit_certs") || "",
		exp_no_of_trades_autocallables: customer.getData("exp_no_of_trades_autocallables") || "",
		exp_no_of_trades_etf: customer.getData("exp_no_of_trades_etf") || "",
		exp_no_of_trades_derivatives: customer.getData("exp_no_of_trades_derivatives") || "",

		exp_knowledge_shares: customer.getData("exp_knowledge_shares") || "",
		exp_knowledge_shares_unlisted: customer.getData("exp_knowledge_shares_unlisted") || "",
		exp_knowledge_funds: customer.getData("exp_knowledge_funds") || "",
		exp_knowledge_funds_special: customer.getData("exp_knowledge_funds_special") || "",
		exp_knowledge_bonds: customer.getData("exp_knowledge_bonds") || "",
		exp_knowledge_bonds_unlisted: customer.getData("exp_knowledge_bonds_unlisted") || "",
		exp_knowledge_eln: customer.getData("exp_knowledge_eln") || "",
		exp_knowledge_hedgefunds: customer.getData("exp_knowledge_hedgefunds") || "",
		exp_knowledge_credit_certs: customer.getData("exp_knowledge_credit_certs") || "",
		exp_knowledge_autocallables: customer.getData("exp_knowledge_autocallables") || "",
		exp_knowledge_management: customer.getData("exp_knowledge_management") || "",
		exp_knowledge_etf: customer.getData("exp_knowledge_etf") || "",
		exp_knowledge_derivatives: customer.getData("exp_knowledge_derivatives") || "",

		exp_educated_shares: customer.getData("exp_educated_shares") || "no",
		exp_educated_shares_unlisted: customer.getData("exp_educated_shares_unlisted") || "no",
		exp_educated_funds: customer.getData("exp_educated_funds") || "no",
		exp_educated_funds_special: customer.getData("exp_educated_funds_special") || "no",
		exp_educated_bonds: customer.getData("exp_educated_bonds") || "no",
		exp_educated_bonds_unlisted: customer.getData("exp_educated_bonds_unlisted") || "no",
		exp_educated_eln: customer.getData("exp_educated_eln") || "no",
		exp_educated_hedgefunds: customer.getData("exp_educated_hedgefunds") || "no",
		exp_educated_credit_certs: customer.getData("exp_educated_credit_certs") || "no",
		exp_educated_autocallables: customer.getData("exp_educated_autocallables") || "no",
		exp_educated_management: customer.getData("exp_educated_management") || "no",
		exp_educated_etf: customer.getData("exp_educated_etf") || "no",
		exp_educated_derivatives: customer.getData("exp_educated_derivatives") || "no",

		exp_understood_risks_shares: customer.getData("exp_understood_risks_shares") || "no",
		exp_understood_risks_shares_unlisted: customer.getData("exp_understood_risks_shares_unlisted") || "no",
		exp_understood_risks_funds: customer.getData("exp_understood_risks_funds") || "no",
		exp_understood_risks_funds_special: customer.getData("exp_understood_risks_funds_special") || "no",
		exp_understood_risks_bonds: customer.getData("exp_understood_risks_bonds") || "no",
		exp_understood_risks_bonds_unlisted: customer.getData("exp_understood_risks_bonds_unlisted") || "no",
		exp_understood_risks_eln: customer.getData("exp_understood_risks_eln") || "no",
		exp_understood_risks_hedgefunds: customer.getData("exp_understood_risks_hedgefunds") || "no",
		exp_understood_risks_credit_certs: customer.getData("exp_understood_risks_credit_certs") || "no",
		exp_understood_risks_autocallables: customer.getData("exp_understood_risks_autocallables") || "no",
		exp_understood_risks_management: customer.getData("exp_understood_risks_management") || "no",
		exp_understood_risks_etf: customer.getData("exp_understood_risks_etf") || "no",
		exp_understood_risks_derivatives: customer.getData("exp_understood_risks_derivatives") || "no"
	};
}

export function getViewInitialStateForCustomer(customer) {

	return {
		customerState: {
			exp_is_professional: customer.getData("exp_is_professional") || "",
			exp_comment: customer.getData("exp_comment") || "",
			exp_comment_portfoljforvaltning: customer.getData("exp_comment_portfoljforvaltning") || "",
			exp_years_traded: customer.getData("exp_years_traded") || "",
			exp_understands_total_loss: customer.getData("exp_understands_total_loss") || "",

			tableData: getTableData(customer),

			// TableSelect
			sharesKnowledgeIndex: (
				customer.getData("exp_knowledge_shares") === "Ingen" ? (customer.getData("exp_no_of_trades_shares") !== "0" ? 1 : 0) :
					customer.getData("exp_knowledge_shares") === "Grundläggande" ? 1 :
						customer.getData("exp_knowledge_shares") === "Omfattande" ? 2 :
							-1
			),
			sharesUnlistedKnowledgeIndex: (
				customer.getData("exp_knowledge_shares_unlisted") === "Ingen" ? (customer.getData("exp_no_of_trades_shares_unlisted") !== "0" ? 1 : 0) :
					customer.getData("exp_knowledge_shares_unlisted") === "Grundläggande" ? 1 :
						customer.getData("exp_knowledge_shares_unlisted") === "Omfattande" ? 2 :
							-1
			),
			fundsKnowledgeIndex: (
				customer.getData("exp_knowledge_funds") === "Ingen" ? (customer.getData("exp_no_of_trades_funds") !== "0" ? 1 : 0) :
					customer.getData("exp_knowledge_funds") === "Grundläggande" ? 1 :
						customer.getData("exp_knowledge_funds") === "Omfattande" ? 2 :
							-1
			),
			fundsSpecialKnowledgeIndex: (
				customer.getData("exp_knowledge_funds_special") === "Ingen" ? (customer.getData("exp_no_of_trades_funds_special") !== "0" ? 1 : 0) :
					customer.getData("exp_knowledge_funds_special") === "Grundläggande" ? 1 :
						customer.getData("exp_knowledge_funds_special") === "Omfattande" ? 2 :
							-1
			),
			obligationsKnowledgeIndex: (
				customer.getData("exp_knowledge_bonds") === "Ingen" ? (customer.getData("exp_no_of_trades_bonds") !== "0" ? 1 : 0) :
					customer.getData("exp_knowledge_bonds") === "Grundläggande" ? 1 :
						customer.getData("exp_knowledge_bonds") === "Omfattande" ? 2 :
							-1
			),
			obligationsUnlistedKnowledgeIndex: (
				customer.getData("exp_knowledge_bonds_unlisted") === "Ingen" ? (customer.getData("exp_no_of_trades_bonds_unlisted") !== "0" ? 1 : 0) :
					customer.getData("exp_knowledge_bonds_unlisted") === "Grundläggande" ? 1 :
						customer.getData("exp_knowledge_bonds_unlisted") === "Omfattande" ? 2 :
							-1
			),
			aioKnowledgeIndex: (
				customer.getData("exp_knowledge_eln") === "Ingen" ? (customer.getData("exp_no_of_trades_eln") !== "0" ? 1 : 0) :
					customer.getData("exp_knowledge_eln") === "Grundläggande" ? 1 :
						customer.getData("exp_knowledge_eln") === "Omfattande" ? 2 :
							-1
			),
			hedgefundsKnowledgeIndex: (
				customer.getData("exp_knowledge_hedgefunds") === "Ingen" ? (customer.getData("exp_no_of_trades_hedgefunds") !== "0" ? 1 : 0) :
					customer.getData("exp_knowledge_hedgefunds") === "Grundläggande" ? 1 :
						customer.getData("exp_knowledge_hedgefunds") === "Omfattande" ? 2 :
							-1
			),
			creditCertificateKnowledgeIndex: (
				customer.getData("exp_knowledge_credit_certs") === "Ingen" ? (customer.getData("exp_no_of_trades_credit_certs") !== "0" ? 1 : 0) :
					customer.getData("exp_knowledge_credit_certs") === "Grundläggande" ? 1 :
						customer.getData("exp_knowledge_credit_certs") === "Omfattande" ? 2 :
							-1
			),
			autocallsKnowledgeIndex: (
				customer.getData("exp_knowledge_autocallables") === "Ingen" ? (customer.getData("exp_no_of_trades_autocallables") !== "0" ? 1 : 0) :
					customer.getData("exp_knowledge_autocallables") === "Grundläggande" ? 1 :
						customer.getData("exp_knowledge_autocallables") === "Omfattande" ? 2 :
							-1
			),
			managementKnowledgeIndex: (
				customer.getData("exp_knowledge_management") === "Ingen" ? 0 :
					customer.getData("exp_knowledge_management") === "Grundläggande" ? 1 :
						customer.getData("exp_knowledge_management") === "Omfattande" ? 2 :
							-1
			),
			etfKnowledgeIndex: (
				customer.getData("exp_knowledge_etf") === "Ingen" ? (customer.getData("exp_no_of_trades_etf") !== "0" ? 1 : 0) :
					customer.getData("exp_knowledge_etf") === "Grundläggande" ? 1 :
						customer.getData("exp_knowledge_etf") === "Omfattande" ? 2 :
							-1
			),
			derivativeKnowledgeIndex: (
				customer.getData("exp_knowledge_derivatives") === "Ingen" ? (customer.getData("exp_no_of_trades_derivatives") !== "0" ? 1 : 0) :
					customer.getData("exp_knowledge_derivatives") === "Grundläggande" ? 1 :
						customer.getData("exp_knowledge_derivatives") === "Omfattande" ? 2 :
							-1
			),
		}
	};
}

function _getFields() {
	return [
		"exp_is_professional",
		"exp_comment",
		"exp_comment_portfoljforvaltning",
		"exp_years_traded",
		"exp_understands_total_loss",

		...Object.keys(this.state.customerState.tableData)
	];
}


function _getTableColumns() {
	return [
		{
			key: "investment",
			label: i18n("experience", "asset_classes"),
			width: "15%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "amountOfbusinessLast3Years",
			label: i18n("experience", "transaction_num_3years"),
			width: "20%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "knowledge",
			label: i18n("cob", "knowledge_and_experience"),
			width: "20%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "education",
			label: i18n("experience", "customer_received_education"),
			width: "15%",
			horizontalAlign: "right",
			verticalAlign: "top"
		},
		{
			key: "brokerRisk",
			label: i18n("experience", "advisor_believes_customer_understands_risks"),
			width: "30%",
			horizontalAlign: "right",
			verticalAlign: "top"
		},
	];
}

function _getTableRows() {
	return [
		_createTableRow.call(this, {
			investment: {name: "exp_no_of_trades_shares", label: i18n("experience", "equities_listed")},
			knowledge: {name: "exp_knowledge_shares", indexName: "sharesKnowledgeIndex"},
			education: {name: "exp_educated_shares"},
			brokerRisk: {name: "exp_understood_risks_shares"},
		}),
		_createTableRow.call(this, {
			investment: {name: "exp_no_of_trades_shares_unlisted", label: i18n("experience", "equities_unlisted")},
			knowledge: {name: "exp_knowledge_shares_unlisted", indexName: "sharesUnlistedKnowledgeIndex"},
			education: {name: "exp_educated_shares_unlisted"},
			brokerRisk: {name: "exp_understood_risks_shares_unlisted"},
		}),
		_createTableRow.call(this, {
			investment: {name: "exp_no_of_trades_funds", label: i18n("experience", "funds_ucits")},
			knowledge: {name: "exp_knowledge_funds", indexName: "fundsKnowledgeIndex"},
			education: {name: "exp_educated_funds"},
			brokerRisk: {name: "exp_understood_risks_funds"},
		}),
		_createTableRow.call(this, {
			investment: {name: "exp_no_of_trades_funds_special", label: i18n("experience", "funds_aif_special_funds")},
			knowledge: {name: "exp_knowledge_funds_special", indexName: "fundsSpecialKnowledgeIndex"},
			education: {name: "exp_educated_funds_special"},
			brokerRisk: {name: "exp_understood_risks_funds_special"},
		}),
		_createTableRow.call(this, {
			investment: {name: "exp_no_of_trades_bonds", label: i18n("experience", "bonds_listed")},
			knowledge: {name: "exp_knowledge_bonds", indexName: "obligationsKnowledgeIndex"},
			education: {name: "exp_educated_bonds"},
			brokerRisk: {name: "exp_understood_risks_bonds"},
		}),
		_createTableRow.call(this, {
			investment: {name: "exp_no_of_trades_bonds_unlisted", label: i18n("experience", "bonds_unlisted")},
			knowledge: {name: "exp_knowledge_bonds_unlisted", indexName: "obligationsUnlistedKnowledgeIndex"},
			education: {name: "exp_educated_bonds_unlisted"},
			brokerRisk: {name: "exp_understood_risks_bonds_unlisted"},
		}),
		_createTableRow.call(this, {
			investment: {name: "exp_no_of_trades_eln", label: i18n("general", "equity_linked_notes")},
			knowledge: {name: "exp_knowledge_eln", indexName: "aioKnowledgeIndex"},
			education: {name: "exp_educated_eln"},
			brokerRisk: {name: "exp_understood_risks_eln"},
		}),
		_createTableRow.call(this, {
			investment: {name: "exp_no_of_trades_hedgefunds", label: i18n("experience", "hedge_funds")},
			knowledge: {name: "exp_knowledge_hedgefunds", indexName: "hedgefundsKnowledgeIndex"},
			education: {name: "exp_educated_hedgefunds"},
			brokerRisk: {name: "exp_understood_risks_hedgefunds"},
		}),
		_createTableRow.call(this, {
			investment: {name: "exp_no_of_trades_credit_certs", label: i18n("general", "credit_certificate")},
			knowledge: {name: "exp_knowledge_credit_certs", indexName: "creditCertificateKnowledgeIndex"},
			education: {name: "exp_educated_credit_certs"},
			brokerRisk: {name: "exp_understood_risks_credit_certs"},
		}),
		_createTableRow.call(this, {
			investment: {name: "exp_no_of_trades_autocallables", label: i18n("experience", "autocalls")},
			knowledge: {name: "exp_knowledge_autocallables", indexName: "autocallsKnowledgeIndex"},
			education: {name: "exp_educated_autocallables"},
			brokerRisk: {name: "exp_understood_risks_autocallables"},
		}),
		_createTableRow.call(this, {
			investment: {name: "exp_no_of_trades_etf", label: i18n("experience", "etfs")},
			knowledge: {name: "exp_knowledge_etf", indexName: "etfKnowledgeIndex"},
			education: {name: "exp_educated_etf"},
			brokerRisk: {name: "exp_understood_risks_etf"},
		}),
		_createTableRow.call(this, {
			investment: {name: "exp_no_of_trades_derivatives", label: i18n("experience", "derivatives_and_commodity_certificates")},
			knowledge: {name: "exp_knowledge_derivatives", indexName: "derivativeKnowledgeIndex"},
			education: {name: "exp_educated_derivatives"},
			brokerRisk: {name: "exp_understood_risks_derivatives"},
		}),
	];
}


function _getTableRowsOther()
{
	return [
		_createTableRowOther.call(this, {
			investment: {name: "exp_no_of_trades_management", label: i18n("experience", "portfolio_management")},
			knowledge: {name: "exp_knowledge_management", indexName: "managementKnowledgeIndex"},
			education: {name: "exp_educated_management"},
			brokerRisk: {name: "exp_understood_risks_management"},
		})
	];
}

function _getTableColumnsOther() {
	return [
		{
			key: "investment",
			label: "",
			width: "15%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "knowledge",
			label: i18n("cob", "knowledge_and_experience"),
			width: "35%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "education",
			label: i18n("experience", "client_received_training"),
			width: "20%",
			horizontalAlign: "right",
			verticalAlign: "top"
		},
		{
			key: "brokerRisk",
			label: i18n("experience", "advisor_believes_customer_understands_portfolio"),
			width: "30%",
			horizontalAlign: "right",
			verticalAlign: "top"
		},
	];
}

function _createTableRow({investment, knowledge, education, brokerRisk}) {
	const {customerState} = this.state;
	const {tableData} = customerState;

	let knowledgeOptions = [
		{label: i18n("general", "none"), value: "Ingen"},
		{label: i18n("general", "basic"), value: "Grundläggande"},
		{label: i18n("general", "compreensive"), value: "Omfattande"}
	];

	return {
		// Investment
		investment: {
			className: "investment",
			value: investment.label
		},

		amountOfbusinessLast3Years: {
			value: (
				<SimpleTextField
					type="number"
					name={investment.name}
					value={tableData[investment.name] || ""}
					decimals={0}
					onChange={({target}) =>
					{
						let numberOfDeals = parseInt(target.value, 10);
						if (numberOfDeals > 0 && tableData[knowledge.name] === 'Ingen')
						{
							this.setCustomerState({
								[knowledge.indexName]: 1,
								tableData: {
									...this.state.customerState.tableData,
									[knowledge.name]: "Grundläggande",
									[investment.name]: target.value ? numberOfDeals.toString() : ""
								}
							});
						}
						else
						{
							this.setTableData({[investment.name]: target.value ? numberOfDeals.toString() : ""});
						}
					}}
				/>
			),
		},

		// Knowledge
		knowledge: {
			value: (
				<TableSelect
					label={"Välj..."}
					name={knowledge.name}
					selectedIndex={customerState[knowledge.indexName]}
					options={knowledgeOptions}
					onChange={(option, index) =>
					{

						let numberOfDealsValue = tableData[investment.name];
						if (index === 0)
						{
							numberOfDealsValue = "0";
						}

						this.setCustomerState({
							[knowledge.indexName]: index,
							tableData: {
								...this.state.customerState.tableData,
								[knowledge.name]: option.value,
								[investment.name]: numberOfDealsValue
							}
						});
					}}
				/>
			),
		},

		// Education
		education: {
			className: "education",
			value: <SimpleCheckbox name={education.name} value={tableData[education.name] === "Ja" ? "Ja" : "Nej"}
								   checked={tableData[education.name] === "Ja"}
								   onChange={checked => this.setTableData({[education.name]: checked ? "Ja" : "Nej"})}/>,
		},

		// Broker risk
		brokerRisk: {
			className: "broker-risk",
			value: <SimpleCheckbox name={brokerRisk.name} value={tableData[brokerRisk.name] === "Ja" ? "Ja" : "Nej"}
								   checked={tableData[brokerRisk.name] === "Ja"}
								   onChange={checked => this.setTableData({[brokerRisk.name]: checked ? "Ja" : "Nej"})}/>,
		},
	};
}

function _createTableRowOther({investment, knowledge, education, brokerRisk}) {
	const {customerState} = this.state;
	const {tableData} = customerState;

	let knowledgeOptions = [
		{label: i18n("general", "none"), value: "Ingen"},
		{label: i18n("general", "basic"), value: "Grundläggande"},
		{label: i18n("general", "compreensive"), value: "Omfattande"}
	];

	return {
		// Investment
		investment: {
			className: "investment",
			value: investment.label
		},

		// Knowledge
		knowledge: {
			value: (
				<TableSelect
					label={"Välj..."}
					name={knowledge.name}
					selectedIndex={customerState[knowledge.indexName]}
					options={knowledgeOptions}
					onChange={(option, index) =>
					{
						this.setCustomerState({
							[knowledge.indexName]: index,
							tableData: {
								...this.state.customerState.tableData,
								[knowledge.name]: option.value
							}
						});
					}}
				/>
			),
		},

		// Education
		education: {
			className: "education",
			value: <SimpleCheckbox name={education.name} value={tableData[education.name] === "Ja" ? "Ja" : "Nej"}
								   checked={tableData[education.name] === "Ja"}
								   onChange={checked => this.setTableData({[education.name]: checked ? "Ja" : "Nej"})}/>,
		},

		// Broker risk
		brokerRisk: {
			className: "broker-risk",
			value: <SimpleCheckbox name={brokerRisk.name} value={tableData[brokerRisk.name] === "Ja" ? "Ja" : "Nej"}
								   checked={tableData[brokerRisk.name] === "Ja"}
								   onChange={checked => this.setTableData({[brokerRisk.name]: checked ? "Ja" : "Nej"})}/>
		},
	};
}