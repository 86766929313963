// HELPERS
import {inArray} from "@helpers/utils";
import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}
	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;
	const {isCompany} = customerState;

	// WARNING Fields specific for private individuals need to be placed lower in the document

	// Hur länge har vi handlat med finansiella instrument måste vara vald
	if (customerState.yearsExperience.length <= 0) {
		return false;
	}

	// Högsta utbildningsnivå måste vara ifylld
	if (customerState.highestEducationIndex === -1) {
		return false;
	}

	// Om man valt universitet måste inriktning anges
	if (customerState.highestEducationIndex === 2 && customerState.customer_university.length <= 0) {
		return false;
	}

	// Nuvarande eller tidigare befattning måste vara vald
	if (customerState.currentOrPreviousCareerIndex === -1) {
		return false;
	}

	// Vad är kundens syfte med investeringen? måste kryssas
	if (!inArray("yes", [
		customerState.checkbox_aml_purpose_longtime_savings,
		customerState.checkbox_aml_purpose_single,
		customerState.checkbox_aml_purpose_other
	]))
	{
		return false;
	}

	// Size of the initial deposit needs to be answered
	if (customerState.aml_first_deposit.length <= 0 || customerState.aml_first_deposit < 0)
	{
		return false;
	}

	// Type of deposit needs to be answered
	if (customerState.radio_deposit_type.length <= 0)
	{
		return false;
	}


	// Q about previous experience needs to be answered
	if (customerState.radio_customer_experience.length <= 0)
	{
		return false;
	}

	if (isCompany)
	{
		if (customerState.radio_company_form == null ||
			customerState.radio_company_form.length <= 0 ||
			customerState.radio_company_active.length <= 0 ||
			customerState.radio_company_revisor.length <= 0 ||
			customerState.company_business.length <= 0 ||
			customerState.radio_company_fininst.length <= 0 ||
			customerState.radio_company_turnover.length <= 0 ||
			customerState.radio_company_employees.length <= 0)
		{
			return false;
		}

		// Var kommer pengarna ifrån, något måste väljas
		if (!inArray("Ja", [
			customerState.checkbox_company_funds_profit,
			customerState.checkbox_company_funds_aktiekapital,
			customerState.checkbox_company_funds_compsales,
			customerState.checkbox_company_funds_invest,
			customerState.checkbox_company_funds_membership,
			customerState.checkbox_company_funds_donation,
			customerState.checkbox_company_funds_grants
		]))
		{
			return false;
		}

		// Från vilken bank institut...
		if (!inArray("yes", [
			customerState.swedishBank,
			customerState.foreignBank,
			customerState.swedishInsurance,
			customerState.foreignInsurance,
			customerState.sourcePension,
			customerState.sourceEmployer,
			customerState.checkbox_customer_kyc_deposit_other
		]))
		{
			return false;
		}

		// Om man valt "Annat" måste textfältet fyllas i
		if (customerState.checkbox_customer_kyc_deposit_other === "yes" && customerState.customer_kyc_deposit_other.length <= 0)
		{
			return false;
		}

		return true;
	}


	// Huvudsaklig sysselsättning needs to be selected
	if (customerState.radio_kyc_form_employment.length <= 0) {
		return false;
	}

	if (customerState.radio_kyc_form_employment !== "Pensionär" &&
		customerState.radio_kyc_form_employment !== "Studerande" &&
		customerState.radio_kyc_employment_text.length <= 0) {
		return false;
	}

	// Sysselsättning nu och sist
	if (customerState.customer_occupation_last24m.length <= 0)
	{
		return false;
	}

	// Kunden måste äga pengar / värdepapper
	if (customerState.checkbox_aml_own_money !== 'yes')
	{
		return false;
	}

	// Var kommer pengarna ifrån, något måste väljas
	if (!inArray("yes", [
		customerState.checkbox_aml_origin_salary_pension,
		customerState.checkbox_aml_origin_capital,
		customerState.checkbox_advice_origin_business,
		customerState.checkbox_advice_origin_companyexit,
		customerState.checkbox_aml_origin_savings_herit_gift,
		customerState.checkbox_aml_origin_other,
	]))
	{
		return false;
	}

	if (customerState.checkbox_aml_origin_other === "yes" && customerState.aml_origin_other.length <= 0)
	{
		return false;
	}

	// Storlek på kundens insättningar
	if (!customerState.radio_aml_deposit_amt || customerState.radio_aml_deposit_amt.length <= 0)
	{
		return false;
	}

	// Hur ofta kund kommer göra insättningar
	if (!customerState.radio_aml_deposit || customerState.radio_aml_deposit.length <= 0)
	{
		return false;
	}

	// Från vilken bank/försäkringsbolag tar du kapital för dina investeringar?
	if (!inArray("yes", [
		customerState.swedishBank,
		customerState.swedishInsurance,
		customerState.foreignBank,
		customerState.foreignInsurance,
		customerState.sourcePension,
		customerState.sourceEmployer,
		customerState.checkbox_customer_kyc_deposit_other
	]))
	{
		return false;
	}

	// Om man valt "Annat" måste textfältet fyllas i
	if (customerState.checkbox_customer_kyc_deposit_other === "yes" && customerState.customer_kyc_deposit_other.length <= 0)
	{
		return false;
	}

	// If given other purpose the text needs to be filled out
	if (customerState.checkbox_aml_purpose_other === "yes" && customerState.aml_purpose_other.length <= 0)
	{
		return false;
	}

	// Type of deposit needs to be answered
	if (customerState.radio_deposit_type.length <= 0)
	{
		return false;
	}

	return true;
}