// MODULES
import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// HOOKS
import useResponsive from "@hooks/useResponsive";

const ExpandableRowContainer = props =>
{
	const {title, icon: Icon, sxStyle, children} = props;

	const isMobileOrTablet = useResponsive("down", "md");

	return (
		<Box sx={{
			width: isMobileOrTablet ? "100%" : "33%",
			minHeight: "100px",
			display: "flex",
			mb: "10px",
			padding: isMobileOrTablet ? 6 : "0 10px 0 0",
			alignItems: "flex-start",
			...sxStyle
		}}>
			<Box sx={{
				width: "100%",
				display: "flex",
				flexDirection: "row",
				alignItems: "flex-start",
				padding: "5px 8px",
				fontSize: "12px",
				backgroundColor: "#FFF",
				border: "1px solid #EAEAEA",
				borderTopLeftRadius: "5px",
				borderTopRightRadius: "5px",
			}}>
				<strong>{title}</strong>
			</Box>
			<Box sx={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				padding: "5px 8px",
				fontSize: "12px",
				backgroundColor: "#FFF",
				border: "1px solid #EAEAEA",
				borderTop: "none",
				borderBottomLeftRadius: "5px",
				borderBottomRightRadius: "5px",
			}}>
				{children}
			</Box>
		</Box>
	);
};

export default ExpandableRowContainer;