import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const CloseModalBox = props => (
  <Box
    {...(!props?.override && props)} // override needs string "true" or ommit (false)
    sx={{
      // do not use shorthand on overrides, props need to match exactly!
      width: "100%",
      // height: 60,
      display: "flex",
      paddingLeft: 4,
      paddingRight: 4,
      ...props.sx
    }}
    // {...(props.override && props)}
  >
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: 1.5,
        paddingBottom: 0,
        borderTop:1,
        borderColor: "grey.200"
      }}
    >
      {props.children}
    </Box>
  </Box>
);

export default CloseModalBox;

CloseModalBox.propTypes = {
  override: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.node
};
