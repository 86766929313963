import { useState, useEffect, useRef } from "react";
import i18n from "@helpers/i18n";
import api from "@helpers/api";
import { getCookie } from "@helpers/cookies";
import { formatSSN } from "@helpers/utils";

const usePoaTable = props => {
  const { 
    data,
    totalCases,
    modalWidget,
    onAction,
    faAuth,
    caseFilter,
    setCaseFilter,
    getCases,
    isLoadingCases,
    currentAction,
    user,
    partner,
    advisor,
    caseFilterContext,
    setCustomerState,
    nextStepOptions
  } = props;

  const [mounted, setMounted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [copyList, setCopyList] = useState(data);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [addCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [numCases, setNumCases] = useState(totalCases);
  const [page, setPage] = useState(0);
  const [searchString, setSearchString] = useState('');
  const searchTimeout = useRef(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created");
  
  // check if we have any options saved..
  const cobOptions = JSON.parse(getCookie("cobOptions")) || null;
  // .. and set rowsPerPage setting accordingly
  const [rowsPerPage, setRowsPerPage] = useState(
    cobOptions?.rowsPerPage["poa"] || 10
  );

  const tabs = [
    {
      key: "all",
      translationKey: "all"
    },
    {
      key: "sent",
      translationKey: "sent",
      color: "#FFFBCE"
    },
    {
      key: "signed",
      translationKey: "valid",
      color: "#D1FFC8"
    },
    {
      key: "recalled",
      translationKey: "revoked_expired",
      color: "#FFD5D5"
    }
  ];

  let columns = [];
  columns.push(      
    {
      Header: "",
      id: 'expander',
      accessor: "expander",
      width: "3%",        
      disablePadding: true,
    },
    {
      Header: "",
      className: "state",
      accessor: "state",
      width: "15%"
    },
    {
      Header: `${i18n("tables", "advisor")}`,
      className: "advisor",
      accessor: "advisorName",
      width: "15%",
    },
    {
      Header: `${i18n("tables", "personal_no")}`,
      className: "ssn",
      accessor: "ssn",
      width: "12%",
      disableSort: true
    },
    {
      Header: `${i18n("tables", "name")}`,
      className: "name",
      accessor: "name",
      width: "14%"
    },
    {
      Header: `${i18n("dashboard_poas", "table_header_created")}`,
      accessor: "created",
      className: "created",
      width: "10%",
      disableFilters: true
    },
    {
      Header: `${i18n("dashboard_poas", "table_header_signed")}`,
      accessor: "signed",
      className: "signed",
      width: "10%"
    },
    {
      Header: `${i18n("dashboard_poas", "table_header_valid_to")}`,
      accessor: "valid_to",
      className: "valid_to",
      width: "10%"
    },
    {
      Header: `${i18n("dashboard_poas", "table_header_invalidated")}`,
      accessor: "invalidated",
      className: "invalidated",
      width: "10%"
    }
  );

  const resetPaginationAndFilters = () => {
    // if there's no change to page and searchString trigger case list fetch manually
    if (page === 0 && searchString === "") fetchCases(0, "", "desc", "created");
    
    setPage(0);
    setSearchString("");
    setCaseFilter("poa", "all");
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setPage(0);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }; 
  
  const updateCustomers = customer => {
    console.log(customer, "customer");
    console.log(data, "data");
    //update all data records that match the ssn
    let updatedData = data.map(c => {
      if (formatSSN(c.ssn, {withDash: true}) === customer.ssn) {
        return {
          ...c,
          name: customer.name
        };
      }
      return c;
    });
    
    // sort alphabetically
    updatedData.sort((a, b) =>
      a.name.localeCompare(b.name, "sv", { sensitivity: "base" })
    );

    // if a customer is selected, update it (edit from customer modal)
    if (selectedCustomer) setSelectedCustomer(customer);

    return setCopyList(updatedData);
  };

  const requestSearch = searched => {
    setSearchString(searched.toLowerCase());
  };

  const toggleAddCustomerModal = action => {
    if (!customerModalOpen && (action === "add" || addCustomerModalOpen)) setSelectedCustomer(null);

    if (addCustomerModalOpen) { // disable/enable background content scrolling while modal is open    
      document.documentElement.style.overflow = "auto";
    } else {
      document.documentElement.style.overflow = "hidden";
    }

    setAddCustomerModalOpen(!addCustomerModalOpen);
  };

  const handleClientClick = ssn => async e => {
    e.preventDefault();

    const { client } = await api("/client/relationship", { ssn: formatSSN(ssn, {withDash: true}) });

    setCustomerState({
      isCompany: false,
      ssnOrg: client.id ? (client.id).replace("-", "") : "",
      isNewCase: null,
      standaloneApplicationsChecked: [],
      needsNewDocumentation: false,
      kycCaseId: null,
      kycInfoLoading: false,
      overrideNewDocumentation: false,
      casesLoading: false,
      cases: null,
      selectedCaseId: null,
      selectedCaseIndex: -1,
      customerModalIsOpen: true,
    }, () =>
		{
      setCustomerModalOpen(true);
      setSelectedCustomer(client);
      document.documentElement.style.overflow = "hidden";
		});
  };

  const handleClientModalClose = () => {
    setCustomerState({
      isCompany: false,
      ssnOrg: "",
      customerModalIsOpen: false
    }, () =>
		{
      setCustomerModalOpen(false);
      setSelectedCustomer(null);
		});
  };

  const handleEntryClick = poa_id => {
    const index = copyList.findIndex(object => {
      return object["poa_id"] === poa_id;
    });

    let updatedData = [...copyList]; // copy prev state data array
    updatedData[index]["toggled"] = !copyList[index].toggled;

    setCopyList(updatedData);
  };

  const fetchCases = async (page, searchString, order, orderBy) => {
    setLoaded(false);
    const cases = await getCases(page, searchString, order, orderBy);
    setCopyList(cases.batch);
    setNumCases(parseInt(cases.total));
    setLoaded(true);
  }

  // inital load and page/rowsPerPage changes useEffect
  useEffect(() => {   
    // (ideally we would get rid of this but will need reactoring work on the DashboardPoas component)
    if (!mounted) {      
      setLoaded(true);
      setMounted(true);
    }

    if (mounted) {
      fetchCases(page, searchString, order, orderBy);
    }
  }, [page, rowsPerPage, order, orderBy])
  
  // Search useEffect
  useEffect(() => {   
    if (mounted) {
      if (searchTimeout.current > 0) clearTimeout(searchTimeout.current);
      searchTimeout.current = setTimeout(() => {
        if (page > 0) setPage(0);
        fetchCases(page, searchString, order, orderBy);        
      }, 2000);
    }
  }, [searchString])

  // TAB(data) change useEffect
  useEffect(() => {   
    if (mounted) {      
      setOrder("desc");
      setOrderBy("created"); // default sort - setOrder/setOrderBy trigger adds double or triple (searchString !== "") spending when changing tabs, optimise later!

      setCopyList(data);
      setNumCases(totalCases);
    }
  }, [data])
  
  // CASES loading useEffect
  useEffect(() => {   
    if (mounted) {
      setLoaded(!isLoadingCases);
    }
  }, [isLoadingCases])



  return {
    user,
    partner,
    advisor,
    loaded,
    modalWidget,
    tabs,
    columns,
    copyList,
    numCases,
    page,
    setPage,
    searchString,
    setSearchString,
    order,
    orderBy,
    rowsPerPage,
    selectedCustomer,
    customerModalOpen,
    addCustomerModalOpen,
    setRowsPerPage,
    setSelectedCustomer,
    handleChangePage,
    handleRequestSort,
    requestSearch,
    toggleAddCustomerModal,
    handleClientClick,
    handleClientModalClose,
    updateCustomers,
    caseFilterContext,
    handleEntryClick,
    onAction,
    caseFilter,
    setCaseFilter,
    isLoadingCases,
    currentAction,
    resetPaginationAndFilters,
    setCustomerState,
    nextStepOptions
  };
};

export default usePoaTable;