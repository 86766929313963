import React from "react";

// HELPERS
import {createClassName} from "@helpers/utils";


export default class TextField extends React.Component {
	state = {
		filled: false,
		focused: false,
		success: false,
		invalid: false,
	};

	get value() {
		return this.input.value
	}

	componentDidMount() {
		_validationAsync.call(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps !== this.props) _validationAsync.call(this);
	}

	render() {
		const {props, state} = this;
		const {style, id, name, label, type, placeholder, required, readOnly, lang, autocomplete, hideRequiredSymbol, value, tooltip, onChange, onKeyPress} = props;
		const disabled = props.disabled === true;
		const classes = createClassName(props.className, {
			"TextField": true,
			[type || "text"]: true,
			"focus": state.focused,
			"disabled": disabled === true,
			"read-only": readOnly === true,
			"placeholder": !!placeholder,
			"filled": state.filled || !!value,
			"required": required === true,
			"tooltip": !!tooltip,
			"invalid": state.invalid,
			"success": state.success,
		});

		return (
			<div className={classes} style={style}>
				<label className="TextField-label">{(state.invalid && props.invalidMessage) || label}</label>

				{onChange && (
					<input
						ref={ref => this.input = ref}
						className="TextField-input"
						id={id}
						name={name}
						type={type || "text"}
						placeholder={placeholder}
						value={value}
						required={required === true}
						disabled={disabled}
						readOnly={readOnly}
						autoComplete={autocomplete}
						lang={lang}
						onFocus={!disabled && !readOnly ? this._onFocus : undefined}
						onBlur={!disabled && !readOnly ? this._onBlur : undefined}
						onInput={!disabled ? this._onInput : undefined}
						onChange={e => !disabled ? onChange(e, this) : undefined}
						onKeyPress={!disabled ? onKeyPress : undefined}
            onWheel={!disabled ? this._onWheel : undefined}
            onPaste={e => e.target.setAttribute('pasted', '1')}
					/>
				)}

				{!onChange && (
					<input
						ref={ref => this.input = ref}
						className="TextField-input"
						id={id}
						name={name}
						type={type || "text"}
						placeholder={placeholder}
						defaultValue={value}
						required={required === true}
						disabled={disabled}
						readOnly={readOnly}
						autoComplete={autocomplete}
						lang={lang}
						onFocus={!disabled && !readOnly ? this._onFocus : undefined}
						onBlur={!disabled && !readOnly ? this._onBlur : undefined}
						onInput={!disabled ? this._onInput : undefined}
						onChange={!disabled ? onChange : undefined}
						onKeyPress={!disabled ? onKeyPress : undefined}
            onWheel={!disabled ? this._onWheel : undefined}
					/>
				)}

				{!!tooltip ?
					<TextFieldTooltip text={tooltip}/>
					: state.success ?
						<TextFieldStatus icon="done"/>
						: required === true && !hideRequiredSymbol ?
							<TextFieldStatus requiredSymbol/>
							: state.invalid ?
								<TextFieldStatus icon="error"/>
								: null}
			</div>
		);
	}

	// Internal methods
	_onWheel = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
	}

	_onFocus = (e) => {
    e.preventDefault()
		const {onFocus} = this.props;
		this.setState({focused: true}, onFocus ? onFocus(e) : null);
	}

	_onBlur = (e) => {
    e.preventDefault()
		const {onBlur, validation} = this.props;
		const inputValue = this.input.value;
		const newState = {focused: false};

		if (inputValue && validation) {
			if (validation(inputValue) === true) {
				newState.invalid = false;
				newState.success = true;
			} else {
				newState.invalid = true;
				newState.success = false;
			}
		}

		this.setState(newState, onBlur ? onBlur(e) : null);
	}

	_onInput = (e) => {
		const {onInput} = this.props;
		_validationAsync.call(this, onInput || undefined);
	}
}


// PRIVATE COMPONENTS
function TextFieldTooltip({text, onClick}) {
	return (
		<div className="TextField-tooltip">
			<div className="TextField-tooltip-icon">?</div>
			<div className="TextField-tooltip-text">{text}</div>
		</div>
	);
}

function TextFieldStatus({icon, requiredSymbol}) {
	return (
		<div className="TextField-status">
			<div className={createClassName("material-icons", {
				"icon": !!icon,
				"required-symbol": requiredSymbol === true,
			})}>{requiredSymbol === true ? "*" : icon}</div>
		</div>
	);
}


// PRIVATE FUNCTIONS
function _validationAsync(callback) {
	return new Promise(resolve => {
		const {value, validation, success, invalid, highlightErrors} = this.props;
		const inputValue = this.input.value || value || "";
		const newState = {filled: !!inputValue};

		if (validation) {
			if (validation(inputValue) === true) {
				newState.success = true;
				newState.invalid = false;
			} else {
				newState.invalid = true;
				newState.success = false;
			}
		} else if (success === true) {
			newState.success = true;
			newState.invalid = false;
		} else if (invalid === true) {
			newState.success = false;
			newState.invalid = true;
		}

		if (!inputValue && !newState.success) newState.invalid = validation && highlightErrors ? true : false;

		this.setState(newState, () => {
			if (callback) callback(inputValue);
			resolve();
		});
	});
}