// MODULES
import React from "react";

import RepeatIcon from '@mui/icons-material/Repeat';

import Card from "@cobComponents/Card";
import BankDetails from "./BankDetails/BankDetails";

// HELPERS
// import i18n from "@helpers/i18n";

const BankDetailsCard = props => {
  const { advisor, client, standaloneApplications } = props;

  return (
    <Card 
      title="Föranmält konto"
      icon={RepeatIcon}
    >
      <BankDetails
        advisor={advisor}
        client={client}
        standaloneApplications={standaloneApplications}
      />
    </Card>
  );
};

export default BankDetailsCard;