import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountIcon from "@mui/icons-material/PersonOutlined";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Divider from '@mui/material/Divider';

// HELPERS
import i18n from "@helpers/i18n";

const MobileMenu = props => {
  const {
    user,
    locationPath,
    settings,
    anchorElUser,
    handleOpenNavMenu,
    handleOpenUserMenu,
    handleCloseUserMenu
  } = props.handlers;

  return (
    <>
      <Tooltip title="Open settings">
        <IconButton size="small" onClick={handleOpenUserMenu}>
        <AccountIcon
            fontSize="large"
            sx={{
              p: "2px",
              mr: "3px",
              fill: "#FFF",
              backgroundColor: "#a7b1d6",
              borderRadius: "50%",
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{
          "& .MuiPaper-root": { borderRadius: 1, width: 160 },
          "& .MuiPaper-root ul": { width: "100%", p: 0 }
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        transitionDuration={0}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Box 
          sx={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-start",
            px: 1,
            height: 35,
            minHeight: "unset",
          }}
        >
          <Typography 
            color="common.black"
            sx={{
              fontFamily: "Roboto",
              fontSize: "0.8rem",
              fontWeight: 600,
            }}
          >
            {user?.firstName} {user?.lastName}
            <em
              style={{
                display: "block",
                fontStyle: "normal",
                fontSize: "0.65rem",
                color: "#999",
                lineHeight: "11px"
              }}
            >
              {i18n("user_roles", user.role)}
            </em>
          </Typography>
        </Box>
        <Divider flexItem />
        {settings.map((setting, key) => (
          <MenuItem
            key={key}
            onClick={() => setting.handleClick()}
            sx={{              
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-start",
              p: 0,
              height: 35,
              minHeight: "unset",
              color:
                setting.navTo === locationPath
                  ? "common.white"
                  : "common.black",
              backgroundColor:
                setting.navTo === locationPath ? "primary.light" : "transparent"
            }}
          >
            <Box
              sx={{
                mt: 0,
                ml: setting.icon ? 1.8 : 0,
                "& .MuiSvgIcon-root": { fontSize: "1.2rem" }
              }}
            >
              {setting?.icon}
            </Box>
            <Typography
              sx={{
                fontSize: "0.875rem",
                px: setting.icon ? 0 : 2,
                ml: setting.icon ? 0.6 : 0
              }}
            >
              {setting.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      {locationPath === "/cob/1" && (
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          sx={{
            display: { xs: "inline", md: "none" },
            px: 1,
            pt: 1.2,
            ml: 0.4
          }}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
        )}
    </>
  );
};

export default MobileMenu;

MobileMenu.propTypes = {
  handlers: PropTypes.object
};
