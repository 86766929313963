import {mergeViewStateForFulfillment} from "../../Cob.helpers";

export default function AssetsFulfillment(state, prevState) {
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") {
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	/**
	 * Rådgivningen måste ha ett datum
	 */
	if (customerState.date.length <= 0)
	{
		return false;
	}

	// Rådgivningens utförande måste vara valt
	if (customerState.meetingFormatIndex === -1)
	{
		return false;
	}

	if ((customerState.meetingFormat === "phone" || customerState.meetingFormat === "video")
		&& (customerState.checkbox_customer_extcheck !== "yes" || customerState.checkbox_customer_countercheck !== "yes"))
	{
		return false;
	}

	// Någon av boxarna för identitetskontroll måste kryssas
	if (customerState.checkbox_idcheck_id !== 'yes' &&
		customerState.checkbox_idcheck_drivinglic !== 'yes' &&
		customerState.checkbox_idcheck_passport !== 'yes' &&
		customerState.checkbox_idcheck_known !== 'Ja'
	)
	{
		return false;
	}

	return !(customerState.customer_asset_bank.length === 0 ||
		customerState.customer_asset_lifeinsurance.length === 0 ||
		customerState.customer_asset_realestate.length === 0 ||
		customerState.customer_asset_kinsurance_trad.length === 0 ||
		customerState.customer_asset_kinsurance_fund.length === 0 ||
		customerState.customer_asset_kinsurance_account.length === 0 ||
		customerState.customer_asset_pinsurance_trad.length === 0 ||
		customerState.customer_asset_pinsurance_fund.length === 0 ||
		customerState.customer_asset_pinsurance_account.length === 0 ||
		customerState.customer_asset_isk_strukt.length === 0 ||
		customerState.customer_asset_isk_stockfund.length === 0 ||
		customerState.customer_asset_account_strukt.length === 0 ||
		customerState.customer_asset_other.length === 0);
}