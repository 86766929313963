// CLASSES
import Settings from "@classes/Settings";
import en from "@src/i18n/en.json";
import sv from "@src/i18n/sv.json";

export default function i18n(category, key, lowerCase = false) {
    const fallbackValue = `{{ ${category}.${key} }}`;

    try {
        const result = _getLanguage()[category][key] || fallbackValue;
        return lowerCase === true ? result.toLowerCase() : result;
    } catch(e) {
        return lowerCase === true ? fallbackValue.toLowerCase() : fallbackValue;
    }
}


// PRIVATE FUNCTIONS & DATA
const _getLanguage = () => {
  let langLib;
  const langCode = Settings.get("LANGUAGE");

  if(langCode === "sv") {
    langLib = sv;
  } else {
    langLib = en;
  }
  
  return _importedLanguages[langCode] || (_importedLanguages[langCode] = langLib);
};

const _importedLanguages = {};