import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./Purpose.fulfillment";
import TextView from "../../../../../components/TextView/TextView";
import OptionsList from "../../../components/OptionsList/OptionsList";
import i18n from "@helpers/i18n";

export default class Purpose extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	static purposeOptions = [
		{
			label: "Mitt fokus i investeringar är risk och avkastning.",
			value: "Mitt fokus i investeringar är risk och avkastning.",
			checked: false
		},
		{
			label: "Jag vill integrera hållbarhetsaspekter där det är möjligt utan att göra avkall risk och avkastningsutsikter.",
			value: "Jag vill integrera hållbarhetsaspekter där det är möjligt utan att göra avkall risk och avkastningsutsikter.",
			checked: false
		},
		{
			label: "Hållbarhet är en mycket viktig fråga i min portfölj och jag kan tänka mig att sänka kraven på risk och avkastningsutsikter för att exkludera vissa branscher.",
			value: "Hållbarhet är en mycket viktig fråga i min portfölj och jag kan tänka mig att sänka kraven på risk och avkastningsutsikter för att exkludera vissa branscher.",
			checked: false
		}
	];

	static additionalPurposeOptions = [
		{
			label: "Inga - Avkastning är i fokus.",
			value: "Inga - Avkastning är i fokus.",
			checked: false
		},
		{
			label: "Grundläggande hållbarhet (ESG).",
			value: "Grundläggande hållbarhet (ESG).",
			checked: false
		},
		{
			label: "Djupgående hållbarhet (SRI).",
			value: "Djupgående hållbarhet (SRI).",
			checked: false
		},
		{
			label: "Stark påverkan (Impact Investing).",
			value: "Stark påverkan (Impact Investing).",
			checked: false
		}
	];

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
					onClick: () =>
					{
						if (this.state.isFulfilled === true)
						{
							return;
						}

						this.setState({hasFormValidationErrors: true});
						return setAppState({
							showValidationWarning: true
						});
					}
				}
			}
		});
	}

	render()
	{
		const {customerState, hasFormValidationErrors} = this.state;
		const classes = createClassName("Cob-view Cob-view2", {});

		Purpose.purposeOptions.forEach(function (option)
		{
			option.checked = customerState.rdo_purpose_invcsr === option.value;
		});

		Purpose.additionalPurposeOptions.forEach(function (option)
		{
			option.checked = customerState.rdo_purpose_additional_invcsr === option.value;
		});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>{i18n("purpose", "purpose")}</h2>

					<div className="Cob-view2-fields">

						<h3>Syfte och hjärtefrågor med investeringarna</h3>

						<h4>Vad är syftet och målet med investeringarna?*</h4>
						<TextView
							name="purpose_investment"
							label={`Ange (${800 - customerState.purpose_investment.length} tecken kvar)`}
							value={customerState.purpose_investment}
							maxLength={800}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({purpose_investment: target.value})}
							fullWidth
							minHeight={120}
							highlightErrors={hasFormValidationErrors}
						/>

						<h4>Har du några “hjärtefrågor” som du vill ska beaktas vid valet av investeringar?</h4>

						<TextView
							name="purpose_corevalues"
							label={`Ange (${800 - customerState.purpose_corevalues.length} tecken kvar)`}
							value={customerState.purpose_corevalues}
							maxLength={800}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({purpose_corevalues: target.value})}
							fullWidth
							minHeight={120}
						/>

						<h4>Utöver avkastningen, finns det kvalitativa mål med investeringarna?*</h4>
						<OptionsList
							type="radio"
							orientation="vertical"
							name="rdo_purpose_additional_invcsr"
							value={customerState.rdo_purpose_additional_invcsr}
							options={Purpose.additionalPurposeOptions}
							validation={value => (value && value.length > 0) || false}
							onChange={(checked, rdo_purpose_additional_invcsr) => this.setCustomerState({rdo_purpose_additional_invcsr})}
							highlightErrors={hasFormValidationErrors}
						/>

						<h4>Vilket påstående nedan beskriver din syn på investeringar och hållbarhetsfrågor bäst?*</h4>
						<OptionsList
							type="radio"
							orientation="vertical"
							name="rdo_purpose_invcsr"
							value={customerState.rdo_purpose_invcsr}
							options={Purpose.purposeOptions}
							validation={value => (value && value.length > 0) || false}
							onChange={(checked, rdo_purpose_invcsr) => this.setCustomerState({rdo_purpose_invcsr})}
							highlightErrors={hasFormValidationErrors}
						/>

						<h4>
							Finns det några övriga aspekter på arbetet med portföljen som Citroneer bör känna till?
						</h4>
						<TextView
							name="purpose_otherconcerns"
							label={`Ange (${200 - customerState.purpose_otherconcerns.length} tecken kvar)`}
							value={customerState.purpose_otherconcerns}
							maxLength={200}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({purpose_otherconcerns: target.value})}
							fullWidth
							minHeight={100}
						/>
					</div>
				</ContentBox>
			</div>
		);
	}
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, viewNo, hasFormValidationErrors} = this.props;
	const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
	const hasBeenSeen = (seenViews.includes(viewNo));

	// if it hasn't been seen add the view to local storage for the specific case
	if (customer.caseId && !hasBeenSeen)
	{
		localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
	}

	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	let initialState = {
		customerState: {
			purpose_investment: customer.getData("purpose_investment") || "",
			purpose_corevalues: customer.getData("purpose_corevalues") || "",
			purpose_otherconcerns: customer.getData("purpose_otherconcerns") || "",
			rdo_purpose_invcsr: customer.getData("rdo_purpose_invcsr") || "",
			rdo_purpose_additional_invcsr: customer.getData("rdo_purpose_additional_invcsr") || ""
		}
	};

	// Patch old values that have been changed
	if (initialState.customerState.rdo_purpose_invcsr === "Mitt huvudfokus är risk och avkastning.")
	{
		initialState.customerState.rdo_purpose_invcsr = "Mitt fokus i investeringar är risk och avkastning.";
	}
	else if (initialState.customerState.rdo_purpose_invcsr === "Jag vill ta hänsyn till hållbarhet men behålla mina risk och avkastningsmål.")
	{
		initialState.customerState.rdo_purpose_invcsr = "Jag vill integrera hållbarhetsaspekter där det är möjligt utan att göra avkall risk och avkastningsutsikter.";
	}
	else if (initialState.customerState.rdo_purpose_invcsr === "Hållbarhet är en viktig fråga för mig och jag kan tänka mig att sänka mina krav på risk och avkastning och avstå från investeringar i vissa tillgångslag och företag.")
	{
		initialState.customerState.rdo_purpose_invcsr = "Hållbarhet är en mycket viktig fråga i min portfölj och jag kan tänka mig att sänka kraven på risk och avkastningsutsikter för att exkludera vissa branscher.";
	}

	return initialState;
}

function _getFields()
{
	return [
		"purpose_investment",
		"purpose_corevalues",
		"purpose_otherconcerns"
	];
}