import React from "react";
// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
// HELPERS
import {createClassName} from "@helpers/utils";
// OTHER
import fulfillment from "./Autogiro.fulfillment";
import OptionsList from "../../../components/OptionsList/OptionsList";
import NumberField from "../../../components/NumberField/NumberField";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import TextField from "../../../../../components/TextField/TextField";
import Select from "../../../../../components/Select/Select";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import Validator from "../../../../../classes/Validator";
import ActivityIndicator from "../../../../../components/ActivityIndicator/ActivityIndicator";
import api from "../../../../../helpers/api";

export default class AutogiroView extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state)
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) callbackFn();
		});
	}

	async componentDidMount() {
		const {setAppState, instruments} = this.props;
		const {customerState} = this.state;

		let instrumentsForState = instruments;
		if (instrumentsForState == null) {
			console.log("Fetching instruments...");
			instrumentsForState = await _setupInstrumentsAsync.call(this);
		}

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
          onClick: () => {
            if (this.state.isFulfilled === true) {
              return;
            }
            
            this.setState({hasFormValidationErrors: true});
            return setAppState({
              showValidationWarning: true
            });
          }
        }
			}
		});

		let fundsList = instrumentsForState;

		let singleIndex_1 = -1, singleIndex_2 = -1, singleIndex_3 = -1, singleIndex_4 = -1,
			monthlyIndex_1 = -1, monthlyIndex_2 = -1, monthlyIndex_3 = -1, monthlyIndex_4 = -1;

		for (let i = 0; i < fundsList.length; i++) {
			if (fundsList[i].isin === customerState.autogiro_single_isin_1) {
				singleIndex_1 = i;
			}

			if (fundsList[i].isin === customerState.autogiro_single_isin_2) {
				singleIndex_2 = i;
			}

			if (fundsList[i].isin === customerState.autogiro_single_isin_3) {
				singleIndex_3 = i;
			}

			if (fundsList[i].isin === customerState.autogiro_single_isin_4) {
				singleIndex_4 = i;
			}

			if (fundsList[i].isin === customerState.autogiro_monthly_isin_1) {
				monthlyIndex_1 = i;
			}

			if (fundsList[i].isin === customerState.autogiro_monthly_isin_2) {
				monthlyIndex_2 = i;
			}

			if (fundsList[i].isin === customerState.autogiro_monthly_isin_3) {
				monthlyIndex_3 = i;
			}

			if (fundsList[i].isin === customerState.autogiro_monthly_isin_4) {
				monthlyIndex_4 = i;
			}
		}

		customerState.checkbox_autogiro_single_1_selected = singleIndex_1;
		customerState.checkbox_autogiro_single_1 = singleIndex_1 > -1;

		customerState.checkbox_autogiro_single_2_selected = singleIndex_2;
		customerState.checkbox_autogiro_single_2 = singleIndex_2 > -1;

		customerState.checkbox_autogiro_single_3_selected = singleIndex_3;
		customerState.checkbox_autogiro_single_3 = singleIndex_3 > -1;

		customerState.checkbox_autogiro_single_4_selected = singleIndex_4;
		customerState.checkbox_autogiro_single_4 = singleIndex_4 > -1;

		customerState.checkbox_autogiro_monthly_1_selected = monthlyIndex_1;
		customerState.checkbox_autogiro_monthly_1 = monthlyIndex_1 > -1;

		customerState.checkbox_autogiro_monthly_2_selected = monthlyIndex_2;
		customerState.checkbox_autogiro_monthly_2 = monthlyIndex_2 > -1;

		customerState.checkbox_autogiro_monthly_3_selected = monthlyIndex_3;
		customerState.checkbox_autogiro_monthly_3 = monthlyIndex_3 > -1;

		customerState.checkbox_autogiro_monthly_4_selected = monthlyIndex_4;
		customerState.checkbox_autogiro_monthly_4 = monthlyIndex_4 > -1;

		this.setState({
			isLoading: false,
			fundsList: fundsList,
			customerState
		});
	}

	render() {
		const {state, props} = this;
		const {customerState, hasFormValidationErrors} = state;
		const {availableBanks} = props;
		const classes = createClassName("Cob-view Cob-autogiro", {});

		if (state.isLoading) {
			return <ActivityIndicator busy/>;
		}

		const textClasses = createClassName("description", {
			"centered": true,
			"contractable": true,
			"expanded": state.textFieldExpanded
		});

		/**
		 * Set up the dropdown with selectable banks.
		 */
		let availableBankOptions = [];
		for (const bankName in availableBanks) {
			if (bankName === 'length' || !availableBanks.hasOwnProperty(bankName)) continue;
			availableBankOptions.push({
				label: bankName,
				value: bankName
			});
		}

		availableBankOptions.push({
			label: "Övriga",
			value: "-"
		});

		let autogiroSingleFundsSelected = 0;
		autogiroSingleFundsSelected += customerState.checkbox_autogiro_single_1 ? 1 : 0;
		autogiroSingleFundsSelected += customerState.checkbox_autogiro_single_2 ? 1 : 0;
		autogiroSingleFundsSelected += customerState.checkbox_autogiro_single_3 ? 1 : 0;
		autogiroSingleFundsSelected += customerState.checkbox_autogiro_single_4 ? 1 : 0;

		let autogiroSingleFundsShare = "";
		if (autogiroSingleFundsSelected === 4) {
			autogiroSingleFundsShare = "25%";
		}
		else if (autogiroSingleFundsSelected === 3) {
			autogiroSingleFundsShare = "33,3%";
		}
		else if (autogiroSingleFundsSelected === 2) {
			autogiroSingleFundsShare = "50%";
		}
		else if (autogiroSingleFundsSelected === 1) {
			autogiroSingleFundsShare = "100%";
		}

		let autogiroMonthlyFundsSelected = 0;
		autogiroMonthlyFundsSelected += customerState.checkbox_autogiro_monthly_1 ? 1 : 0;
		autogiroMonthlyFundsSelected += customerState.checkbox_autogiro_monthly_2 ? 1 : 0;
		autogiroMonthlyFundsSelected += customerState.checkbox_autogiro_monthly_3 ? 1 : 0;
		autogiroMonthlyFundsSelected += customerState.checkbox_autogiro_monthly_4 ? 1 : 0;

		let autogiroMonthlyFundsShare = "";
		if (autogiroMonthlyFundsSelected === 4) {
			autogiroMonthlyFundsShare = "25%";
		}
		else if (autogiroMonthlyFundsSelected === 3) {
			autogiroMonthlyFundsShare = "33,3%";
		}
		else if (autogiroMonthlyFundsSelected === 2) {
			autogiroMonthlyFundsShare = "50%";
		}
		else if (autogiroMonthlyFundsSelected === 1) {
			autogiroMonthlyFundsShare = "100%";
		}

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Autogiro</h2>

					<div className="Cob-autogiro-fields">

						<h3>Autogiromedgivande</h3>

						<div className={textClasses}>
							<strong>
								För att kunden ska kunna sätta in och ta ut pengar, samt månadsspara via Autogiro
								måste nedan Autogiromedgivande fyllas i:
							</strong>
							<p>
								Undertecknad (”betalaren”), medger att betalning får göras genom uttag från angivet
								konto eller av betalaren senare angivet Konto, på begäran av angiven betalningsmottagare
								för betalning till denne på viss dag (”förfallodagen”) via Autogiro. Betalaren medger
								att insättning och uttag från angivet konto eller av betalaren föranmält konto får göras
								på begäran av betalaren. Betalaren samtycker till att behandling av personuppgifter som
								lämnats i detta medgivande behandlas av betalarens betaltjänstleverantör,
								betalningsmottagaren, betalningsmottagarens betaltjänstleverantör och Bankgirocentralen
								BGC AB för administration av tjänsten. Personuppgiftsansvariga för denna
								personuppgiftsbehandling är betalarens betaltjänstleverantör, betalningsmottagaren samt
								betalningsmottagarens betaltjänstleverantör. Betalaren kan när som helst begära att få
								tillgång till eller rättelse av personuppgifterna genom att kontakta betalarens
								betaltjänstleverantör. Ytterligare information om behandling av personuppgifter i
								samband med betalningar kan finnas i villkoren för kontot och i avtalet med
								betalningsmottagaren. Betalaren kan när som helst återkalla sitt samtycke, vilket medför
								att tjänsten i sin helhet avslutas.
							</p>
							<div className="expand">
								<div className="first"></div>
								<div className="second"></div>
								<div className="third" onClick={this._onExpandText}>Expandera texten</div>
							</div>
							<div className="contract" onClick={this._onExpandText}>
								Minimera texten
							</div>
						</div>

						<div className="centered" style={{marginTop: "20px"}}>
							<OptionsList
								type="radio"
								orientation="horizontal"
								name="radio_customer_autogiro"
                value={customerState.radio_customer_autogiro}
								description="Kunden ger sitt medgivande till betalning via autogiro"
								options={[
									{
										label: "Nej",
										value: "no",
										checked: customerState.radio_customer_autogiro === "no"
									},
									{
										label: "Ja",
										value: "yes",
										checked: customerState.radio_customer_autogiro === "yes"
									},
								]}
                validation={value => ((value && value.length > 0) || false)}
								onChange={(checked, radio_customer_autogiro) => this.setCustomerState({radio_customer_autogiro})}
                highlightErrors={hasFormValidationErrors}
							/>

							{customerState.radio_customer_autogiro === "yes" && (
								<React.Fragment>
									<h4 style={{margin: "50px 0 10px 0"}}>Kontouppgifter</h4>

									<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
										<div className="column">
											<Select
												name="customer_autogiro_bank"
												label="Bank"
												selectedIndex={customerState.selectedBankIndex}
												options={availableBankOptions}
												onChange={(option, selectedBankIndex) => {
													this.setCustomerState({
														customer_autogiro_bank: option.value,
														selectedBankIndex
													});
												}}
                        highlightErrors={hasFormValidationErrors}
											/>
										</div>

										<div className="column">
											{customerState.customer_autogiro_bank === '-' && (
												<React.Fragment>
													<TextField
														name="customer_autogiro_bank_custom"
														label="Namn på bank"
														value={customerState.customer_autogiro_bank_custom}
														validation={value => value.length > 0}
														onChange={({target}) => this.setCustomerState({customer_autogiro_bank_custom: target.value})}
                            highlightErrors={hasFormValidationErrors}
													/>
													<input type="hidden" name="customer_autogiro_bank"
														   value={customerState.customer_autogiro_bank_custom}/>
												</React.Fragment>
											)}

											{customerState.customer_autogiro_bank !== '-' && (
												<input type="hidden" name="customer_autogiro_bank_custom" value=""/>
											)}
										</div>
									</StackLayout>

									<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
										<div>
											<NumberField
												name="customer_autogiro_bank_clearingno"
												label="Clearingnummer"
												value={customerState.customer_autogiro_bank_clearingno || ""}
												showSeparators={false}
												delimiter1={""}
												validation={customerState.checkbox_override_validation ? () => true : this._validateClearingNumber}
												onChange={this._onAccountClearingChange}
                        highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<NumberField
												name="customer_autogiro_bank_no"
												label="Depå-/kontonummer"
												value={customerState.customer_autogiro_bank_no || ""}
												showSeparators={false}
												delimiter1={""}
												validation={customerState.checkbox_override_validation ? () => true : this._numberValidation}
												onChange={this._onAccountNumberChange}
                        highlightErrors={hasFormValidationErrors}
											/>
										</div>
									</StackLayout>

									{customerState.customer_autogiro_bank === 'Swedbank' && (
										<p style={{
											background: "#FFFFD5",
											padding: "10px",
											fontSize: "12px",
											margin: "-10px 0 20px 0",
											maxWidth: "600px"
										}}>
											Det förekommer att Swedbank har en femte siffra i clearingnumret. Vänligen
											ange enbart de fyra första innan bindestrecket. Exempel: 8214-9 ska anges som
											8214.
										</p>
									)}

									<div className="column">
										<Checkbox
											style={{margin: "0 0 20px 5px"}}
											type="checkbox"
											name="checkbox_override_validation"
											label="Tillåt konto- och clearingnummer som inte kan valideras"
											value={customerState.checkbox_override_validation}
											checked={customerState.checkbox_override_validation}
											onChange={checked => this.setCustomerState({checkbox_override_validation: checked})}
										/>
									</div>

									<h4 style={{margin: "40px 0 10px 0"}}>
										Vill kunden göra en engångsinsättning eller öppna ett månadssparande via
										autogiro?
									</h4>

									<div className="ag-payments">
										<Checkbox
											label="Kunden vill göra en engångsinsättning via Autogiro"
											name="checkbox_autogiro_single"
											value={customerState.checkbox_autogiro_single}
											checked={customerState.checkbox_autogiro_single}
											onChange={checked => this.setCustomerState({
                        checkbox_autogiro_single: checked,
                        ...(!checked && {
                          checkbox_autogiro_single_1: false,
                          checkbox_autogiro_single_2: false,
                          checkbox_autogiro_single_3: false,
                          checkbox_autogiro_single_4: false,
                        })
                      })}
											style={{margin: "20px 0"}}
										/>

										{customerState.checkbox_autogiro_single && (
											<div
												style={{margin: "0 0 40px 0", width: "100%", alignItems: "flex-start"}}>
												<NumberField
													name="customer_autogiro_amount_onetime"
													label="Belopp engångsinsättning"
													unit=" SEK"
													value={customerState.customer_autogiro_amount_onetime || ""}
													showSeparators={true}
													style={{maxWidth: "340px"}}
													onChange={({target}) => {
														this.setCustomerState({customer_autogiro_amount_onetime: target.value.split(' ').join('')});
													}}
                          highlightErrors={hasFormValidationErrors}
												/>

												<strong style={{margin: "35px 0 15px 2px"}}>Fördela belopp på följande
													fonder:</strong>
												<div style={{margin: "0 0 10px 0", flexDirection: "row"}}>
													<Checkbox
														name="checkbox_autogiro_single_1"
														value={customerState.checkbox_autogiro_single_1}
														checked={customerState.checkbox_autogiro_single_1}
														onChange={checked => this.setCustomerState({checkbox_autogiro_single_1: checked})}
														style={{marginRight: "20px"}}
													/>

													<Select
														className="NameIsinSelect"
														label="Produktnamn & ISIN"
														options={state.fundsList}
														disabled={!customerState.checkbox_autogiro_single_1}
														renderOption={this._renderNameIsinOption}
														renderSelectedOption={this._renderNameIsinOption}
														onChange={(option, selectedIndex) => this.setCustomerState({
															checkbox_autogiro_single_1_selected: selectedIndex
														})}
														selectedIndex={customerState.checkbox_autogiro_single_1_selected}
                            highlightErrors={customerState.checkbox_autogiro_single_1 && hasFormValidationErrors}
													/>

													<input type={"hidden"}
														   name={"customer_allocation_single_fundname_0"}
														   value={customerState.checkbox_autogiro_single_1_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_single_1_selected].name : ""}/>
													<input type={"hidden"}
														   name={"customer_allocation_single_isin_0"}
														   value={customerState.checkbox_autogiro_single_1_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_single_1_selected].isin : ""}/>
													<input type={"hidden"} name={"customer_allocation_single_share_0"}
														   value={autogiroSingleFundsShare}/>

													{!customerState.checkbox_autogiro_single_1 && (
														<React.Fragment>
															<input type={"hidden"}
																   name={"customer_allocation_single_fundname_0"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_single_isin_0"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_single_share_0"}
																   value={""}/>
														</React.Fragment>
													)}
												</div>
												<div style={{margin: "0 0 10px 0", flexDirection: "row"}}>
													<Checkbox
														name="checkbox_autogiro_single_2"
														value={customerState.checkbox_autogiro_single_2}
														checked={customerState.checkbox_autogiro_single_2}
														onChange={checked => this.setCustomerState({checkbox_autogiro_single_2: checked})}
														style={{marginRight: "20px"}}
													/>

													<Select
														className="NameIsinSelect"
														label="Produktnamn & ISIN"
														options={state.fundsList}
														disabled={!customerState.checkbox_autogiro_single_2}
														renderOption={this._renderNameIsinOption}
														renderSelectedOption={this._renderNameIsinOption}
														selectedIndex={customerState.checkbox_autogiro_single_2_selected}
														onChange={(option, selectedIndex) => this.setCustomerState({
															checkbox_autogiro_single_2_selected: selectedIndex
														})}
                            highlightErrors={customerState.checkbox_autogiro_single_2 && hasFormValidationErrors}
													/>

													<input type={"hidden"}
														   name={"customer_allocation_single_fundname_1"}
														   value={customerState.checkbox_autogiro_single_2_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_single_2_selected].name : ""}/>
													<input type={"hidden"}
														   name={"customer_allocation_single_isin_1"}
														   value={customerState.checkbox_autogiro_single_2_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_single_2_selected].isin : ""}/>
													<input type={"hidden"} name={"customer_allocation_single_share_1"}
														   value={autogiroSingleFundsShare}/>

													{!customerState.checkbox_autogiro_single_2 && (
														<React.Fragment>
															<input type={"hidden"}
																   name={"customer_allocation_single_fundname_1"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_single_isin_1"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_single_share_1"}
																   value={""}/>
														</React.Fragment>
													)}
												</div>
												<div style={{margin: "0 0 10px 0", flexDirection: "row"}}>
													<Checkbox
														name="checkbox_autogiro_single_3"
														value={customerState.checkbox_autogiro_single_3}
														checked={customerState.checkbox_autogiro_single_3}
														onChange={checked => this.setCustomerState({checkbox_autogiro_single_3: checked})}
														style={{marginRight: "20px"}}
													/>

													<Select
														className="NameIsinSelect"
														label="Produktnamn & ISIN"
														options={state.fundsList}
														disabled={!customerState.checkbox_autogiro_single_3}
														renderOption={this._renderNameIsinOption}
														renderSelectedOption={this._renderNameIsinOption}
														selectedIndex={customerState.checkbox_autogiro_single_3_selected}
														onChange={(option, selectedIndex) => this.setCustomerState({
															checkbox_autogiro_single_3_selected: selectedIndex
														})}
                            highlightErrors={customerState.checkbox_autogiro_single_3 && hasFormValidationErrors}
													/>

													<input type={"hidden"}
														   name={"customer_allocation_single_fundname_2"}
														   value={customerState.checkbox_autogiro_single_3_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_single_3_selected].name : ""}/>
													<input type={"hidden"}
														   name={"customer_allocation_single_isin_2"}
														   value={customerState.checkbox_autogiro_single_3_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_single_3_selected].isin : ""}/>
													<input type={"hidden"} name={"customer_allocation_single_share_2"}
														   value={autogiroSingleFundsShare}/>

													{!customerState.checkbox_autogiro_single_3 && (
														<React.Fragment>
															<input type={"hidden"}
																   name={"customer_allocation_single_fundname_2"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_single_isin_2"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_single_share_2"}
																   value={""}/>
														</React.Fragment>
													)}
												</div>
												<div style={{margin: "0 0 10px 0", flexDirection: "row"}}>
													<Checkbox
														name="checkbox_autogiro_single_4"
														value={customerState.checkbox_autogiro_single_4}
														checked={customerState.checkbox_autogiro_single_4}
														onChange={checked => this.setCustomerState({checkbox_autogiro_single_4: checked})}
														style={{marginRight: "20px"}}
													/>

													<Select
														className="NameIsinSelect"
														label="Produktnamn & ISIN"
														options={state.fundsList}
														disabled={!customerState.checkbox_autogiro_single_4}
														renderOption={this._renderNameIsinOption}
														renderSelectedOption={this._renderNameIsinOption}
														selectedIndex={customerState.checkbox_autogiro_single_4_selected}
														onChange={(option, selectedIndex) => this.setCustomerState({
															checkbox_autogiro_single_4_selected: selectedIndex
														})}
                            highlightErrors={customerState.checkbox_autogiro_single_4 && hasFormValidationErrors}
													/>

													<input type={"hidden"}
														   name={"customer_allocation_single_fundname_3"}
														   value={customerState.checkbox_autogiro_single_4_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_single_4_selected].name : ""}/>
													<input type={"hidden"}
														   name={"customer_allocation_single_isin_3"}
														   value={customerState.checkbox_autogiro_single_4_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_single_4_selected].isin : ""}/>
													<input type={"hidden"} name={"customer_allocation_single_share_3"}
														   value={autogiroSingleFundsShare}/>

													{!customerState.checkbox_autogiro_single_4 && (
														<React.Fragment>
															<input type={"hidden"}
																   name={"customer_allocation_single_fundname_3"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_single_isin_3"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_single_share_3"}
																   value={""}/>
														</React.Fragment>
													)}
												</div>

												<div className="h-line spacing"/>
											</div>
										)}

										{!customerState.checkbox_autogiro_single && (
											<React.Fragment>
												<input type={"hidden"} name={"customer_allocation_single_fundname_0"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_single_isin_0"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_single_share_0"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_single_fundname_1"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_single_isin_1"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_single_share_1"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_single_fundname_2"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_single_isin_2"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_single_share_2"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_single_fundname_3"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_single_isin_3"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_single_share_3"}
													   value={""}/>
											</React.Fragment>
										)}

										<Checkbox
											label="Kunden vill starta ett månadssparande via Autogiro"
											name="checkbox_autogiro_monthly"
											value={customerState.checkbox_autogiro_monthly}
											checked={customerState.checkbox_autogiro_monthly}
											onChange={checked => this.setCustomerState({
                        checkbox_autogiro_monthly: checked,                        
                        ...(!checked && {
                          checkbox_autogiro_monthly_1: false,
                          checkbox_autogiro_monthly_2: false,
                          checkbox_autogiro_monthly_3: false,
                          checkbox_autogiro_monthly_4: false,
                        })
                      })}
											style={{margin: "0 0 20px 0"}}
										/>

										{customerState.checkbox_autogiro_monthly && (
											<div
												style={{margin: "0 0 40px 0", width: "100%", alignItems: "flex-start"}}>
												<NumberField
													name="customer_autogiro_amount_monthly"
													label="Belopp månadssparande"
													unit=" SEK"
													value={customerState.customer_autogiro_amount_monthly || ""}
													showSeparators={true}
													onChange={({target}) => {
														this.setCustomerState({customer_autogiro_amount_monthly: target.value.split(' ').join('')});
													}}
                          highlightErrors={hasFormValidationErrors}
												/>

												<strong style={{margin: "35px 0 15px 2px"}}>
													Fördela belopp på följande fonder:</strong>
												<div style={{margin: "0 0 10px 0", flexDirection: "row"}}>
													<Checkbox
														name="checkbox_autogiro_monthly_1"
														value={customerState.checkbox_autogiro_monthly_1}
														checked={customerState.checkbox_autogiro_monthly_1}
														onChange={checked => this.setCustomerState({checkbox_autogiro_monthly_1: checked})}
														style={{marginRight: "20px"}}
													/>

													<Select
														className="NameIsinSelect"
														label="Produktnamn & ISIN"
														options={state.fundsList}
														disabled={!customerState.checkbox_autogiro_monthly_1}
														renderOption={this._renderNameIsinOption}
														renderSelectedOption={this._renderNameIsinOption}
														onChange={(option, selectedIndex) => this.setCustomerState({
															checkbox_autogiro_monthly_1_selected: selectedIndex
														})}
														selectedIndex={customerState.checkbox_autogiro_monthly_1_selected}
                            highlightErrors={customerState.checkbox_autogiro_monthly_1 && hasFormValidationErrors}
													/>

													<input type={"hidden"}
														   name={"customer_allocation_fundname_0"}
														   value={customerState.checkbox_autogiro_monthly_1_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_monthly_1_selected].name : ""}/>
													<input type={"hidden"}
														   name={"customer_allocation_isin_0"}
														   value={customerState.checkbox_autogiro_monthly_1_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_monthly_1_selected].isin : ""}/>
													<input type={"hidden"} name={"customer_allocation_share_0"}
														   value={autogiroMonthlyFundsShare}/>

													{!customerState.checkbox_autogiro_monthly_1 && (
														<React.Fragment>
															<input type={"hidden"}
																   name={"customer_allocation_fundname_0"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_isin_0"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_share_0"}
																   value={""}/>
														</React.Fragment>
													)}
												</div>
												<div style={{margin: "0 0 10px 0", flexDirection: "row"}}>
													<Checkbox
														name="checkbox_autogiro_monthly_2"
														value={customerState.checkbox_autogiro_monthly_2}
														checked={customerState.checkbox_autogiro_monthly_2}
														onChange={checked => this.setCustomerState({checkbox_autogiro_monthly_2: checked})}
														style={{marginRight: "20px"}}
													/>

													<Select
														className="NameIsinSelect"
														label="Produktnamn & ISIN"
														options={state.fundsList}
														disabled={!customerState.checkbox_autogiro_monthly_2}
														renderOption={this._renderNameIsinOption}
														renderSelectedOption={this._renderNameIsinOption}
														selectedIndex={customerState.checkbox_autogiro_monthly_2_selected}
														onChange={(option, selectedIndex) => this.setCustomerState({
															checkbox_autogiro_monthly_2_selected: selectedIndex
														})}
                            highlightErrors={customerState.checkbox_autogiro_monthly_2 && hasFormValidationErrors}
													/>

													<input type={"hidden"}
														   name={"customer_allocation_fundname_1"}
														   value={customerState.checkbox_autogiro_monthly_2_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_monthly_2_selected].name : ""}/>
													<input type={"hidden"}
														   name={"customer_allocation_isin_1"}
														   value={customerState.checkbox_autogiro_monthly_2_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_monthly_2_selected].isin : ""}/>
													<input type={"hidden"} name={"customer_allocation_share_1"}
														   value={autogiroMonthlyFundsShare}/>

													{!customerState.checkbox_autogiro_monthly_2 && (
														<React.Fragment>
															<input type={"hidden"}
																   name={"customer_allocation_fundname_1"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_isin_1"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_share_1"}
																   value={""}/>
														</React.Fragment>
													)}
												</div>
												<div style={{margin: "0 0 10px 0", flexDirection: "row"}}>
													<Checkbox
														name="checkbox_autogiro_monthly_3"
														value={customerState.checkbox_autogiro_monthly_3}
														checked={customerState.checkbox_autogiro_monthly_3}
														onChange={checked => this.setCustomerState({checkbox_autogiro_monthly_3: checked})}
														style={{marginRight: "20px"}}
													/>

													<Select
														className="NameIsinSelect"
														label="Produktnamn & ISIN"
														options={state.fundsList}
														disabled={!customerState.checkbox_autogiro_monthly_3}
														renderOption={this._renderNameIsinOption}
														renderSelectedOption={this._renderNameIsinOption}
														selectedIndex={customerState.checkbox_autogiro_monthly_3_selected}
														onChange={(option, selectedIndex) => this.setCustomerState({
															checkbox_autogiro_monthly_3_selected: selectedIndex
														})}
                            highlightErrors={customerState.checkbox_autogiro_monthly_3 && hasFormValidationErrors}
													/>

													<input type={"hidden"}
														   name={"customer_allocation_fundname_2"}
														   value={customerState.checkbox_autogiro_monthly_3_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_monthly_3_selected].name : ""}/>
													<input type={"hidden"}
														   name={"customer_allocation_isin_2"}
														   value={customerState.checkbox_autogiro_monthly_3_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_monthly_3_selected].isin : ""}/>
													<input type={"hidden"} name={"customer_allocation_share_2"}
														   value={autogiroMonthlyFundsShare}/>

													{!customerState.checkbox_autogiro_monthly_3 && (
														<React.Fragment>
															<input type={"hidden"}
																   name={"customer_allocation_fundname_2"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_isin_2"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_share_2"}
																   value={""}/>
														</React.Fragment>
													)}
												</div>
												<div style={{margin: "0 0 10px 0", flexDirection: "row"}}>
													<Checkbox
														name="checkbox_autogiro_monthly_4"
														value={customerState.checkbox_autogiro_monthly_4}
														checked={customerState.checkbox_autogiro_monthly_4}
														onChange={checked => this.setCustomerState({checkbox_autogiro_monthly_4: checked})}
														style={{marginRight: "20px"}}
													/>

													<Select
														className="NameIsinSelect"
														label="Produktnamn & ISIN"
														options={state.fundsList}
														disabled={!customerState.checkbox_autogiro_monthly_4}
														renderOption={this._renderNameIsinOption}
														renderSelectedOption={this._renderNameIsinOption}
														selectedIndex={customerState.checkbox_autogiro_monthly_4_selected}
														onChange={(option, selectedIndex) => this.setCustomerState({
															checkbox_autogiro_monthly_4_selected: selectedIndex
														})}
                            highlightErrors={customerState.checkbox_autogiro_monthly_4 && hasFormValidationErrors}
													/>

													<input type={"hidden"}
														   name={"customer_allocation_fundname_3"}
														   value={customerState.checkbox_autogiro_monthly_4_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_monthly_4_selected].name : ""}/>
													<input type={"hidden"}
														   name={"customer_allocation_isin_3"}
														   value={customerState.checkbox_autogiro_monthly_4_selected > -1 ? state.fundsList[customerState.checkbox_autogiro_monthly_4_selected].isin : ""}/>
													<input type={"hidden"} name={"customer_allocation_share_3"}
														   value={autogiroMonthlyFundsShare}/>

													{!customerState.checkbox_autogiro_monthly_4 && (
														<React.Fragment>
															<input type={"hidden"}
																   name={"customer_allocation_fundname_3"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_isin_3"}
																   value={""}/>
															<input type={"hidden"}
																   name={"customer_allocation_share_3"}
																   value={""}/>
														</React.Fragment>
													)}
												</div>

												<div className="h-line spacing"/>
											</div>
										)}

										{!customerState.checkbox_autogiro_monthly && (
											<React.Fragment>
												<input type={"hidden"} name={"customer_allocation_fundname_0"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_isin_0"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_share_0"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_fundname_1"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_isin_1"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_share_1"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_fundname_2"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_isin_2"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_share_2"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_fundname_3"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_isin_3"}
													   value={""}/>
												<input type={"hidden"} name={"customer_allocation_share_3"}
													   value={""}/>
											</React.Fragment>
										)}

									</div>

								</React.Fragment>
							)}

						</div>
					</div>
				</ContentBox>
			</div>
		);
	}

	_renderNameIsinOption = ({name, isin}) => (
		<React.Fragment>
			<div className="name">{name}</div>
			<div className="isin">{isin}</div>
		</React.Fragment>
	);

	_onExpandText = () => {
		this.setState({
			textFieldExpanded: !this.state.textFieldExpanded
		});
	};

	_onAccountClearingChange = ({target}) => {
		// Remove all non-numbers
		let value = target.value + "";
		value = value.replace(/[^0-9]+/g, "");
		value = value.replace(/\+/g, "");
		value = value.replace(/-/g, "");

		// Limit clearing numbers to 4 numbers, we'll show a message that says to enter only first four for the
		// banks with longer numbers
		if (value.length >= 4) {
			value = value.substring(0, 4);
		}

		this.setCustomerState({customer_autogiro_bank_clearingno: value});
	};

	_validateClearingNumber = (value) => {
    const {customerState} = this.state;
		if (customerState.checkbox_override_validation || customerState.customer_autogiro_bank === '-') {
			return true;
		}

		return Validator.validateClearingNumber(value, customerState.customer_autogiro_bank, this.props.availableBanks);
	};

	_numberValidation = (value) => {
    const {customerState} = this.state;
		if (customerState.checkbox_override_validation || customerState.customer_autogiro_bank === '-') {
			return true;
		}

		if (value.length < 5 || isNaN(value)) {
			return false;
		}

		/**
		 * For the banks where we know the validation rules, check if the number should be validated.
		 */
		let shouldValidateAccountNumber = false;
		const availableBanks = this.props.availableBanks;
		for (const bankName in availableBanks) {
			if (bankName === 'length' || !availableBanks.hasOwnProperty(bankName)) continue;

			if (customerState.customer_autogiro_bank === bankName) {
				const bankInfo = availableBanks[bankName];
				if (bankInfo.length > 0) {
					if (bankInfo[0]['validationEnabled'] === "1") {
						shouldValidateAccountNumber = true;
					}
				}
				break;
			}
		}

		if (!shouldValidateAccountNumber) {
			return true;
		}

		let bankNoToValidate = customerState.customer_autogiro_bank_clearingno + ", " + value;
		let kontonummer = window.kontonummer(bankNoToValidate);
		return kontonummer !== false;
	};

	_onAccountNumberChange = ({target}) => !isNaN(target.value) ? this.setCustomerState({customer_autogiro_bank_no: target.value}) : undefined;
}


// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, viewNo, hasFormValidationErrors} = this.props;
  const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
  const hasBeenSeen = (seenViews.includes(viewNo));   
  // if it hasn't been seen add the view to local storage for the specific case
  if (customer.caseId && !hasBeenSeen) {
    localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
  }

	let newState = getViewInitialStateForCustomer(customer);  
	newState.isFulfilled = this.isFulfilled(newState);  
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;

	let selectedBankIndex = -1;
	if (newState.customerState.customer_autogiro_bank && newState.customerState.customer_autogiro_bank.length > 0) {
		let bankFoundInList = false;
		for (const bankName in this.props.availableBanks) {
			if (bankName === 'length' || !this.props.availableBanks.hasOwnProperty(bankName)) {
				continue;
			}

			selectedBankIndex++;
			if (bankName === newState.customerState.customer_autogiro_bank) {
				bankFoundInList = true;
				break;
			}
		}

		/**
		 * No bank found => Övrig bank
		 */
		if (!bankFoundInList) {
			selectedBankIndex++;
			newState.customerState.customer_autogiro_bank_custom = newState.customerState.customer_autogiro_bank;
			newState.customerState.customer_autogiro_bank = "-";
		}
	}

	newState.customerState.selectedBankIndex = selectedBankIndex;
	newState.availableBanks = this.props.availableBanks;

	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	let state = {
		isLoading: true,
		isFulfilled: false,
		fundsList: {},
		customerState: {
			textFieldExpanded: false,
			radio_customer_autogiro: customer.getData("radio_customer_autogiro"),
			customer_autogiro_amount_monthly: customer.getData("customer_autogiro_amount_monthly"),
			customer_autogiro_amount_onetime: customer.getData("customer_autogiro_amount_onetime"),

			customer_autogiro_bank: customer.getData("customer_autogiro_bank"),
			customer_autogiro_bank_clearingno: customer.getData("customer_autogiro_bank_clearingno"),
			customer_autogiro_bank_no: customer.getData("customer_autogiro_bank_no"),
			checkbox_override_validation: (customer.getData("checkbox_override_validation") === "true"),

			checkbox_autogiro_single: (customer.getData("checkbox_autogiro_single") === "true"),
			checkbox_autogiro_monthly: (customer.getData("checkbox_autogiro_monthly") === "true"),

			autogiro_single_isin_1: customer.getData("customer_allocation_single_isin_0"),
			autogiro_single_isin_2: customer.getData("customer_allocation_single_isin_1"),
			autogiro_single_isin_3: customer.getData("customer_allocation_single_isin_2"),
			autogiro_single_isin_4: customer.getData("customer_allocation_single_isin_3"),

			checkbox_autogiro_single_1_selected: -1,
			checkbox_autogiro_single_2_selected: -1,
			checkbox_autogiro_single_3_selected: -1,
			checkbox_autogiro_single_4_selected: -1,

			checkbox_autogiro_single_1: (customer.getData("checkbox_autogiro_single_1") === "true"),
			checkbox_autogiro_single_2: (customer.getData("checkbox_autogiro_single_2") === "true"),
			checkbox_autogiro_single_3: (customer.getData("checkbox_autogiro_single_3") === "true"),
			checkbox_autogiro_single_4: (customer.getData("checkbox_autogiro_single_4") === "true"),

			autogiro_monthly_isin_1: customer.getData("customer_allocation_isin_0"),
			autogiro_monthly_isin_2: customer.getData("customer_allocation_isin_1"),
			autogiro_monthly_isin_3: customer.getData("customer_allocation_isin_2"),
			autogiro_monthly_isin_4: customer.getData("customer_allocation_isin_3"),

			checkbox_autogiro_monthly_1_selected: -1,
			checkbox_autogiro_monthly_2_selected: -1,
			checkbox_autogiro_monthly_3_selected: -1,
			checkbox_autogiro_monthly_4_selected: -1,

			checkbox_autogiro_monthly_1: (customer.getData("checkbox_autogiro_monthly_1") === "true"),
			checkbox_autogiro_monthly_2: (customer.getData("checkbox_autogiro_monthly_2") === "true"),
			checkbox_autogiro_monthly_3: (customer.getData("checkbox_autogiro_monthly_3") === "true"),
			checkbox_autogiro_monthly_4: (customer.getData("checkbox_autogiro_monthly_4") === "true")
		}
	};

	// Loop over fund rows and set the selected index

	return state;
}

function _getFields() {
	return [
		"radio_customer_autogiro",
		"customer_autogiro_amount_monthly",
		"customer_autogiro_amount_onetime",

		"customer_autogiro_bank",
		"customer_autogiro_bank_custom",
		"customer_autogiro_bank_clearingno",
		"customer_autogiro_bank_no",
		"checkbox_autogiro_single",
    "checkbox_autogiro_single_1",
    "checkbox_autogiro_single_2",
    "checkbox_autogiro_single_3",
    "checkbox_autogiro_single_4",
		"checkbox_autogiro_monthly",
    "checkbox_autogiro_monthly_1",
    "checkbox_autogiro_monthly_2",
    "checkbox_autogiro_monthly_3",
    "checkbox_autogiro_monthly_4"
	];
}


function _setupInstrumentsAsync() {
	return new Promise(async (resolve, reject) => {
		try {
			const instrumentsByTypeNumber = await api("/bfs/instruments");

			// const typeLabels = [
			// 	"Aktier",
			// 	"Fonder",
			// 	"ETF:er",
			// 	"Futures",
			// 	"Optioner",
			// 	"Warranter",
			// 	"Certifikat",
			// 	"Råvaror",
			// 	"Index",
			// 	"Obligationer",
			// 	"Ränteprodukter",
			// 	"Strukturerade produkter",
			// 	"Lån"
			// ];

			const mergeNameWithIsin = (name, isin) => {
				name = name.length > 40 ? name.substring(0, 40).trim() + "…" : name;
				return name + " | " + isin;
			};

			// // Map instruments with type labels
			// const result = {};
			// for (let typeNumber in instrumentsByTypeNumber) {
			// 	typeNumber = parseFloat(typeNumber, 10);
			// 	const typeLabel = typeLabels[typeNumber - 1];
			// 	const instruments = instrumentsByTypeNumber[typeNumber].map(instrument => {
			// 		const optionValue = mergeNameWithIsin(instrument.name, instrument.isin);
			// 		return {
			// 			...instrument,
			// 			typeNumber,
			// 			label: optionValue,
			// 			value: optionValue,
			// 			title: instrument.name + " | " + instrument.isin
			// 		};
			// 	});

			// 	result[typeLabel] = instruments;
			// }

			for (let typeNumber in instrumentsByTypeNumber) {
        const instrument = instrumentsByTypeNumber[typeNumber];
        const optionValue = mergeNameWithIsin(instrument.name, instrument.isin);
        instrument.label = optionValue;
        instrument.value = optionValue;
        instrument.value = instrument.name + " | " + instrument.isin;
			}

			resolve(instrumentsByTypeNumber);
		}
		catch (e) {
			console.error(e.message);
			reject();
		}
	});
}