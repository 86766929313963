import React from "react";

// CLASSES
import Validator from "@classes/Validator";

// COMPONENTS
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import Button from "@components/Button/Button";
import Checkbox from "@components/Checkbox/Checkbox";
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import Modal from "@components/Modal/Modal";
import Select from "@components/Select/Select";
import StackLayout from "@components/layouts/StackLayout/StackLayout";
import TextField from "@components/TextField/TextField";

// HELPERS
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";
import countries from "@helpers/countries";

// OTHER
import fulfillment from "./CustomerInformation.fulfillment";
import OptionsList from "../../../components/OptionsList/OptionsList";
import LeiCodeField from "../../../components/LeiCode/LeiCodeField";
import LeiCodeFieldFulfillment from "../../../components/LeiCode/LeiCodeField.fulfillment";
import TextView from "../../../../../components/TextView/TextView";
import PartnerHiddenFields from "../../../components/PartnerHiddenFields/PartnerHiddenFields";

export default class CustomerInformation extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);
	countries = countries.map(({label, code}) => ({label, value: label}));
	saveHorizonValues = [
		{label: "Kortare än 3 år", value: "<3"},
		{label: "Minst 3 år", value: "3+"},
		{label: "Minst 4 år", value: "4+"},
		{label: "Minst 5 år", value: "5+"},
		{label: "6 till 10 år", value: "6-10"},
		{label: "Över 10 år", value: ">10"}
	];

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	setLookupInfo(lookupInfo)
	{
		const {props, state} = this;
		const {customerState} = state;
		const {isCompany} = props.customer;

		const clientInfoPhone = lookupInfo.client.phone && lookupInfo.client.phone !== "-" ? lookupInfo.client.phone : "";
		const clientInfoPhoneAlt = lookupInfo.client.phone_alternative && lookupInfo.client.phone_alternative !== "-" ? lookupInfo.client.phone_alternative : "";

		this.setState({lookupInfo: lookupInfo}, () =>
		{
			this.setCustomerState({
				firstName: customerState.firstName || lookupInfo[isCompany ? "companyName" : "firstName"] || "",
				lastName: isCompany ? "" : (customerState.lastName || lookupInfo.lastName || ""),
				street: customerState.street || lookupInfo.street || "",
				zipCode: customerState.zipCode || lookupInfo.zipCode || "",
				city: customerState.city || lookupInfo.city || "",
				email: customerState.email || lookupInfo.email || lookupInfo.client.email || "",
				phoneNumber: customerState.phoneNumber || lookupInfo.phoneNumber || clientInfoPhone || "",
				phoneNumberHome: customerState.phoneNumberHome || lookupInfo.phoneNumberHome || clientInfoPhoneAlt || "",
				leiCodeValid: LeiCodeFieldFulfillment.call(this, LeiCodeField.getViewInitialStateForCustomer(props.customer, lookupInfo, false), null)
			});
		});
	}

	async componentDidMount()
	{
		const {props} = this;
		const {customer, history, setAppState} = props;
		const ssnOrg = customer.getData("customer_social_security_number", null);

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {disabled: true},
				next: {
					onClick: () =>
					{
						if (this.state.isFulfilled === true)
						{
							return;
						}

						this.setState({hasFormValidationErrors: true});
						return setAppState({
							showValidationWarning: true
						});
					}
				}
			}
		});

		if (!!ssnOrg)
		{
			if (!customer.lookupInfo)
			{
				try
				{
					await customer.lookupAsync();
					const lookupNotFound = customer.lookupInfo.type === undefined;
					this.setState({
						isLoading: false,
						lookupInfo: null,
						lookupNotFound
					}, () => this.setLookupInfo(customer.lookupInfo));
				}
				catch (e)
				{
					if (e.message === 'FRAUD_FLAG')
					{
						console.warn("Customer information locked. Case can not be completed.");
						this.setState({isLoading: false, lookupInfo: null, lookupNotFound: true, fraudFlag: true});
					}
					else
					{
						console.warn("Customer information not found. Information must be entered manually.");
						this.setState({isLoading: false, lookupInfo: null, lookupNotFound: true, fraudFlag: false});
					}
				}
			}
			else
			{
				this.setState({isLoading: false, lookupInfo: null}, () => this.setLookupInfo(customer.lookupInfo));
			}
		}
		else
		{
			history.push("/cob/1");
		}
	}

	render()
	{
		const {props, state} = this;
		const {user, customer, onRestartSession} = props;
		const {customerState, lookupNotFound, hasFormValidationErrors} = state;
		const classes = createClassName("Cob-view Cob-view2", {});

		if (state.isLoading)
		{
			return <ActivityIndicator busy/>;
		}

		const companySignatoriesForDisplay = [];

		if (customer.isCompany)
		{
			for (let i = 0; i < customerState.companySignatories; i++)
			{
				const fieldNameIndex = i + 1;
				const fieldNameSuffix = fieldNameIndex === 1 ? "" : ("_" + fieldNameIndex);

				companySignatoriesForDisplay.push(
					<div className="Cob-view2-CompanyReps-Rep" style={{paddingBottom: "0"}} key={fieldNameIndex}>
						<h4 style={{marginBottom: "5px"}}>Fullmaktstagare/firmatecknare {fieldNameIndex}</h4>
						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<TextField
									name={`customer_inv_poa_firstname${fieldNameSuffix}`}
									label="Förnamn"
									autocomplete="nope"
									value={customerState[`customer_inv_poa_firstname${fieldNameSuffix}`]}
									validation={this._textValidation}
									onChange={({target}) => this.setCustomerState({[`customer_inv_poa_firstname${fieldNameSuffix}`]: target.value})}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									name={`customer_inv_poa_lastname${fieldNameSuffix}`}
									label="Efternamn"
									autocomplete="nope"
									value={customerState[`customer_inv_poa_lastname${fieldNameSuffix}`]}
									validation={this._textValidation}
									onChange={({target}) => this.setCustomerState({[`customer_inv_poa_lastname${fieldNameSuffix}`]: target.value})}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									name={`customer_inv_poa_ssn${fieldNameSuffix}`}
									label="Personnummer"
									autocomplete="nope"
									value={customerState[`customer_inv_poa_ssn${fieldNameSuffix}`]}
									validation={this._ssnValidation}
									onChange={({target}) => this.setCustomerState({[`customer_inv_poa_ssn${fieldNameSuffix}`]: target.value})}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
						</StackLayout>
					</div>
				);
			}
		}

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>
				<PartnerHiddenFields customer={customer} user={user} />
				<input type="hidden" name="customer_phonenumber_home" value={customerState.phoneNumberHome || "-"}/>
				<input type="hidden" name="customer_phonenumber_cell" value={customerState.phoneNumber || "-"}/>
				<input type="hidden" name="radio_customer_type" value={customer.isCompany ? "company" : "person"}/>

				<Modal visible={state.fraudFlag} status="danger" title="Personnummer låst">
					<React.Fragment>
						<p>
							Kundens personnummer är låst via en extern ID-spärr.
							Vänligen kontakta Fair Investments.
						</p>

						<Modal.ButtonsContainer>
							<Modal.Button label="Avsluta rådgivning" appearance="primary"
										  onClick={onRestartSession} filled/>
						</Modal.ButtonsContainer>
					</React.Fragment>
				</Modal>

				<ContentBox>
					<h2>Kundinformation</h2>

					<div className="Cob-view2-fields">

						<h3>Grunduppgifter</h3>

						{lookupNotFound && !customer.isCompany && (
							<React.Fragment>
								<StackLayout orientation="vertical" alignItems="start">
									<Checkbox
										name="checkbox_customer_foreigner"
										label={i18n("cob", "lives_overseas_foreign_citizen")}
										checked={customerState.isForeigner}
										onChange={isForeigner => this.setCustomerState({isForeigner})}
									/>
									{customerState.isForeigner && (
										<Checkbox
											style={{marginTop: 10}}
											label="Är bosatt i EU"
											checked={customerState.isNonEuPerson}
											onChange={
												isNonEuPerson => this.setState({
													errorMessage: "För hantering av personer bosatta utanför EU, var god kontakta backoffice."
												}, () => this.setCustomerState({isNonEuPerson}))
											}
										/>
									)}
								</StackLayout>
							</React.Fragment>
						)}

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div style={{alignSelf: "flex-start"}}>
								<TextField
									name="customer_firstname"
									value={customerState.firstName}
									autocomplete="nope"
									label={i18n("general", customer.isCompany ? "organization_name" : "first_name")}
									validation={lookupNotFound ? value => value.length > 0 : undefined}
									onChange={lookupNotFound ? ({target}) => this.setCustomerState({firstName: target.value}) : undefined}
									readOnly={!lookupNotFound}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>

							{!customer.isCompany && (
								<div style={{alignSelf: "flex-start"}}>
									<TextField
										name="customer_lastname"
										value={customerState.lastName}
										autocomplete="nope"
										label={i18n("general", "last_name")}
										validation={lookupNotFound ? value => value.length > 0 : undefined}
										onChange={lookupNotFound ? ({target}) => this.setCustomerState({lastName: target.value}) : undefined}
										readOnly={!lookupNotFound}
										highlightErrors={hasFormValidationErrors}
									/>
								</div>
							)}

							<div style={{alignSelf: "flex-start"}}>
								<TextField
									name="customer_social_security_number"
									autocomplete="nope"
									value={customerState.ssnOrg}
									label={i18n("general", customer.isCompany ? "organization_number" : "social_security_number")}
									readOnly
								/>
							</div>

							{customer.isCompany && (
								<div style={{alignSelf: "flex-start"}}>
									<LeiCodeField customer={customer}
												  lookupInfo={state.lookupInfo}
												  onUpdated={this._onLeiUpdated}
												  required={false}
												  highlightErrors={hasFormValidationErrors}
									/>
								</div>
							)}
						</StackLayout>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<TextField
									name="customer_address_street"
									value={customerState.street}
									label={i18n("general", "street_address")}
									autocomplete="nope"
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({street: target.value})}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									name="customer_address_zipcode"
									value={customerState.zipCode}
									label={i18n("general", "zip_code")}
									autocomplete="nope"
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({zipCode: target.value})}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									name="customer_address_city"
									value={customerState.city}
									label={i18n("general", "city")}
									autocomplete="nope"
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({city: target.value})}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
							{!customer.isCompany && (
								<React.Fragment>
									<div>
										<Select
											name="customer_address_country"
											label={i18n("general", "country")}
											selectedIndex={customerState.countryIndex}
											options={this.countries}
											onChange={({value: country}, countryIndex) => this.setCustomerState({
												country,
												countryIndex
											})}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
								</React.Fragment>
							)}
							<div>
								<TextField
									name="customer_phonenumber_home_input"
									value={customerState.phoneNumberHome === "-" ? "" : customerState.phoneNumberHome}
									label={"Telefon"}
									autocomplete="nope"
									validation={customerState.phoneNumberHome && customerState.phoneNumberHome !== "-" ? Validator.validatePhoneNum : false}
									placeholder={"+46 XX XXXXXX"}
									onChange={({target}) => this.setCustomerState({phoneNumberHome: target.value === "" ? "-" : target.value})}
								/>
							</div>
							<div>
								<TextField
									name="customer_phonenumber_cell_input"
									value={customerState.phoneNumber === "-" ? "" : customerState.phoneNumber}
									label="Mobiltelefon"
									autocomplete="nope"
									validation={customerState.phoneNumber && customerState.phoneNumber !== "-" ? Validator.validatePhoneNum : false}
									placeholder={"+46 XX XXXXXX"}
									onChange={({target}) => this.setCustomerState({phoneNumber: target.value === "" ? "-" : target.value})}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									type="email"
									name="customer_email"
									value={customerState.email}
									autocomplete="nope"
									label={i18n("general", "email_address")}
									validation={Validator.validateEmail}
									onChange={({target}) => this.setCustomerState({email: target.value})}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
						</StackLayout>

						{!customer.isCompany && (
							<React.Fragment>
								<OptionsList
									type="checkbox"
									description="För vems räkning agerar kunden?"
									orientation="horizontal"
									style={{
										marginBottom: "20px"
									}}
									value={
										customerState.checkbox_customer_kyc_inv_own_part === "yes" ||
										customerState.checkbox_customer_kyc_inv_through_poa === "ja" ?
											"filled" :
											""
									}
									options={[
										{
											name: "checkbox_customer_kyc_inv_own_part",
											label: "För egen räkning",
											value: customerState.checkbox_customer_kyc_inv_own_part === "yes" ? "yes" : "no",
											checked: customerState.checkbox_customer_kyc_inv_own_part === "yes",
											onChange: checked => this.setCustomerState({checkbox_customer_kyc_inv_own_part: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_customer_kyc_inv_through_poa",
											label: "Själv via fullmaktstagare",
											value: customerState.checkbox_customer_kyc_inv_through_poa === "ja" ? "ja" : "nej",
											checked: customerState.checkbox_customer_kyc_inv_through_poa === "ja",
											onChange: checked => this.setCustomerState({checkbox_customer_kyc_inv_through_poa: checked ? "ja" : "nej"})
										}
									]}
									validation={value => (value && value === "filled") || false}
									highlightErrors={hasFormValidationErrors}
								/>
							</React.Fragment>
						)}

						{!customer.isCompany && customerState.checkbox_customer_kyc_inv_through_poa === "ja" && (
							<div style={{width: "100%", marginBottom: "20px", alignItems: "flex-start"}}>
								<strong>Uppgifter för fullmaktstagare</strong>

								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div>
										<TextField
											name="customer_inv_poa_firstname"
											label="Förnamn"
											autocomplete="nope"
											value={customerState.customer_inv_poa_firstname}
											validation={this._textValidation}
											onChange={({target}) => this.setCustomerState({customer_inv_poa_firstname: target.value})}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
									<div>
										<TextField
											name="customer_inv_poa_lastname"
											label="Efternamn"
											autocomplete="nope"
											value={customerState.customer_inv_poa_lastname}
											validation={this._textValidation}
											onChange={({target}) => this.setCustomerState({customer_inv_poa_lastname: target.value})}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
									<div>
										<TextField
											name="customer_inv_poa_ssn"
											label="Personnummer"
											autocomplete="nope"
											value={customerState.customer_inv_poa_ssn}
											validation={this._ssnValidation}
											onChange={({target}) => this.setCustomerState({customer_inv_poa_ssn: target.value})}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
								</StackLayout>
							</div>
						)}

						{customer.isCompany && (
							<div style={{width: "100%", marginBottom: "20px", alignItems: "flex-start"}}>
								<input type="hidden" name="checkbox_customer_kyc_inv_through_poa" value="yes"/>

								<h4>Kund agerar via fullmaktstagare / firmatecknare</h4>

								{companySignatoriesForDisplay}

								<StackLayout fullWidth>
									<Button
										size="small"
										label={i18n("general", "remove_last")}
										appearance={customerState.companySignatories <= 1 ? "medium" : "danger"}
										disabled={customerState.companySignatories <= 1}
										onClick={() => this.setCustomerState({companySignatories: customerState.companySignatories - 1})}
									/>

									<Button
										size="small"
										label="Lägg till firmatecknare"
										appearance={customerState.companySignatories >= 4 ? "medium" : "primary"}
										disabled={customerState.companySignatories >= 4}
										onClick={() => this.setCustomerState({companySignatories: customerState.companySignatories + 1})}
										filled
									/>
								</StackLayout>
							</div>
						)}

						{customerState.needsGuardian && (
							<React.Fragment>
								<div className="h-line spacing"/>

								<h3>Uppgift om vårdnadshavare</h3>
								<p style={{paddingBottom: "10px"}}>
									Då personen är minderårig måste vårdnadshavare anges.
								</p>

								<OptionsList
									style={{paddingBottom: "0", marginBottom: "0"}}
									type="radio"
									name="child_caretaker"
									value={customerState.child_caretaker}
									options={[
										{
											label: "Ensam vårdnadshavare",
											value: "alone",
											checked: customerState.child_caretaker === "alone"
										},
										{
											label: "Två vårdnadshavare",
											value: "two",
											checked: customerState.child_caretaker === "two"
										}
									]}
									onChange={(checked, child_caretaker) => this.setCustomerState({child_caretaker})}
									validation={this._optionsValidation}
									highlightErrors={hasFormValidationErrors}
								/>
							</React.Fragment>
						)}

						{customerState.needsGuardian && customerState.child_caretaker === "alone" && (
							<React.Fragment>
								<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
									<div>
										<h4>Vårdnadshavare</h4>

										<TextField
											name="caretaker_name_1"
											label="Namn"
											autocomplete="nope"
											value={customerState.caretaker_name_1}
											validation={this._textValidation}
											onChange={({target}) => this.setCustomerState({caretaker_name_1: target.value})}
											style={{marginBottom: "10px"}}
											highlightErrors={hasFormValidationErrors}
										/>

										<TextField
											name="caretaker_ssn_1"
											label="Personnr"
											autocomplete="nope"
											value={customerState.caretaker_ssn_1}
											validation={this._ssnValidation}
											onChange={({target}) => this.setCustomerState({caretaker_ssn_1: target.value})}
											highlightErrors={hasFormValidationErrors}
										/>

										<p style={{
											padding: "10px",
											lineHeight: "22px",
											background: "#ffffcc"
										}}>
											Intyg om ensam vårdnadshavare kommer behöva bifogas på sidan "Övriga
											dokument".
										</p>

										<input type="hidden" name="child_caretaker_intyg" value="yes"/>
									</div>
								</StackLayout>
							</React.Fragment>
						)}

						{customerState.needsGuardian && customerState.child_caretaker === "two" && (
							<React.Fragment>
								<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
									<div>
										<h4>Vårdnadshavare 1</h4>

										<TextField
											name="caretaker_name_2"
											label="Namn"
											autocomplete="nope"
											value={customerState.caretaker_name_2}
											validation={this._textValidation}
											onChange={({target}) => this.setCustomerState({caretaker_name_2: target.value})}
											style={{marginBottom: "10px"}}
											highlightErrors={hasFormValidationErrors}
										/>

										<TextField
											name="caretaker_ssn_2"
											label="Personnr"
											autocomplete="nope"
											value={customerState.caretaker_ssn_2}
											validation={this._ssnValidation}
											onChange={({target}) => this.setCustomerState({caretaker_ssn_2: target.value})}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
									<div>
										<h4>Vårdnadshavare 2</h4>

										<TextField
											name="caretaker_name_3"
											label="Namn"
											autocomplete="nope"
											value={customerState.caretaker_name_3}
											validation={this._textValidation}
											onChange={({target}) => this.setCustomerState({caretaker_name_3: target.value})}
											style={{marginBottom: "10px"}}
											highlightErrors={hasFormValidationErrors}
										/>

										<TextField
											name="caretaker_ssn_3"
											label="Personnr"
											autocomplete="nope"
											value={customerState.caretaker_ssn_3}
											validation={this._ssnValidation}
											onChange={({target}) => this.setCustomerState({caretaker_ssn_3: target.value})}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
								</StackLayout>
							</React.Fragment>
						)}

						<div className="h-line spacing"/>

						<h3>Skattehemvist</h3>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>

							<div>
								<Select
									name="customer_citizenship"
									label={i18n("general", "citizenship")}
									selectedIndex={customerState.citizenshipIndex}
									options={this.countries}
									onChange={({value: citizenship}, citizenshipIndex) => this.setCustomerState({
										citizenship,
										citizenshipIndex
									})}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>

							<div>
								<Select
									name="customer_tax_country"
									label={i18n("general", "tax_country")}
									selectedIndex={customerState.taxCountryIndex}
									options={this.countries}
									onChange={({value: taxCountry}, taxCountryIndex) => this.setCustomerState({
										taxCountry,
										taxCountryIndex
									})}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>

							{customerState.taxCountry !== 'Sverige' && (
								<div>
									<TextField
										name="customer_TIN"
										value={customerState.customer_TIN}
										autocomplete="nope"
										label="TIN nr"
										validation={value => value.length > 0}
										onChange={({target}) => this.setCustomerState({customer_TIN: target.value})}
										highlightErrors={hasFormValidationErrors}
									/>
									<input type="hidden" name="radio_customer_kyc_taxdom" value="other"/>
								</div>
							)}

							{customerState.taxCountry === 'Sverige' && (
								<React.Fragment>
									<input type="hidden" name="radio_customer_kyc_taxdom" value="swe"/>
									<input type="hidden" name="customer_tax_country" value=""/>
									<input type="hidden" name="customer_TIN" value=""/>
								</React.Fragment>
							)}
						</StackLayout>

						<div className="h-line spacing"/>
						<h4 style={{width: "100%", padding: "0 0 5px 0"}}>
							Bakgrunden/anledningen till varför kunden söker portföljförvaltning/investeringsrådgivning vid detta tillfälle.
						</h4>
						<p style={{margin: "-10px 0 10px 0"}}>
							Vad har kunden för lösning idag och målsättning idag? Finns det någon del vi kan hjälpa
							till med utifrån kundens målsättning?
						</p>

						<TextView
							name="advice_notes"
							label={`Fritext (${1800 - customerState.advice_notes.length} tecken kvar)`}
							value={customerState.advice_notes}
							maxLength={1800}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({advice_notes: target.value})}
							fullWidth
							minHeight={250}
							highlightErrors={hasFormValidationErrors}
						/>

						<StackLayout style={{marginTop: "30px"}}>

							<OptionsList
								type="checkbox"
								description="Vad är kundens mål med investeringen?"
								orientation="vertical"
								style={{
									paddingTop: "20px",
									width: "400px"
								}}
								value={
									customerState.checkbox_customer_kyc_purpose_pension === "Ja" ||
									customerState.checkbox_customer_kyc_purpose_beneficiary === "Ja" ||
									customerState.checkbox_customer_kyc_purpose_speculation === "Ja" ||
									customerState.checkbox_customer_kyc_purpose_regpayments === "Ja" ||
									customerState.checkbox_customer_kyc_purpose_financial_growth === "Ja" ||
									customerState.checkbox_customer_kyc_purpose_other === "Ja" ?
										"filled" :
										""
								}
								options={[
									{
										name: "checkbox_customer_kyc_purpose_pension",
										label: "Öka pension",
										value: customerState.checkbox_customer_kyc_purpose_pension === "Ja" ? "Ja" : "Nej",
										checked: customerState.checkbox_customer_kyc_purpose_pension === "Ja",
										onChange: checked => this.setCustomerState({checkbox_customer_kyc_purpose_pension: checked ? "Ja" : "Nej"})
									},
									{
										name: "checkbox_customer_kyc_purpose_beneficiary",
										label: "Trygga efterlevande",
										value: customerState.checkbox_customer_kyc_purpose_beneficiary === "Ja" ? "Ja" : "Nej",
										checked: customerState.checkbox_customer_kyc_purpose_beneficiary === "Ja",
										onChange: checked => this.setCustomerState({checkbox_customer_kyc_purpose_beneficiary: checked ? "Ja" : "Nej"})
									},
									{
										name: "checkbox_customer_kyc_purpose_speculation",
										label: "Spekulation",
										value: customerState.checkbox_customer_kyc_purpose_speculation === "Ja" ? "Ja" : "Nej",
										checked: customerState.checkbox_customer_kyc_purpose_speculation === "Ja",
										onChange: checked => this.setCustomerState({checkbox_customer_kyc_purpose_speculation: checked ? "Ja" : "Nej"})
									},
									{
										name: "checkbox_customer_kyc_purpose_regpayments",
										label: "Regelbunden utbetalning",
										value: customerState.checkbox_customer_kyc_purpose_regpayments === "Ja" ? "Ja" : "Nej",
										checked: customerState.checkbox_customer_kyc_purpose_regpayments === "Ja",
										onChange: checked => this.setCustomerState({checkbox_customer_kyc_purpose_regpayments: checked ? "Ja" : "Nej"})
									},
									{
										name: "checkbox_customer_kyc_purpose_financial_growth",
										label: "Ekonomisk tillväxt (långsiktig)",
										value: customerState.checkbox_customer_kyc_purpose_financial_growth === "Ja" ? "Ja" : "Nej",
										checked: customerState.checkbox_customer_kyc_purpose_financial_growth === "Ja",
										onChange: checked => this.setCustomerState({checkbox_customer_kyc_purpose_financial_growth: checked ? "Ja" : "Nej"})
									},
									{
										name: "checkbox_customer_kyc_purpose_other",
										label: "Annat",
										value: customerState.checkbox_customer_kyc_purpose_other === "Ja" ? "Ja" : "Nej",
										checked: customerState.checkbox_customer_kyc_purpose_other === "Ja",
										onChange: checked => this.setCustomerState({checkbox_customer_kyc_purpose_other: checked ? "Ja" : "Nej"})
									}
								]}
								validation={value => (value && value === "filled") || false}
								highlightErrors={hasFormValidationErrors}
							/>
						</StackLayout>
						{customerState.checkbox_customer_kyc_purpose_other === "Ja" && (
							<TextField
								name="customer_kyc_purpose_savinggoal"
								label="Vänligen beskriv"
								value={customerState.customer_kyc_purpose_savinggoal}
								validation={this._textValidation}
								style={{marginTop: "-10px", marginLeft: "11px"}}
								onChange={({target}) => this.setCustomerState({customer_kyc_purpose_savinggoal: target.value})}
								highlightErrors={hasFormValidationErrors}
							/>
						)}

						<div className="h-line spacing" style={{marginTop: "30px"}}/>

						<h3>Risk och placeringshorisont</h3>

						<h4 style={{margin: "10px 0"}}>
							Befintlig risknivå, totala portföljen
						</h4>

						<div className="numbers-with-colors">
							<OptionsList
								type="radio"
								name="radio_advice_currentportfolio_risk"
								value={customerState.radio_advice_currentportfolio_risk}
								options={[
									{
										label: "1",
										value: "1",
										checked: customerState.radio_advice_currentportfolio_risk === "1"
									},
									{
										label: "2",
										value: "2",
										checked: customerState.radio_advice_currentportfolio_risk === "2"
									},
									{
										label: "3",
										value: "3",
										checked: customerState.radio_advice_currentportfolio_risk === "3"
									},
									{
										label: "4",
										value: "4",
										checked: customerState.radio_advice_currentportfolio_risk === "4"
									},
									{
										label: "5",
										value: "5",
										checked: customerState.radio_advice_currentportfolio_risk === "5"
									},
									{
										label: "6",
										value: "6",
										checked: customerState.radio_advice_currentportfolio_risk === "6"
									},
									{
										label: "7",
										value: "7",
										checked: customerState.radio_advice_currentportfolio_risk === "7"
									},
								]}
								onChange={(checked, radio_advice_currentportfolio_risk) => this.setCustomerState({radio_advice_currentportfolio_risk})}
								validation={this._optionsValidation}
								style={{maxWidth: "unset"}}
								highlightErrors={hasFormValidationErrors}
							/>
						</div>

						<div style={{marginTop: "0"}}>
							<h4 style={{marginTop: "10px", marginBottom: "0"}}>
								Placeringshorisont, totala portföljen
							</h4>
							<Select
								name="checkbox_advice_savehoriz_tot"
								label="Ange år"
								selectedIndex={customerState.checkbox_advice_savehoriz_totIndex}
								options={this.saveHorizonValues}
								onChange={(option, index) => {
									this.setCustomerState({checkbox_advice_savehoriz_tot: option.value, checkbox_advice_savehoriz_totIndex: index});
								}}
								highlightErrors={hasFormValidationErrors}
							/>
							<input type={"hidden"} name={"text_savehoriz_total"}
							       value={customerState.checkbox_advice_savehoriz_totIndex !== -1 ?
								       this.saveHorizonValues[customerState.checkbox_advice_savehoriz_totIndex].label :
								       ""}/>
						</div>
					</div>
				</ContentBox>
			</div>
		);
	}

	_textValidation = (value) => (value.length > 0);

	_optionsValidation = (value) => ((value && value.length > 0) || false);

	/**
	 * SSN numbers need to be 12 digits and start with 19 or 20
	 */
	_ssnValidation = (value) => (!isNaN(value) && value.length === 12 &&
		(value.substring(0, 2) === "19" || value.substring(0, 2) === "20") && Validator.validateSsn(value)
	);

	_onLeiUpdated = (fulfilled) =>
	{
		this.setCustomerState({leiCodeValid: fulfilled});
	}
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, viewNo, hasFormValidationErrors} = this.props;
	const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
	const hasBeenSeen = (seenViews.includes(viewNo));

	// if it hasn't been seen add the view to local storage for the specific case
	if (customer.caseId && !hasBeenSeen)
	{
		localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
	}

	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	const newState = {
		isFulfilled: false,
		isLoading: true,
		lookupNotFound: false,
		fraudFlag: false,
		errorMessage: null,
		customerState: {
			isCompany: customer.isCompany,

			ssnOrg: customer.getData("customer_social_security_number"),
			firstName: customer.getData("customer_firstname"),
			lastName: customer.getData("customer_lastname"),
			street: customer.getData("customer_address_street"),
			zipCode: customer.getData("customer_address_zipcode"),
			city: customer.getData("customer_address_city"),
			country: customer.getData("customer_address_country") || "Sverige",
			countryIndex: countries.map(c => c.label).indexOf(customer.getData("customer_address_country") || "Sverige"),
			citizenship: customer.getData("customer_citizenship") || "Sverige",
			citizenshipIndex: countries.map(c => c.label).indexOf(customer.getData("customer_citizenship") || "Sverige"),
			taxCountry: customer.getData("customer_tax_country") || "Sverige",
			customer_TIN: customer.getData("customer_TIN") || "",
			taxCountryIndex: countries.map(c => c.label).indexOf(customer.getData("customer_tax_country") || "Sverige"),
			phoneNumber: customer.getData("customer_phonenumber_cell"),
			phoneNumberHome: customer.getData("customer_phonenumber_home"),
			email: customer.getData("customer_email"),

			child_caretaker: customer.getData("child_caretaker"),

			leiCodeValid: LeiCodeFieldFulfillment.call(this, LeiCodeField.getViewInitialStateForCustomer(customer, null, false), null),

			companyReps: customer.companyRepsFair,
			companyRepsActiveAmount: 0,
			companySignatories: 1,
			isForeigner: false,
			isNonEuPerson: false,

			checkbox_customer_kyc_inv_own_part: customer.getData("checkbox_customer_kyc_inv_own_part") || "no",
			checkbox_customer_kyc_inv_through_poa: customer.isCompany ? "ja" : customer.getData("checkbox_customer_kyc_inv_through_poa") || "no",

			caretaker_name_1: customer.getData("caretaker_name_1"),
			caretaker_ssn_1: customer.getData("caretaker_ssn_1"),
			caretaker_name_2: customer.getData("caretaker_name_2"),
			caretaker_ssn_2: customer.getData("caretaker_ssn_2"),
			caretaker_name_3: customer.getData("caretaker_name_3"),
			caretaker_ssn_3: customer.getData("caretaker_ssn_3"),

			customer_inv_poa_firstname: customer.getData("customer_inv_poa_firstname"),
			customer_inv_poa_lastname: customer.getData("customer_inv_poa_lastname"),
			customer_inv_poa_ssn: customer.getData("customer_inv_poa_ssn"),

			customer_inv_poa_firstname_2: customer.getData("customer_inv_poa_firstname_2"),
			customer_inv_poa_lastname_2: customer.getData("customer_inv_poa_lastname_2"),
			customer_inv_poa_ssn_2: customer.getData("customer_inv_poa_ssn_2"),

			customer_inv_poa_firstname_3: customer.getData("customer_inv_poa_firstname_3"),
			customer_inv_poa_lastname_3: customer.getData("customer_inv_poa_lastname_3"),
			customer_inv_poa_ssn_3: customer.getData("customer_inv_poa_ssn_3"),

			customer_inv_poa_firstname_4: customer.getData("customer_inv_poa_firstname_4"),
			customer_inv_poa_lastname_4: customer.getData("customer_inv_poa_lastname_4"),
			customer_inv_poa_ssn_4: customer.getData("customer_inv_poa_ssn_4"),

			advice_notes: customer.getData("advice_notes") || "",

			checkbox_customer_kyc_purpose_pension: customer.getData("checkbox_customer_kyc_purpose_pension", null),
			checkbox_customer_kyc_purpose_beneficiary: customer.getData("checkbox_customer_kyc_purpose_beneficiary", null),
			checkbox_customer_kyc_purpose_speculation: customer.getData("checkbox_customer_kyc_purpose_speculation", null),
			checkbox_customer_kyc_purpose_regpayments: customer.getData("checkbox_customer_kyc_purpose_regpayments", null),
			checkbox_customer_kyc_purpose_financial_growth: customer.getData("checkbox_customer_kyc_purpose_financial_growth", null),
			checkbox_customer_kyc_purpose_other: customer.getData("checkbox_customer_kyc_purpose_other", null),
			customer_kyc_purpose_savinggoal: customer.getData("customer_kyc_purpose_savinggoal") || "",

			radio_advice_currentportfolio_risk: customer.getData("radio_advice_currentportfolio_risk"),
			checkbox_advice_savehoriz_tot: customer.getData("checkbox_advice_savehoriz_tot"),
			checkbox_advice_savehoriz_totIndex: (
				customer.getData("checkbox_advice_savehoriz_tot") === "<1" ? 0 :
					customer.getData("checkbox_advice_savehoriz_tot") === "<3" ? 0 :
						customer.getData("checkbox_advice_savehoriz_tot") === "1-2" ? 0 :
							customer.getData("checkbox_advice_savehoriz_tot") === "3+" ? 1 :
								customer.getData("checkbox_advice_savehoriz_tot") === "4+" ? 2 :
									customer.getData("checkbox_advice_savehoriz_tot") === "5+" ? 3 :
										customer.getData("checkbox_advice_savehoriz_tot") === "4-6" ? 2 :
											customer.getData("checkbox_advice_savehoriz_tot") === "6-10" ? 4 :
												customer.getData("checkbox_advice_savehoriz_tot") === ">10" ? 5 :
													-1
			)
		}
	};

	for (let i = 0; i < 4; i++)
	{
		const rep = newState.customerState.companyReps[i];
		if (rep.firstName)
		{
			newState.customerState.companyRepsActiveAmount++;
		}
		else
		{
			break;
		}
	}

	// Check if the person is a minor. If so, we will need to enter their guardians.
	let needsGuardianToSign = false;
	if (newState.customerState.ssnOrg.startsWith("20"))
	{
		const dateOfBirthYear = newState.customerState.ssnOrg.substring(0, 4);
		const dateOfBirthMonth = newState.customerState.ssnOrg.substring(4, 6);
		const dateOfBirthDay = newState.customerState.ssnOrg.substring(6, 8);
		const dateOfBirth = new Date(dateOfBirthYear + "-" + dateOfBirthMonth + "-" + dateOfBirthDay);

		const date18yearsAgo = new Date();
		date18yearsAgo.setFullYear(date18yearsAgo.getFullYear() - 18);

		if (dateOfBirth > date18yearsAgo)
		{
			needsGuardianToSign = true;
		}
	}

	newState.customerState.needsGuardian = !customer.isCompany && needsGuardianToSign;

	newState.customerState.companySignatories =
		newState.customerState.customer_inv_poa_ssn_4.length > 0 ? 4 :
			newState.customerState.customer_inv_poa_ssn_3.length > 0 ? 3 :
				newState.customerState.customer_inv_poa_ssn_2.length > 0 ? 2 : 1;

	// Translate legacy values into new equivalents
	if (newState.customerState.checkbox_advice_savehoriz_tot === "<1" ||
		newState.customerState.checkbox_advice_savehoriz_tot === "1-2")
	{
		newState.customerState.checkbox_advice_savehoriz_tot = "<3";
	}
	else if (newState.customerState.checkbox_advice_savehoriz_tot === "4-6")
	{
		newState.customerState.checkbox_advice_savehoriz_tot = "4+";
	}

	return newState;
}

function _getFields()
{
	let fields = [
		"company_city_hq",
		"customer_social_security_number",
		"customer_firstname",
		"customer_lastname",
		"customer_address_street",
		"customer_address_zipcode",
		"customer_address_city",
		"customer_address_country",
		"customer_citizenship",
		"customer_tax_country",
		"customer_TIN",
		"customer_phonenumber_home",
		"customer_phonenumber_cell",
		"customer_email",

		"checkbox_customer_kyc_inv_own_part",
		"checkbox_customer_kyc_inv_through_poa",
		"customer_inv_poa_firstname",
		"customer_inv_poa_lastname",
		"customer_inv_poa_ssn",
		"customer_inv_poa_firstname_2",
		"customer_inv_poa_lastname_2",
		"customer_inv_poa_ssn_2",
		"customer_inv_poa_firstname_3",
		"customer_inv_poa_lastname_3",
		"customer_inv_poa_ssn_3",
		"customer_inv_poa_firstname_4",
		"customer_inv_poa_lastname_4",
		"customer_inv_poa_ssn_4",
		"child_caretaker",

		"checkbox_advice_savehoriz_tot",
		"radio_advice_currentportfolio_risk",

		"caretaker_name_1",
		"caretaker_ssn_1",
		"child_caretaker_intyg",

		"caretaker_name_2",
		"caretaker_ssn_2",

		"caretaker_name_3",
		"caretaker_ssn_3",

		"advice_notes",

		"checkbox_customer_kyc_purpose_pension",
		"checkbox_customer_kyc_purpose_beneficiary",
		"checkbox_customer_kyc_purpose_speculation",
		"checkbox_customer_kyc_purpose_regpayments",
		"checkbox_customer_kyc_purpose_financial_growth",
		"checkbox_customer_kyc_purpose_other",
		"customer_kyc_purpose_savinggoal"
	];

	fields = fields.concat(LeiCodeField.getFields());
	return fields;
}