export const initialState = {
  action: "add",
  clientId: null,
  type: "person",
  advisorData: null,
  ssn: "",
  orgNumber: "",
  firstName: "",
  lastName: "",
  companyName: "",
  country: "SE",
  street: "",
  zipCode: "",
  city: "",
  email: "",
  phoneNumber: "",
  lookingUp: false,
  fetching: false,
  inputErrors: null,
  serverMessage: null
};

const addCustomerModalReducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return initialState;
    case "handleInput":
      return {
        ...state,
        [action.field]: action.payload,
        inputErrors: { ...state.inputErrors, [action.field]: null },
        serverMessage: null
      };
    case "lookingUp":
      return { ...state, [action.type]: action.payload };
    case "setCustomer": {
      // Here we have to take into account if the payload comes from the backend or frontend as the prop names and stucture can be different
      const payload = action.payload;

      const customerData = {
        action: "update",
        clientId: payload.client_id || payload.clientId,
        type: payload.type,
        ssn: payload.type === "person" ? payload.id || payload.ssn || "" : "",
        orgNumber: payload.type === "company" ? payload.id || payload.orgNumber || "" : "",
        firstName: payload.first_name || payload.firstName || "",
        lastName: payload.last_name || payload.lastName || "",
        name: payload.name || "",
        companyName: payload.name || payload.companyName || "",
        country: payload.country_code || payload.country || "SE",
        street: payload.street || "",
        zipCode: payload.postal_code || payload.zipCode || "",
        city: payload.city || "",
        email: payload.email || "",
        phoneNumber: payload.phone || payload.phoneNumber || ""
      };
      
      return { 
        ...state,
        ...customerData,
      };
    }
    case "setAdvisor": {
      return { 
        ...state,
        advisorData: {
          ...action.payload,
          role: "advisor" // might need changing in the future
        }
      };
    }
    case "setLookupResult":
      return { 
        ...state,
        ...action.payload,
        country: "SE", // default to Sweden on lookup
        lookingUp: false
      };
    case "fetching":
      return { ...state, [action.type]: action.payload };
    case "handleSwitchPersonType":
      return { ...initialState, type: action.payload };
    case "handleInputErrors":
      return { ...state, inputErrors: action.payload };
    case "handleServerError":
      return {
        ...state,
        fetching: false,
        serverMessage: { type: "error", text: action.payload }
      };
    case "handleServerSuccess":
      return {
        ...state,
        fetching: false,
        serverMessage: { type: "success", text: action.payload }
      };
    default:
      return state;
  }
};

export default addCustomerModalReducer;
