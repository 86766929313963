import React from "react";

// COMPONENTS
import Button from "@components/Button/Button";
import ContentBox from "@components/ContentBox/ContentBox";
import Icon from "@components/Icon/Icon";

// HELPERS
import {connectRedux, createClassName} from "@helpers/utils";

// REDUCERS
import {actions as appActions} from "@reducers/app";


export default connectRedux(state => ({
	app: state.app,
}), {
	setAppState: appActions.setState,
}, class PartnerNotFoundRoute extends React.PureComponent {

	componentDidMount() {

	}

	render() {
		const partner = this.props.reducers.app.partner;
		const classes = createClassName("PartnerNotFound", {});

		return (
			<div className={classes}>
				<ContentBox>

					<div className="icon-circle">
						<Icon value="error" size="xlarge"/>
					</div>

					<h2>Partnerkonto kunde inte hittas</h2>

					{partner.name === 'fair' && (
						<p style={{textAlign: "center"}}>
							Du verkar inte ha något registrerat partnerkonto. <br />
							Vänligen kontakta Fair Investments AB på: <br />
							<a href="mailto:bo.admin@fairinvestments.se">bo.admin@fairinvestments.se</a>
						</p>
					)}

					<Button
						label="Försök igen"
						appearance="primary"
						onClick={this._onRetryClick}
						filled
					/>

				</ContentBox>
			</div>
		);
	}

	// Internal methods
	_onRetryClick = () => {
		const {actions, history} = this.props;
		actions.setAppState({busy: false});
		history.push("/");
	}
});