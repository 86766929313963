import { useState, useEffect, useRef } from "react";
import moment from "moment";
import api from "@helpers/api";
import { useReactToPrint } from "react-to-print";

const useSignedVolume = props => {
  const [loaded, setLoaded] = useState(false);
  const [report, setReport] = useState([]);
  const yesterday = new Date (moment().subtract(1, "days"));
  const lastWeek = new Date(moment().subtract(1, "weeks"));
  const [dateRange, setDateRange] = useState([lastWeek, yesterday]);
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({ contentRef });
  const [error, setError] = useState(null);

  const ranges = [
    {
      label: 'Förra veckan',
      value: [lastWeek, yesterday]
    }
  ];

  const locale = {
    // sunday: 'Sö',
    // monday: 'Må',
    // tuesday: 'Ti',
    // wednesday: 'On',
    // thursday: 'To',
    // friday: 'Fr',
    // saturday: 'Lö',
    // ok: 'OK',
    // today: 'Idag',
    // yesterday: 'Igår',
    // last7Days: 'Senaste 7 dagarna'
  };  
  
  const getPrintPageMargins = () => {
    return `@page { margin: 60px 40px !important; }`;
  };
  
  const handleDateChange = (data) => {
    if (data) {
      setDateRange([data[0], data[1]]);
    }
  };

  const fetchData = async () => {    
    setLoaded(false);

    try {
      const result = await api("/reports/signedvolume", {
        startDate: moment(dateRange[0]).format("YYYY-MM-DD"),
        endDate: moment(dateRange[1]).format("YYYY-MM-DD")
      });
      setReport(result);
      setLoaded(true);
    }
    catch (error) {
      setError(error.message);
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      fetchData(); 
    }
  }, [dateRange[0], dateRange[1]]);

  return {
    loaded,
    error,
    locale,
    ranges,
    dateRange,
    yesterday,
    report,
    contentRef,
    handlePrint,
    handleDateChange,
    getPrintPageMargins
  };
};

export default useSignedVolume;
