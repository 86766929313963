import { useState, useEffect } from "react";

// HELPERS
import api from "@helpers/api";
import { formatSSN } from "@helpers/utils";

const useAdvisorsCard = props => {
  const { client, addCustomerModalOpen } = props;

  const [loaded, setLoaded] = useState(false);
  const [advisors, setAdvisors] = useState([]);
  const [activeTab, setActiveTab] = useState("current");

  const tabs = [
    {
      key: "current",
      label: "Aktuella"
    },
    {
      key: "previous",
      label: "Tidigare"
    }
  ]

  const getAdvisors = async () => {
    // 🤪
    const id = client.client_id || client.clientId;
    const ssn = client.id || client.ssn;

    try {
      const data = await api("/client/advisors", {
        // we need to send both ids here to get previous advisors
        id,
        ...(ssn && { ssn: formatSSN(ssn, {withDash: false}) })
      });
      setAdvisors(data);
      setLoaded(true);
    }
    catch (e) {
      console.error(e.message);
    }
  }

  useEffect(() => {
    if (!addCustomerModalOpen) getAdvisors();
  }, [addCustomerModalOpen]);
  
  return {
    loaded,
    tabs,
    activeTab,
    setActiveTab,
    advisors,
  };
};

export default useAdvisorsCard;