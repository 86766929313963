// MODULES
import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

// HOOKS
// import useResponsive from "@hooks/useResponsive";

const Card = props =>
{
	const {title, icon: Icon, loading, sxStyle, children} = props;

	return (
		<Box sx={{
			width: {xs: "100%", md: "32%"},
			maxWidth: {xs: "100%", md: "580px"},
			minWidth: {xs: "unset", md: "236px"},
			minHeight: "245px",
			display: "flex",
			mt: {xs: 4, md: 0},
			padding: 0,
			alignItems: "flex-start",
			border: "2px solid",
			borderColor: "secondary.main",
			borderRadius: "5px",
			...sxStyle
		}}>
			<Box sx={{
				width: "100%",
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				padding: 1.2,
				backgroundColor: "secondary.main",
				borderTopLeftRadius: "5px",
				borderTopRightRadius: "5px"
			}}>
				{Icon && <Icon sx={{fontSize: "22px", marginRight: 0.8, color: "primary.main"}}/>}
				<Typography sx={{
					fontFamily: "Lato", fontSize: "15px", fontWeight: 600, color: "primary.main", lineHeight: 0.9
				}}>
					{title}
				</Typography>
			</Box>
			<Box sx={{
				width: "100%",
				display: "flex",
				flex: "1",
				padding: 1.5,
        justifyContent: loading ? "center" : "flex-start"
			}}>
        {loading ?         
          <CircularProgress
            color="primary"
            sx={{
              display: loading ? "flex" : "none"
            }}
          />
        :
          <>
				    {children}
          </>
        }
			</Box>
		</Box>
	);
};

export default Card;