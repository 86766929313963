import { mergeViewStateForFulfillment } from "../../Cob.helpers";


export default function(state, prevState) {
    if(import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") return true;
    state = mergeViewStateForFulfillment(state, prevState);
    const { customerState } = state;

    return !!(
        customerState.checkbox_customer_kyc_gdpr === "yes"
        && customerState.advice_sign_row_1 === "yes"
        && customerState.advice_sign_row_2 === "yes"
        && customerState.advice_sign_row_3 === "yes"
        && customerState.advice_sign_row_4 === "yes"
        && customerState.advice_sign_row_5 === "yes"
        && customerState.advice_sign_row_6 === "yes"
    );
}