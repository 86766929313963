// CLASSES
import {mergeViewStateForFulfillment} from "../../../Cob.helpers";

export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);

	if (!state.onboardingInfo.has_onboarded && state.customerState.checkbox_advice_onboarding !== "yes")
	{
		return false;
	}

	if (state.customerState.checkbox_advice_onboarding === "yes" && !state.isAccountSelectionFulfilled)
	{
		return false;
	}

	if (state.customerState.checkbox_advice_recommendation === "yes" && !state.isPlacementRecommendationFulfilled)
	{
		return false;
	}

	return true;
}