// MODULES
import React from "react";

// COMPONENTS
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import InfoIcon from '@mui/icons-material/Info';
import Modal from "@components/Modal/Modal";

import Card from "@cobComponents/Card";
import usePoaCard from "./usePoaCard";

const PoaCard = props => {
  const { 
    loaded,
    fetching,
    fmk,
    confirmDialogOpen,
    onActionClick,
    OnModalClose,
    handleRevoke,
    handleRemove,
    handleRemind
  } = usePoaCard(props);

  let poaInfo, poaAnswers;
  switch (fmk.status) {
    case 'not_sent':
      poaInfo =  <Typography variant="body2">Fullmaktsförfrågan ej skickad.</Typography>
      poaAnswers =  <Typography color="inherit" variant="body2" sx={{fontStyle: "italic"}}>
        Skicka fullmaktsförfrågan för att se vilka försäkringsbolag personen eller företaget är kund hos.
      </Typography>
      break;
    case 'sent':
      poaInfo = (
        <>
          <Typography color="inherit" variant="body2">Skickad, ej signerad.</Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 0.6
          }}>
            <Link 
              component="button"
              type="button"
              onClick={() => onActionClick("remind")}
              sx={{
                color: "inherit",
                cursor: "pointer"
              }}
            >
              <Typography color="inherit" variant="body2">Påminn kund</Typography>
            </Link>
            <Link 
              component="button"
              type="button"
              onClick={() => onActionClick("remove")}
              sx={{
                color: "inherit",
                cursor: "pointer"
              }}
            >
              <Typography color="inherit" variant="body2">Återkalla</Typography>
            </Link>
          </Box>
        </>        
      );
      poaAnswers =  <Typography color="#c0ac61" variant="body2">Inväntar signering av informationsfullmakt</Typography>
      break;
    case 'signed':
      poaInfo = (
        <>
          <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 0.6
          }}>
            <Box sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start"
            }}>
              <Typography color="inherit" variant="body2">Signerad: {fmk.signedDate}</Typography>
              <Typography color="inherit" variant="body2">Giltig: {fmk.validTo ? fmk.validTo : "Tills vidare"}</Typography>
            </Box>
            <Link 
              component="button"
              type="button"
              onClick={() => onActionClick("revoke")}
              sx={{
                color: "inherit",
                cursor: "pointer"
              }}
            >
              <Typography color="inherit" variant="body2">Återkalla</Typography>
            </Link>
          </Box>
        </>
      );
      poaAnswers = (
        <>
          {fmk.companies.map((company, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mt: 0.6
                  }}
                >
                  <Typography color="inherit" variant="body2">
                    <b>{company.company}</b>
                  </Typography>
                  <Typography color={company.is_customer ? "#15ca85" : "inherit"} variant="body2">
                    {company.is_customer ? "Kund" : "Ej kund"}
                  </Typography>
                </Box>
              )
          })}
        </>
      );
      break;
    case 'reminder_sent':
      poaInfo = <Typography color="inherit" variant="body2">Påminnelse skickas till kund.</Typography>;
      break;
    case 'error':
      poaInfo = <Typography color="#cc2d23" variant="body2">Något gick fel, kontakta supporten.</Typography>;
      break;
     default:
      break;
  }

  const confirmDialogAction = () => {    
    switch (confirmDialogOpen) {
      case "remind":
        return handleRemind();
      case "remove":
        return handleRemove();
      case "revoke":
        return handleRevoke();
      default:
        break;
    }
  }

  return (
    <Card 
      title="Fullmaktskollen"
      icon={InfoIcon}
      loading={!loaded}
      sxStyle={{
        mt: {xs: 2, md: 0},
      }}
    >
      <Box sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        mt: 0.6
      }}>
        <Typography color="inherit" variant="body2">
          <b>Informationsfullmakt</b>
        </Typography>                                
        {poaInfo}
      </Box>
      <Box sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        mt: 2
      }}>
        <Typography color="inherit" variant="body2">
          <b>Svar från försäkringsbolag</b>
        </Typography>
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: fmk.status === "signed" ? "column" :"row",
          justifyContent: "flex-start"
        }}>
          {poaAnswers}
        </Box>
      </Box>
      <Modal
          visible={confirmDialogOpen ? true : false}
          title={confirmDialogOpen === "remind" ? "Bekräfta med OK att du vill påminna kunden" : "Bekräfta med OK att du vill ta bort/återkalla fullmakten"}
        >
          <Modal.ButtonsContainer justifyContent="space-between">
            <Modal.Button
              label="Nej"
              appearance="primary"
              onClick={OnModalClose}
            />
            <Modal.Button
              label="Ok"
              appearance="warning"
              onClick={confirmDialogAction}
              busy={fetching}
            />
          </Modal.ButtonsContainer>
      </Modal>
    </Card>
  );
};

export default PoaCard;