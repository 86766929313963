import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./FinancialPlanning.fulfillment";
import OptionsList from "../../../components/OptionsList/OptionsList";
import TextView from "../../../../../components/TextView/TextView";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import NumberField from "../../../components/NumberField/NumberField";
import TextField from "../../../../../components/TextField/TextField";
import Button from "../../../../../components/Button/Button";
import i18n from "@helpers/i18n";

export default class FinancialPlanning extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {}
			}
		});
	}

	render()
	{
		const {state} = this;
		const {customerState} = state;
		const classes = createClassName("Cob-view Cob-view2", {});

		const savingsGoals = [];
		for (let i = 1; i <= customerState.numberOfSavingGoals; i++)
		{
			savingsGoals.push(
				<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth key={"goal_" + i}
							 style={{marginTop: "0"}}>
					<div style={{alignSelf: "flex-start"}}>
						<strong style={{marginBottom: "10px"}}>{i18n("general", "name")}</strong>
						<TextField
							name={"planning_savings_goal_name_" + i}
							value={customerState[`planning_savings_goal_name_${i}`]}
							label={i18n("general", "enter_name")}
							autocomplete="nope"
							validation={value => (value.length > 0 && value.length <= 30)}
							onChange={({target}) => this.setCustomerState({[`planning_savings_goal_name_${i}`]: target.value})}
						/>
						<span onClick={this._removeSavingsGoal.bind(this, i)}
							  style={{
								  fontSize: "11px",
								  fontWeight: "bold",
								  textDecoration: "underline",
								  cursor: "pointer",
								  color: "red",
								  margin: "10px 0 0 5px"
							  }}>{i18n("financial_planning", "remove_goal_savings")}</span>
					</div>
					<div style={{alignSelf: "flex-start"}}>
						<strong style={{marginBottom: "10px"}}>{i18n("financial_planning", "years_to_purchase")}</strong>
						<NumberField label={i18n("financial_planning", "years_to_purchase")}
									 unit={" " + i18n("general", "years", true)}
									 name={"planning_savings_goal_target_years_" + i}
									 value={customerState[`planning_savings_goal_target_years_${i}`]}
									 showSeparators={true}
									 validation={value => (value.length > 0)}
									 onChange={({target}) => this.setCustomerState({[`planning_savings_goal_target_years_${i}`]: target.value})}/>
					</div>
					<div style={{alignSelf: "flex-start"}}>
						<strong style={{marginBottom: "10px"}}>{i18n("general", "amount")}</strong>
						<NumberField label={i18n("general", "provide_amount")}
									 unit=" kr"
									 name={"planning_savings_goal_amount_" + i}
									 value={customerState[`planning_savings_goal_amount_${i}`]}
									 showSeparators={true}
									 validation={value => (value.length > 0)}
									 onChange={({target}) => this.setCustomerState({[`planning_savings_goal_amount_${i}`]: target.value})}/>
					</div>
				</StackLayout>
			);
		}

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>{i18n("financial_planning", "basic_management_conditions")}</h2>

					<div className="Cob-view2-fields">
						<OptionsList
							style={{marginTop: "10px"}}
							type="radio"
							name="planning_liqudate_quickly"
							description={`${i18n("financial_planning", "short_notice_liquidation_required")}*`}
							options={[
								{
									label: i18n("general", "no"),
									value: "Nej",
									checked: customerState.planning_liqudate_quickly === "Nej"
								},
								{
									label: i18n("general", "yes"),
									value: "Ja",
									checked: customerState.planning_liqudate_quickly === "Ja"
								}
							]}
							onChange={(checked, planning_liqudate_quickly) => this.setCustomerState({planning_liqudate_quickly})}
						/>

						<h4>
              {i18n("financial_planning", "future_generations_benefits")}?
						</h4>
						<TextView
							name="planning_future_gen_thoughts"
							label={`${i18n("general", "free_text")} (${200 - customerState.planning_future_gen_thoughts.length} ${i18n("general", "remaining_chars")})`}
							value={customerState.planning_future_gen_thoughts}
							maxLength={200}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({planning_future_gen_thoughts: target.value})}
							fullWidth
							minHeight={90}
						/>

						<StackLayout className="Cob-fields-collection per-row-2" rowWrap fullWidth>
							<div style={{paddingRight: "20px", alignSelf: "flex-start"}}>
								<h4>{i18n("financial_planning", "time_horizon")}*</h4>
								<TextField
									name="planning_savings_horizon"
									value={customerState.planning_savings_horizon}
									autocomplete="nope"
									label={i18n("general", "enter")}
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({planning_savings_horizon: target.value})}
								/>
							</div>

							<div style={{paddingLeft: "20px", alignSelf: "flex-start"}}>
								<h4>{i18n("financial_planning", "time_horizon_comment")}</h4>
								<TextView
									name="planning_savings_horizon_comment"
                  label={`${i18n("general", "free_text")} (${200 - customerState.planning_savings_horizon_comment.length} ${i18n("general", "remaining_chars")})`}
									value={customerState.planning_savings_horizon_comment}
									maxLength={200}
									validation={value => value.length > 0}
									resize="vertical"
									onChange={({target}) => this.setCustomerState({planning_savings_horizon_comment: target.value})}
									fullWidth
									minHeight={90}
								/>
							</div>

						</StackLayout>

						{this.state.workflow !== "futur" && (
							<React.Fragment>
								<div className="h-line spacing" style={{margin: "40px 0"}}/>

								<h3 style={{marginBottom: "20px"}}>{i18n("financial_planning", "emergency_funds")}*</h3>

								<NumberField label={i18n("financial_planning", "specify_desired_emergency_savings")}
											 unit="kr"
											 name="planning_buffer_amount"
											 value={customerState.planning_buffer_amount}
											 showSeparators={true}
											 validation={value => (value.length > 0)}
											 onChange={({target}) =>
											 {
												 this.setCustomerState({planning_buffer_amount: target.value});
											 }}/>

								<h3 style={{marginTop: "20px"}}>{i18n("financial_planning", "goal_savings")}</h3>

								{customerState.numberOfSavingGoals === 0 && (
									<p>{i18n("financial_planning", "not_specified_goal_savings")}
									</p>
								)}

								{savingsGoals}

								{customerState.numberOfSavingGoals < 5 && (
									<Button
										size="small"
										label={i18n("financial_planning", "add_goal_savings")}
										appearance={"primary"}
										onClick={this._addSavingsGoal}
										filled
									/>
								)}
							</React.Fragment>
						)}
					</div>
				</ContentBox>
			</div>
		);
	}

	_addSavingsGoal = () =>
	{
		this.setCustomerState({numberOfSavingGoals: (this.state.customerState.numberOfSavingGoals + 1)});
	};

	_removeSavingsGoal = (savingsRowNumber) =>
	{
		const newCustomerState = {...this.state.customerState};
		const newNumberOfSavingsGoals = newCustomerState.numberOfSavingGoals > 0 ? (newCustomerState.numberOfSavingGoals - 1) : 0;
		newCustomerState.numberOfSavingGoals = newNumberOfSavingsGoals;

		// Clear the values at the selected index
		const indexInArray = savingsRowNumber - 1;
		newCustomerState['planning_savings_goal_name_' + savingsRowNumber] = "";
		newCustomerState['planning_savings_goal_amount_' + savingsRowNumber] = "";
		newCustomerState['planning_savings_goal_target_years_' + savingsRowNumber] = "";

		// If we didn't remove the last index, we move all the subsequent values "up" in the array
		if (indexInArray !== newNumberOfSavingsGoals)
		{
			for (let i = savingsRowNumber; i <= 5; i++)
			{
				if (i === 5)
				{
					newCustomerState['planning_savings_goal_name_' + i] = "";
					newCustomerState['planning_savings_goal_amount_' + i] = "";
					newCustomerState['planning_savings_goal_target_years_' + i] = "";
				}
				else
				{
					newCustomerState['planning_savings_goal_name_' + i] = newCustomerState['planning_savings_goal_name_' + (i + 1)];
					newCustomerState['planning_savings_goal_amount_' + i] = newCustomerState['planning_savings_goal_amount_' + (i + 1)];
					newCustomerState['planning_savings_goal_target_years_' + i] = newCustomerState['planning_savings_goal_target_years_' + (i + 1)];
				}
			}
		}
		this.setCustomerState(newCustomerState);
	};
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, currentWorkflow} = this.props;
	let newState = getViewInitialStateForCustomer(customer, null);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.workflow = currentWorkflow;
	return newState;
}

export function getViewInitialStateForCustomer(customer, workflow)
{
	workflow = workflow == null ? "default" : workflow;

	let initialState = {
		isFulfilled: false,
		workflow: workflow,
		customerState: {
			numberOfSavingGoals: 0,

			planning_liqudate_quickly: customer.getData("planning_liqudate_quickly") || "",
			planning_future_gen_thoughts: customer.getData("planning_future_gen_thoughts") || "",

			planning_savings_horizon: customer.getData("planning_savings_horizon") || "",
			planning_savings_horizon_comment: customer.getData("planning_savings_horizon_comment") || "",

			planning_buffer_amount: customer.getData("planning_buffer_amount") ? customer.getData("planning_buffer_amount") : "",

			planning_savings_goal_name_1: customer.getData("planning_savings_goal_name_1") || "",
			planning_savings_goal_name_2: customer.getData("planning_savings_goal_name_2") || "",
			planning_savings_goal_name_3: customer.getData("planning_savings_goal_name_3") || "",
			planning_savings_goal_name_4: customer.getData("planning_savings_goal_name_4") || "",
			planning_savings_goal_name_5: customer.getData("planning_savings_goal_name_5") || "",

			planning_savings_goal_amount_1: customer.getData("planning_savings_goal_amount_1") ? parseInt(customer.getData("planning_savings_goal_amount_1").replaceAll(" kr", "").replaceAll(" ", ""), 10) : "",
			planning_savings_goal_amount_2: customer.getData("planning_savings_goal_amount_2") ? parseInt(customer.getData("planning_savings_goal_amount_2").replaceAll(" kr", "").replaceAll(" ", ""), 10) : "",
			planning_savings_goal_amount_3: customer.getData("planning_savings_goal_amount_3") ? parseInt(customer.getData("planning_savings_goal_amount_3").replaceAll(" kr", "").replaceAll(" ", ""), 10) : "",
			planning_savings_goal_amount_4: customer.getData("planning_savings_goal_amount_4") ? parseInt(customer.getData("planning_savings_goal_amount_4").replaceAll(" kr", "").replaceAll(" ", ""), 10) : "",
			planning_savings_goal_amount_5: customer.getData("planning_savings_goal_amount_5") ? parseInt(customer.getData("planning_savings_goal_amount_5").replaceAll(" kr", "").replaceAll(" ", ""), 10) : "",

			planning_savings_goal_target_years_1: customer.getData("planning_savings_goal_target_years_1") ? parseInt(customer.getData("planning_savings_goal_target_years_1").replaceAll(" år", "").replaceAll(" ", ""), 10) : "",
			planning_savings_goal_target_years_2: customer.getData("planning_savings_goal_target_years_2") ? parseInt(customer.getData("planning_savings_goal_target_years_2").replaceAll(" år", "").replaceAll(" ", ""), 10) : "",
			planning_savings_goal_target_years_3: customer.getData("planning_savings_goal_target_years_3") ? parseInt(customer.getData("planning_savings_goal_target_years_3").replaceAll(" år", "").replaceAll(" ", ""), 10) : "",
			planning_savings_goal_target_years_4: customer.getData("planning_savings_goal_target_years_4") ? parseInt(customer.getData("planning_savings_goal_target_years_4").replaceAll(" år", "").replaceAll(" ", ""), 10) : "",
			planning_savings_goal_target_years_5: customer.getData("planning_savings_goal_target_years_5") ? parseInt(customer.getData("planning_savings_goal_target_years_5").replaceAll(" år", "").replaceAll(" ", ""), 10) : "",
		}
	};

	initialState.customerState.numberOfSavingGoals = initialState.customerState.planning_savings_goal_name_5.length > 0 ? 5 :
		initialState.customerState.planning_savings_goal_name_4.length > 0 ? 4 :
			initialState.customerState.planning_savings_goal_name_3.length > 0 ? 3 :
				initialState.customerState.planning_savings_goal_name_2.length > 0 ? 2 :
					initialState.customerState.planning_savings_goal_name_1.length > 0 ? 1 : 0;

	if (customer.getData("planning_liqudate_quickly") === "no")
	{
		initialState.customerState.planning_liqudate_quickly = "Nej";
	}

	if (customer.getData("planning_liqudate_quickly") === "yes")
	{
		initialState.customerState.planning_liqudate_quickly = "Ja";
	}

	return initialState;
}

function _getFields()
{
	return [
		"planning_liqudate_quickly",
		"planning_future_gen_thoughts",

		"planning_savings_horizon",
		"planning_savings_horizon_comment",

		"planning_buffer_amount",

		"planning_pension_years_remaining",
		"planning_pension_withdrawals",
		"planning_pension_rest_capital",

		"planning_savings_goal_amount_1",
		"planning_savings_goal_amount_2",
		"planning_savings_goal_amount_3",
		"planning_savings_goal_amount_4",
		"planning_savings_goal_amount_5",

		"planning_savings_goal_target_years_1",
		"planning_savings_goal_target_years_2",
		"planning_savings_goal_target_years_3",
		"planning_savings_goal_target_years_4",
		"planning_savings_goal_target_years_5",

		"planning_savings_goal_name_1",
		"planning_savings_goal_name_2",
		"planning_savings_goal_name_3",
		"planning_savings_goal_name_4",
		"planning_savings_goal_name_5"
	];
}