import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./KYCCompany.fulfillment";
import i18n from "../../../../../helpers/i18n";
import Modal from "../../../../../components/Modal/Modal";
import OptionsList from "../../../components/OptionsList/OptionsList";
import Select from "../../../../../components/Select/Select";
import CountrySelection from "../../../components/CountrySelection/CountrySelection";
import CompanyOwnership from "../../../components/CompanyOwnership/CompanyOwnership";
import FileDrop from "../../../../../components/FileDrop/FileDrop";
import {DocsList} from "../../../../../components/DocsList/DocsList";
import TextField from "../../../../../components/TextField/TextField";
import TextView from "../../../../../components/TextView/TextView";
import NumberField from "../../../components/NumberField/NumberField";
import {localeNumber} from "../../../../../helpers/utils";
import KYC from "./KYC";

export default class KYCCompany extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	companyTypeOptions = [
		{
			label: "Aktiebolag",
			value: "Aktiebolag"
		},
		{
			label: "Handelsbolag/Kommanditbolag",
			value: "Handelsbolag/Kommanditbolag"
		},
		{
			label: "Enskild firma",
			value: "Enskild firma"
		},
		{
			label: "Börsbolag i Sverige",
			value: "Börsbolag i Sverige"
		},
		{
			label: "Stat/Region/Kommun",
			value: "Stat/Region/Kommun"
		},
		{
			label: "Ekonomisk förening",
			value: "Ekonomisk förening"
		},
		{
			label: "Ideell förening",
			value: "Ideell förening"
		},
		{
			label: "Stiftelse",
			value: "Stiftelse"
		},
		{
			label: "Trossamfund",
			value: "Trossamfund"
		}
	];

	businessTypeOptions = [
		{
			label: "Bygg/Anläggning/Infrastruktur",
			value: "Bygg/Anläggning/Infrastruktur"
		},
		{
			label: "Data/Teknik/IT",
			value: "Data/Teknik/IT"
		},
		{
			label: "Drift/Underhåll",
			value: "Drift/Underhåll"
		},
		{
			label: "Ekonomi/Finans",
			value: "Ekonomi/Finans"
		},
		{
			label: "Fastigheter",
			value: "Fastigheter"
		},
		{
			label: "FoU/Vetenskap",
			value: "FoU/Vetenskap"
		},
		{
			label: "Försäljning/Affärsutveckling",
			value: "Försäljning/Affärsutveckling"
		},
		{
			label: "Hotell/Restaurang/Turism",
			value: "Hotell/Restaurang/Turism"
		},
		{
			label: "HR/Personal",
			value: "HR/Personal"
		},
		{
			label: "Import/Export/Handel",
			value: "Import/Export/Handel"
		},
		{
			label: "Juridik",
			value: "Juridik"
		},
		{
			label: "Kundsupport/Service",
			value: "Kundsupport/Service"
		},
		{
			label: "Lantbruk/Skogsbruk",
			value: "Lantbruk/Skogsbruk"
		},
		{
			label: "Ledning/Management",
			value: "Ledning/Management"
		},
		{
			label: "Logistik/Transport",
			value: "Logistik/Transport"
		},
		{
			label: "Marknad/Reklam",
			value: "Marknad/Reklam"
		},
		{
			label: "Sjukvård/Hälsa",
			value: "Sjukvård/Hälsa"
		},
		{
			label: "Skribent/Publishing",
			value: "Skribent/Publishing"
		},
		{
			label: "Säkerhet/Räddningstjänst",
			value: "Säkerhet/Räddningstjänst"
		},
		{
			label: "Teologi/Psykologi/Filosofi",
			value: "Teologi/Psykologi/Filosofi"
		},
		{
			label: "Tillverkning/Produktion",
			value: "Tillverkning/Produktion"
		},
		{
			label: "Utbildning",
			value: "Utbildning"
		}
	];

	employeeCountOptions = [
		{
			label: "0",
			value: "0"
		},
		{
			label: "1-9",
			value: "1-9"
		},
		{
			label: "10-50",
			value: "10-50"
		},
		{
			label: "51-99",
			value: "51-99"
		},
		{
			label: "100+",
			value: "100+"
		}
	];

	turnoverOptions = [
		{
			label: "Nystartat bolag",
			value: "Nystartat bolag"
		},
		{
			label: "Mindre än 3 miljoner SEK",
			value: "Mindre än 3 miljoner SEK"
		},
		{
			label: "3-40 miljoner SEK",
			value: "3-40 miljoner SEK"
		},
		{
			label: "Över 40 miljoner SEK",
			value: "Över 40 miljoner SEK"
		}
	];

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {}
			}
		});
	}

	render()
	{
		const {state, props} = this;
		const {onRestartSession} = props;
		const {customerState} = state;
		const classes = createClassName("Cob-view Cob-view2", {});

		const totalDocsSize = state.docs.reduceRight((a, b) => a + Math.round(b.size / 1024), 0);
		const maxUploadFileSize = Math.round(state.maxUploadFileSize / 1024);

		const purposeCombined = [];

		if (customerState.kyc_purpose_savings === "yes")
		{
			purposeCombined.push("Sparande/placering");
		}

		if (customerState.kyc_purpose_security === "yes")
		{
			purposeCombined.push("Ekonomisk trygghet");
		}

		if (customerState.kyc_purpose_pension === "yes")
		{
			purposeCombined.push("Pension");
		}

		if (customerState.kyc_purpose_consumption === "yes")
		{
			purposeCombined.push("Privat konsumtion");
		}

		if (customerState.kyc_purpose_gift === "yes")
		{
			purposeCombined.push("Gåva till närstående");
		}

		if (customerState.kyc_purpose_beneficiary === "yes")
		{
			purposeCombined.push("Förmånstagare");
		}

		if (customerState.kyc_purpose_other === "yes")
		{
			purposeCombined.push("Annat: " + customerState.kyc_purpose_other_details);
		}

		const srcFundsCombined = [];

		if (customerState.kyc_src_funds_company_profits === "yes")
		{
			srcFundsCombined.push("Vinst från egna verksamheten");
		}

		if (customerState.kyc_src_funds_savings === "yes")
		{
			srcFundsCombined.push("Placeringar och investeringar");
		}

		if (customerState.kyc_src_funds_exit === "yes")
		{
			srcFundsCombined.push("Bolagsförsäljning");
		}

		if (customerState.kyc_src_funds_share_capital === "yes")
		{
			srcFundsCombined.push("Aktiekapital");
		}

		if (customerState.kyc_src_funds_member_fees === "yes")
		{
			srcFundsCombined.push("Medlemsavgifter");
		}

		if (customerState.kyc_src_funds_donations === "yes")
		{
			srcFundsCombined.push("Donationer");
		}

		if (customerState.kyc_src_funds_grants === "yes")
		{
			srcFundsCombined.push("Anslag");
		}

		if (customerState.kyc_src_funds_other === "yes")
		{
			srcFundsCombined.push("Annat: " + customerState.kyc_src_funds_other_details);
		}

		const srcCombined = [];

		if (customerState.kyc_src_bank_sweden === "yes")
		{
			srcCombined.push("Bank eller värdepappersinstitut i Sverige");
		}

		if (customerState.kyc_src_insurance_sweden === "yes")
		{
			srcCombined.push("Försäkringsbolag i Sverige");
		}

		if (customerState.kyc_src_bank_eu === "yes")
		{
			srcCombined.push("Bank eller värdepappersinstitut i annat land inom EU/EES");
		}

		if (customerState.kyc_src_insurance_eu === "yes")
		{
			srcCombined.push("Försäkringsbolag i annat land inom EU/EES");
		}

		if (customerState.kyc_src_bank_world === "yes")
		{
			srcCombined.push("Bank eller värdepappersinstitut utanför EU/EES");
		}

		if (customerState.kyc_src_insurance_world === "yes")
		{
			srcCombined.push("Försäkringsbolag utanför EU/EES");
		}

		let depositFrequencyOptions = [
			{label: "0 - 10 gånger", value: "0 - 10 gånger"},
			{label: "11 - 50 gånger", value: "11 - 50 gånger"},
			{label: "51 - 100 gånger", value: "51 - 100 gånger"},
			{label: "Fler än 100 gånger", value: "Fler än 100 gånger"}
		];

		let withdrawalFrequencyOptions = [
			{label: "0 - 10 gånger", value: "0 - 10 gånger"},
			{label: "11 - 50 gånger", value: "11 - 50 gånger"},
			{label: "51 - 100 gånger", value: "51 - 100 gånger"},
			{label: "Fler än 100 gånger", value: "Fler än 100 gånger"}
		];

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<Modal status="danger" title="Utlandet"
					   visible={customerState.rdo_kyc_usperson === "yes" || customerState.rdo_kyc_inttrans === "yes"}>
					<React.Fragment>
						<p>
							Personer med anknytning till USA eller som regelbundet gör transaktioner med utlandet kan
							inte fullfölja onboardingen här utan måste fylla i separat blankett.
						</p>
						<Modal.ButtonsContainer>
							<Modal.Button label={i18n("general", "close")} appearance="primary"
										  onClick={() => this.setCustomerState({
											  rdo_kyc_usperson: this.state.customerState.rdo_kyc_usperson === "yes" ? null : this.state.customerState.rdo_kyc_usperson,
											  rdo_kyc_inttrans: this.state.customerState.rdo_kyc_inttrans === "yes" ? null : this.state.customerState.rdo_kyc_inttrans
										  })}/>
							<Modal.Button label="Avsluta rådgivning" appearance="primary"
										  onClick={onRestartSession} filled/>
						</Modal.ButtonsContainer>
					</React.Fragment>
				</Modal>

				<Modal status="danger" title="Ägare av innehav på konton"
					   visible={customerState.kyc_owner_of_funds === "no"}>
					<React.Fragment>
						<p>
							Som kund hos oss måste du själv äga dina pengar och värdepapper.
						</p>
						<Modal.ButtonsContainer>
							<Modal.Button label={i18n("general", "close")} appearance="primary"
										  onClick={() => this.setCustomerState({kyc_owner_of_funds: ""})}/>
							<Modal.Button label="Avsluta rådgivning" appearance="primary"
										  onClick={onRestartSession} filled/>
						</Modal.ButtonsContainer>
					</React.Fragment>
				</Modal>

				<ContentBox>
					<h2>Kundkännedom</h2>

					<div className="Cob-view2-fields">

						<CompanyOwnership customer={this.props.customer}
										  onChange={this._onOwnershipChanged} />

						<h4 style={{marginTop: "20px"}}>Ladda upp kopior på pass eller ID-kort för huvudmän</h4>

						<div style={{maxWidth: "500px", width: "100%"}}>
							<FileDrop
								filterKey={"passport-id-ubo"}
								dropText="Dra och släpp kopior på pass eller ID-kort här"
								busyText="Laddar in dokument..."
								accept="image/png, image/jpeg, application/pdf"
								onDrop={this._onDocDrop}
								style={{minHeight: "0", paddingTop: "20px", paddingBottom: "30px"}}
							/>

							<DocsList
								filterKey={"passport-id-ubo"}
								items={state.docs}
								totalSize={totalDocsSize}
								maxSize={maxUploadFileSize}
								onDocRemove={this._onDocRemove}
								alignBottom={true}
								required={false}
							/>
						</div>

						<div className="h-line spacing"/>

						<div style={{alignItems: "flex-start"}}>
							<h3>Frågor om företaget</h3>

							<h4>Vilken bolagsform har företaget?</h4>
							<Select
								name="kyc_company_form"
								label="Välj bolagsform"
								selectedIndex={this.companyTypeOptions.map(c => c.value).indexOf(customerState.kyc_company_form)}
								options={this.companyTypeOptions}
								onChange={(option) => this.setCustomerState({kyc_company_form: option.value})}
							/>

							<h4>I vilken bransch verkar företaget?</h4>
							<Select
								name="kyc_company_business"
								label="Välj bransch"
								selectedIndex={this.businessTypeOptions.map(c => c.value).indexOf(customerState.kyc_company_business)}
								options={this.businessTypeOptions}
								onChange={(option) => this.setCustomerState({kyc_company_business: option.value})}
							/>

							<h4>I vilket land har företaget sin skattehemvist?</h4>
							<CountrySelection label={i18n("general", "tax_country")}
											  field={"kyc_tax_country"}
											  selected={customerState.kyc_tax_country}
											  mandatory={true}
											  onChange={(value) => this.setCustomerState({kyc_tax_country: value.label})}/>

							<h4>Är företaget ett finansiellt institut?</h4>

							<OptionsList type="radio"
										 orientation="horizontal"
										 name="kyc_is_financial_institution"
										 options={[
											 {
												 label: "Nej",
												 value: "Nej",
												 checked: customerState.kyc_is_financial_institution === "Nej"
											 },
											 {
												 label: "Ja",
												 value: "Ja",
												 checked: customerState.kyc_is_financial_institution === "Ja"
											 }
										 ]}
										 onChange={(checked, kyc_is_financial_institution) => this.setCustomerState({kyc_is_financial_institution})}
							/>

							<h4>Är företaget aktivt eller passivt?</h4>
							<OptionsList type="radio"
										 orientation="horizontal"
										 name="kyc_is_active"
										 options={[
											 {
												 label: "Passivt",
												 value: "Passivt",
												 checked: customerState.kyc_is_active === "Passivt"
											 },
											 {
												 label: "Aktivt",
												 value: "Aktivt",
												 checked: customerState.kyc_is_active === "Aktivt"
											 }
										 ]}
										 onChange={(checked, kyc_is_active) => this.setCustomerState({kyc_is_active})}
							/>

							<h4>Hur många anställda har företaget?</h4>
							<Select
								name="kyc_company_employee_count"
								label="Välj antal"
								selectedIndex={this.employeeCountOptions.map(c => c.value).indexOf(customerState.kyc_company_employee_count)}
								options={this.employeeCountOptions}
								onChange={(option) => this.setCustomerState({kyc_company_employee_count: option.value})}
							/>

							<h4>Vad är företagets omsättning (netto)?</h4>
							<Select
								name="kyc_company_net_turnover"
								label="Välj omsättning"
								selectedIndex={this.turnoverOptions.map(c => c.value).indexOf(customerState.kyc_company_net_turnover)}
								options={this.turnoverOptions}
								onChange={(option) => this.setCustomerState({kyc_company_net_turnover: option.value})}
							/>

							<h4>Har företaget en revisor?</h4>
							<OptionsList type="radio"
										 orientation="horizontal"
										 name="kyc_has_auditor"
										 options={[
											 {
												 label: "Nej",
												 value: "Nej",
												 checked: customerState.kyc_has_auditor === "Nej"
											 },
											 {
												 label: "Ja",
												 value: "Ja",
												 checked: customerState.kyc_has_auditor === "Ja"
											 }
										 ]}
										 onChange={(checked, kyc_has_auditor) => this.setCustomerState({kyc_has_auditor})}
							/>
						</div>

						<div className="h-line spacing"/>
						<h3>Syfte med engagemang</h3>

						<OptionsList
							style={{marginTop: "10px"}}
							type="checkbox"
							description="Vad är syftet med ert engagemang?*"
							orientation="vertical"
							options={[
								{
									name: "kyc_purpose_savings",
									label: "Sparande/placering",
									value: customerState.kyc_purpose_savings === "yes" ? "yes" : "no",
									checked: customerState.kyc_purpose_savings === "yes",
									onChange: checked => this.setCustomerState({kyc_purpose_savings: checked ? "yes" : "no"})
								},
								{
									name: "kyc_purpose_security",
									label: "Ekonomisk trygghet",
									value: customerState.kyc_purpose_security === "yes" ? "yes" : "no",
									checked: customerState.kyc_purpose_security === "yes",
									onChange: checked => this.setCustomerState({kyc_purpose_security: checked ? "yes" : "no"})
								},
								{
									name: "kyc_purpose_pension",
									label: "Pension",
									value: customerState.kyc_purpose_pension === "yes" ? "yes" : "no",
									checked: customerState.kyc_purpose_pension === "yes",
									onChange: checked => this.setCustomerState({kyc_purpose_pension: checked ? "yes" : "no"})
								},
								{
									name: "kyc_purpose_consumption",
									label: "Privat konsumtion",
									value: customerState.kyc_purpose_consumption === "yes" ? "yes" : "no",
									checked: customerState.kyc_purpose_consumption === "yes",
									onChange: checked => this.setCustomerState({kyc_purpose_consumption: checked ? "yes" : "no"})
								},
								{
									name: "kyc_purpose_gift",
									label: "Gåva till närstående",
									value: customerState.kyc_purpose_gift === "yes" ? "yes" : "no",
									checked: customerState.kyc_purpose_gift === "yes",
									onChange: checked => this.setCustomerState({kyc_purpose_gift: checked ? "yes" : "no"})
								},
								{
									name: "kyc_purpose_beneficiary",
									label: "Förmånstagare",
									value: customerState.kyc_purpose_beneficiary === "yes" ? "yes" : "no",
									checked: customerState.kyc_purpose_beneficiary === "yes",
									onChange: checked => this.setCustomerState({kyc_purpose_beneficiary: checked ? "yes" : "no"})
								},
								{
									name: "kyc_purpose_other",
									label: "Annat",
									value: customerState.kyc_purpose_other === "yes" ? "yes" : "no",
									checked: customerState.kyc_purpose_other === "yes",
									onChange: checked => this.setCustomerState({kyc_purpose_other: checked ? "yes" : "no"})
								}
							]}
						/>

						{customerState.kyc_purpose_other === "yes" && (
							<TextField
								name="kyc_purpose_other_details"
								value={customerState.kyc_purpose_other_details}
								label={"Vänligen beskriv"}
								validation={this._textValidation}
								onChange={({target}) => this.setCustomerState({kyc_purpose_other_details: target.value})}
								required={true}
							/>
						)}

						<input type={"hidden"} name={"kyc_purpose_savings_combined"}
							   value={purposeCombined.join(", ")}/>

						<div className="h-line spacing"/>
						<h3>Kapitalöverföring</h3>

						<OptionsList
							style={{marginTop: "10px"}}
							type="radio"
							name="kyc_owner_of_funds"
							description={"Är det företagets/organisationens pengar som kommer överföras?*"}
							options={[
								{
									label: "Nej",
									value: "Nej",
									checked: customerState.kyc_owner_of_funds === "Nej"
								},
								{
									label: "Ja",
									value: "Ja",
									checked: customerState.kyc_owner_of_funds === "Ja"
								}
							]}
							onChange={(checked, kyc_owner_of_funds) => this.setCustomerState({kyc_owner_of_funds})}
						/>

						{(customerState.kyc_owner_of_funds === "Nej") && (
							<React.Fragment>
								<h4 className={"bottom-margin"} style={{paddingTop: "15px"}}>
									Förklara vems pengar som kommer att överföras
								</h4>
								<TextView
									name="kyc_owner_of_funds_text"
									label={`Ange (${500 - customerState.kyc_owner_of_funds_text.length} tecken kvar)`}
									value={customerState.kyc_owner_of_funds_text}
									maxLength={500}
									validation={value => value.length > 0}
									resize="vertical"
									onChange={({target}) => this.setCustomerState({kyc_owner_of_funds_text: target.value})}
									fullWidth
									minHeight={100}
									required={true}
								/>

								<h4 className={"bottom-margin"} style={{paddingTop: "15px"}}>Ladda upp dokument
									(valfritt)</h4>
								<div style={{maxWidth: "500px", width: "100%"}}>
									<FileDrop
										filterKey={"owner-of-funds"}
										dropText="Dra och släpp dokument här"
										busyText="Laddar in dokument..."
										accept="image/png, image/jpeg, application/pdf"
										onDrop={this._onDocDrop}
										style={{minHeight: "0", paddingTop: "20px", paddingBottom: "30px"}}
									/>

									<DocsList
										filterKey={"owner-of-funds"}
										items={state.docs}
										totalSize={totalDocsSize}
										maxSize={maxUploadFileSize}
										onDocRemove={this._onDocRemove}
										alignBottom={true}
									/>
								</div>
							</React.Fragment>
						)}

						<OptionsList
							style={{marginTop: "20px"}}
							type="checkbox"
							description="Varifrån kommer företagets pengar och/eller värdepapper som företaget/organisationen sätter in?*"
							orientation="vertical"
							options={[
								{
									name: "kyc_src_funds_company_profits",
									label: "Vinst från egna verksamheten",
									value: customerState.kyc_src_funds_company_profits === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_funds_company_profits === "yes",
									onChange: checked => this.setCustomerState({kyc_src_funds_company_profits: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_funds_exit",
									label: "Bolagsförsäljning",
									value: customerState.kyc_src_funds_exit === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_funds_exit === "yes",
									onChange: checked => this.setCustomerState({kyc_src_funds_exit: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_funds_share_capital",
									label: "Aktiekapital",
									value: customerState.kyc_src_funds_share_capital === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_funds_share_capital === "yes",
									onChange: checked => this.setCustomerState({kyc_src_funds_share_capital: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_funds_savings",
									label: "Placeringar och investeringar",
									value: customerState.kyc_src_funds_savings === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_funds_savings === "yes",
									onChange: checked => this.setCustomerState({kyc_src_funds_savings: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_funds_member_fees",
									label: "Medlemsavgifter",
									value: customerState.kyc_src_funds_member_fees === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_funds_member_fees === "yes",
									onChange: checked => this.setCustomerState({kyc_src_funds_member_fees: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_funds_donations",
									label: "Donationer",
									value: customerState.kyc_src_funds_donations === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_funds_donations === "yes",
									onChange: checked => this.setCustomerState({kyc_src_funds_donations: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_funds_grants",
									label: "Anslag",
									value: customerState.kyc_src_funds_grants === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_funds_grants === "yes",
									onChange: checked => this.setCustomerState({kyc_src_funds_grants: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_funds_other",
									label: "Annat",
									value: customerState.kyc_src_funds_other === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_funds_other === "yes",
									onChange: checked => this.setCustomerState({kyc_src_funds_other: checked ? "yes" : "no"})
								}
							]}
						/>

						{customerState.kyc_src_funds_other === "yes" && (
							<TextView
								name="kyc_src_funds_other_details"
								label={`Vänligen beskriv (${200 - customerState.kyc_src_funds_other_details.length} tecken kvar)`}
								value={customerState.kyc_src_funds_other_details}
								maxLength={200}
								validation={value => value.length > 0}
								resize="vertical"
								onChange={({target}) => this.setCustomerState({kyc_src_funds_other_details: target.value})}
								fullWidth
								minHeight={100}
								required={true}
							/>
						)}

						<input type={"hidden"} name={"kyc_src_funds_combined"} value={srcFundsCombined.join(", ")}/>

						<OptionsList
							style={{marginTop: "20px"}}
							type="checkbox"
							description="Från vilken bank/värdepappersinstitut/försäkringsbolag kommer ert startkapital ifrån?*"
							orientation="vertical"
							options={[
								{
									name: "kyc_src_bank_sweden",
									label: "Bank eller värdepappersinstitut i Sverige",
									value: customerState.kyc_src_bank_sweden === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_bank_sweden === "yes",
									onChange: checked => this.setCustomerState({kyc_src_bank_sweden: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_insurance_sweden",
									label: "Försäkringsbolag i Sverige",
									value: customerState.kyc_src_insurance_sweden === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_insurance_sweden === "yes",
									onChange: checked => this.setCustomerState({kyc_src_insurance_sweden: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_bank_eu",
									label: "Bank eller värdepappersinstitut i annat land inom EU/EES",
									value: customerState.kyc_src_bank_eu === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_bank_eu === "yes",
									onChange: checked => this.setCustomerState({kyc_src_bank_eu: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_insurance_eu",
									label: "Försäkringsbolag i annat land inom EU/EES",
									value: customerState.kyc_src_insurance_eu === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_insurance_eu === "yes",
									onChange: checked => this.setCustomerState({kyc_src_insurance_eu: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_bank_world",
									label: "Bank eller värdepappersinstitut utanför EU/EES",
									value: customerState.kyc_src_bank_world === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_bank_world === "yes",
									onChange: checked => this.setCustomerState({kyc_src_bank_world: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_insurance_world",
									label: "Försäkringsbolag utanför EU/EES",
									value: customerState.kyc_src_insurance_world === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_insurance_world === "yes",
									onChange: checked => this.setCustomerState({kyc_src_insurance_world: checked ? "yes" : "no"})
								}
							]}
						/>

						<input type={"hidden"} name={"kyc_src_combined"} value={srcCombined.join(", ")}/>

						<strong style={{marginTop: "20px"}}>
							Vilket ungefärligt värde kommer du att överföra i samband med öppnandet av ditt konto?*
						</strong>
						<Select label="Välj"
								name={"kyc_first_deposit"}
								selectedIndex={KYC.firstDepositOptions.map(c => c.value).indexOf(customerState.kyc_first_deposit)}
								options={KYC.firstDepositOptions}
								onChange={(value) => this.setCustomerState({kyc_first_deposit: value.value})}/>

						{customerState.kyc_first_deposit === "Över 5 000 000 kr" && (
							<div style={{maxWidth: "200px", width: "100%"}}>
								<NumberField label="Ange estimat*"
											 unit="SEK"
											 name="kyc_first_deposit_estimate"
											 value={customerState.kyc_first_deposit_estimate}
											 showSeparators={true}
											 validation={this._validateEstimatedAmount}
											 onChange={({target}) =>
											 {
												 this.setCustomerState({kyc_first_deposit_estimate: target.value.split(' ').join('')});
											 }}/>
								<input type="hidden" name="kyc_first_deposit_estimate"
									   value={customerState.kyc_first_deposit_estimate.replace("SEK", "")}/>
								<input type="hidden" name="kyc_first_deposit"
									   value={localeNumber(customerState.kyc_first_deposit_estimate.replace("SEK", ""), 0) + " kr"}/>
							</div>
						)}

						<div className="h-line spacing"/>

						<h3>Insättningar, uttag och transaktioner</h3>

						<strong style={{marginTop: "20px"}}>
							Vilket värde kommer era insättningar att uppgå till i genomsnitt per år?*
						</strong>
						<Select label="Välj"
								name={"kyc_yearly_deposit"}
								selectedIndex={KYC.yearlyDepositOptions.map(c => c.value).indexOf(customerState.kyc_yearly_deposit)}
								options={KYC.yearlyDepositOptions}
								onChange={(value) => this.setCustomerState({kyc_yearly_deposit: value.value})}/>

						{customerState.kyc_yearly_deposit === "Över 5 000 000 kr" && (
							<div style={{maxWidth: "200px", width: "100%"}}>
								<NumberField label="Ange estimat*"
											 unit="SEK"
											 name="kyc_yearly_deposit_estimate"
											 value={customerState.kyc_yearly_deposit_estimate}
											 showSeparators={true}
											 validation={this._validateEstimatedAmount}
											 onChange={({target}) =>
											 {
												 this.setCustomerState({kyc_yearly_deposit_estimate: target.value.split(' ').join('')});
											 }}/>
								<input type="hidden" name="kyc_yearly_deposit_estimate"
									   value={customerState.kyc_yearly_deposit_estimate.replace("SEK", "")}/>
								<input type="hidden" name="kyc_yearly_deposit"
									   value={localeNumber(customerState.kyc_yearly_deposit_estimate.replace("SEK", ""), 0) + " kr"}/>
							</div>
						)}

						<strong style={{marginTop: "20px"}}>Hur ofta kommer insättningar att göras per år?*</strong>
						<Select label="Välj"
								name={"kyc_deposit_frequency"}
								selectedIndex={depositFrequencyOptions.map(c => c.value).indexOf(customerState.kyc_deposit_frequency)}
								options={depositFrequencyOptions}
								onChange={(value) => this.setCustomerState({kyc_deposit_frequency: value.value})}/>

						<strong style={{marginTop: "20px"}}>Hur ofta kommer uttag att göras per år?*</strong>
						<Select label="Välj"
								name={"kyc_withdrawal_frequency"}
								selectedIndex={withdrawalFrequencyOptions.map(c => c.value).indexOf(customerState.kyc_withdrawal_frequency)}
								options={withdrawalFrequencyOptions}
								onChange={(value) => this.setCustomerState({kyc_withdrawal_frequency: value.value})}/>


						<strong style={{marginTop: "20px"}}>
							Hur stora enskilda transaktioner i finansiella instrument gör ni normalt?*
						</strong>
						<Select label="Välj"
								name={"kyc_transaction_size"}
								selectedIndex={KYC.transactionSizeOptions.map(c => c.value).indexOf(customerState.kyc_transaction_size)}
								options={KYC.transactionSizeOptions}
								onChange={(value) => this.setCustomerState({kyc_transaction_size: value.value})}/>

						{customerState.kyc_transaction_size === "Över 5 000 000 kr" && (
							<div style={{maxWidth: "200px", width: "100%"}}>
								<NumberField label="Ange estimat*"
											 unit="SEK"
											 name="kyc_transaction_size_estimate"
											 value={customerState.kyc_transaction_size_estimate}
											 showSeparators={true}
											 validation={this._validateEstimatedAmount}
											 onChange={({target}) =>
											 {
												 this.setCustomerState({kyc_transaction_size_estimate: target.value.split(' ').join('')});
											 }}/>
								<input type="hidden" name="kyc_transaction_size_estimate"
									   value={customerState.kyc_transaction_size_estimate.replace("SEK", "")}/>
								<input type="hidden" name="kyc_transaction_size"
									   value={localeNumber(customerState.kyc_transaction_size_estimate.replace("SEK", ""), 0) + " kr"}/>
							</div>
						)}
					</div>
				</ContentBox>
			</div>
		);
	}

	_validateEstimatedAmount = (value) =>
	{
		if (!value)
		{
			return false;
		}

		value = value.split(" ").join("");
		value = parseInt(value, 10);
		return !(isNaN(value) || value <= 5000000);
	};

	_onOwnershipChanged = (fulfilled) =>
	{
		this.setCustomerState({kyc_company_ownership_fulfilled: fulfilled});
	};

	// Internal methods
	_onDocDrop = (documents) =>
	{
		const {props, state} = this;
		const {customer} = props;
		const docs = [...state.docs];
		const docNames = [...state.docNames];


		for (let i = 0, n = documents.length; i < n; i++)
		{
			const doc = documents[i];

			// Prevent duplicates
			if (docNames.indexOf(doc.name) !== -1)
			{
				alert('Fil med det namnet har redan bifogats ärendet och behöver inte bifogas på nytt.');
				console.log("Skipped duplicate document " + doc.name);
				continue;
			}

			docs.push({name: doc.name, size: doc.size, filterKey: doc.filterKey});
			docNames.push(doc.name);

			customer.addDocument({
				name: doc.name,
				size: doc.size,
				data: doc.data,
				filterKey: doc.filterKey
			});
		}

		this.setState({docs, docNames, isFulfilled: this.isFulfilled({docs, docNames})}, () =>
		{
			props.setAppState({viewFulfilled: this.state.isFulfilled});
		});
	};

	_onDocRemove = (item) =>
	{
		const {props, state} = this;
		const {customer} = props;

		const docs = [...state.docs].filter((d) =>
		{
			return d.name !== item.name;
		});

		const docNames = [...state.docNames].filter((d) =>
		{
			return d !== item.name;
		});

		customer.removeDocumentWithName(item.name);
		this.setState({docs, docNames, isFulfilled: this.isFulfilled({docs, docNames})}, () =>
		{
			props.setAppState({viewFulfilled: this.state.isFulfilled});
		});
	}
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer} = this.props;
	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	let newState = {
		docs: customer.documents.map(doc => ({name: doc.name, size: doc.size, filterKey: doc.filterKey})),
		docNames: customer.documents.map(doc => doc.name),
		maxUploadFileSize: customer.maxUploadFileSize,
		customerState: {
			kyc_company_form: customer.getData("kyc_company_form") || "",
			kyc_company_business: customer.getData("kyc_company_business") || "",

			kyc_tax_country: customer.getData("kyc_tax_country") || "",

			kyc_is_financial_institution: customer.getData("kyc_is_financial_institution") || "",
			kyc_is_active: customer.getData("kyc_is_active") || "",
			kyc_company_employee_count: customer.getData("kyc_company_employee_count") || "",
			kyc_company_net_turnover: customer.getData("kyc_company_net_turnover") || "",

			kyc_has_auditor: customer.getData("kyc_has_auditor") || "",

			kyc_purpose_savings: customer.getData("kyc_purpose_savings") || "",
			kyc_purpose_security: customer.getData("kyc_purpose_security") || "",
			kyc_purpose_pension: customer.getData("kyc_purpose_pension") || "",
			kyc_purpose_consumption: customer.getData("kyc_purpose_consumption") || "",
			kyc_purpose_gift: customer.getData("kyc_purpose_gift") || "",
			kyc_purpose_beneficiary: customer.getData("kyc_purpose_beneficiary") || "",
			kyc_purpose_other: customer.getData("kyc_purpose_other") || "",
			kyc_purpose_other_details: customer.getData("kyc_purpose_other_details") || "",

			kyc_owner_of_funds: customer.getData("kyc_owner_of_funds") || "",
			kyc_owner_of_funds_text: customer.getData("kyc_owner_of_funds_text") || "",

			kyc_src_bank_sweden: customer.getData("kyc_src_bank_sweden") || "",
			kyc_src_insurance_sweden: customer.getData("kyc_src_insurance_sweden") || "",
			kyc_src_bank_eu: customer.getData("kyc_src_bank_eu") || "",
			kyc_src_insurance_eu: customer.getData("kyc_src_insurance_eu") || "",
			kyc_src_bank_world: customer.getData("kyc_src_bank_world") || "",
			kyc_src_insurance_world: customer.getData("kyc_src_insurance_world") || "",

			kyc_src_funds_company_profits: customer.getData("kyc_src_funds_company_profits") || "",
			kyc_src_funds_savings: customer.getData("kyc_src_funds_savings") || "",
			kyc_src_funds_share_capital: customer.getData("kyc_src_funds_share_capital") || "",
			kyc_src_funds_exit: customer.getData("kyc_src_funds_exit") || "",
			kyc_src_funds_member_fees: customer.getData("kyc_src_funds_member_fees") || "",
			kyc_src_funds_donations: customer.getData("kyc_src_funds_donations") || "",
			kyc_src_funds_grants: customer.getData("kyc_src_funds_grants") || "",
			kyc_src_funds_other: customer.getData("kyc_src_funds_other") || "",
			kyc_src_funds_other_details: customer.getData("kyc_src_funds_other_details") || "",

			kyc_first_deposit: customer.getData("kyc_first_deposit") || "",
			kyc_first_deposit_estimate: customer.getData("kyc_first_deposit_estimate") ? customer.getData("kyc_first_deposit_estimate").replace("SEK", "") : "",

			kyc_yearly_deposit: customer.getData("kyc_yearly_deposit") || "",
			kyc_yearly_deposit_estimate: customer.getData("kyc_yearly_deposit_estimate") ? customer.getData("kyc_yearly_deposit_estimate").replace("SEK", "") : "",

			kyc_transaction_size: customer.getData("kyc_transaction_size") || "",
			kyc_transaction_size_estimate: customer.getData("kyc_transaction_size_estimate") ? customer.getData("kyc_transaction_size_estimate").replace("SEK", "") : "",

			kyc_deposit_frequency: customer.getData("kyc_deposit_frequency") || "",
			kyc_withdrawal_frequency: customer.getData("kyc_withdrawal_frequency") || "",

			kyc_company_ownership_fulfilled: CompanyOwnership.isComponentFulfilled(CompanyOwnership.getInitialStateForComponent(customer))
		}
	};

	/**
	 * Translate old values for Aktivt (Ja), Passivt (Nej) to actual values: Aktivt, Passivt.
	 */
	if (newState.customerState.kyc_is_active === "Ja")
	{
		newState.customerState.kyc_is_active = "Aktivt";
	}
	else if (newState.customerState.kyc_is_active === "Nej")
	{
		newState.customerState.kyc_is_active = "Passivt";
	}

	const firstDepositIndex = KYC.firstDepositOptions.map(c => c.value).indexOf(customer.getData("kyc_first_deposit"));
	if (firstDepositIndex <= -1 && customer.getData("kyc_first_deposit_estimate"))
	{
		newState.customerState.kyc_first_deposit = "Över 5 000 000 kr";
	}

	const yearlyDepositIndex = KYC.yearlyDepositOptions.map(c => c.value).indexOf(customer.getData("kyc_yearly_deposit"));
	if (yearlyDepositIndex <= -1 && customer.getData("kyc_yearly_deposit_estimate"))
	{
		newState.customerState.kyc_yearly_deposit = "Över 5 000 000 kr";
	}

	const transSizeIndex = KYC.transactionSizeOptions.map(c => c.value).indexOf(customer.getData("kyc_transaction_size"));
	if (transSizeIndex <= -1 && customer.getData("kyc_transaction_size_estimate"))
	{
		newState.customerState.kyc_transaction_size = "Över 5 000 000 kr";
	}

	return newState;
}


function _getFields()
{
	let fields = [
		"kyc_company_form",
		"kyc_company_business",

		"kyc_tax_country",

		"kyc_is_financial_institution",
		"kyc_is_active",
		"kyc_company_employee_count",
		"kyc_company_net_turnover",

		"kyc_yearly_deposit",
		"kyc_yearly_deposit_estimate",
		"kyc_transaction_size",
		"kyc_transaction_size_estimate",
		"kyc_deposit_frequency",
		"kyc_withdrawal_frequency",

		"kyc_has_auditor",

		"kyc_purpose_savings",
		"kyc_purpose_security",
		"kyc_purpose_pension",
		"kyc_purpose_consumption",
		"kyc_purpose_gift",
		"kyc_purpose_beneficiary",
		"kyc_purpose_other",
		"kyc_purpose_other_details",

		"kyc_owner_of_funds",
		"kyc_owner_of_funds_text",

		"kyc_src_bank_sweden",
		"kyc_src_insurance_sweden",
		"kyc_src_bank_eu",
		"kyc_src_insurance_eu",
		"kyc_src_bank_world",
		"kyc_src_insurance_world",

		"kyc_src_funds_company_profits",
		"kyc_src_funds_savings",
		"kyc_src_funds_share_capital",
		"kyc_src_funds_exit",
		"kyc_src_funds_member_fees",
		"kyc_src_funds_donations",
		"kyc_src_funds_grants",
		"kyc_src_funds_other",
		"kyc_src_funds_other_details",

		"kyc_first_deposit",
		"kyc_first_deposit_estimate",

		"company_has_controlling_owner",

		"company_alt_ubo_is_company",
		"company_alt_ubo_missing_personnr",
		"company_alt_ubo_role",
		"company_alt_ubo_firstname",
		"company_alt_ubo_companyname",
		"company_alt_ubo_lastname",
		"company_alt_ubo_ssn",
		"company_alt_ubo_citizenship",
		"company_alt_ubo_tax_country",
		"company_alt_ubo_dob",
		"company_alt_ubo_tin",
		"company_alt_ubo_country",
		"company_alt_ubo_street",
		"company_alt_ubo_zip",
		"company_alt_ubo_city",
		"company_alt_ubo_pep",
		"company_alt_ubo_ownership",
		"company_alt_ubo_has_controlling_owner",
	];

	for (let i = 1; i <= 4; i++)
	{
		fields.push("company_ubo_" + i + "_is_company");
		fields.push("company_ubo_" + i + "_missing_personnr");
		fields.push("company_ubo_" + i + "_role");
		fields.push("company_ubo_" + i + "_firstname");
		fields.push("company_ubo_" + i + "_companyname");
		fields.push("company_ubo_" + i + "_lastname");
		fields.push("company_ubo_" + i + "_ssn");
		fields.push("company_ubo_" + i + "_citizenship");
		fields.push("company_ubo_" + i + "_citizenship_code");
		fields.push("company_ubo_" + i + "_tax_country");
		fields.push("company_ubo_" + i + "_tax_country_code");
		fields.push("company_ubo_" + i + "_dob");
		fields.push("company_ubo_" + i + "_tin");
		fields.push("company_ubo_" + i + "_country");
		fields.push("company_ubo_" + i + "_street");
		fields.push("company_ubo_" + i + "_zip");
		fields.push("company_ubo_" + i + "_city");
		fields.push("company_ubo_" + i + "_pep");
		fields.push("company_ubo_" + i + "_ownership");
		fields.push("company_ubo_" + i + "_has_controlling_owner");
	}

	return fields;
}