import React from "react";

// HELPERS
import { createClassName } from "@helpers/utils";


export default class ContentBox extends React.PureComponent {

    render() {
        const { props } = this;
        const { children, horizontal, style } = props;
        const classes = createClassName(props.className, {
            "ContentBox": true,
            "horizontal": horizontal === true,
        });

        return (
            <div className={ classes } style={style}>
                { children }
            </div>
        );
    }
}