import React from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";

const EnhancedTableHead = props => {
  const { columns, order, orderBy, onRequestSort } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => (
          <TableCell
            key={column.accessor}
            align="left"
            padding={column.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === column.accessor ? order : false}
            sx={{
              width: column.width,
              px: 0,
              py: 1,
              textAlign: index >= columns.length - 1 ? "right" : "left"
            }}
          >
            <TableSortLabel
              active={orderBy === column.accessor}
              direction={orderBy === column.accessor ? order : "asc"}
              onClick={
                column.disableSort
                  ? null
                  :     
                 createSortHandler(column.accessor)
              }
              hideSortIcon
              sx={{
                cursor: column.disableSort ? "default" : "pointer",
                "& .MuiSvgIcon-root": {
                  ml: 0.2,
                  mr: 0,
                  fontSize: "12px"
                },
                "& :hover": {
                  color: column.disableSort ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.6)"
                }                
              }}
            >
              <Typography variant="tableCellHeader">
                {column.Header}
              </Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

export default EnhancedTableHead;
