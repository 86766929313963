import {mergeViewStateForFulfillment} from "../../Cob.helpers";


export default function (state, prevState) {
	state = mergeViewStateForFulfillment(state, prevState);
	const {isCompany, ssnOrg} = state.customerState;

	// One of the dropdown options need to be selected
	if (state.customerState.isNewCase === null) {
		return false;
	}

	const ssnOrgIsPerson = !isNaN(ssnOrg) && ssnOrg.length === 12 && (ssnOrg.substring(0, 2) === "19" || ssnOrg.substring(0, 2) === "20");
	const ssnOrgIsCompany = isCompany && inputIsCompany(ssnOrg);

	return ssnOrgIsPerson || ssnOrgIsCompany;
}

// Todo: Move all these to Cob.helpers.js
export function inputIsCompany(input) {
	return !isNaN(input) && input.length === 12 && input.substring(0, 2) === "16" && input[4] >= 2;
}