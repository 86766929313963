import React from "react";
import PropTypes from "prop-types";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Typography from "@mui/material/Typography";
import AccountIcon from "@mui/icons-material/PersonOutlined";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// HELPERS
import i18n from "@helpers/i18n";

const DesktopMenu = props => {
  const {
    user,
    locationPath,
    menuOpen,
    settings,
    handleCloseUserMenu,
    toggleUserMenu
  } = props.handlers;

  return (
    <ClickAwayListener onClickAway={handleCloseUserMenu}>
      <Accordion
        expanded={menuOpen}
        onClick={toggleUserMenu}
        sx={{
          display: "inline-block",
          height: "min-content",
          minWidth: 200,
          width: "min-content",
          position: "fixed",
          top: 10,
          right: 12,
          margin: "0 !important",
          borderBottomRightRadius: "8px !important",
          borderBottomLeftRadius: "8px !important",
          borderTopLeftRadius: "8px !important",
          borderTopRightRadius: "8px !important",
          "& .MuiAccordionSummary-root": {
            minHeight: "40px",
            margin: 0,
          },          
          "& .MuiAccordionSummary-root.Mui-expanded": {
            width: "100%",
            minHeight: "40px",
            margin: 0,
          },       
          "& .MuiAccordion-region": { 
            width: "100%",
          },
          "&::before": {
            position: "relative"          
          }          
        }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            width: "100%",
            flexDirection: "row",
            px: 1,
            margin: 0,
            "& .Mui-expanded": { margin: "inherit" },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: 0
            },
            "& .MuiAccordionSummary-content": {
              flexDirection: "row", 
              width: "100%",
              margin: "inherit" 
            },
          }}
        >
          <AccountIcon
            fontSize="medium"
            sx={{
              p: "2px",
              mr: "3px",
              mb: 0.25,
              height: 25,
              width: 25,
              fill: "#FFF",
              backgroundColor: "#a7b1d6",
              borderRadius: "50%",
            }}
          />
          <Typography
            variant="button"
            fontFamily="Roboto"
            sx={{
              fontSize: "12px",
              lineHeight: "15px",
              alignSelf: "center",
              ml: 0.65,
              whiteSpace: "nowrap",
              maxWidth: 150,
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {user?.firstName} {user?.lastName}
            <em
              style={{
                display: "block",
                fontStyle: "normal",
                fontSize: "10px",
                color: "#999",
                // marginTop: "-3px",
                lineHeight: "11px"
              }}
            >
              {i18n("user_roles", user.role)}
            </em>
          </Typography>
        </AccordionSummary>
        <AccordionDetails 
          sx={{
            width: "100%",
            p: 0,
            borderTop: "1px solid #EEE", 
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
            borderBottomRightRadius: "8px",
            borderBottomLeftRadius: "8px"
          }}>
          <List
            sx={{
              height: "auto",
              width: "100%",
              maxWidth: "unset",
              bgcolor: "background.paper",
              py: 0,
              borderBottomRightRadius: "8px",
              borderBottomLeftRadius: "8px"
            }}
            component="nav"
          >
            {settings.map((setting, key) => (
              <ListItemButton
                key={key}
                disableRipple
                alignItems="flex-start"
                onClick={() => setting.handleClick()}
                sx={{
                  flexDirection: "row",
                  height: 40,
                  width: "100%",
                  p: 0,
                  px: "8px",
                  m: 0,
                  alignItems: "center",
                  color:
                    setting.navTo === locationPath
                      ? "common.white"
                      : "common.black",
                  backgroundColor:
                    setting.navTo === locationPath
                      ? "primary.light"
                      : "transparent",
                  transition: "color 0.3s, background-color 0.3s",
                  "&:hover": {
                    color: "common.white",
                    backgroundColor: "primary.light",
                    borderBottomLeftRadius:
                      key === settings.length - 1 ? "8px" : 0,
                    borderBottomRightRadius:
                      key === settings.length - 1 ? "8px" : 0
                  }
                }}
              >
                {setting?.icon ? (
                  <>
                    <ListItemIcon
                      sx={{
                        color: "inherit",
                        mt: 0,
                        mr: "3px",
                        mb: 0.25,
                        // ml: 1.5,
                        minWidth: "25px"
                      }}
                    >
                      {setting.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={setting.label}
                      sx={{
                        alignItems: "flex-start",
                        "& .MuiListItemText-primary": {
                          fontSize: "0.9rem",
                          ml: 0.65,
                        }
                      }}
                    />
                  </>
                ) : (
                  <ListItemText
                    primary={setting.label}
                    sx={{
                      "& .MuiListItemText-primary": {
                        fontSize: "0.9rem",
                        ml: 1.5
                      }
                    }}
                  />
                )}
              </ListItemButton>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </ClickAwayListener>
  );
};

export default DesktopMenu;

DesktopMenu.propTypes = {
  handlers: PropTypes.object
};
