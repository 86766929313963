import React from "react";
// HELPERS
import i18n from "@helpers/i18n";
import {createClassName} from "@helpers/utils";

import Box from "@mui/material/Box";

const DashboardTabs = (props) => {
  const {type, translationGroup, tabs, fetchingData, setPage, setSearchString, caseFilter, setCaseFilter} = props;

  const handleTabClick = (tabKey) => {
    setPage(0); // TODO: move this to each "TAB(data) change" useEffect
    setSearchString(''); // TODO: move this to each "TAB(data) change" useEffect
    setCaseFilter(type, tabKey);
  }

  return (
    <Box
      sx={{
        display: "block",
        width: "100%",
        border: 0,
        mb: {xs: 0.8, md: 0},
        overflow: "auto"
      }}
    >
      <ul className="dashboard-tabs">
        {tabs.map((tab) => {
          const classes = createClassName(tab.key, {
            "selected": caseFilter[type] === tab.key,
            "disabled": fetchingData || false
          });
          return (
          <li
            key={tab.key}
            className={classes}
            onClick={() => !fetchingData && handleTabClick(tab.key)}
          >
            {i18n(translationGroup, tab.translationKey)}
          </li>
        )})}
      </ul>
    </Box>
  );
};

export default DashboardTabs;