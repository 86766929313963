// MODULES
import React from "react";
import PropTypes from "prop-types";

// CLASSES
// COMPONENTS
import Modal from "@components/Modal/Modal";

// HELPERS
import i18n from "@helpers/i18n";
import api from "@helpers/api";
import Settings from "../../../../../../classes/Settings";


export default class AssistantModal extends React.PureComponent
{
	static propTypes = {
		busy: PropTypes.bool.isRequired,
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		onSend: PropTypes.func.isRequired
	};

	state = _getInitialState.call(this);

	render()
	{
		const {props} = this;
		const {visible, onRestart, advisor} = props;

		if (!visible)
		{
			return null;
		}

		return (
			<Modal
				className="Cob-SignAgreement-SignModal"
				visible={true}
				title={i18n("cob", "sign_agreement_assistant")}>
				<div className="Cob-SignAgreement-SignModal-content">
					<p>
						Då du enbart har assistentbehörighet kan du ej skicka ut ärendet för signering av kund.
					</p>
					<p>
						Klicka på knappen nedan för att meddela rådgivaren (<strong>{advisor.firstName + " " +
						advisor.lastName}</strong>) att ärendet är redo.
					</p>
					<p>
						Du kan även avsluta rådgivningen utan att meddela rådgivaren.
					</p>

					<Modal.ButtonsContainer>
						<Modal.Button
							label={i18n("footer", "end_consultation")}
							appearance="medium"
							disabled={this.state.busy}
							onClick={onRestart}
						/>

						<Modal.Button
							label={i18n("sign_agreement", "send_for_signing_assistant")}
							appearance={"success"}
							busy={this.state.busy}
							onClick={this._onSend}
							filled
						/>
					</Modal.ButtonsContainer>
				</div>
			</Modal>
		);
	}

	_onSend = async () =>
	{
		this.setState({busy: true});
		await api("/case/ready", {
			ssn: this.props.user.ssn,
			caseId: Settings.get("CASE_ID")
		});

		this.props.onRestart();
	};
}

function _getInitialState() {
	const {props} = this;

	return {
		busy: props.busy
	};
}