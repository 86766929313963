import React from "react";

// HELPERS
import { localeNumber } from "@helpers/utils";


export default function TableSummaryField({ name, label, description, value, valueSuffix, format = true, decimals = 0 }) {
    const f = (v) => format === true ? localeNumber(v, decimals) : v.toString().replace(".", ",");

    return (
        <div className="Cob-TableSummaryField">
            <div className="Cob-TableSummaryField-label">{ label }</div>
            { !!description && <div className="Cob-TableSummaryField-description">{ description }</div> }

            <div className="Cob-TableSummaryField-value">{ f(value) + (valueSuffix || "") }</div>
            { name && <input type="hidden" name={ name } value={ f(value) + (valueSuffix || "") } /> }
        </div>
    );
}