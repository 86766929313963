// MODULES
import React from "react";
import PropTypes from "prop-types";

// CLASSES
import Validator from "@classes/Validator";

// COMPONENTS
import Checkbox from "@components/Checkbox/Checkbox";
import Modal from "@components/Modal/Modal";
import Notice from "@components/Notice/Notice";
import Signatory from "../Signatory/Signatory";

// HELPERS
import i18n from "@helpers/i18n";


export default class SignModal extends React.PureComponent {
	static propTypes = {
		busy: PropTypes.bool.isRequired,
		visible: PropTypes.bool.isRequired,
		data: PropTypes.object,
		status: PropTypes.string,
		errorMessage: PropTypes.string,
		signUrls: PropTypes.array,
		onClose: PropTypes.func.isRequired,
		onSign: PropTypes.func.isRequired,
		onRetry: PropTypes.func.isRequired,
	};

	state = _getInitialState.call(this);

	componentDidUpdate(prevProps) {
		const {props} = this;
		const newStateProps = {};

		if (prevProps.data !== props.data && props.data) newStateProps.dataEditable = _cloneData(props.data);

		if (Object.keys(newStateProps).length > 0) this.setState(newStateProps);
	}

	render() {
		const {props, state} = this;
		const {busy, visible, status, errorMessage, signUrls, onClose, onRetry, onRestart, signUrlsSecond, signUrlsLabel, signUrlsSecondLabel, user} = props;
		const {dataEditable} = state;

		if (!visible)
		{
			return null;
		}

		const canSign = user.role !== "assistant" && this._canSign();

		return (
			<Modal
				className="Cob-SignAgreement-SignModal"
				visible={visible}
				title={i18n("cob", "sign_agreement")}
				status={status === "fail" ? "danger" : status}>
				{!!dataEditable && (
					<div className="Cob-SignAgreement-SignModal-content">
						{!status ? (
							<React.Fragment>
								<p className="Cob-description">{dataEditable.signatories.length === 1 ? i18n("sign_agreement", "signatory") : i18n("sign_agreement", "signatories")}</p>

								<div className="Cob-SignAgreement-SignModal-signatories">
									{dataEditable.signatories.map((signatoryData, index) => (
										<Signatory
											key={index}
											data={signatoryData}
											index={index}
											selectable={dataEditable.signatories.length > 1}
											selectedSignatoriesAmount={dataEditable.signatories.filter(s => s.selected !== false).length}
											onChange={this._onSignatoryChange}
										/>
									))}
								</div>

								<div className="h-line"/>

								<Checkbox
									label={i18n("sign_agreement", "sign_on_screen")}
									checked={dataEditable.signOnScreen}
									onChange={this._onSignOnScreenChange}
								/>

								{dataEditable.signOnScreen && (
									<Notice className="sign-on-screen-notice" appearance="warning">
										<p>När avtal signeras på skärm kan du välja följande alternativ:</p>

										<ul>
											<li>
												Ange telefonnummer till en smartphone och signera på skärmen.
											</li>
											<li>
												Har rådgivningen genomförts på en enhet med touch-screen, tryck på &quot;Gå
												till signeringssida&quot; efter nästa steg och låt kunden signera på skärmen.
											</li>
											<li>
												Be kunden öppna signeringsmejlet på en enhet med touch-screen (som
												skickats till kundens angivna e-postadress).
											</li>
										</ul>

										<p>
											<strong>
												Observera att ett foto av kundens legitimation måste bifogas vid
												signering.
											</strong>
										</p>
									</Notice>
								)}

								<Modal.ButtonsContainer>
									<Modal.Button
										label={i18n("general", "close")}
										appearance="medium"
										disabled={busy}
										onClick={onClose}
									/>

									<Modal.Button
										label={i18n("sign_agreement", "send_for_signing")}
										appearance={canSign ? "success" : "medium"}
										disabled={!canSign}
										busy={busy}
										onClick={this._onSign}
										filled
									/>
								</Modal.ButtonsContainer>
							</React.Fragment>
						) : (
							<React.Fragment>
								{status === "success" ? (
									<React.Fragment>
										{signUrls != null ? (
											<React.Fragment>
												<p>{i18n("sign_agreement", "agreement_sent")}</p>

												{signUrlsSecond != null && (
													<React.Fragment>
														<p>
															{i18n("sign_agreement", "agreement_sent_text")}
														</p>
														<div className="Cob-SignAgreement-SignModal-signUrls" style={{marginBottom: "20px"}}>
															<p className="Cob-description">{signUrlsLabel}</p>
															<ul>
																{signUrls.map((url, index) =>
																	{
																		if (index >= dataEditable.signatories.length)
																		{
																			return null;
																		}

																		return (
																			<li key={index}>
																				<a href={url} target="_blank"
																				   rel="noopener noreferrer">
																					{dataEditable.signatories[index].firstName + " " + dataEditable.signatories[index].lastName}
																				</a>
																			</li>
																		);
																	}
																)}
															</ul>
														</div>
														<div className="Cob-SignAgreement-SignModal-signUrls" style={{marginBottom: "20px"}}>
															<p className="Cob-description">{signUrlsSecondLabel}</p>
															<ul>
																{signUrlsSecond.map((url, index) =>
																	{
																		if (index >= dataEditable.signatories.length)
																		{
																			return null;
																		}

																		return (
																			<li key={index}>
																				<a href={url} target="_blank"
																				   rel="noopener noreferrer">
																					{dataEditable.signatories[index].firstName + " " + dataEditable.signatories[index].lastName}
																				</a>
																			</li>
																		);
																	}
																)}
															</ul>
														</div>
													</React.Fragment>
												)}

												{signUrlsSecond == null && (
													<React.Fragment>
													{signUrls.length === 1 ? (
															<p>{i18n("sign_agreement", "agreement_sent_single_text")}</p>
														) : (
															<React.Fragment>
																<p>{i18n("sign_agreement", "agreement_sent_text")}</p>
																<div className="Cob-SignAgreement-SignModal-signUrls">
																	<p className="Cob-description">{i18n("table_container", "signatories")}</p>
																	<ul>
																		{signUrls.map((url, index) => {
																				return index < dataEditable.signatories.length && (
																					<li key={index}>
																						<a href={url} target="_blank"
																						   rel="noopener noreferrer">
																							{dataEditable.signatories[index].firstName + " " + dataEditable.signatories[index].lastName}
																						</a>
																					</li>
																				)
																			}
																		)}
																	</ul>
																</div>
															</React.Fragment>
														)}
													</React.Fragment>
												)}
											</React.Fragment>
										) : (
											<React.Fragment>
												<p>Dokumentationen har skapats.</p>
											</React.Fragment>
										)}
									</React.Fragment>
								) : (
									<React.Fragment>
										<p>Avtal kunde inte skickas iväg för signering.</p>
										<div className="error-message" data-label="Felmeddelande">{errorMessage}</div>
									</React.Fragment>
								)}

								<Modal.ButtonsContainer>
									{status === "fail" && (
										<Modal.Button
											key="retry-sign"
											label="Försök igen"
											appearance="primary"
											disabled={busy}
											onClick={onRetry}
										/>
									)}

									<Modal.Button
										key="quit-sign-modal"
										label={i18n("footer", "end_consultation")}
										appearance="primary"
										disabled={busy}
										onClick={onRestart}
										filled={status === "fail" || (!!signUrls && signUrls.length > 1)}
									/>

									{status === "success" && signUrls != null && signUrls.length === 1 && signUrlsSecond == null && (
										<Modal.Button
											key="go-sign-page"
											label={i18n("sign_agreement", "to_signing_page")}
											appearance="primary"
											disabled={busy}
											onClick={this._onOpenSingleSignPage}
											filled
										/>
									)}
								</Modal.ButtonsContainer>
							</React.Fragment>
						)}
					</div>
				)}
			</Modal>
		);
	}

	// Internal methods
	_canSign = () => _validateSignatories.call(this);
	_onSignOnScreenChange = (signOnScreen) => this.setState({dataEditable: {...this.state.dataEditable, signOnScreen}});
	_onOpenSingleSignPage = () => window.open(this.props.signUrls[0], "_blank");

	_onSignatoryChange = (signatoryData, index) => {
		const dataEditable = {...this.state.dataEditable};
		dataEditable.signatories[index] = signatoryData;
		this.setState({dataEditable});
	};

	_onSign = () => {
		const {props, state} = this;
		const {onSign} = props;
		const {signOnScreen} = state.dataEditable;
		const signatories = state.dataEditable.signatories.filter(s => s.selected).map(s => {
			s = {...s};
			delete s.selected;
			return s;
		});

		onSign({signatories, signOnScreen});
	};
}

// PRIVATE FUNCTIONS
function _getInitialState() {
	const {props} = this;

	return {
		dataEditable: props.data ? _cloneData(props.data) : null,
	};
}

function _cloneData(data) {
	return JSON.parse(JSON.stringify(data));
}

function _validateSignatories() {
	const {state} = this;
	const {dataEditable} = state;

	if (!dataEditable) return false;
	const signatories = dataEditable.signatories;

	for (let i = 0, n = signatories.length; i < n; i++) {
		const {firstName, lastName, ssn, email, phoneNumber, selected} = signatories[i];

		// If not selected as signatory, continue to next
		if (selected === false) continue;

		// First name
		if (firstName.length === 0) return false;

		// Last name
		if (lastName.length === 0) return false;

		// Social security number
		if (ssn.length === 0 || !Validator.validateSsn(ssn.replace("-", ""))) return false;

		// Email
		if (email.length === 0 || !Validator.validateEmail(email)) return false;


		// Phone number needs to be a valid Swedish phone if we requested an SMS to be sent
		if (signatories[i].isSmsRequested) {
			if (phoneNumber.length <= 0 || !Validator.validatePhoneForSmsSigning(phoneNumber)) {
				return false;
			}
		}
	}

	return true;
}