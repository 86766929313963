// MODULES
import React from "react";
import PropTypes from "prop-types";
import i18n from "../../../../helpers/i18n";

export default class PageHeader extends React.PureComponent {
	static propTypes = {
		user: PropTypes.object.isRequired,
		advisor: PropTypes.object.isRequired
	};

	render() {
		const {user, advisor} = this.props;

		return (
			<h2>
				{i18n("general", "welcome") + ", " + user.firstName + "!"}
				{user.ssn !== advisor.ssn && (
					<span className={"advisor"}>Assistent till: {advisor.firstName} {advisor.lastName}</span>
				)}
			</h2>
		);
	}
}