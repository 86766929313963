import {mergeViewStateForFulfillment} from "../../../Cob.helpers";
import Validator from "../../../../../classes/Validator";


export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	if (state.pageDisabled)
	{
		return true;
	}

	if (state.accountNaked)
	{
		if (customerState["uppdragsavtal_af_task_1"].length <= 0 ||
			customerState["uppdragsavtal_af_fee_1"].length <= 0 ||
			customerState["uppdragsavtal_af_fee_1"] === "-")
		{
			return false;
		}

		for (let i = 1; i <= 3; i++)
		{
			const taskEmpty = customerState["uppdragsavtal_af_task_" + i].length <= 0 || customerState["uppdragsavtal_af_task_" + i] === "-";
			const feeEmpty = customerState["uppdragsavtal_af_fee_" + i].length <= 0 || customerState["uppdragsavtal_af_fee_" + i] === "-";
			if ((!taskEmpty && feeEmpty) || (!feeEmpty && taskEmpty))
			{
				return false;
			}
		}
	}

	if (state.accountIsk)
	{
		if (customerState["uppdragsavtal_isk_task_1"].length <= 0 ||
			customerState["uppdragsavtal_isk_fee_1"].length <= 0 ||
			customerState["uppdragsavtal_isk_fee_1"] === "-")
		{
			return false;
		}

		for (let i = 1; i <= 3; i++)
		{
			const taskEmpty = customerState["uppdragsavtal_isk_task_" + i].length <= 0 || customerState["uppdragsavtal_isk_task_" + i] === "-";
			const feeEmpty = customerState["uppdragsavtal_isk_fee_" + i].length <= 0 || customerState["uppdragsavtal_isk_fee_" + i] === "-";
			if ((!taskEmpty && feeEmpty) || (!feeEmpty && taskEmpty))
			{
				return false;
			}
		}
	}

	if (state.accountKf)
	{
		if (customerState["uppdragsavtal_kf_task_1"].length <= 0 ||
			customerState["uppdragsavtal_kf_fee_1"].length <= 0 ||
			customerState["uppdragsavtal_kf_fee_1"] === "-")
		{
			return false;
		}

		for (let i = 1; i <= 3; i++)
		{
			const taskEmpty = customerState["uppdragsavtal_kf_task_" + i].length <= 0 || customerState["uppdragsavtal_kf_task_" + i] === "-";
			const feeEmpty = customerState["uppdragsavtal_kf_fee_" + i].length <= 0 || customerState["uppdragsavtal_kf_fee_" + i] === "-";
			if ((!taskEmpty && feeEmpty) || (!feeEmpty && taskEmpty))
			{
				return false;
			}
		}
	}

	if (state.accountTp)
	{
		if (customerState["uppdragsavtal_tp_task_1"].length <= 0 ||
			customerState["uppdragsavtal_tp_fee_1"].length <= 0 ||
			customerState["uppdragsavtal_tp_fee_1"] === "-")
		{
			return false;
		}

		for (let i = 1; i <= 3; i++)
		{
			const taskEmpty = customerState["uppdragsavtal_tp_task_" + i].length <= 0 || customerState["uppdragsavtal_tp_task_" + i] === "-";
			const feeEmpty = customerState["uppdragsavtal_tp_fee_" + i].length <= 0 || customerState["uppdragsavtal_tp_fee_" + i] === "-";
			if ((!taskEmpty && feeEmpty) || (!feeEmpty && taskEmpty))
			{
				return false;
			}
		}
	}

	if (customerState.uppdragsavtal_confirm_terms !== "Ja")
	{
		return false;
	}

	if (customerState.uppdragsavtal_confirm_forkop !== "Ja")
	{
		return false;
	}

	if (customerState.uppdragsavtal_confirm_pricelist !== "Ja")
	{
		return false;
	}

	const availableBanks = this === undefined ? state.availableBanks : this.props.availableBanks;

	const clearingNoValid = Validator.validateClearingNumber(
		customerState.bank_clearingno,
		customerState.bank_name,
		availableBanks
	);

	if (customerState.checkbox_override_validation === "no" && !clearingNoValid)
	{
		return false;
	}

	/**
	 * For the banks where we know the validation rules, check if the number should be validated.
	 */
	let shouldValidateAccountNumber = false;
	for (const bankName in availableBanks)
	{
		if (bankName === 'length' || !availableBanks.hasOwnProperty(bankName))
		{
			continue;
		}

		if (customerState.bank_name === bankName)
		{
			const bankInfo = availableBanks[bankName];
			if (bankInfo.length > 0)
			{
				if (bankInfo[0]['validationEnabled'] === "1")
				{
					shouldValidateAccountNumber = true;
				}
			}
			break;
		}
	}

	if (shouldValidateAccountNumber && customerState.checkbox_override_validation !== "yes")
	{
		if (customerState.bank_clearingno.length <= 0 ||
			customerState.bank_accountno.length <= 0)
		{
			return false;
		}

		let bnk = customerState.bank_clearingno + ", " + customerState.bank_accountno;
		if (!window.kontonummer(bnk))
		{
			return false;
		}
	}

	return true;
}