export default class Settings {
	static keysWithDefaults = Object.freeze({
		"AUTH_TOKEN": null,
		"LANGUAGE": "sv",
		"CASE_ID": null,
		"LAST_VIEW": null,
		"FA_SSN": null,
	});

	static get(key) {
		try {
			if (typeof Settings.keysWithDefaults[key] === "undefined") {
				throw new Error("UNKNOWN_KEY");
			}

			const value = localStorage.getItem(key);
			return value !== null ? JSON.parse(atob(value)) : Settings.keysWithDefaults[key];
		}
		catch (e) {
			console.error({status: e, key});
		}
	}

	static set(key, value) {
		try {
			if (typeof Settings.keysWithDefaults[key] === "undefined") {
				throw new Error("UNKNOWN_KEY");
			}

			localStorage.setItem(key, btoa(JSON.stringify(value)));
		}
		catch (e) {
			console.error({status: e, key});
		}
	}

	static remove(key) {
		localStorage.removeItem(key);
	}

	static clear() {
		for (const key in Settings.keysWithDefaults) localStorage.removeItem(key);
	}
}