import React from "react";

// HELPERS
import api from "@helpers/api";
import i18n from "@helpers/i18n";
import { getCookie } from "@helpers/cookies";
import {formatSSN} from "@helpers/utils";

// COMPONENTS
import Modal from "@components/Modal/Modal";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import ContentBox from "@components/ContentBox/ContentBox";

import PoaTable from "./PoaTable";


/**
 * Futurkonton
 */
export default class DashboardPoas extends React.PureComponent
{

	state = _getInitialState.call(this);

	async componentDidMount()
	{
		this._isMounted = true;

    try {
      const userCaseList = await _getUserPoaCaseList.call(this);
      if (userCaseList) {
        this.setState({
          isLoading: false,
          userCaseList: userCaseList.batch,
          totalCases: parseInt(userCaseList.total)
        });
      }
    }
    catch (error) {
      this.setState({
        error: true,
        isLoading: false
      });
      console.error(error);
    }
	}

	componentWillUnmount()
	{
		this._isMounted = false;
	}

  async componentDidUpdate(prevProps, prevState)
	{
    if (this.props.caseFilter.poa !== prevProps.caseFilter.poa) {
      this.setState({
        isLoadingCases: true
      });
      const userCaseList = await _getUserPoaCaseList.call(this);
      this.setState({
        isLoadingCases: false,
        userCaseList: userCaseList.batch,
        totalCases: parseInt(userCaseList.total)
      });
    }
	}

	render()
	{
		const {props, state} = this;
		const {user, advisor, partner, faAuth, showHeader} = props;

		// /**
		//  * Filter caseList depending on which tab has been selected (if the selected tab is not "all")
		//  */

    // const caseList = props.caseFilter.poa === "all"
    // ? state.userCaseList
    // : state.userCaseList.filter(userCase => userCase.state === props.caseFilter.poa &&
    // (
    //     (props.type === 'customerModal' && props?.client?.id === userCase.ssn) ||
    //     (props.type !== 'customerModal')
    // ));


		return (
			<>
				<ContentBox
         style={
          props.type === "customerModal" ? 
            {
              minHeight: "66vh",
              margin: 0,
              padding: 0,
              overflow: "auto",
              boxShadow: "none"
            }
          :
            {
              width: "98.5%",
              margin: 0,
              marginBottom: "22.5px"
            }
          }
        >
          {showHeader && (
            <h2>
              {i18n("dashboard_selector", "poa")}
            </h2>
          )}
					{state.isLoading && (
						<div style={{padding: "91px 0"}}>
							<ActivityIndicator color="#4cb7d5" busy/>
						</div>
					)}
          {state.error && (
						<div style={{padding: "91px 0"}}>Ett fel uppstod när data laddades, kontakta supporten.
						</div>
					)}
					{!state.error && !state.isLoading && (
            <>
              <div
                className="dashboard-cases"
                style={
                  props.type === "customerModal" ? 
                    { 
                      minHeight: "unset"
                    }
                  :
                    {}
                }
              >
                <PoaTable
                  user={user}
                  partner={partner}
                  advisor={advisor}
                  data={state.userCaseList}
                  totalCases={state.totalCases}
                  isLoadingCases={state.isLoadingCases || false}
                  currentAction={state.currentAction}
                  modalWidget={props.type === "customerModal" ? true : false}
                  faAuth={faAuth}
                  caseFilter={props.caseFilter} 
                  setCaseFilter={props.setCaseFilter}
                  nextStepOptions={props.nextStepOptions}
                  caseFilterContext={props.caseFilterContext}
                  setCustomerState={props.setCustomerState}
                  getCases={_getUserPoaCaseList.bind(this)}
                  onAction={this._onRowAction}
                />

                {state.isDisabled && (
                  <div className="dashboard-cases-disabled">
                    <ActivityIndicator color="#4cb7d5" busy/>
                  </div>
                )}
              </div>
            </>
					)}
				</ContentBox>

				<Modal visible={state.isReminderModalVisible}>
					<p>{i18n("dashboard_poas", "reminder_sent")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideReminderModal}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isRecallModalVisible}>
					<p>{i18n("dashboard_poas", "case_revoked")}.</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideRecallModal}/>
					</Modal.ButtonsContainer>
				</Modal>
			</>
		);
	}

	_onRowAction = async (action, poaId, customerSsn, callbackFunc) =>
	{    
		const {type, advisor} = this.props;

    this.setState({
      currentAction: action
    });
    
		if (action === 'remind')
		{
			api("/fmk/remind", {
				ssn: customerSsn,
				advisor: advisor.ssn
			});

			this.setState({
        currentAction: 'none',
				isReminderModalVisible: true
			});
		}
		else if (action === 'revoke')
		{
      this.setState({
        isLoadingCases: true
      });

			api("/fmk/revoke", {
				poaId: poaId,
				advisor: advisor.ssn
			});
      
      const userCaseList = await _getUserPoaCaseList.call(this);
			this.setState({
				isRecallModalVisible: true,
        isLoadingCases: false,
        currentAction: 'none',
				userCaseList: userCaseList.batch
			});      
      
      if (callbackFunc) callbackFunc();
		}

    if (type === "customerModal") {
      document.documentElement.style.overflow = "auto"; // re-enable main page scroll
    }
	};

	_onHideReminderModal = () =>
	{
		this.setState({
			isReminderModalVisible: false
		});
	};

	_onHideRecallModal = () =>
	{
		this.setState({
			isRecallModalVisible: false
		});
	};
}

function _getInitialState()
{
	return {
		tabSelected: "all",
		isLoading: true,
    isLoadingCases: false,
    currentAction: 'none',
		isDisabled: false,
		isMounted: false,
		isReminderModalVisible: false,
		isRecallModalVisible: false,
		userCaseList: []
	};
}

function _getUserPoaCaseList(page, searchString, order, orderBy)
{
	const ssn = this.props.user.ssn;
  
  // check if we have any options saved..
  const cobOptions = JSON.parse(getCookie("cobOptions")) || null;
  // .. and set rowsPerPage
  const rowsPerPage= cobOptions?.rowsPerPage["poa"] || 10;

	return new Promise(async (resolve, reject) =>
	{
		try
		{
			console.log("Loading list of poas...");
			const cases = await api("/fmk/list", {
				ssn: ssn,
        customerSsn:
          this.props?.client?.id ? // if a client was passed as a prop (customer modal)
            formatSSN(this.props.client.id, {withDash: false})
          : this.props?.client?.id === "" ? // handle client was passed as a prop but with no ssn set
              ""
            : null, // no ssn passed means it's a main dashboard widget not filtered by customer
        filter: this.props.caseFilter.poa,
        page: page || 0,
        order: order || "desc",
        orderBy: orderBy || "created",
        searchString: searchString || "",
        limit: rowsPerPage === -1 ? 100 : rowsPerPage
			});

			if (this._isMounted)
			{
				resolve(cases);
			}
		}
		catch (e)
		{
			reject(e);
		}
	});
}