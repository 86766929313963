// MODULES
import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AdvisorsIcon from '@mui/icons-material/PeopleAltOutlined';

import Card from "@cobComponents/Card";
import WidgetTabs from "@components/WidgetTabs";
import useAdvisorsCard from "./useAdvisorsCard";

const AdvisorsCard = props => {
  const { 
    loaded,
    tabs,
    activeTab,
    setActiveTab,
    advisors
  } = useAdvisorsCard(props);

  return (
    <Card
      title="Rådgivare"
      icon={AdvisorsIcon}
      loading={!loaded}
      sxStyle={{
        mt: {xs: 2, md: 0},
      }}
    >
        <WidgetTabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />        
        {advisors[activeTab] && advisors[activeTab].length < 1 && (
          <Box 
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              px: 1,
              mt: 0.6
            }}
          >
            <Typography color="inherit" variant="body2" align="left">
              Inga rådgivare hittades
            </Typography>
          </Box>
        )}
        {advisors[activeTab]?.map((advisor, index) => (
          <Box 
            key={index}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              mt: 0.6,
              px: 1,
              pt: "3px",
              pb: "3px",
              backgroundColor: index % 2 !== 0 ? "#f5f9ff" : "inherit",
            }}
          >
            <Typography color="inherit" variant="listItemStrong">
              {advisor.first_name} {advisor.last_name}
            </Typography>
            <Typography color="inherit" variant="listItemSub">
              {advisor.company_name}
            </Typography>
          </Box>
        ))}
    </Card>
  );
};

export default AdvisorsCard;