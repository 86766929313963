// MODULES
import React from "react";
import PropTypes from "prop-types";

// CLASSES
import Validator from "@classes/Validator";

// COMPONENTS
import FieldsCollection from "@components/FieldsCollection/FieldsCollection";
import Checkbox from '@components/Checkbox/Checkbox';
import TextField from "@components/TextField/TextField";
import Select from "@components/Select/Select";

// HELPERS
import i18n from "@helpers/i18n";
import countries from "@helpers/countries";


const countriesOptions = countries.map(({label, code}) => ({label, value: code}));
const countriesOptionsValuesOnly = countries.map(({label, code}) => code);

export default class CompanyReps extends React.PureComponent {
	static propTypes = {
		reps: PropTypes.array.isRequired,
		onRepChange: PropTypes.func.isRequired,
		activeAmount: PropTypes.number.isRequired,
    highlightErrors: PropTypes.bool,
	};

	render() {
		const {props} = this;
		const {reps, activeAmount, highlightErrors} = props;

		return (
			<div className="Cob-view2-CompanyReps">
				<h3>Information om företagsföreträdare</h3>

				<div className="Cob-view2-CompanyReps-list">
					{reps.map((rep, index) => index < activeAmount && (
						<React.Fragment key={index}>
							<CompanyRepsRep key={index}
                data={rep}
                index={index}
                showHeading={activeAmount > 1}
                onChange={this._onRepChange}
                highlightErrors={highlightErrors}/>
						</React.Fragment>
					))}
				</div>
			</div>
		);
	}

	// Internal methods
	_onRepChange = (data, index) => {
		this.props.onRepChange(data, index);
	}
}


// PRIVATE COMPONENTS
class CompanyRepsRep extends React.PureComponent {
	static propTypes = {
		data: PropTypes.object.isRequired,
		onChange: PropTypes.func.isRequired,
		index: PropTypes.number.isRequired,
		showHeading: PropTypes.bool.isRequired,
		highlightErrors: PropTypes.bool,
	};

	render() {
		const {props} = this;
		const {highlightErrors, data, index, showHeading} = props;
		const selectedCitizenIndex = (() => {
			let returnValue = countriesOptionsValuesOnly.indexOf(data.citizenship);
			if (returnValue === -1) returnValue = countriesOptionsValuesOnly.indexOf("SE"); // If -1, set "SE" as default
			return returnValue;
		})();

		return (
			<div className="Cob-view2-CompanyReps-Rep">
				{showHeading && <h4>Företrädare {index + 1}</h4>}

				<div className="Cob-view2-CompanyReps-Rep-type">

					<CompanyRepsRepCheckbox type="signatory" repIndex={index} label="Firmatecknare"
											checked={data.types.indexOf("signatory") !== -1}
											onChange={this._onTypeChange}/>

					<CompanyRepsRepCheckbox type="poa" repIndex={index} label="Fullmaktshavare"
											checked={data.types.indexOf("poa") !== -1} onChange={this._onTypeChange}/>

					<CompanyRepsRepCheckbox type="benowner" repIndex={index} label="Verklig huvudman"
											checked={data.types.indexOf("benowner") !== -1} onChange={this._onTypeChange}/>

					<CompanyRepsRepCheckbox type="board" repIndex={index} label="Styrelsemedlem"
											checked={data.types.indexOf("board") !== -1} onChange={this._onTypeChange}/>

				</div>

				<FieldsCollection
					fieldsPerRow={2}
					fields={[
						{
							key: "firstName",
							render: (
								<CompanyRepsRepTextField
									label={i18n("general", "first_name")}
									value={data.firstName}
									nameSuffix={"firstname"}
									repIndex={index}
									onChange={this._onTextFieldChange}
                  highlightErrors={highlightErrors}
								/>
							)
						}, {
							key: "lastName",
							render: (
								<CompanyRepsRepTextField
									label={i18n("general", "last_name")}
									value={data.lastName}
									nameSuffix={"lastname"}
									repIndex={index}
									onChange={this._onTextFieldChange}
                  highlightErrors={highlightErrors}
								/>
							)
						}, {
							key: "email",
							render: (
								<CompanyRepsRepTextField
									type="email"
									label={i18n("general", "email_address")}
									value={data.email}
									nameSuffix={"email"}
									repIndex={index}
									onChange={this._onTextFieldChange}
                  highlightErrors={highlightErrors}
								/>
							)
						}, {
							key: "ssn",
							render: (
								<CompanyRepsRepTextField
									type="number"
									label={i18n("general", "social_security_number")}
									value={data.ssn}
									nameSuffix="sec_num"
									repIndex={index}
									onChange={this._onTextFieldChange}
                  highlightErrors={highlightErrors}
								/>
							)
						}, {
							key: "citizenship",
							render: (
								<Select
									name={`company_representative_citizenship_${index}`}
									label="Nationalitet"
									options={countriesOptions}
									selectedIndex={selectedCitizenIndex}
									onChange={this._onCitizenshipChange}
                  highlightErrors={highlightErrors}
								/>
							)
						}
					]}
				/>
			</div>
		);
	}

	// Internal methods
	_onTypeChange = (typeName, checked) => {
		const {onChange, data, index} = this.props;
		const types = [...data.types];
		const typeIndex = types.indexOf(typeName);

		if (checked && typeIndex === -1) types.push(typeName);
		if (!checked && typeIndex !== -1) types.splice(typeIndex, 1);

		onChange({...data, types}, index);
	};

	_onTextFieldChange = (nameSuffix, value) => {
		const {onChange, data, index} = this.props;
		let type;

		switch (nameSuffix) {
			case "firstname":
				type = "firstName";
				break;
			case "lastname":
				type = "lastName";
				break;
			case "sec_num":
				type = "ssn";
				break;
			case "email":
				type = "email";
				break;
			default:
				break;
		}

		if (type) onChange({...data, [type]: value}, index);
	};

	_onCitizenshipChange = (option) => {
		const {onChange, data, index} = this.props;
		onChange({...data, citizenship: option.value}, index);
	}
}

class CompanyRepsRepCheckbox extends React.PureComponent {
	static propTypes = {
		type: PropTypes.string.isRequired,
		repIndex: PropTypes.number.isRequired,
		label: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
		checked: PropTypes.bool.isRequired,
    highlightErrors: PropTypes.bool,
	};

	render() {
		const {label, repIndex, checked, type, highlightErrors} = this.props;

		return (
			<Checkbox
				className="Cob-view2-CompanyReps-Checkbox"
				name={`checkbox_customer_comprepr_${type}_${repIndex}`}
				label={label}
				value={checked ? 'yes' : 'no'}
				checked={checked}
				onChange={this._onChange}
        highlightErrors={highlightErrors}
			/>
		);
	}

	// Internal methods
	_onChange = (checked) => {
		const {type, onChange} = this.props;
		onChange(type, checked);
	}
}

class CompanyRepsRepTextField extends React.PureComponent {
	static propTypes = {
		type: PropTypes.string.isRequired,
		repIndex: PropTypes.number.isRequired,
		label: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
		nameSuffix: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
    highlightErrors: PropTypes.bool,
	};

	static defaultProps = {
		type: "text",
	};

	render() {
		const {label, repIndex, type, nameSuffix, value, highlightErrors} = this.props;

		return (
			<TextField
				className={"Cob-view2-CompanyReps-TextField"}
				type={type}
				name={`customer_comprepr_${nameSuffix}_${repIndex}`}
				label={label}
				value={value}
				validation={this._validation}
				onChange={this._onChange}
        highlightErrors={highlightErrors}
			/>
		);
	}

	// Internal methods
	_onChange = ({target}) => {
		const {nameSuffix, onChange} = this.props;
		onChange(nameSuffix, target.value);
	};

	_validation = (value) => {
		if (this.props.nameSuffix === "sec_num") {
			return Validator.validateSsn(value);
		}
		else if (this.props.type === "number") {
			return value.length > 0 && !isNaN(value);
		}
		else if (this.props.type === "email") {
			return Validator.validateEmail(value);
		}

		return value.length > 0;
	}
}