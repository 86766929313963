import React from "react";

// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../components/DefaultFields/DefaultFields";
import TextView from "@components/TextView/TextView";

// HELPERS
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";

// OTHER
import fulfillment from "./MeetingNotes.fulfillment";


export default class MeetingNotesView extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state)
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) callbackFn();
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {}
			},
		});
	}

	render() {
		const {state} = this;
		const {customerState} = state;
		const classes = createClassName("Cob-view Cob-view8", {});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Rådet</h2>
					<h3>Mötesnoteringar</h3>

					<div className="Cob-view8-fields">

						<h4>Bakgrund och förutsättning till mötet</h4>
						<TextView
							name="advice_notes"
							label={`${i18n("general", "notes")} (${1700 - customerState.advice_notes.length} tecken kvar)`}
							value={customerState.advice_notes}
							maxLength={1500}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({advice_notes: target.value})}
							fullWidth
							minHeight={250}
						/>

						<h4>Saker som har diskuterats under mötet</h4>
						<TextView
							name="advice_notes2"
							label={`${i18n("general", "notes")} (${1700 - customerState.advice_notes2.length} tecken kvar)`}
							value={customerState.advice_notes2}
							maxLength={1500}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({advice_notes2: target.value})}
							fullWidth
							minHeight={250}
						/>

						<h4>Information om produkten/portföljen</h4>
						<p>T.ex. risker, löptid, produktslag</p>
						<TextView
							name="advice_notes3"
							label={`${i18n("general", "notes")} (${1800 - customerState.advice_notes3.length} tecken kvar)`}
							value={customerState.advice_notes3}
							maxLength={1800}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({advice_notes3: target.value})}
							fullWidth
							minHeight={250}
						/>

						<h4>Tredjepartsersättning</h4>
						<p>
							Motivera eventuell tredjepartsersättning till rådgivaren genom att beskriva hur ni ökar
							kvalitén eller iakttar kundens intresse genom t.ex möten, uppföljning, analys, utbildning
							osv.
						</p>
						<TextView
							name="advice_notes4"
							label={`${i18n("general", "notes")} (${1500 - customerState.advice_notes4.length} tecken kvar)`}
							value={customerState.advice_notes4}
							maxLength={1500}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({advice_notes4: target.value})}
							fullWidth
							minHeight={250}
						/>

						<h4>Övrigt</h4>
						<p>
							Eventuella ytterligare intressekonflikter / annat som tagits i beaktan i mötet
						</p>
						<TextView
							name="advice_notes5"
							label={`${i18n("general", "notes")} (${3000 - customerState.advice_notes5.length} tecken kvar)`}
							value={customerState.advice_notes5}
							maxLength={3000}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({advice_notes5: target.value})}
							fullWidth
							minHeight={250}
						/>

						<input type="hidden" name="radio_advice_insurance"
							   value={customerState.radio_advice_insurance}/>
					</div>
				</ContentBox>
			</div>
		);
	}
}


// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, viewNo, hasFormValidationErrors} = this.props;
  const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
  const hasBeenSeen = (seenViews.includes(viewNo));   
  // if it hasn't been seen add the view to local storage for the specific case
  if (customer.caseId && !hasBeenSeen) {
    localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
  }

	let newState = getViewInitialStateForCustomer(customer);  
	newState.isFulfilled = this.isFulfilled(newState);  
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	const newState = {
		customerState: {
			// Input
			advice_notes: customer.getData("advice_notes"),
			advice_notes2: customer.getData("advice_notes2"),
			advice_notes3: customer.getData("advice_notes3"),
			advice_notes4: customer.getData("advice_notes4"),
			advice_notes5: customer.getData("advice_notes5"),

			// Checkbox/radio
			radio_advice_insurance: "no"
		},
	};

	newState.isFulfilled = false;

	return newState;
}

function _getFields() {
	return [
		"advice_notes",
		"radio_advice_insurance",
	];
}