import React from "react";
import i18n from "@helpers/i18n";

export default class SriRiskTable extends React.PureComponent {
	render() {

		let {color1, color2, color3, color4, color5, color6, color7} = this.props;

		color1 = color1 || "#3F9C3E";
		color2 = color2 || "#A6C44B";
		color3 = color3 || "#8FA842";
		color4 = color4 || "#FFC74A";
		color5 = color5 || "#F48847";
		color6 = color6 || "#EB4841";
		color7 = color7 || "#CC3B34";

		return (
			<table className={"sri-table"}>
				<thead>
				<tr>
					<td className="risk-label">{i18n("risk_profile", "risk_class")}</td>
					<td className="sri">MRM</td>
					<td className="volatility">{i18n("risk_profile", "volatility")}</td>
				</tr>
				</thead>

				<tbody>
				<tr>
					<td className="risk-label">{i18n("risk_profile", "very_low_risk")}</td>
					<td className="sri" style={{background: color1, borderColor: color1}}>1</td>
					<td className="volatility">&lt; 0,5%</td>
				</tr>
				<tr>
					<td className="risk-label">{i18n("risk_profile", "low_risk")}</td>
					<td className="sri" style={{background: color2, borderColor: color2}}>2</td>
					<td className="volatility">&ge; 0,5% och &lt; 5,0%</td>
				</tr>
				<tr>
					<td className="risk-label">{i18n("risk_profile", "medium_low_risk")}</td>
					<td className="sri" style={{background: color3, borderColor: color3}}>3</td>
					<td className="volatility">&ge; 5,0% och &lt; 12%</td>
				</tr>
				<tr>
					<td className="risk-label">{i18n("risk_profile", "medium_risk")}</td>
					<td className="sri" style={{background: color4, borderColor: color4}}>4</td>
					<td className="volatility">&ge; 12% och &lt; 20%</td>
				</tr>
				<tr>
					<td className="risk-label">{i18n("risk_profile", "medium_high_risk")}</td>
					<td className="sri" style={{background: color5, borderColor: color5}}>5</td>
					<td className="volatility">&ge; 20% och &lt; 30%</td>
				</tr>
				<tr>
					<td className="risk-label">{i18n("risk_profile", "high_risk")}</td>
					<td className="sri" style={{background: color6, borderColor: color6}}>6</td>
					<td className="volatility">&ge; 30% och &lt; 80%</td>
				</tr>
				<tr>
					<td className="risk-label">{i18n("risk_profile", "very_high_risk")}</td>
					<td className="sri" style={{background: color7, borderColor: color7}}>7</td>
					<td className="volatility">&ge; 80%</td>
				</tr>
				</tbody>
			</table>
		);
	}
}