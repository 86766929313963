import React from "react";
import moment from "moment";

// CLASSES
import Settings from "@classes/Settings";

// COMPONENTS
import Checkbox from "@components/Checkbox/Checkbox";
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import SignModal from "../subcomponents/SignModal/SignModal";

// HELPERS
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";
import api from "@helpers/api";

// OTHER
import fulfillment from "./SignAgreement.fulfillment";
import TextField from "../../../../../components/TextField/TextField";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import Button from "../../../../../components/Button/Button";
import Validator from "../../../../../classes/Validator";
import TextView from "../../../../../components/TextView/TextView";
import imgPdf from "@assets/img/ic_pdf.png";
import AssistantModal from "../subcomponents/AssistantModal/AssistantModal";


export default class SignAgreement extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state)
	}

	setCustomerState = (customerState, callbackFn) => {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) callbackFn();
		});
	};

	componentDidMount() {
		const {setAppState, user} = this.props;
		const signButtonText = user.role === "assistant" ? i18n("cob", "sign_agreement_assistant") : i18n("cob", "sign_agreement");

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {label: signButtonText, appearance: "success"}
			},
		});
	}

	render() {
		const {props, state} = this;
		const {hasFormValidationErrors, signModalVisible, signModalBusy, signModalData, onRestartSession, customer, user, advisor} = props;
		const {customerState} = state;
		const classes = createClassName("Cob-view Cob-SignAgreement", {});
		const signDate = moment();

		const companySignatories = [];
		if (customer.isCompany) {
			companySignatories.push(
				<React.Fragment key={0}>
					<div className="h-line spacing"/>
					<h3 style={{marginBottom: "5px"}}>Signatärer</h3>
					<p>
						Vilka skall underteckna detta avtal?
					</p>
				</React.Fragment>
			);

			for (let i = 0; i < customerState.company_active_signatories; i++) {
				companySignatories.push(
					<React.Fragment key={i + 1}>
						<h4 style={{marginBottom: "5px"}}>Signatär {i + 1}</h4>
						<StackLayout className="Cob-fields-collection" rowWrap fullWidth>
							<div>
								<TextField
									name={"company_signatory_firstname_" + i}
									value={customerState["company_signatory_firstname_" + i]}
									label="Förnamn"
									validation={value => value.length > 0}
									onChange={({target}) => {
										customerState["company_signatory_firstname_" + i] = target.value;
										this.setCustomerState(customerState);
									}}
                  highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									name={"company_signatory_lastname_" + i}
									value={customerState["company_signatory_lastname_" + i]}
									label="Efternamn"
									validation={value => value.length > 0}
									onChange={({target}) => {
										customerState["company_signatory_lastname_" + i] = target.value;
										this.setCustomerState(customerState);
									}}
                  highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									name={"company_signatory_email_" + i}
									value={customerState["company_signatory_email_" + i]}
									label="E-postadress"
									type="email"
									validation={Validator.validateEmail}
									onChange={({target}) => {
										customerState["company_signatory_email_" + i] = target.value;
										this.setCustomerState(customerState);
									}}
                  highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									name={"company_signatory_sec_num_" + i}
									value={customerState["company_signatory_sec_num_" + i]}
									label="Personnummer"
									type="number"
									validation={value => value.length === 12 && (value.substring(0, 2) === "19" || value.substring(0, 2) === "20")}
									onChange={({target}) => {
										customerState["company_signatory_sec_num_" + i] = target.value;
										this.setCustomerState(customerState);
									}}
                  highlightErrors={hasFormValidationErrors}
								/>
							</div>
						</StackLayout>
					</React.Fragment>
				);
			}

			companySignatories.push(
				<React.Fragment key={100}>
					<StackLayout fullWidth>
						<Button
							size="small"
							label="Ta bort sista"
							appearance={customerState.company_active_signatories <= 1 ? "medium" : "danger"}
							disabled={customerState.company_active_signatories <= 1}
							onClick={() => this.setCustomerState({company_active_signatories: customerState.company_active_signatories - 1})}
						/>
						<Button
							size="small"
							label="Lägg till signatär"
							appearance={customerState.company_active_signatories === 4 ? "medium" : "primary"}
							disabled={customerState.company_active_signatories === 4}
							onClick={() => this.setCustomerState({company_active_signatories: customerState.company_active_signatories + 1})}
							filled
						/>
					</StackLayout>
				</React.Fragment>
			);

		}

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				{signModalVisible && user.role !== "assistant"  && (
					<SignModal
						busy={signModalBusy}
						visible={signModalVisible}
						data={signModalData}
						status={state.signStatus}
						errorMessage={state.signErrorMessage}
						signUrls={state.signUrls}
						user={user}
						customer={customer}
						onSign={this._onSignModalSign}
						onClose={this._onSignModalClose}
						onRetry={this._onSignModalRetry}
						onRestart={onRestartSession}
					/>
				)}

				{signModalVisible && user.role === "assistant" && (
					<AssistantModal
						busy={signModalBusy}
						visible={signModalVisible}
						user={user}
						advisor={advisor}
						onSend={this._onSignModalSign}
						onClose={this._onSignModalClose}
						onRestart={onRestartSession}
					/>
				)}

				<input type="hidden" name="advice_sign_date" value={signDate.format("YYYY-MM-DD")}/>

				<ContentBox>
          {hasFormValidationErrors && (<div className="Cob-SignAgreement-error">You must complete all sections of the form before you can proceed.</div>)}
					<h2>Bilagor och underskrift</h2>

					<div className="Cob-SignAgreement-fields">

						<h3 style={{marginBottom: "20px"}}>Följande bilagor är utdelade och genomgångna med kund</h3>

						<Checkbox
							name="advice_sign_row_1"
							label="Information om Glofitech"
							value="Ja"
							checked={customerState.advice_sign_row_1 === "Ja"}
							onChange={checked => this.setCustomerState({advice_sign_row_1: checked ? "Ja" : "Nej"})}
							alignMiddle
							style={{marginBottom: "20px", paddingRight: "30px"}}
              highlightErrors={hasFormValidationErrors}
						/>

						<Checkbox
							name="advice_sign_row_2"
							label="Allmänna bestämmelser för demokonto"
							value="Ja"
							checked={customerState.advice_sign_row_2 === "Ja"}
							onChange={checked => this.setCustomerState({advice_sign_row_2: checked ? "Ja" : "Nej"})}
							alignMiddle
							style={{marginBottom: "20px", paddingRight: "30px"}}
              highlightErrors={hasFormValidationErrors}
						/>

						<Checkbox
							name="advice_sign_row_3"
							label="Riktlinjer för bästa utförande av order"
							value="Ja"
							checked={customerState.advice_sign_row_3 === "Ja"}
							onChange={checked => this.setCustomerState({advice_sign_row_3: checked ? "Ja" : "Nej"})}
							alignMiddle
							style={{marginBottom: "20px", paddingRight: "30px"}}
              highlightErrors={hasFormValidationErrors}
						/>

						<p style={{margin: "10px 0 30px 0"}}>
							<a href="http://pics.glofitech.com/cob-demo/glofidemo.pdf" rel="noopener noreferrer" target="_blank" style={{
								display: "flex",
								alignItems: "center"
							}}>
								<img src={imgPdf} style={{height: "50px", marginRight: "10px"}} alt="Ladda ner" />
								Ladda ner bilagor som PDF.
							</a>
						</p>

						{companySignatories}

						<div className="h-line spacing"/>

						<h4 style={{margin: "0 0 10px 0"}}>Meddelande till backoffice</h4>
						<p style={{margin: "0 0 10px 0"}}>
							Information till BO såsom provisionsfördelning och eventuella avvikande villkor som ska
							godkännas av ansvarig förvaltare. Syns inte i dokumentationen ut mot kund.
						</p>
						<TextView
							name="backoffice_notes"
							label={`Meddelande (${2000 - customerState.backoffice_notes.length} tecken kvar)`}
							value={customerState.backoffice_notes}
							maxLength={2000}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({backoffice_notes: target.value})}
							fullWidth
							minHeight={250}
						/>
					</div>
				</ContentBox>
			</div>
		);
	}

	// Internal methods
	_onSignModalClose = async () => {
		const {setAppState} = this.props;
		await setAppState({signModalVisible: false});
	};

	_onSignModalSign = ({signatories, signOnScreen}) => {
		return new Promise(async resolve => {
			const {props} = this;
			const {user, setAppState} = props;
			const newStateProps = {};

			await setAppState({signModalBusy: true});

			const excludedDocuments = [];

			try {
				// Sign the case and receive its sign URL
				const {signUrls} = await api("/case/sign", {
					caseId: Settings.get("CASE_ID"),
					signatories,
					signOnScreen,
					partner: user.getStakeholderData(),
					excludeDocs: excludedDocuments,
				});

				newStateProps.signStatus = "success";
				newStateProps.signUrls = signUrls;
			} catch (e) {
				console.error(e.message);
				newStateProps.signStatus = "fail";
				newStateProps.signErrorMessage = e.message;
			}

			this.setState(newStateProps, async () => {
				await setAppState({signModalBusy: false});
				resolve();
			});
		});
	};

	_onSignModalRetry = () => {
		this.setState({
			signStatus: null,
			signUrls: null,
			signErrorMessage: null,
		});
	}
}


// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer} = this.props;
	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	const newState = {
		signStatus: null,
		signUrls: null,
		signErrorMessage: null,

		customerState: {
			isCompany: customer.isCompany,

			advice_sign_row_1: customer.getData("advice_sign_row_1"),
			advice_sign_row_2: customer.getData("advice_sign_row_2"),
			advice_sign_row_3: customer.getData("advice_sign_row_3"),

			backoffice_notes: customer.getData("backoffice_notes") || "",

			company_active_signatories: 1,

			company_signatory_firstname_0: customer.getData("customer_inv_poa_firstname"),
			company_signatory_lastname_0: customer.getData("customer_inv_poa_lastname"),
			company_signatory_email_0: customer.getData("company_signatory_email_0"),
			company_signatory_sec_num_0: customer.getData("customer_inv_poa_ssn"),

			company_signatory_firstname_1: customer.getData("customer_inv_poa_firstname_2"),
			company_signatory_lastname_1: customer.getData("customer_inv_poa_lastname_2"),
			company_signatory_email_1: customer.getData("company_signatory_email_1"),
			company_signatory_sec_num_1: customer.getData("customer_inv_poa_ssn_2"),

			company_signatory_firstname_2: customer.getData("customer_inv_poa_firstname_3"),
			company_signatory_lastname_2: customer.getData("customer_inv_poa_lastname_3"),
			company_signatory_email_2: customer.getData("company_signatory_email_2"),
			company_signatory_sec_num_2: customer.getData("customer_inv_poa_ssn_3"),

			company_signatory_firstname_3: customer.getData("customer_inv_poa_firstname_4"),
			company_signatory_lastname_3: customer.getData("customer_inv_poa_lastname_4"),
			company_signatory_email_3: customer.getData("company_signatory_email_3"),
			company_signatory_sec_num_3: customer.getData("customer_inv_poa_ssn_4")
		}
	};

	if (newState.customerState.company_signatory_firstname_3 !== '') {
		newState.customerState.company_active_signatories = 4;
	}
	else if (newState.customerState.company_signatory_firstname_2 !== '') {
		newState.customerState.company_active_signatories = 3;
	}
	else if (newState.customerState.company_signatory_firstname_1 !== '') {
		newState.customerState.company_active_signatories = 2;
	};

	return newState;
}

function _getFields() {
	return [
		"advice_sign_row_1",
		"advice_sign_row_2",
		"advice_sign_row_3",

		"company_signatory_firstname_0",
		"company_signatory_lastname_0",
		"company_signatory_email_0",
		"company_signatory_sec_num_0",

		"company_signatory_firstname_1",
		"company_signatory_lastname_1",
		"company_signatory_email_1",
		"company_signatory_sec_num_1",

		"company_signatory_firstname_2",
		"company_signatory_lastname_2",
		"company_signatory_email_2",
		"company_signatory_sec_num_2",

		"company_signatory_firstname_3",
		"company_signatory_lastname_3",
		"company_signatory_email_3",
		"company_signatory_sec_num_3",

		"backoffice_notes",
	];
}