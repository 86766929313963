import React from "react";
import ReactSlider from "react-slider";

// HELPERS
import { createClassName } from "@helpers/utils";


export default class Slider extends React.PureComponent {

    componentDidUpdate(prevProps) {
        const { props } = this;

        if(prevProps.min !== props.min || prevProps.max !== props.max) {
            if(props.onChange) {
                const value = props.value !== null && !isNaN(props.value) ? props.value : props.min;
                const newValue = Math.min(Math.max(value, props.min), props.max);

                props.onChange(newValue);
            }
        }
    }

    render() {
        const { props } = this;
        const { disabled, name, label, unit, min = 0, max = 100, step, visibleValues, valueFormatter, onChange } = props;
        const value = (v => !disabled ? v : min)((props.value !== null && !isNaN(props.value) ? props.value : min) || 0);
        const classes = createClassName(props.className, {
            "Slider": true,
            "disabled": disabled === true,
        });

        return (
            <div className={ classes }>
                <div className="Slider-top">
                    { !!label && <div className="Slider-label">{ label }</div> }
                    <div className="Slider-value">{ (valueFormatter ? valueFormatter(value) : value) + (unit || "") }</div>
                </div>

                <div className="Slider-container">
                    { !!visibleValues && (
                        <div className="Slider-visible-values">
                            { visibleValues.map((value, index) => (
                                <div key={ `Slider-visible-value-${ index }` } className="Slider-visible-value">{ value }</div>
                            ))}
                        </div>
                    )}

                    { !!name && <input type="hidden" name={ name } value={ value } disabled={ disabled } /> }
                    <ReactSlider
                        className="Slider-slider"
                        handleClassName="Slider-slider-handle"
                        barClassName="Slider-slider-bar"
                        value={ !disabled ? value : min }
                        min={ min }
                        max={ max }
                        step={ step }
                        disabled={ disabled }
                        onChange={ !disabled ? onChange : undefined }
                        withBars
                    />
                </div>
            </div>
        );
    }
}