import {mergeViewStateForFulfillment} from "../../../Cob.helpers";
import Validator from "../../../../../classes/Validator";


export default function (state, prevState) {

	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") return true;

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	if (customerState.radio_customer_autogiro.length <= 0) {
		return false;
	}

	if (customerState.radio_customer_autogiro === 'yes') {
		// Uppgifter om kontonr och innehavare måste vara ifyllda
		if (customerState.customer_autogiro_bank.length <= 0 ||
			customerState.customer_autogiro_bank_clearingno.length <= 0 ||
			customerState.customer_autogiro_bankno.length <= 0) {
			return false;
		}

		/**
		 * Om man valt övrig bank måste man ange bankens namn i fritext
		 */
		if (customerState.customer_autogiro_bank === '-') {
			if (customerState.customer_autogiro_bank_clearingno.length <= 0 ||
				customerState.customer_autogiro_bankno.length <= 0) {
				return false;
			}
		}

		/**
		 * If override validation is not checked the bank account no needs to be at least 4 digits long
		 */
		if (customerState.customer_autogiro_bankno.length <= 4 && !customerState.checkbox_override_validation) {
			return false;
		}

		/**
		 * Övriga banks + skipping validation should... skip validation
		 */
		if (!(customerState.customer_autogiro_bank === '-' || customerState.checkbox_override_validation)) {

      // TODO: This is a hack to to stop fulfillment from crashing when availableBanks is not available anywhere. Might need more work
      let availableBanks = {};
      if (this !== undefined) {
        if (this.hasOwnProperty('props') && this.props.hasOwnProperty('availableBanks')){
          availableBanks = this.props.availableBanks;
        }
      }
      else {
        if (state.hasOwnProperty('availableBanks')){
          availableBanks = state.availableBanks;
        }
      }

			const clearingNoValid = Validator.validateClearingNumber(
				customerState.customer_autogiro_bank_clearingno,
				customerState.customer_autogiro_bank,
				availableBanks
			);

			if (!clearingNoValid) {
				return false;
			}

			/**
			 * For the banks where we know the validation rules, check if the number should be validated.
			 */
			let shouldValidateAccountNumber = false;
			for (const bankName in availableBanks) {
				if (bankName === 'length' || !availableBanks.hasOwnProperty(bankName)) continue;

				if (customerState.customer_autogiro_bank === bankName) {
					const bankInfo = availableBanks[bankName];
					if (bankInfo.length > 0) {
						if (bankInfo[0]['validationEnabled'] === "1") {
							shouldValidateAccountNumber = true;
						}
					}
					break;
				}
			}

			if (shouldValidateAccountNumber) {
				if (customerState.customer_autogiro_bank_clearingno.length <= 0 ||
					customerState.customer_autogiro_bankno.length <= 0) {
					return false;
				}

				let bnk = customerState.customer_autogiro_bank_clearingno + ", " + customerState.customer_autogiro_bankno;
				if (!window.kontonummer(bnk)) {
					return false;
				}
			}
		}

		if (customerState.checkbox_autogiro_onetime === "Ja" && (
			customerState.customer_autogiro_amount_onetime.length === 0 ||
			customerState.customer_autogiro_amount_onetime === "0 SEK" ||
			customerState.customer_autogiro_amount_onetime <= 0)) {
			return false;
		}

		if (customerState.checkbox_autogiro_recurring === "Ja" && (
			customerState.customer_autogiro_amount_recurring.length === 0 ||
			customerState.customer_autogiro_amount_recurring === "0 SEK" ||
			customerState.customer_autogiro_amount_recurring <= 0)) {
			return false;
		}

		if (customerState.checkbox_autogiro_recurring === "Ja" && customerState.recurringIndex === -1) {
			return false;
		}

		if (customerState.customer_autogiro_amount_quantity.length > 0 &&
			customerState.customer_autogiro_amount_quantity <= 0) {
			return false;
		}

		if (customerState.bgIndex === -1) {
			return false;
		}

		if (customerState.customer_accountno_fair.length === 0 && customerState.radio_new_isk !== "yes") {
			return false;
		}
	}

	return true;
}