import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./FinancialSituation.fulfillment";
import TextView from "../../../../../components/TextView/TextView";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import NumberField from "../../../components/NumberField/NumberField";
import i18n from "@helpers/i18n";

export default class FinancialSituation extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) {
				callbackFn();
			}
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
          onClick: () => {
            if (this.state.isFulfilled === true) {
              return;
            }
            
            this.setState({hasFormValidationErrors: true});
            return setAppState({
              showValidationWarning: true
            });
          }
        }
			}
		});
	}

	render() {
		const {customerState, hasFormValidationErrors} = this.state;
		const classes = createClassName("Cob-view Cob-view2", {});

		let surplus = 0;

		surplus += parseInt(customerState.situation_income_work.replaceAll(" ", "") || 0, 10);
		surplus += parseInt(customerState.situation_income_other.replaceAll(" ", "") || 0, 10);
		surplus -= parseInt(customerState.situation_expenses.replaceAll(" ", "") || 0, 10);

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Ekonomisk situation</h2>

					<div className="Cob-view2-fields">
						<h3>Inkomster och utgifter</h3>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<h4>Inkomst från anställning*</h4>
								<NumberField 
                  label={i18n("general", "provide_amount")}
                  unit=" kr"
                  name={"situation_income_work"}
                  value={customerState.situation_income_work}
                  showSeparators={true}
                  validation={value => (value.length > 0)}
                  onChange={({target}) => this.setCustomerState({situation_income_work: target.value})}
                  highlightErrors={hasFormValidationErrors}
                />
							</div>
							<div>
								<h4>Annan inkomst*</h4>
								<NumberField
                  label={i18n("general", "provide_amount")}
                  unit=" kr"
                  name={"situation_income_other"}
                  value={customerState.situation_income_other}
                  showSeparators={true}
                  validation={value => (value.length > 0)}
                  onChange={({target}) => this.setCustomerState({situation_income_other: target.value})}
                  highlightErrors={hasFormValidationErrors}
                />
							</div>
							<div>
								<h4>Utgifter*</h4>
								<NumberField
                  label={i18n("general", "provide_amount")}
                  unit=" kr"
                  name={"situation_expenses"}
                  value={customerState.situation_expenses}
                  showSeparators={true}
                  validation={value => (value.length > 0)}
                  onChange={({target}) => this.setCustomerState({situation_expenses: target.value})}
                  highlightErrors={hasFormValidationErrors}
                />
							</div>
						</StackLayout>

						<h4 style={{marginBottom: "15px", marginTop: "0"}}>Överskott</h4>
						{this._monetaryAmountFormatter(surplus)} kr

						<h4 style={{marginTop: "30px"}}>Kommentar</h4>
						<TextView
							name="situation_income_comment"
							label={`Fritext (${800 - customerState.situation_income_comment.length} tecken kvar)`}
							value={customerState.situation_income_comment}
							maxLength={800}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({situation_income_comment: target.value})}
							fullWidth
							minHeight={120}
						/>
					</div>
				</ContentBox>
			</div>
		);
	}

	_monetaryAmountFormatter = (value) => value.toLocaleString("sv-SE");
}

// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, viewNo, hasFormValidationErrors} = this.props;
  const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
  const hasBeenSeen = (seenViews.includes(viewNo));   
  // if it hasn't been seen add the view to local storage for the specific case
  if (customer.caseId && !hasBeenSeen) {
    localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
  }

	let newState = getViewInitialStateForCustomer(customer);  
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	return {
		isFulfilled: false,
		customerState: {
			situation_income_work: customer.getData("situation_income_work"),
			situation_income_other: customer.getData("situation_income_other"),
			situation_expenses: customer.getData("situation_expenses"),
			situation_surplus: customer.getData("situation_surplus"),
			situation_income_comment: customer.getData("situation_income_comment") || "",
		}
	};
}

function _getFields() {
	return [
		"situation_income_work",
		"situation_income_other",
		"situation_expenses",
		"situation_surplus",
		"situation_income_comment",
	];
}