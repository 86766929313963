import React from "react";

// COMPONENTS
import Checkbox from "@components/Checkbox/Checkbox";

// HELPERS
import { createClassName } from "@helpers/utils";


export default function SimpleCheckbox({ style, className, name, value, checked, disabled, onChange }) {
    const classes = createClassName(className, {
        "Cob-SimpleCheckbox": true,
    });

    return (
        <React.Fragment>
            <Checkbox style={ style } className={ classes } checked={ checked } disabled={ disabled } onChange={ !disabled ? onChange : undefined } />
            { !!name && <input type="hidden" name={ name } value={ value } disabled={ disabled } /> }
        </React.Fragment>
    );
}