import React from "react";
import {Link} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import SearchBar from "./SearchBar";
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";

import useHeader from "./useHeader";

const Header = props => {
  const navBar = useHeader(props);
  const { user, partner, customers, isLaptopOrDesktop, locationPath, classes, logoSrc } = navBar;  
  const headerTheme = partner?.theme?.header;

  return (
    <Box>
      {user &&
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "secondary.main",
            justifyContent: "center",
            height: { xs: "75px", md: "60px" },
            borderBottom: headerTheme?.borderBottom ? headerTheme?.borderBottom : 2,
            borderBottomColor: "primary.main",
            zIndex: 1000
          }}
        >
          <Container
            maxWidth="xxl"
            sx={{
              px: { xs: 1.5, md: 1.5 },
              py: { xs: 0, md: 1.5 }
            }}
          >
            <Toolbar disableGutters sx={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center"
            }}>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  flexDirection: "row",
                  flexGrow: 50,
                  justifyContent: "flex-start",
                  alignItems: "center"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // cursor: "pointer"
                  }}
                  // onClick={e => navBar.handleLogoClick(e)}
                >
                  <Box
                    component="img"
                    sx={{
                      display: { xs: "none", md: "flex" },
                      height: partner?.logoHeight || 48,
                      width: "auto"
                    }}
                    alt="logo"
                    src={logoSrc}
                  />
                  <Typography
                    variant="logo"
                    sx={{
                      display: { xs: "none", md: "flex" },
                      flexDirection: { xs: "none", md: "column" },
                      mr: 2,
                      ml: 1,
                      textDecoration: "none",
                      textAlign: { md: "left" },
                      '& em': {
                        fontSize: "10px",
                        fontStyle: "normal",
                        color: "primary.darker",
                        marginLeft: "1px"
                      }


                    }}
                  >
                    <em>
                      GlofiTech COB
                    </em>
                    {partner?.logoCompanyName || ""}
                  </Typography>
                </Box>
              </Box>
              {/* mobile view */}
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  flexDirection: "row",
                  flexGrow: 50,
                  justifyContent: "flex-start",
                  alignItems: "center"
                }}
              >
                <Box
                  component="img"
                  sx={{
                    display: { xs: "flex", md: "none" },
                    height: 45,
                    width: 45
                  }}
                  alt="Fair Investments logo"
                  src={logoSrc}
                  // onClick={e => navBar.handleLogoClick(e)}
                />
                <Typography
                  variant="logo"
                  sx={{
                    display: { xs: "flex", md: "none" },
                    flexDirection: "column",
                    ml: 1,
                    lineHeight: 1
                  }}
                  // onClick={e => navBar.handleLogoClick(e)}
                >
                  <em
                    style={{
                      fontSize: "10px",
                      fontStyle: "normal",
                      color: "#6c81ad",
                      marginLeft: "1px"
                    }}
                  >
                    GlofiTech COB
                  </em>
                  {partner?.logoCompanyName || ""}
                </Typography>
              </Box>
              <Box sx={{ display: "inline-flex", flexDirection: "row", flexGrow: 0 }}>
                {isLaptopOrDesktop ? (
                  <Box
                    id="searchBarContainer" 
                    sx={{
                      display: "flex",
                      position: "relative",
                      width: "auto"
                    }}
                  >
                    <DesktopMenu handlers={navBar} />
                  </Box>
                ) : (
                  <MobileMenu handlers={navBar} />
                )}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      }
    </Box>
  );
};

export default Header;
