import React from "react";


export default function DefaultFields({ fields }) {
    return(
        <div className="Cob-DefaultFields" style={{ display: "none" }}>
            { !!fields && fields.map(field => {
                const name = typeof field === "object" ? field.name : field;
                const value = typeof field === "object" ? (field.value || "") : "";

                return (
                    <input key={ name } type="hidden" name={ name } value={ value } />
                );
            })}
        </div>
    );
}