import React from "react";
import moment from "moment";

import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import DefaultFields from "../../../../components/DefaultFields/DefaultFields";
import ContentBox from "@components/ContentBox/ContentBox";
import Checkbox from "@components/Checkbox/Checkbox";
import Notice from "@components/Notice/Notice";
import TextField from "@components/TextField/TextField";
import Select from '@components/Select/Select';
import OptionsList from "../../../../components/OptionsList/OptionsList";
import PageHeader from "../../PageHeader";
import Button from "@components/Button/Button";
import UploadAndSign from "../../../../components/UploadAndSign/UploadAndSign";
import Fullmaktskollen from "../../../../components/Fullmaktskollen/Fullmaktskollen";

// HELPERS
import i18n from "@helpers/i18n";
import api from '@helpers/api';

// OTHER
import { inputIsCompany } from "../../Start.fulfillment";
import Validator from "../../../../../../classes/Validator";


export default class InitSession extends React.PureComponent {
	fields = _getFields.call(this);

	render() {
    const {state, fields, props} = this;
		const {user, advisor, partner, customerState, setCustomerState, assentlyStatus, nextStepOptions} = props;

    return (
      <>
        <DefaultFields fields={fields}/>
        <ContentBox>
          <PageHeader user={user} advisor={advisor} />

          <div className="Cob-view1-fields">
            <div className={partner.faSSO && customerState.ssnOrg ? "hidden" : "Cob-view1-fields"}>
              <p className="Cob-description tightened">Ange kundens person-/organisationsnummer</p>

              <Checkbox
                label={i18n("cob", "customer_is_a_company")}
                checked={customerState.isCompany}
                onChange={isCompany => setCustomerState({isCompany})}
              />
              <input
                type="hidden"
                name="checkbox_company"
                value={customerState.isCompany ? "yes" : "no"}
              />
              <TextField
                type="number"
                name="customer_social_security_number"
                value={customerState.ssnOrg}
                autocomplete="nope"
                label={i18n("cob", customerState.isCompany ? "customers_organizational_number" : "customers_social_security_number")}
                placeholder="12-siffrigt utan bindestreck"
                validation={this.ssnValidation(customerState)}
                onChange={this._onSsnOrgChange}
                readOnly={customerState.faRep}
              />

              <p className="Cob-miniNote">
                <strong>Exempel:</strong> <em>YYYYMMDDXXXX</em> eller <em>16XXXXXXXXXX</em>
              </p>
            </div>

            {this.validateSsnInForm(customerState) && (
              <React.Fragment>
                <p className="Cob-description">Alternativ</p>

                <OptionsList
                  type="radio"
                  orientation="vertical"
                  options={nextStepOptions}
                  onChange={this._onCaseTypeSelectChange}
                />

                {customerState.standaloneApplicationsChecked.length === 0 && customerState.isNewCase === false && (
                  <div className="Cob-view1-cases">
                    {!customerState.cases ? (
                      <div className="loading">
                        <ActivityIndicator color="#91a0ad" scale={0.75} busy/>
                        <span>Laddar...</span>
                      </div>
                    ) : (
                      customerState.cases.length > 0 ? (
                        <React.Fragment>
                          <Select
                            className="cases-select"
                            name="caseId"
                            label="Välj rådgivningstillfälle"
                            renderOption={this._renderCaseOption}
                            options={customerState.casesOptions}
                            selectedIndex={customerState.selectedCaseIndex}
                            customFilterer={this._casesSelectFilterer}
                            onChange={this._onCasesSelectChange}
                          />

                          {!!customerState.cases[customerState.selectedCaseIndex] &&
                          !customerState.cases[customerState.selectedCaseIndex].isDraft &&
                          !customerState.cases[customerState.selectedCaseIndex].isRecalled && (
                            <Notice className="no-draft-notice" appearance="warning"
                                message="Eftersom det valda rådgivningstillfället redan slutförts så kommer ett nytt förifyllt rådgivningstillfälle att skapas baserat på dessa uppgifter."/>
                          )}

                          {!!customerState.cases[customerState.selectedCaseIndex] && customerState.cases[customerState.selectedCaseIndex].isRecalled && (
                            <Notice className="no-draft-notice" appearance="warning"
                                message="Eftersom det valda rådgivningstillfället har återkallats så kommer ett nytt förifyllt rådgivningstillfälle att skapas baserat på dessa uppgifter."/>
                          )}
                        </React.Fragment>
                      ) : (
                        <p className="empty">
                          <em>Hittade inga tidigare rådgivningstillfällen.</em>
                        </p>
                      )
                    )}
                  </div>
                )}

                {!partner.startViewWidgets.includes("Fullmaktskollen") && user.hasPermission("fmk.send") && (
                  <React.Fragment>
                    <Fullmaktskollen customerIdentityNumber={state.customerState.ssnOrg}
                            user={user} />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {!partner.startViewWidgets.includes("UploadAndSign") && user.hasPermission("standalone.send") && (
              <UploadAndSign partnerSsn={user.ssn}/>
            )}
          </div>

          {assentlyStatus && assentlyStatus.length > 0 && (
            <div style={{margin: "40px 0 0 0", background: "#FFF5F5", width: "100%", display: "block", padding: "20px", fontSize: "13px"}}>
              <strong style={{display: "block", padding: "0 0 5px 0"}}>Driftstörning Assently (digital signering)</strong>
              För närvarande påverkas följande system: {assentlyStatus.join(", ")} <br /><br />
              <a href="https://assently.statuspage.io/" target="_blank" rel="noopener noreferrer">För mer information, klicka här</a>
            </div>
          )}
        </ContentBox>
      </>
    );
	}

  	/**
	 * SSN numbers need to be 12 digits and start with 19 or 20
	 */
	ssnValidation(customerState)
	{
		return value => !isNaN(value) && (
			(
				Validator.validateSsn(value)
			)
			||
			(
				customerState.isCompany && inputIsCompany(value)
			)
		);
	}

  validateSsnInForm(customerState)
	{
		return !isNaN(customerState.ssnOrg) && (
			(
				Validator.validateSsn(customerState.ssnOrg)
			)
			||
			(
				customerState.isCompany && inputIsCompany(customerState.ssnOrg)
			)
		);
	}

  // Internal methods
	_onCasesSelectChange = ({value}, index) =>  {
    const { setCustomerState } = this.props;
    setCustomerState({selectedCaseId: value, selectedCaseIndex: index});
  };

  _onSsnOrgChange = ({target}) => {
    
    const { setCustomerState } = this.props;
    
    setCustomerState({
		isCompany: inputIsCompany(target.value),
		ssnOrg: target.value,
		isNewCase: null,
		standaloneApplicationsChecked: [],
		casesLoading: false,
		cases: null,
		selectedCaseId: null,
		selectedCaseIndex: -1
	  });
  };

  _onCaseTypeSelectChange = (checked, value, index) =>
	{    
    const { partner, customerState, setCustomerState } = this.props;
		const newStateProps = {
			isNewCase: index === 0,
			standaloneApplicationsChecked: []
		};

		if (index === 0)
		{
			newStateProps.selectedCaseId = null;
			newStateProps.selectedCaseIndex = -1;
		}
		else if (index > 1)
		{
			const applicationIndex = index - 2;
			const applications = partner.standaloneApplications;
			const app = applications[applicationIndex];
			newStateProps.standaloneApplicationsChecked.push(app.label);
		}

		setCustomerState(newStateProps, () =>
		{
			if (index === 1 && !customerState.cases)
			{
				_getCustomerCases.call(this);
			}
		});
	};

	_casesSelectFilterer = ({option}, inputValue) =>
	{
		inputValue = inputValue.toLowerCase();
		return option.label.indexOf(inputValue) === 0 || option.value.indexOf(inputValue) === 0;
	};

	_renderCaseOption = ({label, isDraft, isRecalled}) => {
		return (
			<div className="Cob-view1-caseOption">
				<div className="label">{label}</div>

				{isRecalled && (
					<div className="info recalled">Återkallat</div>
				)}

				{isDraft && !isRecalled && (
					<div className="info draft">Utkast</div>
				)}
			</div>
		);
	};
}

// PRIVATE FUNCTIONS
function _getCustomerCases() {
  const { setCustomerState } = this.props;
	return new Promise((resolve) => {
		setCustomerState({casesLoading: true}, async () => {
			try {
				const {user, customerState} = this.props;

				const cases = await api("/case/customer-cases", {
					partnerSsn: user.ssn,
					customerId: customerState.ssnOrg
				});

				/**
				 * Sort cases according to when they were last modified
				 */
				cases.sort(function (a, b) {
					return b.createdOn - a.createdOn;
				});

				const casesOptions = cases.map(({createdOn, caseId, isDraft, isRecalled}) => ({
					label: moment(createdOn).format("YYYY-MM-DD H:mm"),
					value: caseId,
					title: caseId,
					caseId,
					isDraft,
					isRecalled
				}));

				setCustomerState({cases, casesOptions, casesLoading: false}, () => resolve());
			}
			catch (e) {
				console.error(e.message);
				resolve();
			}
		});
	});
}

function _getFields()
{
	return [
		"checkbox_company",
		"customer_social_security_number"
	];
}