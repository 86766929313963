import { mergeViewStateForFulfillment } from "../../Cob.helpers";


export default function(state, prevState) {
    if(import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") return true;
    state = mergeViewStateForFulfillment(state, prevState);
    const { customerState } = state;
    const { isCompany } = customerState;

    return !!(
        !isCompany ? (
            customerState.serviceObligationNo.length > 0
            && customerState.martailStatus.length > 0
            && customerState.employmentStatus.length > 0
            && customerState.salary.length > 0
            && customerState.monthlyPayment.length > 0
            && customerState.savingsProfileRealEstate.length > 0
            && customerState.savingsProfileDebt.length > 0
        ) : true
    );
}