// Order below in alphabetical order

import i18n from "@helpers/i18n";

export default [
  {
    "label": i18n("countries", "sweden"),
    "code": "SE"
  },
  {
    "label": i18n("countries", "afghanistan"),
    "code": "AF"
  },
  {
    "label": i18n("countries", "aland"),
    "code": "AX"
  },
  {
    "label": i18n("countries", "albania"),
    "code": "AL"
  },
  {
    "label": i18n("countries", "algeria"),
    "code": "DZ"
  },
  {
    "label": i18n("countries", "americanSamoa"),
    "code": "AS"
  },
  {
    "label": i18n("countries", "andorra"),
    "code": "AD"
  },
  {
    "label": i18n("countries", "angola"),
    "code": "AO"
  },
  {
    "label": i18n("countries", "anguilla"),
    "code": "AI"
  },
  {
    "label": i18n("countries", "antarctica"),
    "code": "AQ"
  },
  {
    "label": i18n("countries", "antiguaAndBarbuda"),
    "code": "AG"
  },
  {
    "label": i18n("countries", "argentina"),
    "code": "AR"
  },
  {
    "label": i18n("countries", "armenia"),
    "code": "AM"
  },
  {
    "label": i18n("countries", "aruba"),
    "code": "AW"
  },
  {
    "label": i18n("countries", "australia"),
    "code": "AU"
  },
  {
    "label": i18n("countries", "austria"),
    "code": "AT"
  },
  {
    "label": i18n("countries", "azerbaijan"),
    "code": "AZ"
  },
  {
    "label": i18n("countries", "bahamas"),
    "code": "BS"
  },
  {
    "label": i18n("countries", "bahrain"),
    "code": "BH"
  },
  {
    "label": i18n("countries", "bangladesh"),
    "code": "BD"
  },
  {
    "label": i18n("countries", "barbados"),
    "code": "BB"
  },
  {
    "label": i18n("countries", "belarus"),
    "code": "BY"
  },
  {
    "label": i18n("countries", "belgium"),
    "code": "BE"
  },
  {
    "label": i18n("countries", "belize"),
    "code": "BZ"
  },
  {
    "label": i18n("countries", "benin"),
    "code": "BJ"
  },
  {
    "label": i18n("countries", "bermuda"),
    "code": "BM"
  },
  {
    "label": i18n("countries", "bhutan"),
    "code": "BT"
  },
  {
    "label": i18n("countries", "bolivia"),
    "code": "BO"
  },
  {
    "label": i18n("countries", "bosniaAndHerzegovina"),
    "code": "BA"
  },
  {
    "label": i18n("countries", "botswana"),
    "code": "BW"
  },
  {
    "label": i18n("countries", "bouvetIsland"),
    "code": "BV"
  },
  {
    "label": i18n("countries", "brazil"),
    "code": "BR"
  },
  {
    "label": i18n("countries", "britishIndianOceanTerritory"),
    "code": "IO"
  },
  {
    "label": i18n("countries", "britishVirginIslands"),
    "code": "VG"
  },
  {
    "label": i18n("countries", "brunei"),
    "code": "BN"
  },
  {
    "label": i18n("countries", "bulgaria"),
    "code": "BG"
  },
  {
    "label": i18n("countries", "burkinaFaso"),
    "code": "BF"
  },
  {
    "label": i18n("countries", "burundi"),
    "code": "BI"
  },
  {
    "label": i18n("countries", "cambodia"),
    "code": "KH"
  },
  {
    "label": i18n("countries", "cameroon"),
    "code": "CM"
  },
  {
    "label": i18n("countries", "canada"),
    "code": "CA"
  },
  {
    "label": i18n("countries", "capeVerde"),
    "code": "CV"
  },
  {
    "label": i18n("countries", "caymanIslands"),
    "code": "KY"
  },
  {
    "label": i18n("countries", "centralAfricanRepublic"),
    "code": "CF"
  },
  {
    "label": i18n("countries", "chad"),
    "code": "TD"
  },
  {
    "label": i18n("countries", "chile"),
    "code": "CL"
  },
  {
    "label": i18n("countries", "china"),
    "code": "CN"
  },
  {
    "label": i18n("countries", "christmasIsland"),
    "code": "CX"
  },
  {
    "label": i18n("countries", "cocosIslands"),
    "code": "CC"
  },
  {
    "label": i18n("countries", "colombia"),
    "code": "CO"
  },
  {
    "label": i18n("countries", "comoros"),
    "code": "KM"
  },
  {
    "label": i18n("countries", "congo"),
    "code": "CG"
  },
  {
    "label": i18n("countries", "cookIslands"),
    "code": "CK"
  },
  {
    "label": i18n("countries", "costaRica"),
    "code": "CR"
  },
  {
    "label": i18n("countries", "croatia"),
    "code": "HR"
  },
  {
    "label": i18n("countries", "cuba"),
    "code": "CU"
  },
  {
    "label": i18n("countries", "cyprus"),
    "code": "CY"
  },
  {
    "label": i18n("countries", "czechRepublic"),
    "code": "CZ"
  },
  {
    "label": i18n("countries", "denmark"),
    "code": "DK"
  },
  {
    "label": i18n("countries", "djibouti"),
    "code": "DJ"
  },
  {
    "label": i18n("countries", "dominica"),
    "code": "DM"
  },
  {
    "label": i18n("countries", "dominicanRepublic"),
    "code": "DO"
  },
  {
    "label": i18n("countries", "eastTimor"),
    "code": "TL"
  },
  {
    "label": i18n("countries", "ecuador"),
    "code": "EC"
  },
  {
    "label": i18n("countries", "egypt"),
    "code": "EG"
  },
  {
    "label": i18n("countries", "elSalvador"),
    "code": "SV"
  },
  {
    "label": i18n("countries", "equatorialGuinea"),
    "code": "GQ"
  },
  {
    "label": i18n("countries", "eritrea"),
    "code": "ER"
  },
  {
    "label": i18n("countries", "estonia"),
    "code": "EE"
  },
  {
    "label": i18n("countries", "ethiopia"),
    "code": "ET"
  },
  {
    "label": i18n("countries", "falklandIslands"),
    "code": "FK"
  },
  {
    "label": i18n("countries", "faroeIslands"),
    "code": "FO"
  },
  {
    "label": i18n("countries", "fiji"),
    "code": "FJ"
  },
  {
    "label": i18n("countries", "finland"),
    "code": "FI"
  },
  {
    "label": i18n("countries", "france"),
    "code": "FR"
  },
  {
    "label": i18n("countries", "frenchGuiana"),
    "code": "GF"
  },
  {
    "label": i18n("countries", "frenchPolynesia"),
    "code": "PF"
  },
  {
    "label": i18n("countries", "frenchSouthernTerritories"),
    "code": "TF"
  },
  {
    "label": i18n("countries", "gabon"),
    "code": "GA"
  },
  {
    "label": i18n("countries", "gambia"),
    "code": "GM"
  },
  {
    "label": i18n("countries", "georgia"),
    "code": "GE"
  },
  {
    "label": i18n("countries", "germany"),
    "code": "DE"
  },
  {
    "label": i18n("countries", "ghana"),
    "code": "GH"
  },
  {
    "label": i18n("countries", "gibraltar"),
    "code": "GI"
  },
  {
    "label": i18n("countries", "greece"),
    "code": "GR"
  },
  {
    "label": i18n("countries", "greenland"),
    "code": "GL"
  },
  {
    "label": i18n("countries", "grenada"),
    "code": "GD"
  },
  {
    "label": i18n("countries", "guadeloupe"),
    "code": "GP"
  },
  {
    "label": i18n("countries", "guam"),
    "code": "GU"
  },
  {
    "label": i18n("countries", "guatemala"),
    "code": "GT"
  },
  {
    "label": i18n("countries", "guernsey"),
    "code": "GG"
  },
  {
    "label": i18n("countries", "guinea"),
    "code": "GN"
  },
  {
    "label": i18n("countries", "guineaBissau"),
    "code": "GW"
  },
  {
    "label": i18n("countries", "guyana"),
    "code": "GY"
  },
  {
    "label": i18n("countries", "haiti"),
    "code": "HT"
  },
  {
    "label": i18n("countries", "heardIslandAndMcDonaldIslands"),
    "code": "HM"
  },
  {
    "label": i18n("countries", "honduras"),
    "code": "HN"
  },
  {
    "label": i18n("countries", "hongKong"),
    "code": "HK"
  },
  {
    "label": i18n("countries", "hungary"),
    "code": "HU"
  },
  {
    "label": i18n("countries", "iceland"),
    "code": "IS"
  },
  {
    "label": i18n("countries", "india"),
    "code": "IN"
  },
  {
    "label": i18n("countries", "indonesia"),
    "code": "ID"
  },
  {
    "label": i18n("countries", "iran"),
    "code": "IR"
  },
  {
    "label": i18n("countries", "iraq"),
    "code": "IQ"
  },
  {
    "label": i18n("countries", "ireland"),
    "code": "IE"
  },
  {
    "label": i18n("countries", "isleOfMan"),
    "code": "IM"
  },
  {
    "label": i18n("countries", "israel"),
    "code": "IL"
  },
  {
    "label": i18n("countries", "italy"),
    "code": "IT"
  },
  {
    "label": i18n("countries", "ivoryCoast"),
    "code": "CI"
  },
  {
    "label": i18n("countries", "jamaica"),
    "code": "JM"
  },
  {
    "label": i18n("countries", "japan"),
    "code": "JP"
  },
  {
    "label": i18n("countries", "jersey"),
    "code": "JE"
  },
  {
    "label": i18n("countries", "jordan"),
    "code": "JO"
  },
  {
    "label": i18n("countries", "kazakhstan"),
    "code": "KZ"
  },
  {
    "label": i18n("countries", "kenya"),
    "code": "KE"
  },
  {
    "label": i18n("countries", "kiribati"),
    "code": "KI"
  },
  {
    "label": i18n("countries", "kuwait"),
    "code": "KW"
  },
  {
    "label": i18n("countries", "kyrgyzstan"),
    "code": "KG"
  },
  {
    "label": i18n("countries", "laos"),
    "code": "LA"
  },
  {
    "label": i18n("countries", "latvia"),
    "code": "LV"
  },
  {
    "label": i18n("countries", "lebanon"),
    "code": "LB"
  },
  {
    "label": i18n("countries", "lesotho"),
    "code": "LS"
  },
  {
    "label": i18n("countries", "liberia"),
    "code": "LR"
  },
  {
    "label": i18n("countries", "libya"),
    "code": "LY"
  },
  {
    "label": i18n("countries", "liechtenstein"),
    "code": "LI"
  },
  {
    "label": i18n("countries", "lithuania"),
    "code": "LT"
  },
  {
    "label": i18n("countries", "luxembourg"),
    "code": "LU"
  },
  {
    "label": i18n("countries", "macau"),
    "code": "MO"
  },
  {
    "label": i18n("countries", "macedonia"),
    "code": "MK"
  },
  {
    "label": i18n("countries", "madagascar"),
    "code": "MG"
  },
  {
    "label": i18n("countries", "malawi"),
    "code": "MW"
  },
  {
    "label": i18n("countries", "malaysia"),
    "code": "MY"
  },
  {
    "label": i18n("countries", "maldives"),
    "code": "MV"
  },
  {
    "label": i18n("countries", "mali"),
    "code": "ML"
  },
  {
    "label": i18n("countries", "malta"),
    "code": "MT"
  },
  {
    "label": i18n("countries", "marshallIslands"),
    "code": "MH"
  },
  {
    "label": i18n("countries", "martinique"),
    "code": "MQ"
  },
  {
    "label": i18n("countries", "mauritania"),
    "code": "MR"
  },
  {
    "label": i18n("countries", "mauritius"),
    "code": "MU"
  },
  {
    "label": i18n("countries", "mayotte"),
    "code": "YT"
  },
  {
    "label": i18n("countries", "mexico"),
    "code": "MX"
  },
  {
    "label": i18n("countries", "micronesia"),
    "code": "FM"
  },
  {
    "label": i18n("countries", "moldova"),
    "code": "MD"
  },
  {
    "label": i18n("countries", "monaco"),
    "code": "MC"
  },
  {
    "label": i18n("countries", "mongolia"),
    "code": "MN"
  },
  {
    "label": i18n("countries", "montenegro"),
    "code": "ME"
  },
  {
    "label": i18n("countries", "montserrat"),
    "code": "MS"
  },
  {
    "label": i18n("countries", "morocco"),
    "code": "MA"
  },
  {
    "label": i18n("countries", "mozambique"),
    "code": "MZ"
  },
  {
    "label": i18n("countries", "myanmar"),
    "code": "MM"
  },
  {
    "label": i18n("countries", "namibia"),
    "code": "NA"
  },
  {
    "label": i18n("countries", "nauru"),
    "code": "NR"
  },
  {
    "label": i18n("countries", "nepal"),
    "code": "NP"
  },
  {
    "label": i18n("countries", "netherlands"),
    "code": "NL"
  },
  {
    "label": i18n("countries", "newCaledonia"),
    "code": "NC"
  },
  {
    "label": i18n("countries", "newZealand"),
    "code": "NZ"
  },
  {
    "label": i18n("countries", "nicaragua"),
    "code": "NI"
  },
  {
    "label": i18n("countries", "niger"),
    "code": "NE"
  },
  {
    "label": i18n("countries", "nigeria"),
    "code": "NG"
  },
  {
    "label": i18n("countries", "niue"),
    "code": "NU"
  },
  {
    "label": i18n("countries", "norfolkIsland"),
    "code": "NF"
  },
  {
    "label": i18n("countries", "northKorea"),
    "code": "KP"
  },
  {
    "label": i18n("countries", "northernMarianaIslands"),
    "code": "MP"
  },
  {
    "label": i18n("countries", "norway"),
    "code": "NO"
  },
  {
    "label": i18n("countries", "oman"),
    "code": "OM"
  },
  {
    "label": i18n("countries", "pakistan"),
    "code": "PK"
  },
  {
    "label": i18n("countries", "palau"),
    "code": "PW"
  },
  {
    "label": i18n("countries", "palestine"),
    "code": "PS"
  },
  {
    "label": i18n("countries", "panama"),
    "code": "PA"
  },
  {
    "label": i18n("countries", "papuaNewGuinea"),
    "code": "PG"
  },
  {
    "label": i18n("countries", "paraguay"),
    "code": "PY"
  },
  {
    "label": i18n("countries", "peru"),
    "code": "PE"
  },
  {
    "label": i18n("countries", "philippines"),
    "code": "PH"
  },
  {
    "label": i18n("countries", "pitcairn"),
    "code": "PN"
  },
  {
    "label": i18n("countries", "poland"),
    "code": "PL"
  },
  {
    "label": i18n("countries", "portugal"),
    "code": "PT"
  },
  {
    "label": i18n("countries", "puertoRico"),
    "code": "PR"
  },
  {
    "label": i18n("countries", "qatar"),
    "code": "QA"
  },
  {
    "label": i18n("countries", "reunion"),
    "code": "RE"
  },
  {
    "label": i18n("countries", "romania"),
    "code": "RO"
  },
  {
  "label": i18n("countries", "russia"),
    "code": "RU"
  },
  {
    "label": i18n("countries", "rwanda"),
    "code": "RW"
  },
  {
    "label": i18n("countries", "saintBarthelemy"),
    "code": "BL"
  },
  {
    "label": i18n("countries", "saintHelena"),
    "code": "SH"
  },
  {
    "label": i18n("countries", "saintKittsAndNevis"),
    "code": "KN"
  },
  {
    "label": i18n("countries", "saintLucia"),
    "code": "LC"
  },
  {
    "label": i18n("countries", "saintPierreAndMiquelon"),
    "code": "PM"
  },
  {
    "label": i18n("countries", "saintVincentAndTheGrenadines"),
    "code": "VC"
  },
  {
    "label": i18n("countries", "samoa"),
    "code": "WS"
  },
  {
    "label": i18n("countries", "sanMarino"),
    "code": "SM"
  },
  {
    "label": i18n("countries", "saoTomeAndPrincipe"),
    "code": "ST"
  },
  {
    "label": i18n("countries", "saudiArabia"),
    "code": "SA"
  },
  {
    "label": i18n("countries", "senegal"),
    "code": "SN"
  },
  {
  "label": i18n("countries", "serbia"),
    "code": "RS"
  },
  {
    "label": i18n("countries", "seychelles"),
    "code": "SC"
  },
  {
    "label": i18n("countries", "sierraLeone"),
    "code": "SL"
  },
  {
    "label": i18n("countries", "singapore"),
    "code": "SG"
  },
  {
    "label": i18n("countries", "slovakia"),
    "code": "SK"
  },
  {
    "label": i18n("countries", "slovenia"),
    "code": "SI"
  },
  {
  "label": i18n("countries", "solomonIslands"),
    "code": "SB"
  },
  {
    "label": i18n("countries", "somalia"),
    "code": "SO"
  },
  {
    "label": i18n("countries", "southAfrica"),
    "code": "ZA"
  },
  {
    "label": i18n("countries", "southGeorgiaAndTheSouthSandwichIslands"),
    "code": "GS"
  },
  {
    "label": i18n("countries", "southKorea"),
    "code": "KR"
  },
  {
  "label": i18n("countries", "spain"),
    "code": "ES"
  },
  {
    "label": i18n("countries", "sriLanka"),
    "code": "LK"
  },
  {
    "label": i18n("countries", "sudan"),
    "code": "SD"
  },
  {
    "label": i18n("countries", "suriname"),
    "code": "SR"
  },
  {
    "label": i18n("countries", "svalbardAndJanMayen"),
    "code": "SJ"
  },
  {
    "label": i18n("countries", "switzerland"),
    "code": "CH"
  },
  {
    "label": i18n("countries", "taiwan"),
    "code": "TW"
  },
  {
  "label": i18n("countries", "tajikistan"),
    "code": "TJ"
  },
  {
    "label": i18n("countries", "tanzania"),
    "code": "TZ"
  },
  {
    "label": i18n("countries", "thailand"),
    "code": "TH"
  },
  {
  "label": i18n("countries", "togo"),
    "code": "TG"
  },
  {
    "label": i18n("countries", "tokelau"),
    "code": "TK"
  },
  {
  "label": i18n("countries", "tonga"),
    "code": "TO"
  },
  {
    "label": i18n("countries", "trinidadAndTobago"),
    "code": "TT"
  },
  {
  "label": i18n("countries", "tunisia"),
    "code": "TN"
  },
  {
    "label": i18n("countries", "turkey"),
    "code": "TR"
  },
  {
  "label": i18n("countries", "turkmenistan"),
    "code": "TM"
  },
  {
    "label": i18n("countries", "turksAndCaicosIslands"),
    "code": "TC"
  },
  {
  "label": i18n("countries", "tuvalu"),
    "code": "TV"
  },
  {
  "label": i18n("countries", "uganda"),
    "code": "UG"
  },
  {
  "label": i18n("countries", "ukraine"),
    "code": "UA"
  },
  {
  "label": i18n("countries", "unitedArabEmirates"),
    "code": "AE"
  },
  {
  "label": i18n("countries", "unitedKingdom"),
    "code": "GB"
  },
  {
  "label": i18n("countries", "unitedStates"),
    "code": "US"
  },
  {
  "label": i18n("countries", "uruguay"),
    "code": "UY"
  },
  {
  "label": i18n("countries", "uzbekistan"),
    "code": "UZ"
  },
  {
  "label": i18n("countries", "vanuatu"),
    "code": "VU"
  },
  {
  "label": i18n("countries", "venezuela"),
    "code": "VE"
  },
  {
  "label": i18n("countries", "vietnam"),
    "code": "VN"
  },
  {
  "label": i18n("countries", "usVirginIslands"),
    "code": "VI"
  },
  {
  "label": i18n("countries", "wallisAndFutuna"),
    "code": "WF"
  },
  {
  "label": i18n("countries", "westernSahara"),
    "code": "EH"
  },
  {
  "label": i18n("countries", "yemen"),
    "code": "YE"
  },
  {
  "label": i18n("countries", "zambia"),
    "code": "ZM"
  },
  {
  "label": i18n("countries", "zimbabwe"),
    "code": "ZW"
  }
];