import React from "react";
import i18n from "@helpers/i18n";
import signImage from "@assets/img/ic_sign.png";

export default class ProgressIndicator extends React.PureComponent
{
	render()
	{
		const {props} = this;
		const {hasFormValidationErrors, partner, customer, views, viewNumber, currentWorkflow} = props;
		const signBoxClass = viewNumber === "sign" ? "sign active" : "sign";

		let viewDisplayNumber = 1;
		const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];

		return (
			<div className="Cob-Progress">
				<ul className="Cob-steps">
					{views.map(({
                  id,
									viewIndex,
									label,
									isDisabledForCompanies,
									isDisabledForPersons,
									getInitialState,
									isViewFulfilled,
									useAlternativeViewFulfillment
								}, i) =>
					{
						/**
						 * If view should not be displayed we don't render it's box
						 */
						if ((isDisabledForCompanies && customer.isCompany) || (isDisabledForPersons && !customer.isCompany))
						{
							return null;
						}

						const initialViewState = getInitialState(customer, currentWorkflow);
						let isFulfilled = useAlternativeViewFulfillment ? isViewFulfilled.call(this, initialViewState) : isViewFulfilled(initialViewState);
						if (viewIndex === "1")
						{
							isFulfilled = true; // The start page is always fulfilled once we display the ProgressIndicator
						}

						// check if view is in seenViews
						const hasBeenSeen = (seenViews.includes(viewIndex));

						let boxClass = (hasBeenSeen || hasFormValidationErrors) ?
							(isFulfilled ? "fulfilled" : "unfulfilled") : "";

						if (viewNumber === viewIndex)
						{
							boxClass += " active";
						}

						return (
							<button key={i}
									data-tooltip={label}
									className="cob-steps-btn"
									value={viewIndex}
									type="submit">
								<li className={boxClass}>{viewDisplayNumber++}</li>
							</button>
						);
					})}
					<button className="cob-steps-btn"
							data-tooltip={partner === "citroneer" ? i18n("sign_agreement", "signing") : i18n("nav_labels", "sign_agreement")}
							value="sign"
							type="submit">
						<li className={signBoxClass} style={{backgroundImage: 'url(' + signImage + ')'}}>
							&nbsp;
						</li>
					</button>
				</ul>
			</div>
		);
	};
}