import React from "react";
import moment from "moment";

import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import DefaultFields from "../../../../components/DefaultFields/DefaultFields";
import ContentBox from "@components/ContentBox/ContentBox";
import Checkbox from "@components/Checkbox/Checkbox";
import Notice from "@components/Notice/Notice";
import Select from '@components/Select/Select';
import OptionsList from "../../../../components/OptionsList/OptionsList";
import Button from "@components/Button/Button";
import UploadAndSign from "../../../../components/UploadAndSign/UploadAndSign";
import Fullmaktskollen from "../../../../components/Fullmaktskollen/Fullmaktskollen";
import IdentityNoInputField from "@cobComponents/IdentityNoInputField";

// HELPERS
import i18n from "@helpers/i18n";
import api from '@helpers/api';
import {formatSSN, searchModules} from "@helpers/utils";

// OTHER
import { inputIsCompany } from "../Start.fulfillment";
import Validator from "../../../../../../classes/Validator";


export default class InitSession extends React.PureComponent {
	fields = _getFields.call(this);

	render() {
		const {
      inline,
      showHeader,
      width,
      busy,
      user,
      partner,
      customerState,
      setCustomerState,
      assentlyStatus,
      nextStepOptions,
    } = this.props;

    return (
      <>
        <DefaultFields fields={this.fields}/>
        <ContentBox className={inline ? "inline" : ""} style={{width: width}}>
          <div className="Cob-view1-fields">
            {showHeader && <h2>Rådgivning</h2>}
            <p className="Cob-description tightened">Ange kundens person-/organisationsnummer</p>
            <IdentityNoInputField
              name="customer_social_security_number_init"
              value={!customerState.customerMenuIsOpen && !customerState.customerModalIsOpen ? customerState.ssnOrg : ""}
              label={i18n("cob", customerState.isCompany ? "customers_organizational_number" : "customers_social_security_number")}
              placeholder="12-siffrigt utan bindestreck"
              onChangeCallback={this._onSsnOrgChange}
            />

            <p className="Cob-miniNote">
              <strong>Exempel:</strong> <em>YYYYMMDDXXXX</em> eller <em>16XXXXXXXXXX</em>
            </p>

            {!customerState.customerMenuIsOpen && Validator.validateSsn(customerState.ssnOrg) && customerState.kycInfoLoading && (
              <React.Fragment>
                <div className="loading" style={{flexDirection: "row"}}>
                  <ActivityIndicator color="#91a0ad" scale={0.75} busy/>
                  <span style={{marginLeft: "10px"}}>Laddar...</span>
                </div>
              </React.Fragment>
            )}

            {customerState.needsNewDocumentation && (
              <React.Fragment>
                <Notice className="sign-on-screen-notice" appearance="warning">
                  Kund har i KYC-formuläret angett att vissa förhållanden ändrats sedan det senast
                  signerade rådgivningsprotokollet, varför du som rådgivare ska läsa igenom vad
                  kunden uppgett i KYC:n för att bedöma om en ny lämplighetsbedömning behövs.
                  <br/><br/>
                  <a href={"#"} onClick={this._downloadDocumentation}>Ladda ner ifylld KYC</a>
                  <br/>
                  Var god kontrollera uppgifterna och genomför ny lämplighetsbedömning om nödvändigt.
                  <br/><br/>
                  <strong>Ny rådgivning ej nödvändig</strong>
                  <Checkbox
                    style={{margin: "10px 0"}}
                    type="checkbox"
                    label="Den valda förvaltningen anses fortsatt lämplig för kunden"
                    checked={customerState.overrideNewDocumentation}
                    onChange={checked => setCustomerState({overrideNewDocumentation: checked})}
                  />
                  <Button
                    className="Cob-Footer-button"
                    label="Bekräfta"
                    appearance="primary"
                    disabled={!customerState.overrideNewDocumentation}
                    filled
                    onClick={this._setKycCompleted}
                  />
                </Notice>
              </React.Fragment>
            )}
            {!customerState.customerMenuIsOpen && !customerState.customerModalIsOpen && Validator.validateSsn(customerState.ssnOrg) && (
              <React.Fragment>
                <p className="Cob-description">Alternativ</p>

                <OptionsList
                  type="radio"
                  orientation="vertical"
                  options={nextStepOptions}
                  onChange={this._onCaseTypeSelectChange}
                />                

                {customerState.standaloneApplicationsChecked.length === 0 && customerState.isNewCase === false && (
                  <div className="Cob-view1-cases">
                    {!customerState.cases ? (
                      <div className="loading">
                        <ActivityIndicator color="#91a0ad" scale={0.75} busy/>
                        <span>Laddar...</span>
                      </div>
                    ) : (
                      customerState.cases.length > 0 ? (
                        <React.Fragment>
                          <Select
                            className="cases-select"
                            name="caseId"
                            label="Välj rådgivningstillfälle"
                            renderOption={this._renderCaseOption}
                            options={customerState.casesOptions}
                            selectedIndex={customerState.selectedCaseIndex}
                            customFilterer={this._casesSelectFilterer}
                            onChange={this._onCasesSelectChange}
                          />

                          {!!customerState.cases[customerState.selectedCaseIndex] &&
                            !customerState.cases[customerState.selectedCaseIndex].isDraft &&
                            !customerState.cases[customerState.selectedCaseIndex].isRecalled && (
                              <Notice className="no-draft-notice" appearance="warning"
                                  message="Eftersom det valda rådgivningstillfället redan slutförts så kommer ett nytt förifyllt rådgivningstillfälle att skapas baserat på dessa uppgifter."/>
                            )}

                          {!!customerState.cases[customerState.selectedCaseIndex] && customerState.cases[customerState.selectedCaseIndex].isRecalled && (
                            <Notice className="no-draft-notice" appearance="warning"
                                message="Eftersom det valda rådgivningstillfället har återkallats så kommer ett nytt förifyllt rådgivningstillfälle att skapas baserat på dessa uppgifter."/>
                          )}
                        </React.Fragment>
                      ) : (
                        <p className="empty">
                          <em>Hittade inga tidigare rådgivningstillfällen.</em>
                        </p>
                      )
                    )}
                  </div>
                )}

                {!searchModules(partner.modules, "Fullmaktskollen") && user.hasPermission("fmk.send") && (
                  <Fullmaktskollen customerIdentityNumber={customerState.ssnOrg} user={user}/>
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    marginTop: "20px"                    
                  }}
                >
                  <Button
                    className="Cob-Footer-button"
                    dataInteractive="form-continue-btn"
                    type="submit"
                    label={i18n("general", "continue")}
                    appearance="secondary"
                    disabled={(customerState.selectedOption === "draft-sent-signed" && !customerState.selectedCaseId) || !customerState.selectedOption}
                    busy={busy}
                    style={{
                      width: "100%"                    
                    }}
                    filled
                  />
                </div>
              </React.Fragment>
            )}

            {!searchModules(partner.modules, "UploadAndSign") && user.hasPermission("standalone.send") && (
              <UploadAndSign partnerSsn={user.ssn}/>
            )}
          </div>

          {assentlyStatus && assentlyStatus.length > 0 && (
            <div style={{
              margin: "40px 0 0 0",
              background: "#FFF5F5",
              width: "100%",
              display: "block",
              padding: "20px",
              fontSize: "13px"
            }}>
              <strong style={{display: "block", padding: "0 0 5px 0"}}>
                Driftstörning Assently (digital signering)
              </strong>

              För närvarande påverkas följande system: {assentlyStatus.join(", ")} <br/><br/>
              <a href="https://assently.statuspage.io/" target="_blank" rel="noopener noreferrer">
                För mer information, klicka här
              </a>
            </div>
          )}
        </ContentBox>
      </>
    );
	}

  // Internal methods
	_onCasesSelectChange = ({value}, index) =>  {
    const { setCustomerState } = this.props;
    setCustomerState({selectedCaseId: value, selectedCaseIndex: index});
  };

  _onSsnOrgChange = (value, validated, valueCameFromPaste) =>
	{    
    const { setCustomerState } = this.props;

    const newStateProps = {
      isNewCase: null,
      standaloneApplicationsChecked: [],
      needsNewDocumentation: false,
      kycCaseId: null,
      kycInfoLoading: true,
      overrideNewDocumentation: false,
      casesLoading: false,
      cases: null,
      selectedCaseId: null,
      selectedCaseIndex: -1,
      selectedOption: null
		};

		if (validated)
		{
			setCustomerState({
				isCompany: inputIsCompany(formatSSN(value)),
				ssnOrg: valueCameFromPaste ? formatSSN(value) : value,
        ...newStateProps
			}, async () =>
			{
				await this._checkKycStatus();
			});
		}
		else
		{
			setCustomerState({
				isCompany: false,
				ssnOrg: value,
        ...newStateProps
			});
		}
	};

  _onCaseTypeSelectChange = (checked, value, index) =>
	{    
    const { partner, customerState, setCustomerState } = this.props;

		let selectedOption = null;
    switch (index)
		{
			case 0:
				selectedOption = "new";
				break;
			case 1:
				selectedOption = "draft-sent-signed";
				break;
			case 2:
				selectedOption = "autogiro";
				break;
			case 3:
				selectedOption = "change-details";
				break;
			case 4:
				selectedOption = "termination_full";
				break;
			case 5:
				selectedOption = "termination_partial";
				break;
			case 6:
				selectedOption = "deposit-insurance";
				break;
			default:
				break;
		}
		const newStateProps = {
			isNewCase: index === 0,
      selectedOption,
			standaloneApplicationsChecked: []
		};

		if (index === 0)
		{
			newStateProps.selectedCaseId = null;
			newStateProps.selectedCaseIndex = -1;
		}
		else if (index > 1)
		{
			const applicationIndex = index - 2;
			const applications = partner.standaloneApplications;
			const app = applications[applicationIndex];
			newStateProps.standaloneApplicationsChecked.push(app.label);
		}

		setCustomerState(newStateProps, () =>
		{
			if (index === 1 && !customerState.cases)
			{
				_getCustomerCases.call(this);
			}
		});
	};

	_casesSelectFilterer = ({option}, inputValue) =>
	{
		inputValue = inputValue.toLowerCase();
		return option.label.indexOf(inputValue) === 0 || option.value.indexOf(inputValue) === 0;
	};

	_renderCaseOption = ({label, isDraft, isRecalled, isSigned}) =>
	{
		return (
			<div className="Cob-view1-caseOption">
				<div className="label">{label}</div>

				{isRecalled && (
					<div className="info recalled">Återkallat</div>
				)}

				{!isDraft && isSigned && (
					<div className="info signed">Signerat</div>
				)}

				{!isDraft && !isSigned && !isRecalled && (
					<div className="info sent">Skickat</div>
				)}

				{isDraft && !isRecalled && (
					<div className="info draft">Utkast</div>
				)}
			</div>
		);
	};

  _checkKycStatus = () =>
	{    
    const { partner, customerState, setCustomerState } = this.props;
		setCustomerState({kycInfoLoading: true, kycCaseId: null}, async () =>
		{
			let {kycDate, kycRequiresUpdate, caseId} = await api("/case/kyc-status", {
				ssn: formatSSN(customerState.ssnOrg, {withDash: false}),
				wl: partner._name
			});
			setCustomerState({kycInfoLoading: false, kycDate: kycDate, needsNewDocumentation: kycRequiresUpdate, kycCaseId: caseId});
		});
	};
  
	_setKycCompleted = async () =>
	{    
    const { customerState, setCustomerState } = this.props;
		await api("/case/kyc-dismiss", {
			identity: formatSSN(customerState.ssnOrg, {withDash: false}),
			userId: this.props.user.ssn
		});
		setCustomerState({kycInfoLoading: false, kycDate: null, kycCaseId: null, needsNewDocumentation: false});
	};

  _downloadDocumentation = async () =>
	{
		const caseId = this.props.customerState.kycCaseId;
		const rawDataInBase64 = await api("/case/downloadReceipt", {
			caseId: caseId
		}, {}, true);

		if (rawDataInBase64 !== "")
		{
			console.log("Downloading case data...");
			// It is necessary to create a new blob object with mime-type explicitly set
			// otherwise only Chrome works like it should
			//blob = atob(blob);

			// For other browsers:
			// Create a link pointing to the ObjectURL containing the blob.
			const link = document.createElement('a');
			link.href = "data:application/pdf;base64," + rawDataInBase64;
			link.download = caseId + ".pdf";
			link.click();
		}
		else
		{
			console.log("Could not download case data");
		}
	};
}

// PRIVATE FUNCTIONS
function _getCustomerCases()
{
  const { setCustomerState } = this.props;
	return new Promise((resolve) =>
	{
		setCustomerState({casesLoading: true}, async () =>
		{
			try
			{
				const {user, customerState} = this.props;

				let cases = await api("/case/customer-cases", {
					partnerSsn: user.ssn,
					customerId: formatSSN(customerState.ssnOrg, {withDash: false})
				});

				/**
				 * Sort cases according to when they were last modified
				 */
				cases.sort(function (a, b)
				{
					return b.createdOn - a.createdOn;
				});

				// Filter cases that are the correct whitelabel and workflow
				cases = cases.filter(function (c)
				{
					return c.whitelabel === "fair";
				});

				const casesOptions = cases.map(({createdOn, caseId, isDraft, isRecalled, isSigned}) => ({
					label: moment(createdOn).format("YYYY-MM-DD H:mm"),
					value: caseId,
					title: caseId,
					caseId,
					isDraft,
					isRecalled,
					isSigned
				}));

				setCustomerState({cases, casesOptions, casesLoading: false}, () => resolve());
			}
			catch (e)
			{
				console.error(e.message);
				resolve();
			}
		});
	});
}

function _getFields()
{
	return [
		"checkbox_company",
		"customer_social_security_number"
	];
}