// MODULES
import React from "react";

import EmailIcon from '@mui/icons-material/Email';

import Card from "@cobComponents/Card";
import ContactDetails from "./ContactDetails/ContactDetails";

const ContactDetailsCard = props => {
  const { client, updateCustomers } = props;

  return (
    <Card 
      title="Kontaktuppgifter"
      icon={EmailIcon}
      sxStyle={{
        mt: {xs: 2, md: 0},
      }}
    >
      <ContactDetails
        client={client}
        updateCustomers={updateCustomers}
      />
    </Card>
  );
};

export default ContactDetailsCard;