import React from "react";

// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import TextView from "@components/TextView/TextView";

// HELPERS
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";
import api from "@helpers/api";

// OTHER
import fulfillment from "./MeetingNotes.fulfillment";
import Settings from "../../../../../classes/Settings";


export default class MeetingNotesView extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state)
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) callbackFn();
		});
	}

	async componentDidMount() {
		const {setAppState} = this.props;

		const knowledgeScore = await _getCustomerKnowledgeScore.call(this);

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
          onClick: () => {
            if (this.state.isFulfilled === true) {
              return;
            }
            
            this.setState({hasFormValidationErrors: true});
            return setAppState({
              showValidationWarning: true
            });
          }
        }
			},
		});

		this.setState({
			knowledgeScore: knowledgeScore
		});
	}

	render() {
		const {state} = this;
		const {customerState, hasFormValidationErrors} = state;
		const classes = createClassName("Cob-view Cob-view8", {});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Slutsats</h2>

					<div className="Cob-view8-fields">

						{state.knowledgeScore === "-" && (
							<div style={{
								marginTop: "20px",
								padding: "20px",
								border: "1px solid #CCC",
								width: "100%",
								display: "block",
								textAlign: "center",
								background: "#fff8e5",
								fontSize: "15px",
								fontWeight: "300",
								color: "#000"
							}}>
								Beräknar kunskapsnivå...
							</div>
						)}

						{state.knowledgeScore !== "-" && (
							<div style={{
								marginTop: "20px",
								padding: "20px",
								border: "1px solid #CCC",
								width: "100%",
								display: "block",
								textAlign: "center",
								background: "#fff8e5",
								fontSize: "15px",
								fontWeight: "300",
								color: "#000"
							}} dangerouslySetInnerHTML={{__html: state.knowledgeScore}}/>
						)}

						<input type="hidden" name="customer_knowledge_level"
							   value={state.knowledgeScore.replace("<strong>", "").replace("</strong>", "")} />

						<div className="h-line spacing"/>

						<h4>Mötesnoteringar</h4>
						<TextView
							name="advice_notes"
							label={`${i18n("general", "notes")} (${1700 - customerState.advice_notes.length} tecken kvar)`}
							value={customerState.advice_notes}
							maxLength={1700}
							// validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({advice_notes: target.value})}
							fullWidth
							minHeight={250}
              highlightErrors={hasFormValidationErrors}
						/>

					</div>
				</ContentBox>
			</div>
		);
	}
}


// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, viewNo, hasFormValidationErrors} = this.props;
  const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
  const hasBeenSeen = (seenViews.includes(viewNo));   
  // if it hasn't been seen add the view to local storage for the specific case
  if (customer.caseId && !hasBeenSeen) {
    localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
  }

	let newState = getViewInitialStateForCustomer(customer);  
	newState.isFulfilled = this.isFulfilled(newState);  
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	const newState = {
		customerState: {
			checkbox_advice_suitability_goals: customer.getData("checkbox_advice_suitability_goals"),
			checkbox_advice_suitability_risk: customer.getData("checkbox_advice_suitability_risk"),
			checkbox_advice_suitability_knowledge: customer.getData("checkbox_advice_suitability_knowledge"),
			checkbox_advice_suitability_other: customer.getData("checkbox_advice_suitability_other"),
			advice_suitability_knowledge_other: customer.getData("advice_suitability_knowledge_other"),

			advice_notes: customer.getData("advice_notes")
		},
	};

	newState.isFulfilled = false;
	newState.knowledgeScore = "-";

	return newState;
}

function _getFields() {
	return [
		"advice_notes"
	];
}

function _getCustomerKnowledgeScore() {
	return new Promise(async (resolve, reject) => {

		try {
			const knowledgeScore = await api("/case/knowledge", {
				caseId: Settings.get("CASE_ID")
			});
			resolve(knowledgeScore.text);
		}
		catch (e) {
			console.error(e.message);
			reject();
		}
	});
}