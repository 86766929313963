import React from "react";

// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import Icon from "@components/Icon/Icon";
// import StackLayout from "@components/layouts/StackLayout/StackLayout";

// HELPERS
import { createClassName } from "@helpers/utils";
// import i18n from "@helpers/i18n";


export default class CobFinish extends React.PureComponent {

    componentDidMount() {
        const { setAppState } = this.props;

        // Set navigation events
        setAppState({
            viewFulfilled: false,
            navigationButtons: {
                sharing: {display: false},
                back: { disabled: true },
                next: { disabled: true }
            },
        });
    }

    render() {
        // const { props } = this;
        const classes = createClassName("Cob-Finish", {
            
        });

        return (
            <div className={ classes }>
                <ContentBox>
                    
                    <div className="icon-circle">
                        <Icon value="checkmark" size="xlarge" />
                    </div>
                    
                    <h2>Färdigt!</h2>
                    
                </ContentBox>
            </div>
        );
    }
}