import React from "react";

// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";

// HELPERS
import {createClassName} from "@helpers/utils";

// OTHER
import fulfillment from "./BasicProductSelection.fulfillment";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import TextView from "../../../../../components/TextView/TextView";
import Select from "../../../../../components/Select/Select";
import TextField from "../../../../../components/TextField/TextField";
import NumberField from "../../../components/NumberField/NumberField";
import SimpleTextField from "../../../components/SimpleTextField/SimpleTextField";
import Validator from "../../../../../classes/Validator";
import OptionsList from "../../../components/OptionsList/OptionsList";
import AddonPlacement from "./subcomponents/AddonPlacement";
import {localeNumber} from "../../../../../helpers/utils";


export default class BasicProductSelectionView extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);
	investmentAdviceProducts = [
		{
			label: "Camp B", value: "Camp B"
		},
		{
			label: "Annan (ange)", value: "Annan"
		}];

	static feesTextTemplate = "Du har tagit del av ett erbjudande om reducerat arvode för att sänka din vinstdelning och " +
		"kommer därför vid initial insättning betala en fast andel av insatt belopp (förvaltningsrabatt). Din förvaltningsrabatt uppgår " +
		"till [FR] SEK och kommer inte att utgöra en avgift kommande år. Avgifter kopplade till " +
		"vinstdelning är beroende på förvaltningens avkastning. Vad du årligen betalar för denna tjänst beror på framtida " +
		"marknadsresultat. Den framtida marknadsutvecklingen är osäker och kan inte förutsägas exakt, varför vi istället " +
		"nedan ger exempel på några olika scenarion. Vid en negativ (linjär) utveckling om -5% (-[AMNT-5%] kr) uppgår " +
		"vinstdelningen till Bolaget till 0 kr. Vid en (linjär) svag utveckling om 5% (+[AMNT+5%] kr), uppgår " +
		"vinstdelningen till Bolaget till [RS+5%] kr av dessa. Vid en (linjär) starkt positiv utveckling om 15 % " +
		"(+[AMNT+15%] kr), uppgår vinstdelningen till Bolaget till [RS+15%] kr av dessa.";

	isFulfilled(state)
	{
		return fulfillment.call(this, state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		if (customerState.checkbox_product_advice)
		{
			customerState.checkbox_product_spets = false;
			customerState.checkbox_product_guru = false;
			customerState.checkbox_product_svan = false;
		}
		else if (customerState.checkbox_product_spets)
		{
			customerState.checkbox_product_advice = false;
			customerState.checkbox_product_guru = false;
			customerState.checkbox_product_svan = false;
		}
		else if (customerState.checkbox_product_guru)
		{
			customerState.checkbox_product_advice = false;
			customerState.checkbox_product_spets = false;
			customerState.checkbox_product_svan = false;
		}
		else if (customerState.checkbox_product_svan)
		{
			customerState.checkbox_product_advice = false;
			customerState.checkbox_product_spets = false;
			customerState.checkbox_product_guru = false;
		}

		customerState = {...this.state.customerState, ...customerState};
		updateInvestedProductFields(customerState);

		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
					onClick: () =>
					{
						if (this.state.isFulfilled === true)
						{
							return;
						}

						this.setState({hasFormValidationErrors: true});
						return setAppState({
							showValidationWarning: true
						});
					}
				}
			}
		});
	}

	getAllocationFields()
	{
		const {state} = this;
		const {customerState} = state;

		let feeGuru = customerState.allocation_fee_guru_revenueshare && (
			customerState.allocation_fee_guru_revenueshare === "33" ? "standard" : "reduced"
		);

		let feeSpets = customerState.allocation_fee_spets_revenueshare && (
			customerState.allocation_fee_spets_revenueshare === "33" ? "standard" : "reduced"
		);

		if (customerState.radio_spets_fixed_fee === "yes")
		{
			feeSpets = "fixed";
		}

		if (customerState.radio_guru_fixed_fee === "yes")
		{
			feeGuru = "fixed";
		}

		let amountGuru = customerState.allocation_amount_guru;
		let amountSpets = customerState.allocation_amount_spets;

		if (amountGuru.length > 0 && amountGuru.indexOf(" SEK") <= 0)
		{
			amountGuru = localeNumber(amountGuru, 0) + " SEK";
		}

		if (amountSpets.length > 0 && amountSpets.indexOf(" SEK") <= 0)
		{
			amountSpets = localeNumber(amountSpets, 0) + " SEK";
		}

		if (customerState.checkbox_product_guru && !customerState.checkbox_product_spets)
		{
			return {
				allocation_amount_first: amountGuru,
				allocation_product_first: "Flexibel",
				allocation_product_bilaga_first: "",
				radio_allocation_fee_first: feeGuru,
				allocation_fee_revshare_first: feeGuru === "fixed" ? "" : (customerState.allocation_fee_guru_revenueshare && (customerState.allocation_fee_guru_revenueshare === "0" || customerState.allocation_fee_guru_revenueshare >= "33") ? "" : customerState.allocation_fee_guru_revenueshare),
				allocation_fee_rebate_first: feeGuru === "fixed" ? "" : (customerState.allocation_fee_guru_rebate && customerState.allocation_fee_guru_rebate === "0" ? "" : customerState.allocation_fee_guru_rebate),

				allocation_amount_second: "",
				allocation_product_second: "",
				allocation_product_bilaga_second: "",
				radio_allocation_fee_second: "",
				allocation_fee_revshare_second: "",
				allocation_fee_rebate_second: ""
			}
		}

		if (!customerState.checkbox_product_guru && customerState.checkbox_product_spets)
		{
			return {
				allocation_amount_first: amountSpets,
				allocation_product_first: "Global",
				allocation_product_bilaga_first: "",
				radio_allocation_fee_first: feeSpets,
				allocation_fee_revshare_first: feeSpets === "fixed" ? "" : (customerState.allocation_fee_spets_revenueshare && (customerState.allocation_fee_spets_revenueshare === "0" || customerState.allocation_fee_spets_revenueshare === "33") ? "" : customerState.allocation_fee_spets_revenueshare),
				allocation_fee_rebate_first: feeSpets === "fixed" ? "" : (customerState.allocation_fee_spets_rebate && customerState.allocation_fee_spets_rebate === "0" ? "" : customerState.allocation_fee_spets_rebate),

				allocation_amount_second: "",
				allocation_product_second: "",
				allocation_product_bilaga_second: "",
				radio_allocation_fee_second: "",
				allocation_fee_revshare_second: "",
				allocation_fee_rebate_second: ""
			}
		}

		if (customerState.checkbox_product_guru && customerState.checkbox_product_spets)
		{
			return {
				allocation_amount_first: amountGuru,
				allocation_product_first: "Flexibel",
				allocation_product_bilaga_first: "",
				radio_allocation_fee_first: feeGuru,
				allocation_fee_revshare_first: feeGuru === "fixed" ? "" : (customerState.allocation_fee_guru_revenueshare && (customerState.allocation_fee_guru_revenueshare === "0" || customerState.allocation_fee_guru_revenueshare === "33") ? "" : customerState.allocation_fee_guru_revenueshare),
				allocation_fee_rebate_first: feeGuru === "fixed" ? "" : (customerState.allocation_fee_guru_rebate && customerState.allocation_fee_guru_rebate === "0" ? "" : customerState.allocation_fee_guru_rebate),

				allocation_amount_second: amountSpets,
				allocation_product_second: "Global",
				allocation_product_bilaga_second: "",
				radio_allocation_fee_second: feeSpets,
				allocation_fee_revshare_second: feeSpets === "fixed" ? "" : (customerState.allocation_fee_spets_revenueshare && (customerState.allocation_fee_spets_revenueshare === "0" || customerState.allocation_fee_spets_revenueshare === "33") ? "" : customerState.allocation_fee_spets_revenueshare),
				allocation_fee_rebate_second: feeSpets === "fixed" ? "" : (customerState.allocation_fee_spets_rebate && customerState.allocation_fee_spets_rebate === "0" ? "" : customerState.allocation_fee_spets_rebate),
			}
		}

		return {
			allocation_amount_first: "",
			allocation_product_first: "",
			allocation_product_bilaga_first: "",
			radio_allocation_fee_first: "",
			allocation_fee_revshare_first: "",
			allocation_fee_rebate_first: "",

			allocation_amount_second: "",
			allocation_product_second: "",
			allocation_product_bilaga_second: "",
			radio_allocation_fee_second: "",
			allocation_fee_revshare_second: "",
			allocation_fee_rebate_second: ""
		}
	}

	render()
	{
		const {state} = this;
		const {customer, availableBanks} = this.props;
		const {customerState} = state;

		const hasFormValidationErrors = !this.state.isFulfilled && this.state.hasFormValidationErrors;
		const classes = createClassName("Cob-view Cob-basic-product-selection", {});

		/**
		 * Set up the dropdown with selectable banks.
		 */
		let availableBankOptions = [];
		for (const bankName in availableBanks)
		{
			if (bankName === 'length' || !availableBanks.hasOwnProperty(bankName))
			{
				continue;
			}
			availableBankOptions.push({
				label: bankName,
				value: bankName
			});
		}

		availableBankOptions.push({
			label: "Övriga",
			value: "-"
		});

		let isIskTypeSelected =
			(customerState.checkbox_product_guru && customerState.allocation_amount_guru.length > 0 && customerState.checkbox_accounttype_guru === "isk") ||
			(customerState.checkbox_product_guru && customerState.allocation_amount_guru.length > 0 && customerState.checkbox_accounttype_guru === 1) ||

			(customerState.checkbox_product_spets && customerState.allocation_amount_spets.length > 0 && customerState.checkbox_accounttype_spets === "isk") ||
			(customerState.checkbox_product_spets && customerState.allocation_amount_spets.length > 0 && customerState.checkbox_accounttype_spets === 1) ||

			(customerState.checkbox_product_svan && customerState.allocation_amount_svan.length > 0 && customerState.checkbox_accounttype_svan === "isk") ||
			(customerState.checkbox_product_svan && customerState.allocation_amount_svan.length > 0 && customerState.checkbox_accounttype_svan === 1) ||

			(customerState.checkbox_product_other && customerState.allocation_amount_other.length > 0 && customerState.checkbox_accounttype_other === "isk") ||
			(customerState.checkbox_product_other && customerState.allocation_amount_other.length > 0 && customerState.checkbox_accounttype_other === 1) ||

			(customerState.checkbox_product_advice && customerState.allocation_amount_advice.length > 0 && customerState.checkbox_accounttype_advice === "isk") ||
			(customerState.checkbox_product_advice && customerState.allocation_amount_advice.length > 0 && customerState.checkbox_accounttype_advice === 1);

		let accountTypeOptions = [
			{label: "Depå hos Fair", value: "naked"},
			{label: "ISK hos Fair", value: "isk"},
			{label: "Stiftelse", value: "trust"},
			{label: "P-försäkring", value: "pinsurance"},
			{label: "T-försäkring", value: "tinsurance"},
			{label: "K-försäkring", value: "kinsurance"}
		];

		// ISK not available for companies
		if (customer.isCompany)
		{
			isIskTypeSelected = false;
			accountTypeOptions = [
				{label: "Depå hos Fair", value: "naked"},
				{label: "Stiftelse", value: "trust"},
				{label: "P-försäkring", value: "pinsurance"},
				{label: "T-försäkring", value: "tinsurance"},
				{label: "K-försäkring", value: "kinsurance"}
			];
		}

		let totalAmountInvested = 0;
		totalAmountInvested += customerState.checkbox_product_spets && customerState.allocation_amount_spets !== "" ? parseInt(customerState.allocation_amount_spets.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAmountInvested += customerState.checkbox_product_guru && customerState.allocation_amount_guru !== "" ? parseInt(customerState.allocation_amount_guru.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAmountInvested += customerState.checkbox_product_other && customerState.allocation_amount_other !== "" ? parseInt(customerState.allocation_amount_other.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAmountInvested += customerState.checkbox_product_svan && customerState.allocation_amount_svan !== "" ? parseInt(customerState.allocation_amount_svan.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAmountInvested += customerState.checkbox_product_advice && customerState.allocation_amount_advice !== "" ? parseInt(customerState.allocation_amount_advice.replace(" SEK", "").split(' ').join(''), 10) : 0;
		const totalAmountInvestedFormatted = localeNumber(totalAmountInvested, 0);

		const allocationFields = this.getAllocationFields();

		let spetsAmount = 0;
		if (customerState.checkbox_product_spets && customerState.allocation_amount_spets.length > 0)
		{
			spetsAmount = parseInt(customerState.allocation_amount_spets.split(' ').join('').replace("SEK", ""), 10);
		}

		let depositedAmount = "0 SEK";
		if (customerState.depositedAmount && customerState.depositedAmount > 0)
		{
			depositedAmount = customerState.depositedAmount.toLocaleString('sv') + " SEK";
		}

		let amountIsLessOrEqualToDeposited = true;
		if (totalAmountInvested > 0)
		{
			amountIsLessOrEqualToDeposited = totalAmountInvested <= customerState.depositedAmount;
		}

		let totalCostSpets = "";
		let totalCostGuru = "";
		let totalCostSvan = "";

		if (customerState.radio_product_selection_mode === "new")
		{
			if (customerState.checkbox_product_spets)
			{
				if (customerState.allocation_fee_spets_rebate.length > 0 &&
					customerState.allocation_amount_spets.length > 0 &&
					customerState.allocation_fee_spets_revenueshare.length > 0)
				{
					const spetsAmount = parseInt(customerState.allocation_amount_spets.replace(" SEK", "").split(' ').join(''), 10)
					const spetsRebateInSekRaw = (customerState.allocation_fee_spets_rebate / 100) * spetsAmount;
					const spetsRebateInSek = localeNumber(spetsRebateInSekRaw, 0);
					const spetsMinus5 = localeNumber(((spetsAmount - spetsRebateInSekRaw) * 0.05), 0);
					const spetsPlus5Raw = (spetsAmount - spetsRebateInSekRaw) * 0.05;
					const spetsPlus5 = localeNumber(spetsPlus5Raw, 0);
					const spetsPlus15Raw = (spetsAmount - spetsRebateInSekRaw) * 0.15;
					const spetsPlus15 = localeNumber(spetsPlus15Raw, 0);

					const revSharePct = (customerState.allocation_fee_spets_revenueshare / 100);
					const revShare5 = localeNumber((spetsPlus5Raw * revSharePct), 2);
					const revShare15 = localeNumber((spetsPlus15Raw * revSharePct), 2);

					totalCostSpets = BasicProductSelectionView.feesTextTemplate;
					totalCostSpets = totalCostSpets.replaceAll("[FR]", spetsRebateInSek);
					totalCostSpets = totalCostSpets.replaceAll("[AMNT-5%]", spetsMinus5);
					totalCostSpets = totalCostSpets.replaceAll("[AMNT+5%]", spetsPlus5);
					totalCostSpets = totalCostSpets.replaceAll("[AMNT+15%]", spetsPlus15);
					totalCostSpets = totalCostSpets.replaceAll("[RS+5%]", revShare5);
					totalCostSpets = totalCostSpets.replaceAll("[RS+15%]", revShare15);
				}
				else
				{
					totalCostSpets = "-";
				}
			}

			if (customerState.checkbox_product_guru)
			{
				if (customerState.allocation_fee_guru_rebate.length > 0 &&
					customerState.allocation_amount_guru.length > 0 &&
					customerState.allocation_fee_guru_revenueshare.length > 0)
				{
					const guruAmount = parseInt(customerState.allocation_amount_guru.replace(" SEK", "").split(' ').join(''), 10)
					const guruRebateInSekRaw = (customerState.allocation_fee_guru_rebate / 100) * guruAmount;
					const guruRebateInSek = localeNumber(guruRebateInSekRaw, 0);
					const minus5 = localeNumber(((guruAmount - guruRebateInSekRaw) * 0.05), 0);
					const plus5Raw = (guruAmount - guruRebateInSekRaw) * 0.05;
					const plus5 = localeNumber(plus5Raw, 0);
					const plus15Raw = (guruAmount - guruRebateInSekRaw) * 0.15;
					const plus15 = localeNumber(plus15Raw, 0);

					const revSharePct = (customerState.allocation_fee_guru_revenueshare / 100);
					const revShare5 = localeNumber((plus5Raw * revSharePct), 2);
					const revShare15 = localeNumber((plus15Raw * revSharePct), 2);

					totalCostGuru = BasicProductSelectionView.feesTextTemplate;
					totalCostGuru = totalCostGuru.replaceAll("[FR]", guruRebateInSek);
					totalCostGuru = totalCostGuru.replaceAll("[AMNT-5%]", minus5);
					totalCostGuru = totalCostGuru.replaceAll("[AMNT+5%]", plus5);
					totalCostGuru = totalCostGuru.replaceAll("[AMNT+15%]", plus15);
					totalCostGuru = totalCostGuru.replaceAll("[RS+5%]", revShare5);
					totalCostGuru = totalCostGuru.replaceAll("[RS+15%]", revShare15);
				}
				else
				{
					totalCostGuru = "-";
				}
			}

			if (customerState.checkbox_product_svan)
			{
				if (customerState.allocation_amount_svan.length > 0 &&
					customerState.allocation_fee_guru_revenueshare.length > 0)
				{
					let svanAmount = parseInt(customerState.allocation_amount_svan.replace(" SEK", "").split(' ').join(''), 10)
					const minus5 = localeNumber(((svanAmount) * 0.05), 0);
					const plus5Raw = (svanAmount) * 0.05;
					const plus5 = localeNumber(plus5Raw, 0);
					const plus15Raw = (svanAmount) * 0.15;
					const plus15 = localeNumber(plus15Raw, 0);

					const revSharePct = 0.33;
					const revShare5 = localeNumber((plus5Raw * revSharePct), 2);
					const revShare15 = localeNumber((plus15Raw * revSharePct), 2);

					totalCostSvan = BasicProductSelectionView.feesTextTemplate;
					totalCostSvan = totalCostSvan.replaceAll("[FR]", "0");
					totalCostSvan = totalCostSvan.replaceAll("[AMNT-5%]", minus5);
					totalCostSvan = totalCostSvan.replaceAll("[AMNT+5%]", plus5);
					totalCostSvan = totalCostSvan.replaceAll("[AMNT+15%]", plus15);
					totalCostSvan = totalCostSvan.replaceAll("[RS+5%]", revShare5);
					totalCostSvan = totalCostSvan.replaceAll("[RS+15%]", revShare15);
				}
				else
				{
					totalCostSvan = "-";
				}
			}
		}


		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Förvaltningstjänster</h2>

					<OptionsList
						type="radio"
						name="radio_product_selection_mode"
						style={{maxWidth: "none"}}
						value={customerState.radio_product_selection_mode}
						options={[
							{
								label: "Ny placering",
								value: "new",
								disabled: customerState.force_no_placement,
								checked: customerState.radio_product_selection_mode === "new" && !customerState.force_no_placement
							},
							{
								label: "Tilläggsplacering / Byte av avgiftsmodell",
								value: "addonchange",
								disabled: customerState.force_no_placement,
								checked: customerState.radio_product_selection_mode === "addonchange" && !customerState.force_no_placement
							},
							{
								label: "Ingen placering",
								value: "nochange",
								disabled: !customerState.force_no_placement,
								checked: customerState.force_no_placement
							}
						]}
						onChange={(checked, radio_product_selection_mode) =>
						{
							if (!customerState.force_no_placement)
							{
								this.setCustomerState({radio_product_selection_mode});
							}
						}}
						validation={this._optionsValidation}
						highlightErrors={hasFormValidationErrors}
					/>

					{customerState.radio_product_selection_mode !== "nochange" && (
						<div className="h-line spacing" style={{marginTop: "25px"}}/>
					)}

					{customerState.radio_product_selection_mode === "" && (
						<div style={{fontStyle: "italic", fontSize: "18px", display: "block", width: "100%"}}>
							Var god välj förvaltningstjänst.
						</div>
					)}

					{(customerState.radio_product_selection_mode === "new" ||
						customerState.radio_product_selection_mode === "addonchange") && (
						<div style={{alignItems: "flex-start", alignSelf: "flex-start"}}>
							<Checkbox
								type="checkbox"
								name="checkbox_customer_is_audience"
								label="Rådgivaren har säkerställt att kunden tillhör produkten/produkternas målgrupp"
								value={customerState.checkbox_customer_is_audience}
								checked={customerState.checkbox_customer_is_audience === "Ja"}
								onChange={checked => this.setCustomerState({checkbox_customer_is_audience: checked ? "Ja" : "nej"})}
								highlightErrors={hasFormValidationErrors}
							/>
						</div>
					)}

					{customerState.radio_product_selection_mode === "addonchange" && (
						<AddonPlacement setAppState={this.props.setAppState}
										customer={customer}
										onChange={this._AddonPlacementFulfillment}
						                depositedAmount={customerState.depositedAmount}
						                depositedAmountFormatted={depositedAmount}
										highlightErrors={hasFormValidationErrors}
						/>
					)}

					{customerState.radio_product_selection_mode === "new" && (
						<div className="Cob-basic-product-selection-fields">
							<h4>Förvaltningstjänst som tecknas vid detta tillfälle</h4>
							<p style={{margin: "0 0 20px 0"}}>
								<strong>Investerat belopp: </strong> {depositedAmount}*
								<em style={{fontSize: "11px", marginTop: "3px", display: "block"}}>
									*Beloppet anges på sid 3 i avsnittet "Frågor enligt penningtvättslagen".
								</em>
							</p>

							<Checkbox
								style={{marginTop: "20px", paddingRight: "30px"}}
								type="radio"
								name="checkbox_product_spets"
								label="Fair Globalförvaltning"
								value={customerState.checkbox_product_spets}
								checked={customerState.checkbox_product_spets}
								onChange={checked => this.setCustomerState({checkbox_product_spets: checked})}
								highlightErrors={hasFormValidationErrors}
								groupValid={
									customerState.checkbox_product_spets ||
									customerState.checkbox_product_guru ||
									customerState.checkbox_product_svan ||
									customerState.checkbox_product_advice
								}
							/>

							{customerState.checkbox_product_spets && (
								<div className="product">
									<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">Belopp att investera</p>
											<NumberField
												name="allocation_amount_spets"
												label="Ange i SEK"
												unit=" SEK"
												value={customerState.allocation_amount_spets || ""}
												showSeparators={true}
												onChange={({target}) =>
												{
													this.setCustomerState({allocation_amount_spets: target.value.split(' ').join('')});
												}}
												validation={value => amountIsLessOrEqualToDeposited && value.length > 0}
												highlightErrors={hasFormValidationErrors}
											/>

											{!amountIsLessOrEqualToDeposited && customerState.allocation_amount_spets.length > 0 && (
												<p style={{fontSize: "12px", lineHeight: "14px", margin: "5px 0 0 5px", color: "red"}}>
													Beloppet överskrider av kunden investerad summa ({depositedAmount}).
												</p>
											)}
										</div>
										<div>
											<p className="fieldLabel">Andel av placeringsbara tillgångar</p>
											<Select
												name="radio_allocation_perc_spets"
												label="Ange i procent"
												selectedIndex={customerState.radio_allocation_perc_spetsIndex}
												options={[
													{label: "0-5%", value: "0-5"},
													{label: "6-10%", value: "6-10"},
													{label: "11-20%", value: "11-20"},
													{label: ">20%", value: ">20"}
												]}
												onChange={(option) => this.setCustomerState({radio_allocation_perc_spets: option.value})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Risk enligt "Information om riskklasser"</p>
											<strong style={{fontSize: "16px"}}>4</strong>
											<input type="hidden" name="radio_allocation_risk_spets" value="4"/>
										</div>
										<div>
											<p className="fieldLabel">Vilken skattemiljö gäller för denna placering?</p>
											<Select
												name="checkbox_accounttype_spets"
												label="Ange"
												selectedIndex={customerState.checkbox_accounttype_spetsIndex}
												options={accountTypeOptions}
												onChange={(option) => this.setCustomerState({checkbox_accounttype_spets: option.value})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Placeringshorisont</p>
											<strong>Minst 5 år</strong>
										</div>
									</StackLayout>

									{customerState.radio_spets_fixed_fee === "yes" && (
										<React.Fragment>
											<input type="hidden" name="allocation_fee_spets" value=""/>
											<input type="hidden" name="allocation_fee_spets_revenueshare" value=""/>
											<input type="hidden" name="allocation_fee_spets_rebate" value=""/>
											<input type="hidden" name="radio_allocation_fee_spets" value="fixed"/>
										</React.Fragment>
									)}

									{customerState.radio_spets_fixed_fee === "no" && (
										<React.Fragment>
											<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
												<div>
													<p className="fieldLabel">
														Vinstdelning
													</p>
													<div style={{flexDirection: "row"}}>
														<SimpleTextField
															type="number"
															placeholder="Ange i %"
															value={customerState.allocation_fee_spets_revenueshare || ""}
															textAlign="left"
															validation={value => value.length > 0}
															style={{width: "100px", marginRight: "5px"}}
															onChange={({target}) => this.setCustomerState({allocation_fee_spets_revenueshare: target.value})}
															highlightErrors={hasFormValidationErrors}
														/>
														%
													</div>

													<p className="fieldLabel" style={{marginTop: "10px"}}>
														Förvaltningsrabatt
													</p>
													<div style={{flexDirection: "row", marginBottom: "20px"}}>
														<SimpleTextField
															type="number"
															placeholder="Ange i %"
															value={customerState.allocation_fee_spets_rebate || ""}
															textAlign="left"
															validation={value => value.length > 0}
															style={{width: "100px", marginRight: "5px"}}
															onChange={({target}) => this.setCustomerState({allocation_fee_spets_rebate: target.value})}
															highlightErrors={hasFormValidationErrors}
														/>
														%
													</div>

													<input type="hidden" name="allocation_fee_spets"
														   value={customerState.allocation_fee_spets_revenueshare
															   ? customerState.allocation_fee_spets_revenueshare
															   : ""}/>

													<input type="hidden" name="allocation_fee_spets_revenueshare"
														   value={customerState.allocation_fee_spets_revenueshare
															   ? customerState.allocation_fee_spets_revenueshare
															   : ""}/>

													<input type="hidden" name="allocation_fee_spets_rebate"
														   value={customerState.allocation_fee_spets_rebate
															   ? customerState.allocation_fee_spets_rebate
															   : ""}/>

													<input type="hidden" name="radio_allocation_fee_spets"
														   value={customerState.allocation_fee_spets_revenueshare && customerState.allocation_fee_spets_revenueshare === "33"
															   ? "standard"
															   : "reduced"}/>
												</div>
											</StackLayout>
										</React.Fragment>
									)}

									<div style={{alignItems: "flex-start", width: "100%", margin: "10px 0 0 0"}}>
										<Checkbox
											type="checkbox"
											name="checkbox_spets_mjukstart"
											value={customerState.checkbox_spets_mjukstart === "yes" ? "yes" : "no"}
											label={"Mjukstart"}
											checked={customerState.checkbox_spets_mjukstart === "yes"}
											onChange={checked => this.setCustomerState({checkbox_spets_mjukstart: checked ? "yes" : "no"})}
										/>
									</div>

									<div style={{alignItems: "flex-start", width: "100%", margin: "20px 0 0 0"}}>
										<Checkbox
											type="checkbox"
											name="checkbox_special_terms_agreed"
											value={customerState.checkbox_special_terms_agreed === "yes" ? "yes" : "no"}
											label={"Överrenskommelse om avvikande villkor"}
											checked={customerState.checkbox_special_terms_agreed === "yes"}
											onChange={checked => this.setCustomerState({checkbox_special_terms_agreed: checked ? "yes" : "no"})}
										/>
									</div>

									<div style={{alignItems: "flex-start", width: "100%", margin: "20px 0 0 0"}}>
										<Checkbox
											type="checkbox"
											name="allocation_fxhedge_spets"
											value={customerState.allocation_fxhedge_spets === "yes" ? "yes" : "no"}
											label={"Erbjudande om valutaskydd i Fair Globalförvaltning"}
											checked={customerState.allocation_fxhedge_spets === "yes"}
											onChange={checked => this.setCustomerState({allocation_fxhedge_spets: checked ? "yes" : "no"})}
										/>
										<p style={{width: "600px", margin: "5px 0 10px 34px", lineHeight: "19px"}}>
											Kunden vill ta del av tillägget om valutaskydd inom ramen för
											Fair Globalförvaltning. Kunden har tagit del av “9. Erbjudande om valutaskydd”
											och “11. Avgifter, kostnader och Evigt Highwater Mark” i Bilaga 4 och är
											därför införstådd med hur upplägget med valutaskyddet fungerar samt att
											transaktionskostnaderna relaterade till valutaskyddet kan komma att uppgå
											till 1,9% årligen av det skyddade beloppet.
										</p>
									</div>

									<div style={{alignItems: "flex-start", width: "100%"}}>
										<h4>Verkställighet av förvaltning</h4>
										<p style={{width: "600px", margin: "0 0 10px 0", lineHeight: "19px"}}>
											Insättningsbeloppet ska enligt överenskommelse överföras senast tio (10)
											bankdagar efter undertecknande av Avtalet.
										</p>
										<Checkbox
											type="checkbox"
											name="allocation_separate_deposit"
											value={customerState.allocation_separate_deposit === "yes" ? "yes" : "no"}
											label={"Kunden överför insättningsbeloppet vid uppdelade tillfällen"}
											checked={customerState.allocation_separate_deposit === "yes"}
											onChange={checked => this.setCustomerState({allocation_separate_deposit: checked ? "yes" : "no"})}
										/>

										{customerState.allocation_separate_deposit === "yes" && (
											<React.Fragment>
												<p style={{
													width: "600px",
													margin: "10px 0 5px 34px",
													lineHeight: "19px"
												}}>
													Ange hur stort belopp som ska överföras vid det första
													insättningstillfället:
												</p>

												<StackLayout className="Cob-fields-collection per-row-3" rowWrap
															 fullWidth>
													<div style={{marginLeft: "24px"}}>
														<NumberField
															name="allocation_amount_initial"
															label="Ange i SEK"
															unit=" SEK"
															value={customerState.allocation_amount_initial || ""}
															showSeparators={true}
															validation={value => (value.length > 0 && parseInt(value.split(' ').join('').replace("SEK", ""), 10) <= spetsAmount)}
															onChange={({target}) =>
															{
																this.setCustomerState({allocation_amount_initial: target.value.split(' ').join('')});
															}}
															highlightErrors={hasFormValidationErrors}
														/>
													</div>
												</StackLayout>

												<p style={{
													width: "600px",
													margin: "10px 0 5px 34px",
													lineHeight: "19px"
												}}>
													Ange när återstående insättningsbelopp ska överföras:
												</p>

												<div style={{paddingLeft: "34px", width: "100%", maxWidth: "640px"}}>
													<TextView
														name="allocation_amount_text"
														label={`${800 - customerState.allocation_amount_text.length} tecken kvar`}
														value={customerState.allocation_amount_text}
														maxLength={800}
														validation={value => value.length > 0}
														resize="vertical"
														onChange={({target}) => this.setCustomerState({allocation_amount_text: target.value})}
														fullWidth
														minHeight={150}
														highlightErrors={hasFormValidationErrors}
													/>
												</div>

											</React.Fragment>
										)}
									</div>

									<div style={{alignItems: "flex-start", width: "100%"}}>
										<h4>Översatt i kronor innebär det att kundens kostnader uppgår till</h4>
										<em>Följande text kommer att skrivas ut i avtalet:</em>
										<p style={{fontSize: "13px", maxWidth: "680px"}}>
											{totalCostSpets}
										</p>
										<input type={"hidden"} name={"spets_total_cost"} value={totalCostSpets} />
									</div>
								</div>
							)}

							<Checkbox
								style={{marginTop: "20px", paddingRight: "30px"}}
								type="radio"
								name="checkbox_product_guru"
								label="Fair Flexibel"
								value={customerState.checkbox_product_guru}
								checked={customerState.checkbox_product_guru}
								disabled={customerState.guru_disabled}
								onChange={checked => this.setCustomerState({checkbox_product_guru: checked})}
								highlightErrors={hasFormValidationErrors}
								groupValid={
									customerState.checkbox_product_spets ||
									customerState.checkbox_product_guru ||
									customerState.checkbox_product_svan ||
									customerState.checkbox_product_advice
								}
							/>

							{customerState.checkbox_product_guru && (
								<div className="product">
									<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">Belopp att investera</p>
											<NumberField
												name="allocation_amount_guru"
												label="Ange i SEK"
												unit=" SEK"
												value={customerState.allocation_amount_guru || ""}
												showSeparators={true}
												onChange={({target}) =>
												{
													this.setCustomerState({allocation_amount_guru: target.value.split(' ').join('')});
												}}
												validation={value => amountIsLessOrEqualToDeposited && value.length > 0}
												highlightErrors={hasFormValidationErrors}
											/>

											{!amountIsLessOrEqualToDeposited && customerState.allocation_amount_guru.length > 0 && (
												<p style={{fontSize: "12px", lineHeight: "14px", margin: "5px 0 0 5px", color: "red"}}>
													Beloppet överskrider av kunden investerad summa ({depositedAmount}).
												</p>
											)}
										</div>
										<div>
											<p className="fieldLabel">Andel av placeringsbara tillgångar</p>
											<Select
												name="radio_allocation_perc_guru"
												label="Ange i procent"
												selectedIndex={customerState.radio_allocation_perc_guruIndex}
												options={[
													{label: "0-5%", value: "0-5"},
													{label: "6-10%", value: "6-10"},
													{label: "11-20%", value: "11-20"},
													{label: ">20%", value: ">20"}
												]}
												onChange={(option) => this.setCustomerState({radio_allocation_perc_guru: option.value})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Risk enligt "Information om riskklasser"</p>
											<strong style={{fontSize: "16px"}}>3</strong>
											<input type="hidden" name="radio_allocation_risk_guru" value="3"/>
										</div>
										<div>
											<p className="fieldLabel">Vilken skattemiljö gäller för denna placering?</p>
											<Select
												name="checkbox_accounttype_guru"
												label="Ange"
												selectedIndex={customerState.checkbox_accounttype_guruIndex}
												options={accountTypeOptions}
												onChange={(option) => this.setCustomerState({checkbox_accounttype_guru: option.value})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Placeringshorisont</p>
											<strong>Minst 3 år</strong>
										</div>
									</StackLayout>

									{customerState.radio_guru_fixed_fee === "yes" && (
										<React.Fragment>
											<input type="hidden" name="allocation_fee_guru" value=""/>
											<input type="hidden" name="allocation_fee_guru_revenueshare" value=""/>
											<input type="hidden" name="allocation_fee_guru_rebate" value=""/>
											<input type="hidden" name="radio_allocation_fee_guru" value="fixed"/>
										</React.Fragment>
									)}

									{customerState.radio_guru_fixed_fee === "no" && (
										<React.Fragment>

											<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
												<div>
													<p className="fieldLabel">
														Vinstdelning
													</p>
													<div style={{flexDirection: "row"}}>
														<SimpleTextField
															type="number"
															placeholder="Ange i %"
															value={customerState.allocation_fee_guru_revenueshare || ""}
															textAlign="left"
															validation={value => value.length > 0}
															style={{width: "100px", marginRight: "5px"}}
															onChange={({target}) => this.setCustomerState({allocation_fee_guru_revenueshare: target.value})}
															highlightErrors={hasFormValidationErrors}
														/>
														%
													</div>

													<p className="fieldLabel" style={{marginTop: "10px"}}>
														Förvaltningsrabatt
													</p>
													<div style={{flexDirection: "row"}}>
														<SimpleTextField
															type="number"
															placeholder="Ange i %"
															value={customerState.allocation_fee_guru_rebate || ""}
															textAlign="left"
															validation={value => value.length > 0}
															style={{width: "100px", marginRight: "5px"}}
															onChange={({target}) => this.setCustomerState({allocation_fee_guru_rebate: target.value})}
															highlightErrors={hasFormValidationErrors}
														/>
														%
													</div>

													<input type="hidden" name="allocation_fee_guru"
														   value={customerState.allocation_fee_guru_revenueshare
															   ? customerState.allocation_fee_guru_revenueshare
															   : ""}/>

													<input type="hidden" name="allocation_fee_guru_revenueshare"
														   value={customerState.allocation_fee_guru_revenueshare
															   ? customerState.allocation_fee_guru_revenueshare
															   : ""}/>

													<input type="hidden" name="allocation_fee_guru_rebate"
														   value={customerState.allocation_fee_guru_rebate
															   ? customerState.allocation_fee_guru_rebate
															   : ""}/>

													<input type="hidden" name="radio_allocation_fee_guru"
														   value={customerState.allocation_fee_guru_revenueshare && customerState.allocation_fee_guru_revenueshare === "33"
															   ? "standard"
															   : "reduced"}/>
												</div>
											</StackLayout>

										</React.Fragment>
									)}

									<div style={{alignItems: "flex-start", width: "100%", margin: "10px 0 0 0"}}>
										<Checkbox
											type="checkbox"
											name="checkbox_special_terms_agreed"
											value={customerState.checkbox_special_terms_agreed === "yes" ? "yes" : "no"}
											label={"Överrenskommelse om avvikande villkor"}
											checked={customerState.checkbox_special_terms_agreed === "yes"}
											onChange={checked => this.setCustomerState({checkbox_special_terms_agreed: checked ? "yes" : "no"})}
										/>
									</div>

									<div style={{alignItems: "flex-start", width: "100%"}}>
										<h4>Avgifter</h4>
										<em>Följande text kommer att skrivas ut i avtalet:</em>
										<p style={{fontSize: "13px", maxWidth: "680px"}}>
											{totalCostGuru}
										</p>
										<input type={"hidden"} name={"guru_total_cost"} value={totalCostGuru} />
									</div>
								</div>
							)}

							<Checkbox
								style={{marginTop: "20px", paddingRight: "30px"}}
								type="radio"
								name="checkbox_product_svan"
								label="Strategi Svart Svan"
								value={customerState.checkbox_product_svan}
								checked={customerState.checkbox_product_svan}
								onChange={checked => this.setCustomerState({checkbox_product_svan: checked})}
								highlightErrors={hasFormValidationErrors}
								groupValid={
									customerState.checkbox_product_spets ||
									customerState.checkbox_product_guru ||
									customerState.checkbox_product_svan ||
									customerState.checkbox_product_advice
								}
							/>

							{customerState.checkbox_product_svan && (
								<div className="product">

									<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">Exponeringsbelopp</p>
											<NumberField
												name="allocation_svan_exponeringsbelopp"
												label="Ange i SEK"
												unit=" SEK"
												value={customerState.allocation_svan_exponeringsbelopp || ""}
												showSeparators={true}
												onChange={({target}) =>
												{
													this.setCustomerState({allocation_svan_exponeringsbelopp: target.value.split(' ').join('')});
												}}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
									</StackLayout>

									<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">Belopp att investera</p>
											<NumberField
												name="allocation_amount_svan"
												label="Ange i SEK"
												unit=" SEK"
												value={customerState.allocation_amount_svan || ""}
												showSeparators={true}
												onChange={({target}) =>
												{
													this.setCustomerState({allocation_amount_svan: target.value.split(' ').join('')});
												}}
												validation={value => amountIsLessOrEqualToDeposited && value.length > 0}
												highlightErrors={hasFormValidationErrors}
											/>

											{!amountIsLessOrEqualToDeposited && customerState.allocation_amount_svan.length > 0 && (
												<p style={{fontSize: "12px", lineHeight: "14px", margin: "5px 0 0 5px", color: "red"}}>
													Beloppet överskrider av kunden investerad summa ({depositedAmount}).
												</p>
											)}
										</div>
										<div>
											<p className="fieldLabel">Andel av placeringsbara tillgångar</p>
											<Select
												name="radio_allocation_perc_svan"
												label="Ange i procent"
												selectedIndex={customerState.radio_allocation_perc_svanIndex}
												options={[
													{label: "0-5%", value: "0-5"},
													{label: "6-10%", value: "6-10"},
													{label: "11-20%", value: "11-20"},
													{label: ">20%", value: ">20"}
												]}
												onChange={(option) => this.setCustomerState({radio_allocation_perc_svan: option.value})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Risk enligt "Information om riskklasser"</p>
											<strong style={{fontSize: "16px"}}>7</strong>
											<input type="hidden" name="radio_allocation_risk_svan" value="7"/>
										</div>
										<div>
											<p className="fieldLabel">Vilken skattemiljö gäller för denna placering?</p>
											<Select
												name="checkbox_accounttype_svan"
												label="Ange"
												selectedIndex={customerState.checkbox_accounttype_svanIndex}
												options={accountTypeOptions}
												onChange={(option) => this.setCustomerState({checkbox_accounttype_svan: option.value})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Placeringshorisont</p>
											<NumberField
												name="allocation_horizon_svan"
												label="Ange i år"
												unit=" år"
												value={customerState.allocation_horizon_svan || ""}
												onChange={({target}) =>
												{
													this.setCustomerState({allocation_horizon_svan: target.value});
												}}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
									</StackLayout>
									<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">
												Vinstdelning
											</p>
											<div style={{flexDirection: "row", padding: "3px"}}>
												1/3 med evigt highwatermark
											</div>
											<input type="hidden" name="allocation_fee_svan" value="1/3"/>
										</div>

									</StackLayout>

									<div style={{alignItems: "flex-start", width: "100%"}}>
										<h4>Avgifter</h4>
										<em>Följande text kommer att skrivas ut i avtalet:</em>
										<p style={{fontSize: "13px", maxWidth: "680px"}}>
											{totalCostSvan}
										</p>
										<input type={"hidden"} name={"svan_total_cost"} value={totalCostSvan} />
									</div>
								</div>
							)}

							{customerState.checkbox_special_terms_agreed === "yes" && (
								<React.Fragment>
									<div className="h-line spacing"/>
									<h3 style={{paddingBottom: "10px"}}>Fyll i avvikande villkor</h3>

									<TextView
										name="discagreement_other"
										label={`${100 - customerState.discagreement_other.length} tecken kvar`}
										value={customerState.discagreement_other}
										maxLength={100}
										validation={value => value.length > 0}
										resize="vertical"
										onChange={({target}) => this.setCustomerState({discagreement_other: target.value})}
										fullWidth
										minHeight={50}
										highlightErrors={hasFormValidationErrors}
									/>
								</React.Fragment>
							)}

							<div className="h-line spacing"/>
							<h3>Investeringsrådgivning</h3>

							<Checkbox
								style={{marginTop: "20px"}}
								type="radio"
								name="checkbox_product_advice"
								label="Inkludera avsnitt om investeringsrådgivning"
								value={customerState.checkbox_product_advice}
								checked={customerState.checkbox_product_advice}
								onChange={checked => this.setCustomerState({checkbox_product_advice: checked})}
								highlightErrors={hasFormValidationErrors}
								groupValid={
									customerState.checkbox_product_spets ||
									customerState.checkbox_product_guru ||
									customerState.checkbox_product_svan ||
									customerState.checkbox_product_advice
								}
							/>

							{customerState.checkbox_product_advice && (
								<div className="product">
									<Checkbox
										style={{marginTop: "20px", alignSelf: "flex-start", marginBottom: "20px"}}
										type="checkbox"
										name="checkbox_allocation_advice_understand"
										label="Den rådgivning som du erhåller är icke-oberoende. Min rådgivare har förklarat för mig vad det innebär."
										value="Ja"
										checked={customerState.checkbox_allocation_advice_understand === "Ja"}
										onChange={checked => this.setCustomerState({checkbox_allocation_advice_understand: checked ? "Ja" : "Nej"})}
										highlightErrors={hasFormValidationErrors}
									/>

									<Checkbox
										style={{marginTop: "20px", alignSelf: "flex-start", marginBottom: "20px"}}
										type="checkbox"
										name="checkbox_allocation_advice_is_fund"
										label="Rådgivningen gäller investering i fond."
										value="Ja"
										checked={customerState.checkbox_allocation_advice_is_fund === "Ja"}
										onChange={checked => this.setCustomerState({checkbox_allocation_advice_is_fund: checked ? "Ja" : "Nej"})}
									/>

									<StackLayout className="Cob-fields-collection" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">Produkt*</p>
											<Select
												name="allocation_product_advice_select"
												label="Välj"
												selectedIndex={this.investmentAdviceProducts.map(c => c.value).indexOf(customerState.allocation_product_advice_select)}
												options={this.investmentAdviceProducts}
												onChange={(option) => {
													if (option.value === "Annan")
													{
														this.setCustomerState({allocation_product_advice_select: option.value, allocation_product_advice: ""})
													}
													else
													{
														this.setCustomerState({allocation_product_advice_select: option.value, allocation_product_advice: option.value})
													}

												}}
												highlightErrors={hasFormValidationErrors}
											/>

											{customerState.allocation_product_advice_select === "Annan" && (
												<React.Fragment>
													<TextField
														name="allocation_product_advice"
														type="text"
														label="Ange namn"
														validation={value => value.length > 0}
														value={customerState.allocation_product_advice}
														onChange={({target}) => this.setCustomerState({allocation_product_advice: target.value})}
														highlightErrors={hasFormValidationErrors}
														style={{marginTop: "5px"}}
													/>

													<p style={{padding: "5px 10px", fontSize: "12px"}}>
													* Notera att du kommer behöva bifoga produktens faktablad senare i
													rådgivningsflödet.
													</p>
												</React.Fragment>
											)}

											{customerState.allocation_product_advice_select !== "Annan" && (
												<input type={"hidden"} name={"allocation_product_advice"} value={customerState.allocation_product_advice_select} />
											)}
										</div>
									</StackLayout>

									<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">Belopp att investera</p>
											<NumberField
												name="allocation_amount_advice"
												label="Ange i SEK"
												unit=" SEK"
												value={customerState.allocation_amount_advice || ""}
												showSeparators={true}
												onChange={({target}) =>
												{
													let amountToInvest = target.value.split(' ').join('');
													let courtageSek = "";
													if (amountToInvest > 0 && customerState.allocation_fee_advice > 0)
													{
														courtageSek = Math.ceil(amountToInvest * (customerState.allocation_fee_advice / 100));
													}
													this.setCustomerState({
														allocation_amount_advice: amountToInvest,
														allocation_perffee_advice: courtageSek
													});
												}}
												validation={value => amountIsLessOrEqualToDeposited && value.length > 0}
												highlightErrors={hasFormValidationErrors}
											/>

											{!amountIsLessOrEqualToDeposited && customerState.allocation_amount_advice.length > 0 && (
												<p style={{fontSize: "12px", lineHeight: "14px", margin: "5px 0 0 5px", color: "red"}}>
													Beloppet överskrider av kunden investerad summa ({depositedAmount}).
												</p>
											)}

											<input type={"hidden"} name={"allocation_amount_advice_plain"}
											       value={localeNumber((parseInt(customerState.allocation_amount_advice.replace(" SEK", "").split(' ').join('')) - customerState.allocation_perffee_advice), 0)} />
										</div>
										<div>
											<p className="fieldLabel">Andel av placeringsbara tillgångar</p>
											<Select
												name="radio_allocation_perc_advice"
												label="Ange i procent"
												selectedIndex={customerState.radio_allocation_perc_adviceIndex}
												options={[
													{label: "0-5%", value: "0-5"},
													{label: "6-10%", value: "6-10"},
													{label: "11-20%", value: "11-20"},
													{label: ">20%", value: ">20"}
												]}
												onChange={(option) => this.setCustomerState({radio_allocation_perc_advice: option.value})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Risk enligt "Information om riskklasser"</p>
											<Select
												name="radio_allocation_risk_advice"
												label="Ange risknivå"
												selectedIndex={customerState.radio_allocation_risk_adviceIndex}
												options={[
													{label: "1", value: "1"},
													{label: "2", value: "2"},
													{label: "3", value: "3"},
													{label: "4", value: "4"},
													{label: "5", value: "5"},
													{label: "6", value: "6"},
													{label: "7", value: "7"}
												]}
												onChange={(checked, radio_allocation_risk_advice) => this.setCustomerState({radio_allocation_risk_advice})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Vilken skattemiljö gäller för denna placering?</p>
											<Select
												name="checkbox_accounttype_advice"
												label="Ange"
												selectedIndex={customerState.checkbox_accounttype_adviceIndex}
												options={accountTypeOptions}
												onChange={(option) => this.setCustomerState({checkbox_accounttype_advice: option.value})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Placeringshorisont</p>
											<NumberField
												name="allocation_horizon_advice"
												label="Ange i år"
												unit=" år"
												value={customerState.allocation_horizon_advice || ""}
												onChange={({target}) =>
												{
													this.setCustomerState({allocation_horizon_advice: target.value});
												}}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
									</StackLayout>

									<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">
												Avgift (t.ex. courtage och andra tredjepartsersättningar)
											</p>
											<div style={{flexDirection: "row"}}>
												<SimpleTextField
													type="number"
													placeholder="Ange"
													name="allocation_fee_advice"
													value={customerState.allocation_fee_advice || ""}
													textAlign="left"
													validation={value => value.length > 0}
													style={{width: "100px", marginRight: "5px"}}
													onChange={({target}) =>
													{
														let courtageSek = "";
														let investedAmount = customerState.allocation_amount_advice ? customerState.allocation_amount_advice.replace(" SEK", "").split(' ').join('') : 0;

														if (investedAmount > 0 && target.value > 0)
														{
															courtageSek = Math.ceil(investedAmount * (target.value / 100));
														}

														this.setCustomerState({
															allocation_fee_advice: target.value,
															allocation_perffee_advice: courtageSek
														});
													}}
													highlightErrors={hasFormValidationErrors}
												/>
												%
											</div>

											{customerState.allocation_perffee_advice > 0 && (
												<p className="fieldLabel"
												   style={{marginTop: "3px", fontWeight: "normal", fontSize: "11px"}}>
													= {localeNumber(customerState.allocation_perffee_advice, 0)} SEK
												</p>
											)}

											<input type={"hidden"} name={"allocation_perffee_advice"}
												   value={customerState.allocation_perffee_advice}/>
										</div>
									</StackLayout>

									<input type="hidden" name="account_advice_partnerfeeinsek_advice"
										   value={customerState.account_advice_partnerfeeinsek_advice}/>
								</div>
							)}

							<div className="h-line spacing"/>

							<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
								<div className="yes-no">
									<Checkbox
										style={{
											padding: "15px",
											background: "#FFF6F6",
											borderRadius: "6px"
										}}
										className="no"
										type="radio"
										name="checkbox_advice_customer"
										label="Kunden har tagit del av det diskretionära avtalet/avtalen men förstår inte hur förvaltningen/förvaltningarna fungerar eller vilka risker som den/de är förknippade med. Placeringen avråds av rådgivaren. "
										value="dontunderstand"
										checked={customerState.checkbox_advice_customer === "dontunderstand"}
										onChange={checked => this.setCustomerState({checkbox_advice_customer: checked ? "dontunderstand" : "understand"})}
										highlightErrors={hasFormValidationErrors && customerState.checkbox_advice_customer.length < 1}
									/>
								</div>

								<div className="yes-no">
									<Checkbox
										style={{
											padding: "15px",
											background: "#F3FDF5",
											borderRadius: "6px"
										}}
										className="yes"
										type="radio"
										name="checkbox_advice_customer"
										label="Kunden har tagit del av det diskretionära avtalet/avtalen och intygar att den förstår hur förvaltningen/förvaltningarna fungerar och vilka risker som den/de är förknippade med."
										value="understand"
										checked={customerState.checkbox_advice_customer === "understand"}
										onChange={checked => this.setCustomerState({checkbox_advice_customer: checked ? "understand" : "dontunderstand"})}
										highlightErrors={hasFormValidationErrors && customerState.checkbox_advice_customer.length < 1}
									/>
								</div>

								<input type="hidden" name="checkbox_advice_recommendation2"
									   value={customerState.checkbox_advice_customer}/>
							</StackLayout>

							<div className="h-line spacing"/>

							<h4>Föranmält konto</h4>

							<Checkbox
								style={{marginTop: "20px"}}
								type="checkbox"
								name="checkbox_skip_account"
								label="Försäkring - Kontonummer ej tillämpligt"
								value={customerState.checkbox_skip_account}
								checked={customerState.checkbox_skip_account}
								onChange={checked => this.setCustomerState({checkbox_skip_account: checked})}
							/>

							<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
								<div>
									<TextField
										name="customer_account_bank_owner"
										label="Namn på depå-/kontohavare"
										value={!customerState.checkbox_skip_account ? customerState.customer_account_bank_owner : ""}
										validation={value => value.length > 0}
										disabled={customerState.checkbox_skip_account}
										onChange={({target}) => this.setCustomerState({customer_account_bank_owner: target.value})}
										highlightErrors={hasFormValidationErrors && !customerState.checkbox_skip_account}
									/>
								</div>
								<div>
									<Select
										name="customer_account_bank"
										label="Bank"
										selectedIndex={customerState.selectedBankIndex}
										options={availableBankOptions}
										disabled={customerState.checkbox_skip_account}
										onChange={this._onBankChange}
										highlightErrors={hasFormValidationErrors && !customerState.checkbox_skip_account}
									/>

									{customerState.customer_account_bank === '-' && (
										<React.Fragment>
											<TextField
												name="customer_account_bank_custom"
												label="Namn på bank"
												style={{marginTop: "5px"}}
												value={!customerState.checkbox_skip_account ? customerState.customer_account_bank_custom : ""}
												validation={value => value.length > 0}
												disabled={customerState.checkbox_skip_account}
												onChange={({target}) => this.setCustomerState({customer_account_bank_custom: target.value})}
												highlightErrors={hasFormValidationErrors && !customerState.checkbox_skip_account}
											/>
											<input type="hidden" name="customer_account_bank"
												   value={customerState.customer_account_bank_custom}/>
										</React.Fragment>
									)}

									{customerState.customer_account_bank !== '-' && (
										<input type="hidden" name="customer_account_bank_custom" value=""/>
									)}

									{customerState.checkbox_skip_account && (
										<input type="hidden" name="customer_account_bank" value=""/>
									)}
								</div>
							</StackLayout>

							<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
								<div>
									<NumberField
										name="customer_account_bank_clearingno"
										label="Clearingnummer"
										delimiter1={""}
										disabled={customerState.checkbox_skip_account}
										value={!customerState.checkbox_skip_account ? (customerState.customer_account_bank_clearingno || "") : ""}
										validation={this._validateClearingNumber}
										onChange={this._onAccountClearingChange}
										highlightErrors={hasFormValidationErrors && !customerState.checkbox_skip_account}
									/>

									{customerState.customer_account_bank === 'Swedbank' && (
										<p style={{padding: "0 0 0 7px", fontSize: "12px"}}>
											Det förekommer att Swedbank har en femte siffra i clearingnumret. Vänligen
											ange
											enbart de fyra första innan bindestrecket. Exempel: 8214-9 ska anges som
											8214.
										</p>
									)}
								</div>
								<div>
									<TextField
										name="customer_account_bank_no"
										label="Depå-/kontonummer"
										value={!customerState.checkbox_skip_account ? (customerState.customer_account_bank_no || "") : ""}
										disabled={customerState.checkbox_skip_account}
										type={"number"}
										validation={this._numberValidation}
										onChange={this._onAccountNumberChange}
										highlightErrors={hasFormValidationErrors && !customerState.checkbox_skip_account}
									/>
								</div>
							</StackLayout>

							<Checkbox
								style={{marginTop: "-10px"}}
								type="checkbox"
								name="checkbox_override_validation"
								label="Tillåt konto- och clearingnummer som inte kan valideras"
								value={customerState.checkbox_override_validation}
								checked={customerState.checkbox_override_validation}
								disabled={customerState.checkbox_skip_account}
								onChange={checked => this.setCustomerState({checkbox_override_validation: checked})}
							/>

							{isIskTypeSelected && (
								<input type="hidden" name="checkbox_account_isk" value="Ja"/>
							)}

							{!isIskTypeSelected && (
								<input type="hidden" name="checkbox_account_isk" value="Nej"/>
							)}

							<input type="hidden" name="allocation_amount_total" value={totalAmountInvestedFormatted + " SEK"}/>
						</div>
					)}
				</ContentBox>

				<input type="hidden" name={"allocation_amount_first"} value={allocationFields.allocation_amount_first}/>
				<input type="hidden" name={"allocation_product_first"}
					   value={allocationFields.allocation_product_first}/>
				<input type="hidden" name={"allocation_product_bilaga_first"}
					   value={allocationFields.allocation_product_bilaga_first}/>
				<input type="hidden" name={"radio_allocation_fee_first"}
					   value={allocationFields.radio_allocation_fee_first}/>
				<input type="hidden" name={"allocation_fee_revshare_first"}
					   value={allocationFields.allocation_fee_revshare_first}/>
				<input type="hidden" name={"allocation_fee_rebate_first"}
					   value={allocationFields.allocation_fee_rebate_first}/>

				<input type="hidden" name={"allocation_amount_second"}
					   value={allocationFields.allocation_amount_second}/>
				<input type="hidden" name={"allocation_product_second"}
					   value={allocationFields.allocation_product_second}/>
				<input type="hidden" name={"allocation_product_bilaga_second"}
					   value={allocationFields.allocation_product_bilaga_second}/>
				<input type="hidden" name={"radio_allocation_fee_second"}
					   value={allocationFields.radio_allocation_fee_second}/>
				<input type="hidden" name={"allocation_fee_revshare_second"}
					   value={allocationFields.allocation_fee_revshare_second}/>
				<input type="hidden" name={"allocation_fee_rebate_second"}
					   value={allocationFields.allocation_fee_rebate_second}/>

				{customerState.radio_product_selection_mode !== "addonchange" && (
					<React.Fragment>
						<input type="hidden" name={"investment_product"} value={customerState.investment_product}/>
						<input type="hidden" name={"investment_amount"} value={customerState.investment_amount}/>
						<input type="hidden" name={"investment_share_of_assets"} value={customerState.investment_share_of_assets}/>
						<input type="hidden" name={"investment_time_horizon"} value={customerState.investment_time_horizon}/>
						<input type="hidden" name={"investment_risk_class"} value={customerState.investment_risk_class}/>
						<input type="hidden" name={"investment_product_other_text"} value={customerState.investment_product_other_text}/>
						<input type="hidden" name={"investment_account_type"} value={customerState.investment_account_type}/>
						<input type="hidden" name={"investment_fee_revshare"} value={customerState.investment_fee_revshare}/>
						<input type="hidden" name={"investment_fee_rebate"} value={customerState.investment_fee_rebate}/>
						<input type="hidden" name={"investment_fee_explained"} value={
							customerState.checkbox_product_spets ? totalCostSpets : (
								customerState.checkbox_product_guru ? totalCostGuru : (
									customerState.checkbox_product_svan ? totalCostSvan : customerState.investment_fee_explained
								)
							)}/>
						<input type="hidden" name={"investment_fee_other"} value={customerState.investment_fee_other}/>
						<input type="hidden" name={"investment_fee_other_sek"} value={customerState.investment_fee_other_sek}/>
						<input type="hidden" name={"investment_fee_other_text"} value={customerState.investment_fee_other_text}/>
					</React.Fragment>
				)}
			</div>
		);
	}

	_onBankChange = (option) =>
	{
		this.setCustomerState(
			{
				customer_account_bank: option.value
			}
		);
	};

	_validateClearingNumber = (value) =>
	{
		if (this.state.customerState.customer_account_bank === '-')
		{
			return true;
		}
		return Validator.validateClearingNumber(value, this.state.customerState.customer_account_bank, this.props.availableBanks);
	};

	_onAccountClearingChange = ({target}) =>
	{
		if (isNaN(target.value))
		{
			return;
		}

		this.setCustomerState(
			{
				customer_account_bank_clearingno: target.value
			}
		);
	};

	_numberValidation = (value) =>
	{
		if (this.state.customerState.customer_account_bank === '-')
		{
			return true;
		}

		if (value.length < 5 || isNaN(value))
		{
			return false;
		}

		/**
		 * For the banks where we know the validation rules, check if the number should be validated.
		 */
		let shouldValidateAccountNumber = false;
		const availableBanks = this.props.availableBanks;
		for (const bankName in availableBanks)
		{
			if (bankName === 'length' || !availableBanks.hasOwnProperty(bankName))
			{
				continue;
			}

			if (this.state.customerState.customer_account_bank === bankName)
			{
				const bankInfo = availableBanks[bankName];
				if (bankInfo.length > 0)
				{
					if (bankInfo[0]['validationEnabled'] === "1")
					{
						shouldValidateAccountNumber = true;
					}
				}
				break;
			}
		}

		if (!shouldValidateAccountNumber)
		{
			return true;
		}

		let bankNoToValidate = this.state.customerState.customer_account_bank_clearingno + ", " + value;
		let kontonummer = window.kontonummer(bankNoToValidate);
		return kontonummer !== false;
	};

	_AddonPlacementFulfillment = (fulfilled) =>
	{
		this.setCustomerState({addon_placement_fulfilled: fulfilled});
	};

	_onAccountNumberChange = ({target}) => !isNaN(target.value) ? this.setCustomerState({customer_account_bank_no: target.value}) : undefined;

	_optionsValidation = (value) => ((value && value.length > 0) || false);
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, viewNo, hasFormValidationErrors} = this.props;
	const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
	const hasBeenSeen = (seenViews.includes(viewNo));
	// if it hasn't been seen add the view to local storage for the specific case
	if (customer.caseId && !hasBeenSeen)
	{
		localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
	}

	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;

	let selectedBankIndex = -1;

	if (newState.customerState.customer_account_bank && newState.customerState.customer_account_bank.length > 0)
	{
		let bankFoundInList = false;
		for (const bankName in this.props.availableBanks)
		{
			if (bankName === 'length' || !this.props.availableBanks.hasOwnProperty(bankName))
			{
				continue;
			}
			selectedBankIndex++;
			if (bankName === newState.customerState.customer_account_bank)
			{
				bankFoundInList = true;
				break;
			}
		}

		/**
		 * No bank found => Övrig bank
		 */
		if (!bankFoundInList)
		{
			selectedBankIndex++;
			newState.customerState.customer_account_bank_custom = newState.customerState.customer_account_bank;
			newState.customerState.customer_account_bank = "-";
		}
	}

	newState.customerState.selectedBankIndex = selectedBankIndex;
	newState.availableBanks = this.props.availableBanks;

	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	let newState = {
		isFulfilled: false,
		availableBanks: [],
		customerState: {
			force_no_placement: customer.getData("checkbox_advice_skip_placement") === "yes",
			checkbox_skip_account: customer.getData("checkbox_skip_account") === "true",
			checkbox_override_validation: customer.getData("checkbox_override_validation") === "true",
			checkbox_product_guru: false,
			checkbox_product_spets: false,
			checkbox_product_svan: false,
			checkbox_product_other: false,
			checkbox_product_advice: false,

			checkbox_allocation_advice_understand: customer.getData("checkbox_allocation_advice_understand") || "",
			checkbox_allocation_advice_is_fund: customer.getData("checkbox_allocation_advice_is_fund") || "",

			allocation_product_advice_select: customer.getData("allocation_product_advice_select") || "",
			allocation_product_advice: customer.getData("allocation_product_advice") || "",
			allocation_horizon_advice: customer.getData("allocation_horizon_advice") || "",
			allocation_amount_advice: customer.getData("allocation_amount_advice") || "",
			allocation_perffee_advice: customer.getData("allocation_perffee_advice") || "",
			allocation_fee_advice: customer.getData("allocation_fee_advice") || "",
			account_advice_partnerfee_advice: customer.getData("account_advice_partnerfee_advice") ? customer.getData("account_advice_partnerfee_advice").replace("%", "") : "",

			allocation_fee_guru_revenueshare: customer.getData("allocation_fee_guru") || "",
			allocation_fee_guru_rebate: customer.getData("allocation_fee_guru_rebate") || "",

			allocation_fee_spets_revenueshare: customer.getData("allocation_fee_spets") || "",
			allocation_fee_spets_rebate: customer.getData("allocation_fee_spets_rebate") || "",

			allocation_fee_other_revenueshare: customer.getData("allocation_fee_other_revenueshare") || "",
			allocation_fee_other_rebate: customer.getData("allocation_fee_other_rebate") || "",
			allocation_amount_text: customer.getData("allocation_amount_text") || "",

			allocation_fxhedge_spets: customer.getData("allocation_fxhedge_spets") || "no",

			checkbox_spets_mjukstart: customer.getData("checkbox_spets_mjukstart") || "no",

			checkbox_special_terms_agreed: (customer.getData("discagreement_other") && customer.getData("discagreement_other") !== '') ? "yes" : "no",
			discagreement_other: customer.getData("discagreement_other") || "",

			allocation_amount_guru: customer.getData("allocation_amount_guru") || "",
			allocation_amount_spets: customer.getData("allocation_amount_spets") || "",
			allocation_amount_other: customer.getData("allocation_amount_other") || "",
			allocation_amount_svan: customer.getData("allocation_amount_svan") || "",
			allocation_amount_initial: customer.getData("allocation_amount_initial") || "",

			allocation_svan_exponeringsbelopp: customer.getData("allocation_svan_exponeringsbelopp") || "",

			allocation_fee_guru: customer.getData("allocation_fee_guru") || "",
			allocation_fee_spets: customer.getData("allocation_fee_spets") || "",
			allocation_fee_other: customer.getData("allocation_fee_other") || "",
			allocation_separate_deposit: customer.getData("allocation_separate_deposit") || "",

			allocation_horizon_other: customer.getData("allocation_horizon_other") || "",
			allocation_horizon_svan: customer.getData("allocation_horizon_svan") || "",

			radio_allocation_perc_guru: customer.getData("radio_allocation_perc_guru") || "",
			radio_allocation_perc_guruIndex:
				customer.getData("radio_allocation_perc_guru") === "0-5" ? 0 :
					customer.getData("radio_allocation_perc_guru") === "6-10" ? 1 :
						customer.getData("radio_allocation_perc_guru") === "11-20" ? 2 :
							customer.getData("radio_allocation_perc_guru") === ">20" ? 3 :
								-1,

			radio_allocation_perc_spets: customer.getData("radio_allocation_perc_spets") || "",
			radio_allocation_perc_spetsIndex:
				customer.getData("radio_allocation_perc_spets") === "0-5" ? 0 :
					customer.getData("radio_allocation_perc_spets") === "6-10" ? 1 :
						customer.getData("radio_allocation_perc_spets") === "11-20" ? 2 :
							customer.getData("radio_allocation_perc_spets") === ">20" ? 3 :
								-1,

			radio_allocation_perc_other: customer.getData("radio_allocation_perc_other") || "",
			radio_allocation_perc_otherIndex:
				customer.getData("radio_allocation_perc_other") === "0-5" ? 0 :
					customer.getData("radio_allocation_perc_other") === "6-10" ? 1 :
						customer.getData("radio_allocation_perc_other") === "11-20" ? 2 :
							customer.getData("radio_allocation_perc_other") === ">20" ? 3 :
								-1,

			radio_allocation_perc_svan: customer.getData("radio_allocation_perc_svan") || "",
			radio_allocation_perc_svanIndex:
				customer.getData("radio_allocation_perc_svan") === "0-5" ? 0 :
					customer.getData("radio_allocation_perc_svan") === "6-10" ? 1 :
						customer.getData("radio_allocation_perc_svan") === "11-20" ? 2 :
							customer.getData("radio_allocation_perc_svan") === ">20" ? 3 :
								-1,

			radio_allocation_perc_advice: customer.getData("radio_allocation_perc_advice") || "",
			radio_allocation_perc_adviceIndex:
				customer.getData("radio_allocation_perc_advice") === "0-5" ? 0 :
					customer.getData("radio_allocation_perc_advice") === "6-10" ? 1 :
						customer.getData("radio_allocation_perc_advice") === "11-20" ? 2 :
							customer.getData("radio_allocation_perc_advice") === ">20" ? 3 :
								-1,

			radio_allocation_risk_guru: "3",
			radio_allocation_risk_spets: "4",
			radio_allocation_risk_other: "6",
			radio_allocation_risk_svan: "7",

			radio_allocation_risk_advice: customer.getData("radio_allocation_risk_advice") || "",
			radio_allocation_risk_adviceIndex:
				customer.getData("radio_allocation_risk_advice") === "1" ? 0 :
					customer.getData("radio_allocation_risk_advice") === "2" ? 1 :
						customer.getData("radio_allocation_risk_advice") === "3" ? 2 :
							customer.getData("radio_allocation_risk_advice") === "4" ? 3 :
								customer.getData("radio_allocation_risk_advice") === "5" ? 4 :
									customer.getData("radio_allocation_risk_advice") === "6" ? 5 :
										customer.getData("radio_allocation_risk_advice") === "7" ? 6 :
											-1,

			checkbox_accounttype_advice: customer.getData("checkbox_accounttype_advice") || "",
			checkbox_accounttype_adviceIndex: -1,

			checkbox_accounttype_guru: customer.getData("checkbox_accounttype_guru") || "",
			checkbox_accounttype_guruIndex: -1,

			checkbox_accounttype_spets: customer.getData("checkbox_accounttype_spets") || "",
			checkbox_accounttype_spetsIndex: -1,

			checkbox_accounttype_other: customer.getData("checkbox_accounttype_other") || "",
			checkbox_accounttype_otherIndex: -1,

			checkbox_accounttype_svan: customer.getData("checkbox_accounttype_svan") || "",
			checkbox_accounttype_svanIndex: -1,

			checkbox_advice_customer: customer.getData("checkbox_advice_customer") || "",
			allocation_type_other: "Ädelmetallförvaltning",

			customer_account_bank_owner: customer.getData("customer_account_bank_owner") || "",
			customer_account_bank: customer.getData("customer_account_bank") || "",
			customer_account_bank_custom: "",
			customer_account_bank_clearingno: customer.getData("customer_account_bank_clearingno") || "",
			customer_account_bank_no: customer.getData("customer_account_bank_no") || "",

			checkbox_customer_is_audience: customer.getData("checkbox_customer_is_audience") || "",
			depositedAmount: customer.getData("aml_first_deposit") || 0,

			radio_advice_riskpropensity: customer.getData("radio_advice_riskpropensity") || "",

			radio_product_selection_mode: "",
			change_fee_model_fulfilled: "",
			addon_placement_fulfilled: "",

			radio_spets_fixed_fee: "no",
			radio_guru_fixed_fee: "no",

			investment_product: customer.getData("investment_product") || "",
			investment_amount: customer.getData("investment_amount") || "",
			investment_share_of_assets: customer.getData("investment_share_of_assets") || "",
			investment_time_horizon: customer.getData("investment_time_horizon") || "",
			investment_risk_class: customer.getData("investment_risk_class") || "",
			investment_product_other_text: customer.getData("investment_product_other_text") || "",
			investment_account_type: customer.getData("investment_account_type") || "",
			investment_fee_revshare: customer.getData("investment_fee_revshare") || "",
			investment_fee_rebate: customer.getData("investment_fee_rebate") || "",
			investment_fee_explained: customer.getData("investment_fee_explained") || "",
			investment_fee_other: customer.getData("investment_fee_other") || "",
			investment_fee_other_sek: customer.getData("investment_fee_other_sek") || "",
			investment_fee_other_text: customer.getData("investment_fee_other_text") || ""
		},
	};

	if (newState.customerState.depositedAmount)
	{
		newState.customerState.depositedAmount = newState.customerState.depositedAmount.replaceAll(" ", "");
		newState.customerState.depositedAmount = newState.customerState.depositedAmount.replace("SEK", "");
		newState.customerState.depositedAmount = parseInt(newState.customerState.depositedAmount);
	}

	if (newState.customerState.allocation_amount_guru.length > 0)
	{
		if (customer.getData("customer_guru_depa"))
		{
			newState.customerState.radio_product_selection_mode = "addonchange";
		}
		else
		{
			newState.customerState.radio_product_selection_mode = "new";
			newState.customerState.checkbox_product_guru = true;

			if (((customer.getData("allocation_product_first") === "Guru" ||
									customer.getData("allocation_product_first") === "Flexibel")
						&& customer.getData("radio_allocation_fee_first") === "fixed")
					||
				((customer.getData("allocation_product_second") === "Guru" ||
								customer.getData("allocation_product_second") === "Flexibel")
						&& customer.getData("radio_allocation_fee_second") === "fixed"))
			{
				newState.customerState.radio_guru_fixed_fee = "no"; // Set to yes if we want to enable this functionality again
			}
			else
			{
				newState.customerState.radio_guru_fixed_fee = "no";
			}
		}
	}

	if (newState.customerState.allocation_amount_svan.length > 0)
	{
		if (customer.getData("customer_guru_svan"))
		{
			newState.customerState.radio_product_selection_mode = "addonchange";
		}
		else
		{
			newState.customerState.radio_product_selection_mode = "new";
			newState.customerState.checkbox_product_svan = true;
		}
	}

	if (newState.customerState.allocation_amount_spets.length > 0)
	{
		if (customer.getData("customer_spets_depa"))
		{
			newState.customerState.radio_product_selection_mode = "addonchange";
		}
		else
		{
			newState.customerState.radio_product_selection_mode = "new";
			newState.customerState.checkbox_product_spets = true;

			if (((customer.getData("allocation_product_first") === "Spets" ||
						customer.getData("allocation_product_first") === "Global" ||
						customer.getData("allocation_product_first") === "Total")
					&& customer.getData("radio_allocation_fee_first") === "fixed")
				||
				((customer.getData("allocation_product_second") === "Spets" ||
						customer.getData("allocation_product_second") === "Global" ||
						customer.getData("allocation_product_second") === "Total")
					&& customer.getData("radio_allocation_fee_second") === "fixed")
			)
			{
				newState.customerState.radio_spets_fixed_fee = "no"; // Set to yes if we want to enable this functionality again
			}
			else
			{
				newState.customerState.radio_spets_fixed_fee = "no";
			}
		}
	}

	if (newState.customerState.allocation_amount_other.length > 0)
	{

		if (customer.getData("customer_other_depa"))
		{
			newState.customerState.radio_product_selection_mode = "addonchange";
		}
		else
		{
			newState.customerState.radio_product_selection_mode = "new";
			newState.customerState.checkbox_product_other = false;
		}
	}

	if (newState.customerState.allocation_amount_advice.length > 0)
	{
		newState.customerState.checkbox_product_advice = true;
		newState.customerState.radio_product_selection_mode = "new";
	}

	if (newState.customerState.radio_product_selection_mode === "" &&
		(
			customer.getData("radio_allocation_fee_guru") ||
			customer.getData("radio_allocation_fee_spets") ||
			customer.getData("radio_allocation_fee_other")
		))
	{
		newState.customerState.radio_product_selection_mode = "addonchange";
	}

	if (newState.customerState.radio_product_selection_mode === "")
	{
		newState.customerState.radio_product_selection_mode = customer.getData("radio_product_selection_mode") || "";
	}

	if (customer.getData("checkbox_advice_skip_placement") === "yes")
	{
		newState.customerState.radio_product_selection_mode = "nochange";
	}

	if (customer.isCompany)
	{
		newState.customerState.checkbox_accounttype_guruIndex =
			customer.getData("checkbox_accounttype_guru") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_guru") === "trust" ? 1 :
					customer.getData("checkbox_accounttype_guru") === "pinsurance" ? 2 :
						customer.getData("checkbox_accounttype_guru") === "tinsurance" ? 3 :
							customer.getData("checkbox_accounttype_guru") === "kinsurance" ? 4 :
								-1;

		newState.customerState.checkbox_accounttype_spetsIndex =
			customer.getData("checkbox_accounttype_spets") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_spets") === "trust" ? 1 :
					customer.getData("checkbox_accounttype_spets") === "pinsurance" ? 2 :
						customer.getData("checkbox_accounttype_spets") === "tinsurance" ? 3 :
							customer.getData("checkbox_accounttype_spets") === "kinsurance" ? 4 :
								-1;

		newState.customerState.checkbox_accounttype_otherIndex =
			customer.getData("checkbox_accounttype_other") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_other") === "trust" ? 1 :
					customer.getData("checkbox_accounttype_other") === "pinsurance" ? 2 :
						customer.getData("checkbox_accounttype_other") === "tinsurance" ? 3 :
							customer.getData("checkbox_accounttype_other") === "kinsurance" ? 4 :
								-1;

		newState.customerState.checkbox_accounttype_svanIndex =
			customer.getData("checkbox_accounttype_svan") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_svan") === "trust" ? 1 :
					customer.getData("checkbox_accounttype_svan") === "pinsurance" ? 2 :
						customer.getData("checkbox_accounttype_svan") === "tinsurance" ? 3 :
							customer.getData("checkbox_accounttype_svan") === "kinsurance" ? 4 :
								-1;

		newState.customerState.checkbox_accounttype_adviceIndex =
			customer.getData("checkbox_accounttype_advice") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_advice") === "trust" ? 1 :
					customer.getData("checkbox_accounttype_advice") === "pinsurance" ? 2 :
						customer.getData("checkbox_accounttype_advice") === "tinsurance" ? 3 :
							customer.getData("checkbox_accounttype_advice") === "kinsurance" ? 4 :
								-1;
	}
	else
	{
		newState.customerState.checkbox_accounttype_guruIndex =
			customer.getData("checkbox_accounttype_guru") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_guru") === "isk" ? 1 :
					customer.getData("checkbox_accounttype_guru") === "trust" ? 2 :
						customer.getData("checkbox_accounttype_guru") === "pinsurance" ? 3 :
							customer.getData("checkbox_accounttype_guru") === "tinsurance" ? 4 :
								customer.getData("checkbox_accounttype_guru") === "kinsurance" ? 5 :
									-1;

		newState.customerState.checkbox_accounttype_spetsIndex =
			customer.getData("checkbox_accounttype_spets") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_spets") === "isk" ? 1 :
					customer.getData("checkbox_accounttype_spets") === "trust" ? 2 :
						customer.getData("checkbox_accounttype_spets") === "pinsurance" ? 3 :
							customer.getData("checkbox_accounttype_spets") === "tinsurance" ? 4 :
								customer.getData("checkbox_accounttype_spets") === "kinsurance" ? 5 :
									-1;

		newState.customerState.checkbox_accounttype_otherIndex =
			customer.getData("checkbox_accounttype_other") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_other") === "isk" ? 1 :
					customer.getData("checkbox_accounttype_other") === "trust" ? 2 :
						customer.getData("checkbox_accounttype_other") === "pinsurance" ? 3 :
							customer.getData("checkbox_accounttype_other") === "tinsurance" ? 4 :
								customer.getData("checkbox_accounttype_other") === "kinsurance" ? 5 :
									-1;

		newState.customerState.checkbox_accounttype_svanIndex =
			customer.getData("checkbox_accounttype_svan") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_svan") === "isk" ? 1 :
					customer.getData("checkbox_accounttype_svan") === "trust" ? 2 :
						customer.getData("checkbox_accounttype_svan") === "pinsurance" ? 3 :
							customer.getData("checkbox_accounttype_svan") === "tinsurance" ? 4 :
								customer.getData("checkbox_accounttype_svan") === "kinsurance" ? 5 :
									-1;

		newState.customerState.checkbox_accounttype_adviceIndex =
			customer.getData("checkbox_accounttype_advice") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_advice") === "isk" ? 1 :
					customer.getData("checkbox_accounttype_advice") === "trust" ? 2 :
						customer.getData("checkbox_accounttype_advice") === "pinsurance" ? 3 :
							customer.getData("checkbox_accounttype_advice") === "tinsurance" ? 4 :
								customer.getData("checkbox_accounttype_advice") === "kinsurance" ? 5 :
									-1;
	}

	newState.customerState.guru_disabled = false;
	newState.customerState = updateInvestedProductFields(newState.customerState);

	return newState;
}

function _getFields()
{
	return [
		"allocation_amount_guru",
		"allocation_amount_spets",
		"allocation_amount_svan",
		"allocation_amount_other",
		"allocation_amount_initial",
		"allocation_svan_exponeringsbelopp",
		"allocation_separate_deposit",

		"allocation_fee_guru",
		"allocation_fee_spets",
		"allocation_fee_svan",
		"allocation_fee_other",

		"account_advice_partnerfee_guru",
		"account_advice_partnerfee_spets",
		"account_advice_partnerfee_other",
		"account_advice_partnerfee_svan",

		"allocation_type_other",
		"account_advice_partnerfeeinsek_other",

		"checkbox_spets_mjukstart",

		"checkbox_accounttype_guru",
		"checkbox_accounttype_spets",
		"checkbox_accounttype_other",
		"checkbox_accounttype_svan",

		"radio_allocation_perc_guru",
		"radio_allocation_perc_spets",
		"radio_allocation_perc_other",
		"radio_allocation_perc_svan",

		"radio_allocation_risk_guru",
		"radio_allocation_risk_spets",
		"radio_allocation_risk_other",

		"checkbox_advice_customer",
		"allocation_fxhedge_spets",
		"radio_product_selection_mode",

		"radio_allocation_fee_guru",
		"radio_allocation_fee_spets",
		"radio_allocation_fee_other",

		"allocation_fee_spets_revenueshare",
		"allocation_fee_guru_revenueshare",
		"allocation_fee_other_revenueshare",

		"allocation_fee_guru_rebate",
		"allocation_fee_spets_rebate",
		"allocation_fee_other_rebate",

		"allocation_horizon_other",
		"allocation_horizon_svan",

		"customer_account_bank_owner",
		"customer_account_bank",
		"customer_account_bank_custom",
		"customer_account_bank_clearingno",
		"customer_account_bank_no",

		"checkbox_allocation_advice_understand",
		"allocation_perffee_advice",
		"allocation_product_advice",
		"allocation_product_advice_select",

		"allocation_amount_advice",
		"radio_allocation_perc_advice",
		"radio_allocation_risk_advice",
		"checkbox_accounttype_advice",
		"account_advice_partnerfee_advice",
		"allocation_fee_advice",
		"allocation_horizon_advice",

		"customer_guru_depa",
		"customer_spets_depa",
		"customer_other_depa",

		"assets_spets_depa",
		"previous_fee_spets",
		"onetime_fee_spets",
		"future_onetime_fee_spets",

		"assets_guru_depa",
		"previous_fee_guru",
		"onetime_fee_guru",
		"future_onetime_fee_guru",

		"assets_other_depa",
		"previous_fee_other",
		"onetime_fee_other",
		"future_onetime_fee_other",

		"notes_tillagg_other",
		"discagreement_other",

		"allocation_amount_first",
		"allocation_product_first",
		"allocation_product_bilaga_first",
		"radio_allocation_fee_first",
		"allocation_fee_revshare_first",
		"allocation_fee_rebate_first",
		"account_advice_partnerfeeinsek_advice",
		"checkbox_customer_is_audience",

		"radio_spets_fixed_fee",
		"radio_guru_fixed_fee",
		"allocation_amount_text",

		"allocation_amount_second",
		"allocation_product_second",
		"allocation_product_bilaga_second",
		"radio_allocation_fee_second",
		"allocation_fee_revshare_second",
		"allocation_fee_rebate_second",

		"spets_total_cost",
		"guru_total_cost",
		"svan_total_cost",

		"investment_product",
		"investment_amount",
		"investment_share_of_assets",
		"investment_time_horizon",
		"investment_risk_class",
		"investment_product_other_text",
		"investment_account_type",
		"investment_fee_revshare",
		"investment_fee_rebate",
		"investment_fee_explained",
		"investment_fee_other",
		"investment_fee_other_sek",
		"investment_fee_other_text"
	];
}

function updateInvestedProductFields(customerState)
{
	if (customerState.checkbox_product_advice)
	{
		customerState.investment_product = customerState.allocation_product_advice;
		customerState.investment_amount = getAmountInFixedFormat(customerState.allocation_amount_advice);
		customerState.investment_share_of_assets = customerState.radio_allocation_perc_advice;
		customerState.investment_time_horizon = getHorizonInFixedFormat(customerState.allocation_horizon_advice);
		customerState.investment_risk_class = customerState.radio_allocation_risk_advice;
		customerState.investment_product_other_text = "Den rådgivning kund erhållit är icke-oberoende. Rådgivare har förklarat vad detta innebär.";
		customerState.investment_account_type = customerState.checkbox_accounttype_advice;
		customerState.investment_fee_revshare = "0";
		customerState.investment_fee_rebate = "0";
		customerState.investment_fee_explained = "-";
		customerState.investment_fee_other = customerState.allocation_fee_advice;
		customerState.investment_fee_other_sek = customerState.allocation_perffee_advice && customerState.allocation_perffee_advice > 0 ? localeNumber(customerState.allocation_perffee_advice, 0) : "-";
		customerState.investment_fee_other_text = "Vid byte av benchmark erhåller Fair och Rådgivare ny ersättning enligt denna ersättningmodell.";
	}
	else if (customerState.checkbox_product_spets)
	{
		customerState.investment_product = "Fair Globalförvaltning";
		customerState.investment_amount = getAmountInFixedFormat(customerState.allocation_amount_spets);
		customerState.investment_share_of_assets = customerState.radio_allocation_perc_spets;
		customerState.investment_time_horizon = "Minst 5 år";
		customerState.investment_risk_class = "4";
		customerState.investment_product_other_text = "";
		customerState.investment_account_type = customerState.checkbox_accounttype_spets;
		customerState.investment_fee_revshare = customerState.allocation_fee_spets_revenueshare;
		customerState.investment_fee_rebate = customerState.allocation_fee_spets_rebate;
		customerState.investment_fee_explained = "-"; // Overwritten by correct cost string later.
		customerState.investment_fee_other = "0";
		customerState.investment_fee_other_sek = "0";
		customerState.investment_fee_other_text = "Avtalet löper minst 5 år. Vid förtida uttag tas en avgift ut som uppgår till 5% av det uttagna beloppet under det första året, 4% av det uttagna beloppet under andra året, osv till 0% efter 5 år förflutit. Vi avråder från att investera i aktiemarknaden med en kortare placeringshorisont än 5 år.";
	}
	else if (customerState.checkbox_product_guru)
	{
		customerState.investment_product = "Fair Flexibel";
		customerState.investment_amount = getAmountInFixedFormat(customerState.allocation_amount_guru);
		customerState.investment_share_of_assets = customerState.radio_allocation_perc_guru;
		customerState.investment_time_horizon = "Minst 3 år";
		customerState.investment_risk_class = "3";
		customerState.investment_product_other_text = "";
		customerState.investment_account_type = customerState.checkbox_accounttype_guru;
		customerState.investment_fee_revshare = customerState.allocation_fee_guru_revenueshare;
		customerState.investment_fee_rebate = customerState.allocation_fee_guru_rebate;
		customerState.investment_fee_explained = "-"; // Overwritten by correct cost string later.
		customerState.investment_fee_other = "0";
		customerState.investment_fee_other_sek = "0";
		customerState.investment_fee_other_text = "";
	}
	else if (customerState.checkbox_product_svan)
	{
		customerState.investment_product = "Strategi Svart Svan";
		customerState.investment_amount = getAmountInFixedFormat(customerState.allocation_amount_svan);
		customerState.investment_share_of_assets = customerState.radio_allocation_perc_svan;
		customerState.investment_time_horizon = getHorizonInFixedFormat(customerState.allocation_horizon_svan);
		customerState.investment_risk_class = "7";
		customerState.investment_product_other_text = "Exponeringsbelopp: " + getAmountInFixedFormat(customerState.allocation_svan_exponeringsbelopp);
		customerState.investment_account_type = customerState.checkbox_accounttype_svan;
		customerState.investment_fee_revshare = "33";
		customerState.investment_fee_rebate = "0";
		customerState.investment_fee_explained = "-"; // Overwritten by correct cost string later.
		customerState.investment_fee_other = "0";
		customerState.investment_fee_other_sek = "0";
		customerState.investment_fee_other_text = "";
	}

	return customerState;
}

function getAmountInFixedFormat(inputAmount)
{
	if (inputAmount && inputAmount.length > 0)
	{
		inputAmount = inputAmount.replace("SEK", "");
		inputAmount = inputAmount.replaceAll(" ", "");
		inputAmount = localeNumber(inputAmount, 0) + " SEK";
	}
	return inputAmount;
}

function getHorizonInFixedFormat(horizon)
{
	if (horizon && horizon.length > 0)
	{
		horizon = horizon.replace(" ", "");
		horizon = horizon.replace("år", "");
		horizon = horizon + " år";
	}
	return horizon;
}