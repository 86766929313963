import {mergeViewStateForFulfillment} from "../../Cob.helpers";

export default function (state, prevState) {
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") {
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	return !(customerState.customer_debt_realestate.length === 0 ||
		customerState.customer_debt_loanprotection_re.length === 0 ||
		customerState.customer_debt_study.length === 0 ||
		customerState.customer_debt_other.length === 0 ||
		customerState.customer_debt_loanprotection_other.length === 0 ||
		customerState.customer_costs_loan_realestate.length === 0 ||
		customerState.customer_costs_loan_other.length === 0 ||
		customerState.customer_costs_re.length === 0);
}