import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState) {
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") return true;
	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	return (
		customerState.sign_agreed_gdpr === "Ja" &&
		customerState.sign_seen_relevant_information === "Ja" &&
		customerState.sign_agreed_processing_personal_info === "Ja" &&
		customerState.sign_understands_risk === "Ja"
	);
}