// MODULES
import React from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import AttentionRequiredIcon from '@mui/icons-material/ErrorOutlineOutlined';

import Card from "@cobComponents/Card";
import OptionsList from "@cobComponents/OptionsList/OptionsList";
import Button from "@components/Button/Button";

import useCustomerInformationCard from "./useCustomerInformationCard";

const CustomerInformationCard = props => {
  const {
    loaded,
    street,
    city,
    zipCode,
    country,
    email,
    phoneNumber,
    phone_alternative,
    adviceStatus,
    kycStatus,
    timeSinceKyc,
    kycAction,
    setKycAction,
    toggleAddCustomerModal,
    downloadKyc,
    startNewAdvice,
    kycDismiss
  } = useCustomerInformationCard(props);

  const { kycDate, kycRequiresUpdate, kycNeedsChecking } = kycStatus || {};
  const { latestAdviceDate, timeSinceLatestAdvice } = adviceStatus || {};

  return (
    <Card 
      title="Kundinformation"
      icon={PersonIcon}
      loading={!loaded}
      sxStyle={{
        mt: {xs: 2, md: 0},
      }}
    >
        <Box sx={{
          height: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          top: -31
        }}>
          <Link 
            component="button"
            type="button"
            onClick={toggleAddCustomerModal}
            sx={{
              color: "inherit",
              cursor: "pointer"
            }}>
            <EditIcon sx={{fontSize: "14px", color: "primary.main"}}/>
          </Link>
        </Box>
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 0.6
        }}>
          <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
            <b>Telefon:</b>
          </Typography>                                
          <Typography color="inherit" variant="body2" align="right" sx={{width: "48%"}}>
            {phoneNumber || phone_alternative ? <React.Fragment>{phoneNumber}<br/>{phone_alternative}</React.Fragment> : "-"}
          </Typography>
        </Box>
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 0.6
        }}>
          <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
            <b>E-postadress:</b>
          </Typography>
          <Typography noWrap color="inherit" variant="body2" align="right" sx={{width: "48%"}}>
            <Link
            href={`mailto:${email}`}
            >
              {email}
            </Link>                                
          </Typography>
        </Box>
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          mt: 0.6
        }}>
          <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
            <b>Adress</b>
          </Typography>                                
          <Typography color="inherit" variant="body2" align="right" sx={{width: "48%"}}>
            {street ? <React.Fragment>{street}<br/></React.Fragment> : ""}
            {zipCode || city ? zipCode + " " + city : "" }
            {country && country !== "Sverige" && country !== "-" ? (", " + country) : ""}
          </Typography>
        </Box>
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          mt: "10px",
          pt: "10px",
          borderTop: "1px solid #EEE"
        }}>
          <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
            <b>Senaste rådgivning</b>
          </Typography>
          <Typography color="inherit" variant="body2" align="right" sx={{width: "48%"}}>
            {latestAdviceDate ? latestAdviceDate : <em>Saknas</em>}
            {latestAdviceDate && (
            <Typography color="#AAA" variant="body4">
              {timeSinceLatestAdvice}
            </Typography>
            )}
          </Typography>
        </Box>
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          mt: "10px"
        }}>
          <Typography color="inherit" variant="body2" sx={{width: kycDate ? "48%" : "93%"}}>
            <b>KYC-datum</b>
            <Typography 
              color={kycDate ? "inherit" : "#df4c43"}
              variant="body4"
              sx={{display: "flex", alignItems: "center"}}
            >
              {!kycDate && <AttentionRequiredIcon sx={{fontSize: "14px", marginRight: 0.5, color: "#df4c43"}}/>}
              {kycDate ?
                <Link
                  variant="body4"
                  href="#"
                  onClick={downloadKyc}
                  sx={{
                    textDecoration: "none", "span": {
                      textDecoration: "underline"
                    }
                  }}>
                    <span>Ladda ner PDF</span>
                </Link>
                :
                "KYC saknas för denna kund."
              }
            </Typography>
          </Typography>
          <Typography color="inherit" variant="body2" align="right" sx={{width: kycDate ? "48%" : "5%"}}>
            {kycDate ? kycDate : <em>-</em>}
            {timeSinceKyc && (
            <Typography 
              color={kycRequiresUpdate ? "red" : "green"}
              variant="body4"
              sx={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}
            >
              {kycRequiresUpdate ? <AttentionRequiredIcon sx={{fontSize: "14px", marginRight: 0.5, color: "red"}}/> : ""}
              {timeSinceKyc}
            </Typography>
            )}
          </Typography>
        </Box>
        {kycDate && !kycRequiresUpdate && kycNeedsChecking && (
          <Box
            sx={{
              mt: "4px"
            }}
          >
            <Typography color="#c39300" variant="body2" align="left">
              Kund har i KYC-formuläret angett att vissa förhållanden
              ändrats sedan det senast signerade rådgivnings-
              protokollet, varför du som rådgivare ska läsa igenom vad
              kunden uppgett i KYC:n för att bedöma om en ny
              lämplighetsbedömning behövs.
            </Typography>
            <OptionsList
              type="radio"
              name="fmk_valid_to"
              size="small"
              options={[
                {
                  label: "Den valda förvaltningen anses fortsatt lämplig för kunden",
                  value: "dismiss",
                  checked: kycAction === "dismiss"
                },
                {
                  label: "Genomför rådgivning nu",
                  value: "new-advice",
                  checked: kycAction === "new-advice"
                }
              ]}
              style={{
                fontFamily: "Roboto", paddingBottom: "0", margin: "0"
              }}
              onChange={(checked, action) => setKycAction(action)}
            />
            <Button
              label="Bekräfta"
              appearance="secondary"
              size="small"
              disabled={!kycAction}
              onClick={kycAction === "new-advice" ? startNewAdvice : kycDismiss}
              filled
            />
          </Box>
        )}
    </Card>
  );
};

export default CustomerInformationCard;