import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState) {
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") {
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	if (customerState.risk_sri_level_selected.length === 0) {
		return false;
	}

	return true;
}