// MODULES
import React from "react";
import PropTypes from "prop-types";

// CLASSES
import Validator from "@classes/Validator";

// COMPONENTS
import FieldsCollection from "@components/FieldsCollection/FieldsCollection";
import TextField from "@components/TextField/TextField";
import Slider from "@components/Slider/Slider";

// HELPERS
import i18n from "@helpers/i18n";
import Checkbox from "../../../../../../../components/Checkbox/Checkbox";

export default class CompanyReps extends React.PureComponent {
	static propTypes = {
		reps: PropTypes.array.isRequired,
		onRepChange: PropTypes.func.isRequired,
		activeAmount: PropTypes.number.isRequired,
    highlightErrors: PropTypes.bool,
	};

	render() {
		const {props} = this;
		const {reps, activeAmount, secondary, highlightErrors} = props;

		const companyRepsForDisplay = [];

		let i = 0;
		let loopEnd = activeAmount > 4 ? 4 : activeAmount;

		if (secondary) {
			i = 4;
			loopEnd = 8;
		}

		for (; i < loopEnd; i++) {

			const rep = reps[i];
			companyRepsForDisplay.push(
				<React.Fragment key={i}>
					<CompanyRepsRep key={i}
									data={rep}
									index={i}
									secondary={secondary}
									onChange={this._onRepChange}
                  highlightErrors={highlightErrors}
                  />

					<input type="hidden" name={"company_benowner_firstname_" + i} value={rep.firstName}/>
					<input type="hidden" name={"company_benowner_lastname_" + i} value={rep.isCompany ? "" : rep.lastName}/>
					<input type="hidden" name={"company_benowner_ssn_" + i} value={rep.ssn}/>
					<input type="hidden" name={"company_benowner_votepercentage_" + i}
						   value={parseInt(rep.voting || "0", 10) + "%"}/>
					<input type="hidden" name={"company_benowner_percentage_" + i}
						   value={parseInt(rep.ownership || "0", 10) + "%"}/>
				</React.Fragment>
			);
		}

		return (
			<div className="Cob-view2-CompanyReps">
				{secondary && (
					<React.Fragment>
						<p style={{margin: "10px 0px 10px 0", maxWidth: "600px"}}>
							Vänligen ange nedan vem som har en
							röst- eller ägandeandel större än 25% i den juridiska personen.
						</p>
					</React.Fragment>
				)}

				<div className="Cob-view2-CompanyReps-list">
					{companyRepsForDisplay}
				</div>
			</div>
		);
	}

	// Internal methods
	_onRepChange = (data, index) => {
		this.props.onRepChange(data, index);
	}
}


// PRIVATE COMPONENTS
class CompanyRepsRep extends React.PureComponent {
	static propTypes = {
		data: PropTypes.object.isRequired,
		onChange: PropTypes.func.isRequired,
		index: PropTypes.number.isRequired,
		highlightErrors: PropTypes.bool,
	};

	render() {
		const {props} = this;
		const {highlightErrors, data, index, secondary} = props;

		return (
			<div className="Cob-view2-CompanyReps-Rep">
				<h4>{secondary ? "Huvudman, ägarbolag" : "Huvudman " + (index + 1)}</h4>

				<Checkbox
					value="yes"
					label="Huvudman är juridisk person"
					checked={data.isCompany}
					onChange={this._onCompanyChange}
					style={{margin: "0 0 5px 0"}}
				/>

				{data.isCompany && (
					<input type="hidden" name="checkbox_company_benowners_jurpers" value="Ja" />
				)}

				{!data.isCompany && (
					<FieldsCollection
						fieldsPerRow={3}
						fields={[
							{
								key: "firstName",
								render: (
									<CompanyRepsRepTextField
										label={i18n("general", "first_name")}
										value={data.firstName}
										nameSuffix={"firstname"}
										repIndex={index}
										onChange={this._onTextFieldChange}
                    highlightErrors={highlightErrors}
									/>
								)
							},
							{
								key: "lastName",
								render: (
									<CompanyRepsRepTextField
										label={i18n("general", "last_name")}
										value={data.lastName}
										nameSuffix={"lastname"}
										repIndex={index}
										onChange={this._onTextFieldChange}
                    highlightErrors={highlightErrors}
									/>
								)
							},
							{
								key: "ssn",
								render: (
									<CompanyRepsRepTextField
										type="number"
										label="Personnummer"
										value={data.ssn}
										nameSuffix="sec_num"
										repIndex={index}
										onChange={this._onTextFieldChange}
                    highlightErrors={highlightErrors}
									/>
								)
							}
						]}
					/>
				)}

				{data.isCompany && (
					<React.Fragment>
						<FieldsCollection
							fieldsPerRow={2}
							fields={[
								{
									key: "firstName",
									render: (
										<CompanyRepsRepTextField
											label="Firma"
											value={data.firstName}
											nameSuffix={"firstname"}
											repIndex={index}
											onChange={this._onTextFieldChange}
                      highlightErrors={highlightErrors}
										/>
									)
								},
								{
									key: "ssn",
									render: (
										<CompanyRepsRepTextField
											type="number"
											label="Organisationsnummer"
											value={data.ssn}
											nameSuffix="sec_num"
											repIndex={index}
											isCompany={data.isCompany}
											onChange={this._onTextFieldChange}
                      highlightErrors={highlightErrors}
										/>
									)
								}
							]}
						/>
					</React.Fragment>
				)}


				<FieldsCollection
					fieldsPerRow={2}
					fields={[
						{
							key: "ownership",
							render: (
								<React.Fragment>
									<Slider
										label={i18n("general", "ownership")}
										value={parseInt(data.ownership || "0", 10)}
										unit="%"
										visibleValues={[0, 25, 50, 75, 100]}
										onChange={this._onOwnershipChange}
									/>
								</React.Fragment>
							)
						},
						{
							key: "voting",
							render: (
								<React.Fragment>
									<Slider
										label="Röstandel %"
										value={parseInt(data.voting || "0", 10)}
										unit="%"
										visibleValues={[0, 25, 50, 75, 100]}
										onChange={this._onVotingChange}
									/>
								</React.Fragment>
							)
						}
					]}
				/>
			</div>
		);
	}

	_onTextFieldChange = (nameSuffix, value) => {
		const {onChange, data, index} = this.props;
		let type;

		switch (nameSuffix) {
			case "firstname":
				type = "firstName";
				break;
			case "lastname":
				type = "lastName";
				break;
			case "sec_num":
				type = "ssn";
				break;
			default:
				break;
		}

		if (type) onChange({...data, [type]: value}, index);
	};

	_onCompanyChange = (checked) => {
		const {onChange, data, index} = this.props;
		onChange({...data, isCompany: checked}, index);
	};

	_onOwnershipChange = (value) => {
		const {onChange, data, index} = this.props;
		onChange({...data, ownership: value}, index);
	};

	_onVotingChange = (value) => {
		const {onChange, data, index} = this.props;
		onChange({...data, voting: value}, index);
	};
}

class CompanyRepsRepTextField extends React.PureComponent {
	static propTypes = {
		type: PropTypes.string.isRequired,
		repIndex: PropTypes.number.isRequired,
		label: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
		nameSuffix: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
    highlightErrors: PropTypes.bool,
	};

	static defaultProps = {
		type: "text",
	};

	render() {
		const {label, repIndex, type, nameSuffix, value, highlightErrors} = this.props;

		return (
			<TextField
				className={"Cob-view2-CompanyReps-TextField"}
				type={type}
				name={`company_benowner_${nameSuffix}_${repIndex}`}
				label={label}
				value={value}
				autocomplete="nope"
				validation={this._validation}
				onChange={this._onChange}
        highlightErrors={highlightErrors}
			/>
		);
	}

	// Internal methods
	_onChange = ({target}) => {
		const {nameSuffix, onChange} = this.props;
		onChange(nameSuffix, target.value);
	};

	_validation = (value) => {
		if (this.props.nameSuffix === "sec_num") {
			if (this.props.isCompany !== undefined && this.props.isCompany) {

				let ssnForValidation = value;
				if (ssnForValidation.substring(0, 2) === "16") {
					ssnForValidation = ssnForValidation.substring(2);
				}

				return Validator.validateOrgNum(ssnForValidation);
			}
			return Validator.validateSsn(value);
		}
		else if (this.props.type === "number") {
			return value.length > 0 && !isNaN(value);
		}

		return value.length > 0;
	}
}