// HELPERS
import { inArray } from "@helpers/utils";
import { mergeViewStateForFulfillment } from "../../Cob.helpers";


export default function(state, prevState) {
    if(import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") return true;
    state = mergeViewStateForFulfillment(state, prevState);
    const { customerState } = state;

    // New methods for validating
    if(!inArray("yes", [customerState.swedishBank, customerState.swedishInsurance, customerState.foreignBank, customerState.foreignInsurance])) return false;
    if(customerState.foreignBank === "yes" && customerState.foreignBankText.length === 0) return false;
    if(customerState.foreignInsurance === "yes" && customerState.foreignInsuranceText.length === 0) return false;

    return !!(
        (
            customerState.checkbox_customer_reason_business_relationship_other === "yes" ? (
                customerState.customer_reason_business_relationship_other.length > 0
            ) : (
                customerState.checkbox_customer_reason_business_relationship_saving === "yes"
                || customerState.checkbox_customer_reason_business_relationship_assetmanagement === "yes"
            )
        )
        && (
            customerState.checkbox_customer_kyc_purpose_other === "yes" ? (
                customerState.customer_kyc_purpose_other.length > 0
            ) : (
                customerState.checkbox_customer_kyc_purpose_pension === "yes"
                || customerState.checkbox_customer_kyc_purpose_private_consumption === "yes"
                || customerState.checkbox_customer_kyc_purpose_financial_security === "yes"
                || customerState.checkbox_customer_kyc_purpose_beneficiary === "yes"
                || customerState.checkbox_customer_kyc_purpose_saving === "yes"
            )
        )
        && (
            customerState.checkbox_customer_kyc_inv_other === "yes" ? (
                customerState.customer_kyc_inv_other.length > 0
            ) : (
                customerState.checkbox_customer_kyc_inv_own_part === "yes"
                || customerState.checkbox_customer_kyc_inv_through_poa === "yes"
            )
        )
        && (
            customerState.purposeGains === "no"
            || (
                customerState.purposeGains === "yes"
                && customerState.purposeGainsText.length > 0
            )
        )
        && customerState.customer_kyc_sek_invested.length > 0
        && (
            customerState.originOther === "yes" ? (
                customerState.originOtherDesc.length > 0
            ) : (
                customerState.originSavings === "yes"
                || customerState.originHeritageGift === "yes"
                || customerState.originSalary === "yes"
                || customerState.originRealEstateSales === "yes"
                || customerState.originCapitalReturn === "yes"
            )
        )
        && customerState.transactionSize
        && (
            customerState.isPep === "yes" ? (
                customerState.pepFamilyRelation
                && customerState.pepFamilyName
                && customerState.pepFamilyPosition
                && customerState.pepFamilyCountry
                && customerState.pepFamilyPeriod
            ) : customerState.isPep === "no"
        )
    );
}