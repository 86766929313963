import React from "react";

// HELPERS
import {createClassName, localeNumber, parseLocaleNumber} from "@helpers/utils";


export default class SimpleTextField extends React.PureComponent {
	state = {
		focused: false,
		filled: false,
		invalid: false,
	};

  componentDidMount() {
		_validationAsync.call(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps !== this.props) _validationAsync.call(this);
	}

	render() {
		const {props, state} = this;
		const {style, type, label, name, placeholder, unit = "", value, tooltip, disabled, readOnly, textAlign, showSeparators} = props;
		const classes = createClassName(props.className, {
			"Cob-SimpleTextField": true,
			"focus": state.focused,
			"filled": state.filled || !!value,
			"disabled": disabled === true,
			"read-only": readOnly === true,
			"align-left": textAlign === "left",
			"align-right": textAlign === "right",
			"tooltip": !!tooltip,
			"invalid": state.invalid
		});

		const valueLocale = type === "number" && name ? _localeValue.call(this, value) : value;
		let valueForDisplay = type === "number" && name ? _cleanValue.call(this, value) : value;

		let fieldType = type || "text";
		if (type === "number" && showSeparators) {
			fieldType = "text";
			if (value) {
				valueForDisplay = localeNumber(valueForDisplay, 0);
				if (valueForDisplay.length >= 25) {
					valueForDisplay = valueForDisplay.substr(0, 25);
				}
			}
		}

		return (
			<div className={classes} style={style}>
				{!!label && <label htmlFor={this.uid} className="Cob-SimpleTextField-label">{label}</label>}
				<input
					ref={ref => this.input = ref}
					type={fieldType}
					className="Cob-SimpleTextField-input"
					name={name}
					id={!!this.uid ? this.uid : undefined}
					placeholder={placeholder}
					value={valueForDisplay}
					disabled={disabled}
					readOnly={readOnly}
					onFocus={!disabled && !readOnly ? this._onFocus : undefined}
					onBlur={!disabled && !readOnly ? this._onBlur : undefined}
					onInput={!disabled && !readOnly ? this._onInput : undefined}
					onChange={!disabled && !readOnly ? this._onChange : undefined}
				/>

				{type === "number" && name &&
				<input type="hidden" name={name} value={valueLocale ? valueLocale + unit : ""}/>}
				{!!state.invalid ? <SimpleTextFieldStatus icon="error"/> : null}
			</div>
		);
	}

	// Internal methods
	_onFocus = () => {
		this.setState({focused: true}, () => {
			const {onFocus} = this.props;
			if (onFocus) onFocus();
		});
	};

	_onBlur = () => {
		this.setState({focused: false}, () => {
			const {onBlur} = this.props;
			if (onBlur) onBlur();
		});
	};

	_onInput = (e) => {
		const inputValue = e.target.value;

		this.setState({filled: !!inputValue}, () => {
			const {onInput} = this.props;
			if (onInput) onInput();
		});
	};

	_onChange = (e) => {
		const {name, onChange} = this.props;
		if (onChange) onChange(e, name);
	};
}


// PRIVATE FUNCTIONS
function _cleanValue(value) {
	const {delimiter1, delimiter2} = this.props;
	if (typeof value === "number") return (Math.round(value * 10000) / 10000).toString();
	const result = value ? (!isNaN(value) ? value : parseLocaleNumber(value, delimiter1, delimiter2)) : "";
	return result;
}

function _localeValue(value) {
	const {decimals, delimiter1, delimiter2} = this.props;
	const result = value ? localeNumber(
		!isNaN(value) ? value : parseLocaleNumber(value, delimiter1, delimiter2),
		decimals, delimiter1, delimiter2
	) : "";
	return result;
}

// PRIVATE COMPONENTS
function SimpleTextFieldStatus({icon, requiredSymbol}) {
	return (
		<div className="SimpleTextField-status">
			<div className={createClassName("material-icons", {
				"icon": !!icon,
				"required-symbol": requiredSymbol === true,
			})}>{requiredSymbol === true ? "*" : icon}</div>
		</div>
	);
}

// PRIVATE FUNCTIONS
function _validationAsync(callback) {
	return new Promise(resolve => {
		const {value, validation, highlightErrors} = this.props;
		const inputValue = this.input.value || value || "";
		const newState = {filled: !!inputValue};

		if (validation) {
				newState.invalid = !validation(inputValue) ;
    }

		if (!inputValue) newState.invalid = validation && highlightErrors ? true : false;

		this.setState(newState, () => {
			if (callback) callback(inputValue);
			resolve();
		});
	});
}