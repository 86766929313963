// HELPERS
import Validator from "@classes/Validator";
import {validateEmail} from "@helpers/utils";

// OTHER
import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;
	const {isCompany, companyReps, companyRepsActiveAmount} = customerState;

	if (isCompany)
	{
		let atLeastOneSignatorySelected = _companyRepIsSignatory(companyReps[0]) ||
			_companyRepIsSignatory(companyReps[1]) ||
			_companyRepIsSignatory(companyReps[2]) ||
			_companyRepIsSignatory(companyReps[3]) ||
			_companyRepIsSignatory(companyReps[4]) ||
			_companyRepIsSignatory(companyReps[5]);

		if (!atLeastOneSignatorySelected)
		{
			return false;
		}
	}

	/**
	 * Rådgivningen måste ha ett datum
	 */
	if (customerState.date.length <= 0)
	{
		return false;
	}

	// Person-/orgnr måste fyllas i
	if (customerState.ssnOrg.length <= 0)
	{
		return false;
	}

	// Förnamn måste fyllas i, det är detta som anges som företagsnamn
	if (customerState.firstName.length <= 0)
	{
		return false;
	}

	// Om det inte är ett företag måste efternamn också fyllas i
	if (!isCompany && customerState.lastName.length <= 0)
	{
		return false;
	}

	// Adress måste fyllas i
	if (customerState.street.length <= 0 || customerState.zipCode.length <= 0 || customerState.city.length <= 0)
	{
		return false;
	}

	// E-mail måste stämma
	if (!validateEmail(customerState.email))
	{
		return false;
	}

	// Telefonnr är optional men formatet måste stämma om det fylls i
	if (customerState.phoneNumberHome.length > 0 && customerState.phoneNumberHome !== "-" && !Validator.validatePhoneNum(customerState.phoneNumberHome))
	{
		return false;
	}

	/**
	 * Om personen inte är myndig måste avsnitt om målsman fyllas i
	 */
	if (!isCompany && customerState.needsGuardian)
	{
		if (customerState.child_caretaker.length <= 0)
		{
			return false;
		}

		if (customerState.child_caretaker === "alone" && (
			customerState.caretaker_name_1.length <= 0 ||
			customerState.caretaker_ssn_1.length <= 0
		))
		{
			return false;
		}

		if (customerState.child_caretaker === "two" && (
			customerState.caretaker_name_2.length <= 0 ||
			customerState.caretaker_ssn_2.length <= 0 ||
			customerState.caretaker_name_3.length <= 0 ||
			customerState.caretaker_ssn_3.length <= 0
		))
		{
			return false;
		}
	}

	// PEP - båda frågorna måste kryssas nej
	if (!isCompany && !(customerState.isPep === "no" && customerState.isPepPast === "no"))
	{
		return false;
	}

	// US Person inte tillåten just nu
	if (customerState.isUsPerson !== "no")
	{
		return false;
	}

	/**
	 * Företagsspecifik validering
	 */
	if (isCompany)
	{
		/**
		 * Om man fyllt i en LEI-kod så måste den vara korrekt
		 */
		if (!Validator.validateLeiCode(customerState.leiCode))
		{
			return false;
		}


		/**
		 * Om rådgivningen gäller företag företag så måste alla företagsrepresentanter validera
		 */
		if (!_validateCompanyRep(companyReps[0]) ||
			!_validateCompanyRep(companyReps[1], companyRepsActiveAmount < 2) ||
			!_validateCompanyRep(companyReps[2], companyRepsActiveAmount < 3) ||
			!_validateCompanyRep(companyReps[3], companyRepsActiveAmount < 4) ||
			!_validateCompanyRep(companyReps[4], companyRepsActiveAmount < 5) ||
			!_validateCompanyRep(companyReps[5], companyRepsActiveAmount < 6))
		{
			return false;
		}
	}

	return true;
}

function _companyRepIsSignatory(rep)
{
	return rep.types.length > 0 && rep.types.includes("signatory");
}

function _validateCompanyRep(rep, fallbackValue = false)
{

	if (fallbackValue)
	{
		return true;
	}

	return !!(rep.types.length > 0 && (
		rep.firstName.length > 0
		&& rep.lastName.length > 0
		&& rep.email.length > 0
		&& rep.ssn.length > 0
	));
}