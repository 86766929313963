import {mergeViewStateForFulfillment} from "../../../Cob.helpers";
import Validator from "../../../../../classes/Validator";


export default function (state, prevState) {

	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") return true;

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	if (customerState.radio_customer_autogiro.length <= 0) {
		return false;
	}

	if (customerState.radio_customer_autogiro === 'yes') {
		// Uppgifter om kontonr och innehavare måste vara ifyllda
		if (customerState.customer_autogiro_bank.length <= 0 ||
			customerState.customer_autogiro_bank_clearingno.length <= 0 ||
			customerState.customer_autogiro_bank_no.length <= 0) {
			return false;
		}

		/**
		 * Om man valt övrig bank måste man ange bankens namn i fritext
		 */
		if (customerState.customer_autogiro_bank === '-') {
			if (customerState.customer_autogiro_bank_clearingno.length <= 0 ||
				customerState.customer_autogiro_bank_no.length <= 0 ||
				customerState.customer_autogiro_bank_custom.length <= 0) {
				return false;
			}
		}

		/**
		 * If override validation is not checked the bank account no needs to be at least 4 digits long
		 */
		if (customerState.customer_autogiro_bank_no.length <= 4 && !customerState.checkbox_override_validation) {
			return false;
		}

		/**
		 * Övriga banks + skipping validation should... skip validation
		 */
		if (!(customerState.customer_autogiro_bank === '-' || customerState.checkbox_override_validation)) {

			const availableBanks = this === undefined ? state.availableBanks : this.props.availableBanks;

			const clearingNoValid = Validator.validateClearingNumber(
				customerState.customer_autogiro_bank_clearingno,
				customerState.customer_autogiro_bank,
				availableBanks
			);

			if (!clearingNoValid) {
				return false;
			}

			/**
			 * For the banks where we know the validation rules, check if the number should be validated.
			 */
			let shouldValidateAccountNumber = false;
			for (const bankName in availableBanks) {
				if (bankName === 'length' || !availableBanks.hasOwnProperty(bankName)) continue;

				if (customerState.customer_autogiro_bank === bankName) {
					const bankInfo = availableBanks[bankName];
					if (bankInfo.length > 0) {
						if (bankInfo[0]['validationEnabled'] === "1") {
							shouldValidateAccountNumber = true;
						}
					}
					break;
				}
			}

			if (shouldValidateAccountNumber) {
				if (customerState.customer_autogiro_bank_clearingno.length <= 0 ||
					customerState.customer_autogiro_bank_no.length <= 0) {
					return false;
				}

				let bnk = customerState.customer_autogiro_bank_clearingno + ", " + customerState.customer_autogiro_bank_no;
				if (!window.kontonummer(bnk)) {
					return false;
				}
			}
		}

		/**
		 * Single amount checked and validating
		 */
		if (customerState.checkbox_autogiro_single) {
			if (!customerState.customer_autogiro_amount_onetime || customerState.customer_autogiro_amount_onetime <= 0) {
				return false;
			}

			if (customerState.checkbox_autogiro_single_1 && customerState.checkbox_autogiro_single_1_selected === -1) {
				return false;
			}

			if (customerState.checkbox_autogiro_single_2 && customerState.checkbox_autogiro_single_2_selected === -1) {
				return false;
			}

			if (customerState.checkbox_autogiro_single_3 && customerState.checkbox_autogiro_single_3_selected === -1) {
				return false;
			}

			if (customerState.checkbox_autogiro_single_4 && customerState.checkbox_autogiro_single_4_selected === -1) {
				return false;
			}
		}

		/**
		 * Monthly amount checked and validating
		 */
		if (customerState.checkbox_autogiro_monthly) {
			if (!customerState.customer_autogiro_amount_monthly || customerState.customer_autogiro_amount_monthly <= 0) {
				return false;
			}

			if (customerState.checkbox_autogiro_monthly_1 && customerState.checkbox_autogiro_monthly_1_selected === -1) {
				return false;
			}

			if (customerState.checkbox_autogiro_monthly_2 && customerState.checkbox_autogiro_monthly_2_selected === -1) {
				return false;
			}

			if (customerState.checkbox_autogiro_monthly_3 && customerState.checkbox_autogiro_monthly_3_selected === -1) {
				return false;
			}

			if (customerState.checkbox_autogiro_monthly_4 && customerState.checkbox_autogiro_monthly_4_selected === -1) {
				return false;
			}
		}

	}

	return true;
}