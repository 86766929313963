import React from "react";

// COMPONENTS
import Checkbox from "@components/Checkbox/Checkbox";
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../components/DefaultFields/DefaultFields";
import StackLayout from "@components/layouts/StackLayout/StackLayout";
import SimpleTextField from "../../components/SimpleTextField/SimpleTextField";

// HELPERS
import {createClassName, parseLocaleNumber} from "@helpers/utils";
import i18n from "@helpers/i18n";

// OTHER
import fulfillment from "./Risk.fulfillment";


export default class RiskView extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state)
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) callbackFn();
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {}
			},
		});
	}

	render() {
		const {state} = this;
		const {customerState} = state;
		const classes = createClassName("Cob-view Cob-view6", {});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>{i18n("general", "risk")}</h2>

					<div className="Cob-view6-fields">
						<StackLayout className="Cob-view6-question">
							<div>
								<p className="Cob-description">Några viktiga saker som du behöver känna till att
									investeringar i värdepapper och fonder innebär alltid en risk. En investering kan
									både öka och minska i värde och det är inte säkert att du får tillbaka det
									investerade kapitalet. Högre avkastning är förknippat med högre risk. Omvänt gäller
									att en lägre risk innebär en mindre risk för förluster.</p>

								<div className="question">
									<Checkbox
										type="radio"
										name="radio_advice_customer_risk"
										label="Jag är inte villig och kan finansiellt ej hantera att förlora något av mitt kapital."
										value="not_willing"
										checked={customerState.radio_advice_customer_risk === "not_willing"}
										onChange={(checked, radio_advice_customer_risk) => this.setCustomerState({radio_advice_customer_risk})}
									/>
								</div>
								<div className="question">
									<Checkbox
										type="radio"
										name="radio_advice_customer_risk"
										label="Jag är villig och kan finansiellt hantera att följande andel av mitt kapital går förlorat utan att det påverkar min nuvarande livsstil."
										value="partial_risk"
										checked={customerState.radio_advice_customer_risk === "partial_risk"}
										onChange={(checked, radio_advice_customer_risk) => this.setCustomerState({radio_advice_customer_risk})}
									/>
									<StackLayout style={{marginTop: 10, marginLeft: 34}}>
										<SimpleTextField
											type="number"
											placeholder="Ange %"
											textAlign="left"
											value={customerState.advice_customer_risk}
											disabled={customerState.radio_advice_customer_risk !== "partial_risk"}
											validation={value => value.length > 0}
											onChange={({target}) => this.setCustomerState({advice_customer_risk: target.value})}
										/>
										{!!customerState.advice_customer_risk && <div style={{marginLeft: 10}}>%</div>}
									</StackLayout>
								</div>
								<div className="question">
									<Checkbox
										type="radio"
										name="radio_advice_customer_risk"
										label="Jag är villig och kan finansiellt hantera att förlora hela mitt investerade kapital."
										value="total_loss"
										checked={customerState.radio_advice_customer_risk === "total_loss"}
										onChange={(checked, radio_advice_customer_risk) => this.setCustomerState({radio_advice_customer_risk})}
									/>
								</div>

								<input type="hidden" name="advice_customer_risk"
									   value={customerState.radio_advice_customer_risk === "partial_risk" && customerState.advice_customer_risk ? customerState.advice_customer_risk : ""}/>

								<div style={{
									marginTop: "40px",
									fontSize: "12px",
									width: "100%",
									lineHeight: "150%",
									maxWidth: "480px"
								}}>
									Ovan angivna värden är endast ett riktmärke och kapitalet avser det belopp som
									investeras vid detta tillfälle.
								</div>
							</div>
						</StackLayout>
					</div>
				</ContentBox>
			</div>
		);
	}
}


// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, viewNo, hasFormValidationErrors} = this.props;
  const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
  const hasBeenSeen = (seenViews.includes(viewNo));   
  // if it hasn't been seen add the view to local storage for the specific case
  if (customer.caseId && !hasBeenSeen) {
    localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
  }

	let newState = getViewInitialStateForCustomer(customer);  
	newState.isFulfilled = this.isFulfilled(newState);  
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	const newState = {
		customerState: {
			// Input
			advice_customer_risk: (v => v || v === "0" ? parseLocaleNumber(v).toString() : "")(customer.getData("advice_customer_risk")),

			// Checkbox/radio
			radio_advice_customer_risk: customer.getData("radio_advice_customer_risk"),
		},
	};

	newState.isFulfilled = false;

	return newState;
}

function _getFields() {
	return [
		"advice_customer_risk",
		"radio_advice_customer_risk",
	];
}