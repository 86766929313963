import { useState, useEffect } from "react";

// HELPERS
import api from "@helpers/api";

const useCustomerNotes = props => {
  const { client, user, notes, setNotes, updateCustomers } = props;

  const [loaded, setLoaded] = useState(true);
  const [copyList, setCopyList] = useState([]);
  const [note, setNote] = useState("");
  const [inputErrors, setInputErrors] = useState({});
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [serverMessage, setServerMessage] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);
  const [editNoteModalOpen, setEditNoteModalOpen] = useState(false);
  const [deleteNoteModalOpen, setDeleteNoteModalOpen] = useState(false);

  useEffect(() => {
    setCopyList(notes);
  }, [notes]);

  const updateNotes = async (note, action) => {
    if (action === "new") {
      updateCustomers(client, notes.length + 1);
    }

    if (action === "delete") {
      const updatedNotes = notes.filter(n => n.NoteId !== note.NoteId);
      setCopyList(updatedNotes);
      setNotes(updatedNotes);
      updateCustomers(client, notes.length - 1);
    }

    if (action === "edit") {
      const updatedNotes = notes.map(n => {
        if (n.NoteId === note.NoteId) {
          return note;
        }
        return n;
      });

      updatedNotes.sort((a, b) =>
        b.Created.localeCompare(a.Created, "sv", { sensitivity: "base" })
      );
      
      setCopyList(updatedNotes);
      setNotes(updatedNotes);
    }
  };

  const toggleEditNoteModal = note => {
    setSelectedNote(note || null);
    setEditNoteModalOpen(!editNoteModalOpen);
  };

  const toggleDeleteNoteModal = note => {
    setSelectedNote(note || null);
    setDeleteNoteModalOpen(!deleteNoteModalOpen);
  };

  const requestSearch = searched => {
    // if (page > 0) setPage(0);
    const searchString = searched.toLowerCase();
    setCopyList(
      notes.filter(
        entry =>
          entry.Note?.toLowerCase().includes(searchString) ||
          entry.AdvisorFirstName?.toLowerCase().includes(searchString) ||
          entry.AdvisorLastName?.toLowerCase().includes(searchString)
      )
    );
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!note) {
      return setInputErrors({
        note: "Anteckningen får inte vara tom"
      });
    }

    try {
      setIsAddingNote(true);
      const clientId = client.client_id || client.clientId;
      const response = await api(
        "client/notes/add",
        { clientId, note },
        {},
        true
      );

      const { success, result } = response;

      if (success) {
        setNotes([result, ...copyList]);
        setCopyList([result, ...copyList]);
        setNote("");
        setIsAddingNote(false);
        updateNotes(client, "new");
        return setServerMessage({
          type: "success",
          text: "Anteckningen har lagts till"
        });
      } else {
        setIsAddingNote(false);
        setServerMessage({
          type: "error",
          text: "Något gick fel. Kontakta supporten."
        });
      }
    } catch (error) {
      setIsAddingNote(false);
      setServerMessage({
        type: "error",
        text: "Något gick fel. Kontakta supporten."
      });
      console.error(error);
    }
  };

  return {
    loaded,
    user,
    notes,
    copyList,
    note,
    setNote,
    selectedNote,
    setSelectedNote,
    updateNotes,
    inputErrors,
    isAddingNote,
    handleSubmit,
    serverMessage,
    setServerMessage,
    requestSearch,
    editNoteModalOpen,
    toggleEditNoteModal,
    deleteNoteModalOpen,
    toggleDeleteNoteModal
  };
};

export default useCustomerNotes;
