import React from "react";
import PropTypes from "prop-types";
// material
import { CssBaseline } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider
} from "@mui/material/styles";
//
import defaultPalette from "./palette";
import typography from "./typography";
import componentsOverride from "./overrides";

// ----------------------------------------------------------------------

const ThemeProvider = ({ children, theme }) => {

  const palette = {
    ...defaultPalette,
    ...(theme?.colors && { ...theme.colors }),
  };

  const breakpoints = {
    values: {
      xs: 0,
      sm: 600,
      md: 1050, // custom md breakpoint
      lg: 1200,
      xl: 1536,
    },
  };
  
  const themeOptions = {
      palette,
      typography,
      breakpoints
  };

  const muiTheme = createTheme(themeOptions);
  muiTheme.components = componentsOverride(muiTheme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={muiTheme}>
        {/* <CssBaseline /> */}
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node
};

export default ThemeProvider;
