// MODULES
import React from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import SignedIcon from '@mui/icons-material/Draw';
import DownloadIcon from '@mui/icons-material/Download';

import Card from "@cobComponents/Card";

const LastSignedCard = props => {
  const { client } = props;

  const toggleDowload = () => {
    console.log("toggleDowload");
  }

  return (
    <Card 
      title="Senaste signerade rådgivningar"
      icon={SignedIcon}
      sxStyle={{
        mt: {xs: 2, md: 0},
      }}
    >
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 0.6
        }}>
          <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
            Type
          </Typography>                                
          <Typography color="inherit" variant="body2" align="right" sx={{width: "48%"}}>
            Datum
          </Typography>
        </Box>
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          mt: 0.6
        }}>
          <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
            <b>Nyplacering</b><br/>
            <em>Global (12 SOO OOOkr)</em>
          </Typography>
          <Box
            sx={{
              width: "48%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end"
            }}
          >
            <Typography color="inherit" variant="body2" align="right" sx={{width: "48%"}}>
              2024-02-19
            </Typography>
            <Link
              component="button"
              type="button"
              onClick={toggleDowload}
              sx={{
                color: "inherit",
                cursor: "pointer"
              }}>
                <DownloadIcon/>
            </Link>
          </Box>
        </Box>
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          mt: 0.6
        }}>
          <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
            <b>Grunduppgifter</b>
          </Typography>
          <Box
            sx={{
              width: "48%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end"
            }}
          >
            <Typography color="inherit" variant="body2" align="right" sx={{width: "48%"}}>
              2024-02-19
            </Typography>
            <Link
              component="button"
              type="button"
              onClick={toggleDowload}
              sx={{
                color: "inherit",
                cursor: "pointer"
              }}>
                <DownloadIcon/>
            </Link>
          </Box>
        </Box>
    </Card>
  );
};

export default LastSignedCard;