import React from "react";

// HELPERS
import {createClassName} from "@helpers/utils";


export default class Table extends React.PureComponent {
	state = {
		isScrollable: false,
		scrollStart: false,
		scrollEnd: false,
	};

	componentDidMount() {
		const {container} = this;
		const {scrollLeft, scrollWidth} = container;
		const containerMeasure = container.getBoundingClientRect();
		const isScrollable = scrollWidth > containerMeasure.width;
		const scrollStart = isScrollable && scrollLeft === 0;

		this.setState({isScrollable, scrollStart});
	}

	render() {
		const {props, state} = this;
		const {columns, rows, validation, highlightErrors, renderHeaderTemplate, renderFooterTemplate, selectedRowIndexes, onRowClick, /*onRowSelect, onRowDeselect*/} = props;
		const classes = createClassName(props.className, {
			"Table": true,
			"scrollable": state.isScrollable,
			"scroll-start": state.scrollStart,
			"scroll-end": state.scrollEnd,
			"selectable-rows": !!selectedRowIndexes,
			"invalid": highlightErrors && !validation,
		});

		return (
			<div ref={ref => this.container = ref} className={classes} onScroll={_onContainerScroll.bind(this)}>
				{!!renderHeaderTemplate && <div className="Table-header">{renderHeaderTemplate}</div>}
				<div className="Table-container">
					<div className="Table-wrapper">
						<table className="Table-table" border="0" cellPadding="0" cellSpacing="0">
							<thead>
							<tr className="Table-columns">
								{columns.map((col, index) => (
									<th
										key={`Table-column-label-${ index }`}
										style={{width: col.width}}
										className={createClassName("Table-column-label", {
											"h-left": col.horizontalAlign === "left",
											"h-right": col.horizontalAlign === "right",
											"v-top": col.verticalAlign === "top",
											"v-bottom": col.verticalAlign === "bottom",
										})}
									>
										<div>{col.label}</div>
									</th>
								))}
							</tr>
							</thead>
							<tbody>
							{!!rows && rows.map((row, index) => (
								<TableRow
									key={`TableRow-${ index }`}
									index={index}
									data={row}
									columns={columns}
									selected={selectedRowIndexes ? selectedRowIndexes.indexOf(index) >= 0 : false}
									onClick={onRowClick}
									// onClick={ selectedRowIndexes && onRowSelect && onRowDeselect ? this._selectRowToggler : undefined }
								/>
							))}
							</tbody>
						</table>
					</div>
				</div>
				{!!renderFooterTemplate && <div className="Table-footer">{renderFooterTemplate}</div>}
			</div>
		);
	}

	// Internal methods
	// _selectRowToggler = (index) => {
	//     const { props } = this;
	//     const { onRowSelect, onRowDeselect } = props;
	//     const selectedRowIndexes = [...props.selectedRowIndexes];
	//     const selectedIndex = selectedRowIndexes.indexOf(index);

	//     if(selectedIndex >= 0) {
	//         selectedRowIndexes.splice(selectedIndex, 1);
	//         onRowDeselect(index, selectedRowIndexes);
	//     } else {
	//         selectedRowIndexes.push(index);
	//         onRowSelect(index, selectedRowIndexes);
	//     }
	// }
}


// PRIVATE COMPONENTS
class TableRow extends React.PureComponent {
	state = {
		columns: this.mapColumns(),
	}

	mapColumns() {
		const {props} = this;
		const result = {};

		for (let i = 0; i < props.columns.length; i++) {
			const col = {...props.columns[i]};
			const key = col.key || col.name;

			if (col.key) delete col.key;
			if (col.name) delete col.name;
			result[key] = col;
		}

		return result;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.columns !== this.props.columns) {
			this.setState({columns: this.mapColumns()});
		}
	}

	render() {
		const {props, state} = this;
		const {data, selected, onClick} = props;
		const {columns} = state;
		const classes = createClassName("Table-row", {
			"selected": selected,
		});

		return (
			<tr className={classes} onClick={onClick ? this._onClick : undefined}>
				{!!data && Object.keys(data).map((key, index) => {
					const colProps = columns[key];
					const col = data[key];

					return (
						<td
							key={`Table-row-value-${ index }`}
							className={createClassName(col.className, {
								"Table-row-value": true,
								"h-left": colProps.horizontalAlign === "left",
								"h-right": colProps.horizontalAlign === "right",
								"v-top": colProps.verticalAlign === "top",
								"v-bottom": colProps.verticalAlign === "bottom",
							})}
							style={col.style}
							colSpan={col.colSpan}
							title={col.title}
						>
							{col.renderItem || <div>{col.value}</div>}
						</td>
					);
				})}
			</tr>
		);
	}

	// Internal methods
	_onClick = (e) => {
		const {target} = e;
		const {data, index, onClick} = this.props;
		if (target.nodeName !== "INPUT" && target.nodeName !== "TEXTAREA" && target.nodeName !== "BUTTON") onClick(data, index);
	}
}


// PRIVATE FUNCTIONS
function _onContainerScroll({target: container}) {
	const {scrollLeft, scrollWidth} = container;
	const containerMeasure = container.getBoundingClientRect();
	const isScrollable = scrollWidth > containerMeasure.width;
	const scrollStart = isScrollable && scrollLeft === 0;
	const scrollEnd = isScrollable && scrollLeft === scrollWidth - containerMeasure.width;

	this.setState({isScrollable, scrollStart, scrollEnd});
}