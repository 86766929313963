import React, {Fragment} from "react";
import PropTypes from "prop-types";

import {isEmptyObject} from "@helpers/utils";

import {
	Modal as MuiModal,
	Box,
  Link,
	FormControl,
	Radio,
	RadioGroup,
	FormControlLabel,
	TextField,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Typography,
	Alert
} from "@mui/material";

// import Button from "@components/Button/Button";
import ModalContentBox from "@mui-theme/styled/ModalContentBox";
import CloseModalBox from "@mui-theme/styled/CloseModalBox";
import ThemedButton from "@mui-theme/styled/ThemedButton";

import Modal from "@components/Modal/Modal";
import AdvisorSelect from "./AdvisorSelect";
import useAddCustomerModal from "./useAddCustomerModal";
import countries from "@helpers/countries";

const AddCustomerModal = props =>
{
	const {
		open,
		state,
    hasId,
    userRole,
    advisors,
    currentAdvisors,
    setAdvisor,
    addAdvisor,
    removeAdvisor,
    fetchingAdvisors,
		toggle,
    personalDetailsEnabled,
    setPersonalDetailsEnabled,
    isValidInput,
		validateFields,
    canSubmitForm,
		handleInputChange,
		handleLookUp,
		handleSubmit,
    showDupSsnModal,
    closeDupSsnModal,
    setSelfAsAdvisor
	} = useAddCustomerModal(props);

	const {
		action,
    clientId,
		type,
    advisorData,
		ssn,
		orgNumber,
		firstName,
		lastName,
		companyName,
		country,
		street,
		zipCode,
		city,
		email,
		phoneNumber,
		lookingUp,
		fetching,
		inputErrors,
		serverMessage
	} = state;

	return (
		<MuiModal
      open={open}
      onClose={(_, reason) =>
      {
        if (reason !== "backdropClick")
        {
          toggle();
        }
      }}
      sx={{
        overflow: "scroll"
      }}
    >
			<ModalContentBox sx={{
					maxHeight: "96%"
			}}>
				<Box sx={{
					width: "100%",
					justifyContent: "flex-start",
				}}>
					<Box sx={{
						width: "100%",
						px: 2,
						alignItems: "flex-start",
					}}>
						<Typography variant="h4" color="common.black">
							{action === "add" ? "Skapa ny kund" : "Redigera kund"}
						</Typography>
					</Box>
					<Box component="form" noValidate sx={{
						width: "100%",
						alignItems: "flex-start",
						display: "flex",
						flexFlow: "column",
						px: 2
					}}>
						{/* Basic Information */}
						<Typography variant="h6" sx={{color: "primary.main", mb: "5px"}}>
							Grunduppgifter
						</Typography>

						{/* person */}
            <Box sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              ...(action === "update" && {mt: 1})
            }}>
              {type === "person" && (
                <TextField
                  fullWidth
                  autoFocus={action === "add" ? true : false}
                  color={isValidInput("ssn", ssn) ? "success" : "primary"}
                  size="small"
                  id="ssn"
                  name="ssn"
                  autoComplete="ssn"
                  placeholder="Personnummer"
                  value={ssn}
                  disabled={hasId ? true : false}
                  error={inputErrors?.ssn ? true : false}
                  onChange={e =>
                  {
                    handleInputChange(e);
                  }}
                  helperText={inputErrors?.ssn}
                  sx={{
                    width: "35%",
                    height: 40,
                    alignItems: "flex-start",
                    mb: 2.5,
                    "& input": {
                      width: "95%"
                    },
                    "& fieldset": {
                    borderColor: isValidInput("ssn", ssn) ? "#2e7d32" : "rgba(0, 0, 0, 0.23)"
                    }
                  }}
                />
              )}
              {type === "company" && (
                <TextField
                  fullWidth
                  autoFocus={action === "add" ? true : false}
                  color={isValidInput("orgNumber", orgNumber) ? "success" : "primary"}
                  size="small"
                  id="orgNumber"
                  name="orgNumber"
                  autoComplete="orgNumber"
                  placeholder="Org.nummer"
                  value={orgNumber}
                  disabled={hasId ? true : false}
                  error={inputErrors?.orgNumber ? true : false}
                  onChange={e =>
                  {
                    handleInputChange(e);
                  }}
                  helperText={inputErrors?.orgNumber}
                  sx={{
                    width: "40%",
                    height: 40,
                    alignItems: "flex-start",
                    mb: 2.5,
                    "& input": {
                      width: "95%"
                    },
                    "& fieldset": {
                    borderColor: isValidInput("orgNumber", orgNumber) ? "#2e7d32" : "rgba(0, 0, 0, 0.23)"
                    }
                  }}
                />
              )}
              <ThemedButton 
                variant="outlined"
                disabled={
                  type === "person" ? !isEmptyObject(validateFields({ssn})) : !isEmptyObject(validateFields({orgNumber}))
                }
                loading={lookingUp}
                onClick={handleLookUp}
                sx={{
                  minWidth: "unset",
                  width: 250,
                  top: -5.5,
                  ml: "10px"
                }}
              >
                Hämta uppgifter från SPAR
              </ThemedButton>              
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                borderTop: "1px solid #EEE",
                paddingTop: "15px"
              }}
            >
              <Typography variant="body2" sx={{color: "gray.900"}}>Eller</Typography>&nbsp;
              <Link 
                underline="none"
                color="#4cb7d5"
                onClick={() => setPersonalDetailsEnabled(true)}
                sx={{cursor: "pointer"}}
              >
                <Typography variant="body2" sx={{color: "#4cb7d5", textDecoration: "underline"}}><b>fyll i manuellt</b></Typography>
              </Link>
            </Box>
            {action === "add" && (
              <Box sx={{
                width: "100%",
                alignItems: "flex-start",
                mb: "10px"
              }}>
                <FormControl>
                  <RadioGroup value={type || ""}
                              id="type"
                              name="type"
                              onChange={handleInputChange}
                              sx={{
                                flexDirection: {xs: "column", md: "row"},
                                alignItems: "baseline"
                              }}>
                    <FormControlLabel value="person" control={<Radio/>} label="Privatperson"/>
                    <FormControlLabel value="company"
                                      control={<Radio/>}
                                      label="Företag/Organisation"
                                      sx={{ml: {xs: 0, md: "20px"}}}/>
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
						{/* person */}
						{type === "person" && (
							<Box sx={{
                width: "100%",
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
                mb: 1.5,
                ...(action === "update" && {mt: 1})
							}}>
								<TextField
                  required
                  fullWidth
                  disabled={!personalDetailsEnabled}
                  color={isValidInput("firstName", firstName) ? "success" : "primary"}
                  size="small"
                  id="firstName"
                  name="firstName"
                  autoComplete="firstName"
                  placeholder="Förnamn"
                  value={firstName}
                  error={inputErrors?.firstName ? true : false}
                  onChange={e =>
                  {
                    handleInputChange(e);
                  }}
                  helperText={inputErrors?.firstName}
                  sx={{
                    width: "49%",
                    height: 40,
                    alignItems: "flex-start",
                    mb: 1,
                    "& input": {
                      width: "95%"
                    },
                    "& fieldset": {
                    borderColor: isValidInput("firstName", firstName) ? "#2e7d32" : "rgba(0, 0, 0, 0.23)"
                    }
                  }}
                />
								<TextField
                  required
                  fullWidth
                  disabled={!personalDetailsEnabled}
                  color={isValidInput("lastName", lastName) ? "success" : "primary"}
                  size="small"
                  id="lastName"
                  name="lastName"
                  autoComplete="lastName"
                  placeholder="Efternamn"
                  value={lastName}
                  error={inputErrors?.lastName ? true : false}
                  onChange={e =>
                  {
                    handleInputChange(e);
                  }}
                  helperText={inputErrors?.lastName}
                  sx={{
                    width: "49%",
                    height: 40,
                    alignItems: "flex-start",
                    mb: 1,
                    "& input": {
                      width: "95%"
                    },
                    "& fieldset": {
                    borderColor: isValidInput("lastName", lastName) ? "#2e7d32" : "rgba(0, 0, 0, 0.23)"
                    }
                  }}
                />
							</Box>
						)}
						{/* Company */}
						{type === "company" && (
							<Fragment>
								<Box sx={{
                  width: "100%",
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
                  mb: 1.5,
                  ...(action === "update" && {mt: 1})
								}}>
									<TextField
                    fullWidth
                    disabled={!personalDetailsEnabled}
                    color={isValidInput("companyName", companyName) ? "success" : "primary"}
                    size="small"
                    id="companyName"
                    name="companyName"
                    autoComplete="companyName"
                    placeholder="Företagets / Organisationens namn"
                    value={companyName}
                    error={inputErrors?.companyName ? true : false}
                    onChange={e =>
                    {
                      handleInputChange(e);
                    }}
                    helperText={inputErrors?.companyName}
                    sx={{
                      width: "64%",
                      height: 40,
                      alignItems: "flex-start",
                      mb: 2,
                      "& input": {
                        width: "95%"
                      },
                      "& fieldset": {
                      borderColor: isValidInput("companyName", companyName) ? "#2e7d32" : "rgba(0, 0, 0, 0.23)"
                      }
                    }}
                  />
								</Box>
								<Box sx={{
									width: "100%",
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
                  mb: 1.5,
								}}>
									<TextField
                    fullWidth
                    disabled={!personalDetailsEnabled}
                    // color={isValidInput("leiCode", leiCode) ? "success" : "primary"}
                    size="small"
                    id="leiCode"
                    name="leiCode"
                    autoComplete="leiCode"
                    placeholder="LEI-kod"
                    error={inputErrors?.leiCode ? true : false}
                    onChange={e =>
                    {
                      handleInputChange(e);
                    }}
                    helperText={inputErrors?.leiCode}
                    sx={{
                      width: "34%",
                      height: 40,
                      alignItems: "flex-start",
                      mb: 2,
                      "& input": {
                        width: "95%"
                      }
                    //  "& fieldset": {
                    //   borderColor: isValidInput("leiCode", leiCode) ? "#2e7d32" : "rgba(0, 0, 0, 0.23)"
                    //  }
                    }}
                  />
								</Box>
							</Fragment>
						)}
						<Box sx={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
              mb: 1.5,
						}}>
							<TextField
                size="small"
                required
                fullWidth
                disabled={!personalDetailsEnabled}
                color={isValidInput("street", street) ? "success" : "primary"}
                id="street"
                name="street"
                autoComplete="street"
                placeholder="Utdelningsadress"
                value={street}
                error={inputErrors?.street ? true : false}
                onChange={e =>
                {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.street}
                sx={{
                  width: "46%",
                  height: 40,
                  alignItems: "flex-start",
                  mb: 2,
                  "& input": {
                    width: "95%"
                  },
                  "& fieldset": {
                  borderColor: isValidInput("street", street) ? "#2e7d32" : "rgba(0, 0, 0, 0.23)"
                  }
                }}
              />
							<TextField size="small"
							           required
							           fullWidth
                         disabled={!personalDetailsEnabled}
                         color={isValidInput("zipCode", zipCode) ? "success" : "primary"}
							           id="zipCode"
							           name="zipCode"
							           autoComplete="zipCode"
							           placeholder="Postnummer"
							           value={zipCode}
							           error={inputErrors?.zipCode ? true : false}
							           onChange={e =>
							           {
								           handleInputChange(e);
							           }}
							           helperText={inputErrors?.zipCode}
							           sx={{
								           width: "25%",
								           height: 40,
								           alignItems: "flex-start",
								           mb: 2,
								           "& input": {
									           width: "95%"
								           },
                           "& fieldset": {
                            borderColor: isValidInput("zipCode", zipCode) ? "#2e7d32" : "rgba(0, 0, 0, 0.23)"
                           }
							           }}/>
							<TextField size="small"
							           required
							           fullWidth
                         disabled={!personalDetailsEnabled}
                         color={isValidInput("city", city) ? "success" : "primary"}
							           id="city"
							           name="city"
							           autoComplete="city"
							           placeholder="Postort"
							           value={city}
							           error={inputErrors?.city ? true : false}
							           onChange={e =>
							           {
								           handleInputChange(e);
							           }}
							           helperText={inputErrors?.city}
							           sx={{
								           width: "25%",
								           height: 40,
								           alignItems: "flex-start",
								           mb: 2,
								           "& input": {
									           width: "95%"
								           },
                           "& fieldset": {
                            borderColor: isValidInput("city", city) ? "#2e7d32" : "rgba(0, 0, 0, 0.23)"
                           }
							           }}/>
						</Box>
            
						<FormControl sx={{
							height: 40,
							alignItems: "flex-start",
							width: {xs: "100%", sm: 200},
							mb: 1
						}}>
							<InputLabel id="country" htmlFor="country">
								Land
							</InputLabel>
							<Select size="small"
							        labelId="role-select-label"
							        id="country"
							        name="country"
							        label="Land"
                      disabled={!personalDetailsEnabled}
							        value={country}
							        onChange={e =>
							        {
								        handleInputChange(e);
							        }}
							        sx={{
								        width: 250,
								        textAlign: 'left',
								        alignItems: "flex-start",
                        "& fieldset": {
                         borderColor: "#2e7d32"
                        }
							        }}>
								{countries.map(({label, code}) => (
									<MenuItem key={code}
									          value={code}
									          sx={{
										          width: "100%",
										          fontSize: "1rem",
										          alignItems: "flex-start"
									          }}>
										{label}
									</MenuItem>
								))}
							</Select>
							<FormHelperText error>{inputErrors?.country}</FormHelperText>
						</FormControl>

						{/* Contact */}
						<Typography variant="h6" sx={{
							color: "primary.main",
							mb: 1,
							mt: "10px",
							paddingTop: "15px",
							borderTop: "1px solid #EEE",
							width: "100%",
							textAlign: "left"
						}}>
							Kontaktuppgifter
						</Typography>
						<Box sx={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between"
						}}>
							<TextField size="small"
							           required
							           fullWidth
                         color={isValidInput("email", email) ? "success" : "primary"}
							           id="email"
							           name="email"
							           autoComplete="email"
							           placeholder="E-postadress"
							           value={email}
							           error={inputErrors?.email ? true : false}
							           onChange={e =>
							           {
								           handleInputChange(e);
							           }}
							           helperText={inputErrors?.email}
							           sx={{
								           width: "49%",
								           height: 40,
								           alignItems: "flex-start",
								           mb: 3,
								           "& input": {
									           width: "95%"
								           },
                           "& fieldset": {
                            borderColor: isValidInput("email", email) ? "#2e7d32" : "rgba(0, 0, 0, 0.23)"
                           }
							           }}/>
							<TextField 
                size="small"
                required
                fullWidth
                color={isValidInput("phoneNumber", phoneNumber) ? "success" : "primary"}
                id="phoneNumber"
                name="phoneNumber"
                autoComplete="phoneNumber"
                placeholder="Telefonnummer"
                value={phoneNumber}
                error={inputErrors?.phoneNumber ? true : false}
                onChange={e =>
                {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.phoneNumber}
                sx={{
                  width: "49%",
                  height: 40,
                  alignItems: "flex-start",
                  mb: 3,
                  "& input": {
                    width: "95%"
                  },
                  "& fieldset": {
                   borderColor: isValidInput("phoneNumber", phoneNumber) ? "#2e7d32" : "rgba(0, 0, 0, 0.23)"
                  }
                }}
              />
						</Box>
            {userRole === "backoffice" && (
              <>
                {/* Advisors */}
                <Typography variant="h6" sx={{color: "primary.main",
	                borderTop: "1px solid #EEE",
	                paddingTop: "15px", width: "100%", textAlign: "left"}}>
                  Rådgivare
                </Typography>
                <Box
                  sx={{
                    flexDirection: "row",
                    alignItems: "flex-end"
                  }}
                >
                  <AdvisorSelect
                    advisors={advisors}
                    advisor={advisorData}
                    setAdvisor={setAdvisor}
                  />
                  {action === "update" && (
                    <ThemedButton
                      variant="contained"
                      loading={fetchingAdvisors}
                      disabled={!advisorData}
                      onClick={() => addAdvisor(clientId, advisorData.personal_number)}
                      sx={{
                        minWidth: "unset",
                        width: 120,
                        top: -3.5,
                        ml: "10px"
                      }}
                    >
                      Tilldela
                    </ThemedButton>                    
                  )}
                </Box>
                {currentAdvisors?.length > 0 && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      ml: "5px"
                    }}
                  >
                    {currentAdvisors?.map((advisor, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "50%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          mt: 0.6
                        }}
                      >
                        <Typography color="inherit" variant="body2">
                          <b>{advisor.first_name} {advisor.last_name}</b>
                        </Typography>
                        {/* <Typography color="inherit" variant="body2">
                          {advisor.company_name}
                        </Typography> */}
                        <Typography noWrap color="inherit" variant="body2" align="right" sx={{width: "48%"}}>
                          <Link
                            onClick={() => removeAdvisor(clientId, advisor)}
                            sx={{
                              cursor: "pointer"
                            }}
                          >
                            Ta bort
                          </Link>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </>
            )}
						<Box sx={{
							height: 50,
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center"
						}}>
							<Alert severity={serverMessage?.type === "success" ? "success" : "error"}
							       sx={{
								       display: serverMessage?.text ? "flex" : "none",
								       width: "100%"
							       }}>
								{serverMessage?.text}
							</Alert>
						</Box>
					</Box>
				</Box>
				<CloseModalBox sx={{
					px: 2
				}}>
					<ThemedButton variant="outlined" onClick={toggle} sx={{
						minWidth: "unset",
						width: 100
					}}>
						Avbryt
					</ThemedButton>
					<ThemedButton
            variant="contained" 
            loading={fetching}
            disabled={!canSubmitForm || (inputErrors && !isEmptyObject(inputErrors))}
            onClick={handleSubmit} sx={{
						minWidth: "unset",
						width: 150,
					}}>
						Spara kund
					</ThemedButton>
				</CloseModalBox>
        {/* Dup Ssn Modal */}
        <MuiModal
          open={showDupSsnModal}
        >
          <ModalContentBox
            sx={{
              width: 380
            }}
          >
            <Box sx={{
              width: "100%",
              px: 2,
              alignItems: "flex-start",
            }}>
              <Typography variant="h4" color="common.black">
                Lägg till rådgivare/kundrelation?
              </Typography>
            </Box>
            <Box sx={{
              width: "100%",
              p: 2,
              alignItems: "flex-start",
            }}>
              <Typography variant="subtitle1" color="common.black" align="left">
                Det person- eller orgnummer du har angett finns redan i vår databas. Vill du lägga till dig själv som rådgivare för kunden?
              </Typography>
            </Box>
            <CloseModalBox sx={{
              px: 2
            }}>
              <ThemedButton variant="outlined" onClick={closeDupSsnModal} sx={{
                minWidth: "unset",
                width: 100
              }}>
                Avbryt
              </ThemedButton>
              <ThemedButton
                variant="contained"
                onClick={setSelfAsAdvisor} sx={{
                minWidth: "unset",
                width: 150,
              }}>
                Lägg till relation
              </ThemedButton>
            </CloseModalBox>
          </ModalContentBox>
        </MuiModal>
			</ModalContentBox>
		</MuiModal>
	);
};

AddCustomerModal.propTypes = {
	open: PropTypes.bool
};

export default AddCustomerModal;
