// CLASSES
import {mergeViewStateForFulfillment} from "../../../Cob.helpers";

export default function (state, prevState) {
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") {
		return true;
	}


	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	if (customerState.situation_assets_financial.length <= 0 ||
		customerState.situation_assets_liquid.length <= 0 ||
		customerState.situation_assets_pension.length <= 0 ||
		customerState.situation_assets_other.length <= 0) {
		return false;
	}

	if (customerState.situation_assets_fixed_home.length <= 0 ||
		customerState.situation_assets_fixed_other.length <= 0) {
		return false;
	}

	if (customerState.situation_debts_other.length <= 0 ||
		customerState.situation_debts_mortgage.length <= 0) {
		return false;
	}

	return true;
}