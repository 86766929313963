// MODULES
import React from "react";
import PropTypes from "prop-types";

// CLASSES
import Validator from "@classes/Validator";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";

// COMPONENTS
import FieldsCollection from "@components/FieldsCollection/FieldsCollection";
import Checkbox from '@components/Checkbox/Checkbox';
import TextField from "@components/TextField/TextField";
import Select from "@components/Select/Select";
import Slider from "@components/Slider/Slider";

// HELPERS
import i18n from "@helpers/i18n";
import api from "@helpers/api";
import countries from "@helpers/countries";
import IdentityDocument from "../../../../components/IdentityDocument/IdentityDocument";


const countriesOptions = countries.map(({label, code}) => ({label, value: code}));
const countriesOptionsValuesOnly = countries.map(({label, code}) => code);
const countriesOptionsLabelsOnly = countries.map(({label, code}) => label);

export default class CompanyReps extends React.PureComponent
{
	static propTypes = {
		reps: PropTypes.array.isRequired,
		onRepChange: PropTypes.func.isRequired,
		activeAmount: PropTypes.number.isRequired,
	};

	render()
	{
		const {props} = this;
		const {
			reps,
			activeAmount,
			limitedUbo,
			withTaxCountry,
			fullCountryNameAsValue,
			simplified,
			requireIdUpload,
			customer,
      highlightErrors
		} = props;

		// Count of verkliga huvudmän
		let activeUbo = 0;

		return (
			<div className="Cob-view2-CompanyReps">
				<h3>Information om företagsföreträdare</h3>

				<div className="Cob-view2-CompanyReps-list">
					{reps.map((rep, index) => index < activeAmount && (
						<React.Fragment key={index}>
							<CompanyRepsRep key={index} data={rep} index={index} showHeading={activeAmount > 1}
											limitedUbo={limitedUbo}
											withTaxCountry={withTaxCountry}
											fullCountryNameAsValue={fullCountryNameAsValue}
											simplified={simplified}
											onChange={this._onRepChange}
											requireIdUpload={requireIdUpload}
											customer={customer}
                      highlightErrors={highlightErrors}
							/>

							{rep.types.indexOf("ubo") !== -1 && (
								<React.Fragment>
									<input type="hidden" name={"customer_firstname_poa" + (++activeUbo)}
										   value={rep.firstName}/>
									<input type="hidden" name={"customer_lastname_poa" + activeUbo}
										   value={rep.lastName}/>
									<input type="hidden" name={"customer_social_security_number_poa" + activeUbo}
										   value={rep.ssn}/>

									{!limitedUbo && (
										<input type="hidden" name={"customer_ownershipproc_poa" + activeUbo}
											   value={parseInt(rep.ownership || "0", 10) + "%"}/>
									)}
								</React.Fragment>
							)}
						</React.Fragment>
					))}

					<input type={"hidden"} name={"customer_other"} value={reps[0].firstName + " " + reps[0].lastName}/>
				</div>
			</div>
		);
	}

	// Internal methods
	_onRepChange = (data, index) =>
	{
		this.props.onRepChange(data, index);
	}
}


// PRIVATE COMPONENTS
class CompanyRepsRep extends React.PureComponent
{
	static propTypes = {
		data: PropTypes.object.isRequired,
		onChange: PropTypes.func.isRequired,
		index: PropTypes.number.isRequired,
		showHeading: PropTypes.bool.isRequired,
		requireIdUpload: PropTypes.bool.isRequired,
		highlightErrors: PropTypes.bool,
	};

	state = {
		loadingClientInfo: true,
		ssn: null,
		hasValidIdentityDocument: false,
		identityDocuments: [],
		identityDocumentAdded: null,
		identityDocumentExpiration: null,
		identityDocumentNumber: null
	}

	constructor(props)
	{
		super(props);
		this.state.ssn = props.data.ssn;
	}

	componentDidMount()
	{
		if (Validator.validateSsn(this.state.ssn))
		{
			this._fetchClientInfo(this.state.ssn);
		}
	}

	render()
	{
		const {props} = this;
		const {
			data,
			index,
			showHeading,
			limitedUbo,
			withTaxCountry,
			fullCountryNameAsValue,
			simplified,
			requireIdUpload,
			customer,
      highlightErrors
		} = props;
		const selectedCitizenIndex = (() =>
		{
			let returnValue = countriesOptionsValuesOnly.indexOf(data.citizenship);
			if (returnValue === -1)
			{
				returnValue = countriesOptionsLabelsOnly.indexOf(data.citizenship);
			}
			if (returnValue === -1)
			{
				returnValue = countriesOptionsValuesOnly.indexOf("SE");
			} // If -1, set "SE" as default
			return returnValue;
		})();

		const selectedTaxCountryIndex = (() =>
		{
			let returnValue = countriesOptionsValuesOnly.indexOf(data.tax_country);
			if (returnValue === -1)
			{
				returnValue = countriesOptionsLabelsOnly.indexOf(data.tax_country);
			}
			if (returnValue === -1)
			{
				returnValue = countriesOptionsValuesOnly.indexOf("SE");
			} // If -1, set "SE" as default
			return returnValue;
		})();

		const customerRoles = [];
		if (data.types.indexOf("signatory") !== -1)
		{
			customerRoles.push("Firmatecknare");
		}
		if (data.types.indexOf("ubo") !== -1)
		{
			customerRoles.push("Verklig huvudman");
		}
		if (data.types.indexOf("poa") !== -1)
		{
			customerRoles.push("Fullmaktshavare");
		}
		if (data.types.indexOf("view") !== -1)
		{
			customerRoles.push("Insynsfullmakt");
		}
		if (data.types.indexOf("mng") !== -1)
		{
			customerRoles.push("Förvaltarfullmakt");
		}

		return (
			<div className="Cob-view2-CompanyReps-Rep">
				{showHeading && <h4>Företrädare {index + 1}</h4>}

				<div className="Cob-view2-CompanyReps-Rep-type">
					<CompanyRepsRepCheckbox type="signatory" repIndex={index} label="Firmatecknare"
											checked={data.types.indexOf("signatory") !== -1}
											onChange={this._onTypeChange}/>

					<CompanyRepsRepCheckbox type="poa" repIndex={index} label="Fullmaktshavare"
											checked={data.types.indexOf("poa") !== -1} onChange={this._onTypeChange}/>

					{(simplified === undefined || !simplified) && (
						<React.Fragment>
							<CompanyRepsRepCheckbox type="view" repIndex={index} label="Insynsfullmakt"
													checked={data.types.indexOf("view") !== -1}
													onChange={this._onTypeChange}/>
							<CompanyRepsRepCheckbox type="mng" repIndex={index} label="Förvaltarfullmakt"
													checked={data.types.indexOf("mng") !== -1}
													onChange={this._onTypeChange}/>
							<CompanyRepsRepCheckbox type="ubo" repIndex={index} label="Verklig huvudman"
													checked={data.types.indexOf("ubo") !== -1}
													onChange={this._onTypeChange}/>
						</React.Fragment>
					)}

					<input type={"hidden"} name={`company_representative_role_${index}`}
						   value={customerRoles.join(", ")}/>
					<input type={"hidden"} name={`customer_representative_role_${index}`}
						   value={customerRoles.join(", ")}/>
				</div>

				<FieldsCollection
					fieldsPerRow={2}
					fields={[
						{
							key: "firstName",
							render: (
								<CompanyRepsRepTextField
									label={i18n("general", "first_name")}
									value={data.firstName}
									nameSuffix={"firstname"}
									repIndex={index}
									onChange={this._onTextFieldChange}
                  highlightErrors={highlightErrors}
								/>
							)
						}, {
							key: "lastName",
							render: (
								<CompanyRepsRepTextField
									label={i18n("general", "last_name")}
									value={data.lastName}
									nameSuffix={"lastname"}
									repIndex={index}
									onChange={this._onTextFieldChange}
                  highlightErrors={highlightErrors}
								/>
							)
						},
						{
							key: "email",
							render: (
								<CompanyRepsRepTextField
									type="email"
									label={i18n("general", "email_address")}
									value={data.email}
									nameSuffix={"email"}
									repIndex={index}
									onChange={this._onTextFieldChange}
                  highlightErrors={highlightErrors}
								/>
							)
						},
						{
							key: "phone",
							render: (
								<CompanyRepsRepTextField
									type="phone"
									label={"Telefonnummer"}
									value={data.phone}
									nameSuffix={"phone"}
									repIndex={index}
									onChange={this._onTextFieldChange}
                  highlightErrors={highlightErrors}
								/>
							)
						},
						{
							key: "ssn",
							render: (
								<CompanyRepsRepTextField
									type="number"
									label={i18n("general", "social_security_number")}
									value={data.ssn}
									nameSuffix="sec_num"
									repIndex={index}
									onChange={this._onTextFieldChange}
									onKeyPress={this._stripSsnChars}
                  highlightErrors={highlightErrors}
								/>
							)
						},
						{
							key: "citizenship",
							render: (
								<Select
									name={`company_representative_citizenship_${index}`}
									label="Nationalitet"
									options={countriesOptions}
									selectedIndex={selectedCitizenIndex}
									onChange={this._onCitizenshipChange}
                  highlightErrors={highlightErrors}
								/>
							)
						},
						{
							key: "tax_country",
							display: withTaxCountry !== undefined && withTaxCountry,
							render: (
								<Select
									name={`company_representative_tax_country_${index}`}
									label="Skatterättslig hemvist"
									options={countriesOptions}
									selectedIndex={selectedTaxCountryIndex}
									onChange={this._onTaxCountryChange}
                  highlightErrors={highlightErrors}
								/>
							)
						},
						{
							key: "ownership",
							display: data.types.indexOf("ubo") !== -1 && !limitedUbo,
							render: (
								<React.Fragment>
									<Slider
										label={i18n("general", "ownership")}
										value={parseInt(data.ownership || "0", 10)}
										unit="%"
										visibleValues={[0, 25, 50, 75, 100]}
										onChange={this._onOwnershipChange}
									/>
								</React.Fragment>
							)
						},
					]}
				/>

				{(fullCountryNameAsValue !== undefined && fullCountryNameAsValue) && (
					<React.Fragment>
						<input type={"hidden"} name={`company_representative_citizenship_${index}`}
							   value={countriesOptions[selectedCitizenIndex].label}/>
						<input type={"hidden"} name={`company_representative_tax_country_${index}`}
							   value={countriesOptions[selectedTaxCountryIndex].label}/>
						<input type={"hidden"} name={`company_representative_tax_country_code_${index}`}
							   value={countriesOptions[selectedTaxCountryIndex].value}/>
					</React.Fragment>
				)}

				<input type={"hidden"} name={`customer_representative_social_security_number_${index}`} value={data.ssn}/>
				<input type={"hidden"} name={`customer_representative_ssn_${index}`} value={data.ssn}/>

				<input
					type="hidden"
					name={`company_representative_percentage_${index}`}
					value={data.types.indexOf("ubo") !== -1 ? parseInt(data.ownership || "0", 10) + "%" : ""}
				/>

				<h4 className={"bottom-margin"} style={{paddingTop: "25px"}}>
					Ladda upp kopia på pass eller ID-kort
				</h4>

				{requireIdUpload && customer && !Validator.validateSsn(this.state.ssn) && (
					<React.Fragment>
						<p style={{marginTop: "0", fontStyle: "italic"}}>
							Var god fyll i giltigt personnummer ovan.
						</p>
					</React.Fragment>
				)}

				{requireIdUpload && customer && Validator.validateSsn(this.state.ssn) && !this.state.loadingClientInfo && (
					<IdentityDocument
            identityDocuments={this.state.identityDocuments}
						customer={customer}
						updatedDocument={this._onUpdatedIdentity}
						companyMode={false}
						hasValidIdentityDocument={this.state.hasValidIdentityDocument}
						index={index}
						identityNumber={data.ssn}
						identityDocumentAdded={this.state.identityDocumentAdded}
						identityDocumentExpiration={this.state.identityDocumentExpiration}
						identityDocumentNumber={this.state.identityDocumentNumber}
						standaloneMode={true}
					/>
				)}

				{requireIdUpload && customer && Validator.validateSsn(this.state.ssn) && this.state.loadingClientInfo && (
					<ActivityIndicator color="#4cb7d5" busy/>
				)}
			</div>
		);
	}

	// Internal methods
	_onTypeChange = (typeName, checked) =>
	{
		const {onChange, data, index} = this.props;
		const types = [...data.types];
		const typeIndex = types.indexOf(typeName);

		if (checked && typeIndex === -1)
		{
			types.push(typeName);
		}
		if (!checked && typeIndex !== -1)
		{
			types.splice(typeIndex, 1);
		}

		onChange({...data, types}, index);
	};

	_stripSsnChars = (e) =>
	{
		const charCode = (e.which) ? e.which : e.keyCode;
		if (charCode === 43 || charCode === 45) // Don't allow - or +
		{
			e.preventDefault();
		}
	}

	_onTextFieldChange = (nameSuffix, value) =>
	{
		const {onChange, data, index} = this.props;
		let type;

		switch (nameSuffix)
		{
			case "firstname":
				type = "firstName";
				break;
			case "lastname":
				type = "lastName";
				break;
			case "sec_num":
				type = "ssn";
				break;
			case "email":
				type = "email";
				break;
			case "phone":
				type = "phone";
				break;
			default:
				break;
		}

		if (type === "ssn")
		{
			this.setState({loadingClientInfo: Validator.validateSsn(value)}, () =>
			{
				if (Validator.validateSsn(value))
				{
					const newState = {...this.state};
					newState.hasValidIdentityDocument = false;
					newState.loadingClientInfo = false;
					this._fetchClientInfo(value);
					onChange({...data, [type]: value}, index);
				}
				else
				{
					onChange({...data, [type]: value}, index);
				}
			});
		}
		else if (type)
		{
			onChange({...data, [type]: value}, index);
		}
	};

	_fetchClientInfo = (ssn) =>
	{
		this.setState({loadingClientInfo: true, ssn: ssn, hasValidIdentityDocument: false}, async () =>
		{
			const clientInfo = await api("/lookup-client", {ssn: ssn});
			const newState = {...this.state};
			newState.loadingClientInfo = false;
			if (clientInfo && clientInfo.identity_documents.length > 0)
			{
				const lastDocumentAdded = clientInfo.identity_documents[0];

				try
				{
					newState.identityDocuments = clientInfo.identity_documents;
					newState.identityDocumentExpiration = lastDocumentAdded.expiration_date;
					newState.identityDocumentAdded = lastDocumentAdded.added;
					newState.identityDocumentNumber = lastDocumentAdded.document_number;

					// Check if document has expired
					const expirationDate = new Date(lastDocumentAdded.expiration_date);
					expirationDate.setHours(0, 0, 0, 0);
					const todaysDate = new Date();
					todaysDate.setHours(0, 0, 0, 0);

					newState.hasValidIdentityDocument = todaysDate <= expirationDate;
				}
				catch (ignored) // Invalid expiration date
				{
          console.log(ignored);
				}
			}

			if (!newState.hasValidIdentityDocument)
			{
				newState.identityDocuments = [];
				newState.identityDocumentExpiration = null;
				newState.identityDocumentAdded = null;
				newState.identityDocumentNumber = null;
			}

			this.setState(newState, () => {
				const {onChange, data, index} = this.props;
				onChange({...data, hasDocument: newState.hasValidIdentityDocument}, index);
			});
		});
	}

	_onUpdatedIdentity = (valid) =>
	{
		const {onChange, data, index} = this.props;
		onChange({...data, hasDocument: valid}, index);
	}

	_onOwnershipChange = (value) =>
	{
		const {onChange, data, index} = this.props;
		onChange({...data, ownership: value}, index);
	}

	_onCitizenshipChange = (option) =>
	{
		const {onChange, data, index} = this.props;
		onChange({...data, citizenship: option.value}, index);
	}

	_onTaxCountryChange = (option) =>
	{
		const {onChange, data, index} = this.props;
		onChange({...data, tax_country: option.value}, index);
	}
}

class CompanyRepsRepCheckbox extends React.PureComponent
{
	static propTypes = {
		type: PropTypes.string.isRequired,
		repIndex: PropTypes.number.isRequired,
		label: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
		checked: PropTypes.bool.isRequired,
    highlightErrors: PropTypes.bool,
	};

	render()
	{
		const {label, repIndex, checked, type, highlightErrors} = this.props;

		return (
			<Checkbox
				className="Cob-view2-CompanyReps-Checkbox"
				name={`checkbox_${type}_${repIndex}`}
				label={label}
				value={checked ? 'yes' : 'no'}
				checked={checked}
				onChange={this._onChange}
        highlightErrors={highlightErrors}
			/>
		);
	}

	// Internal methods
	_onChange = (checked) =>
	{
		const {type, onChange} = this.props;
		onChange(type, checked);
	}
}

class CompanyRepsRepTextField extends React.PureComponent
{
	static propTypes = {
		type: PropTypes.string,
		repIndex: PropTypes.number.isRequired,
		label: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
		onKeyPress: PropTypes.func,
		nameSuffix: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
    highlightErrors: PropTypes.bool,
	};

	static defaultProps = {
		type: "text",
	};

	render()
	{
		const {label, repIndex, type, nameSuffix, value, onKeyPress, highlightErrors} = this.props;

		return (
			<TextField
				className={"Cob-view2-CompanyReps-TextField"}
				type={type}
				name={`company_representative_${nameSuffix}_${repIndex}`}
				label={label}
				value={value}
				validation={this._validation}
				onChange={this._onChange}
				onKeyPress={onKeyPress}
        highlightErrors={highlightErrors}
			/>
		);
	}

	// Internal methods
	_onChange = ({target}) =>
	{
		const {nameSuffix, onChange} = this.props;
		onChange(nameSuffix, target.value);
	}

	_validation = (value) =>
	{
		if (this.props.nameSuffix === "sec_num")
		{
			return Validator.validateSsn(value);
		}
		else if (this.props.type === "number")
		{
			return value.length > 0 && !isNaN(value);
		}
		else if (this.props.type === "email")
		{
			return Validator.validateEmail(value);
		}

		return value.length > 0;
	}
}