import React from "react";

// HELPERS
import api from "@helpers/api";
import i18n from "@helpers/i18n";
import { getCookie } from "@helpers/cookies";
import {formatSSN} from "@helpers/utils";

// COMPONENTS
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import ContentBox from "@components/ContentBox/ContentBox";

import KycTable from "./KycTable";


/**
 * KYCs filled out or about to happen in the near future
 */
export default class DashboardKycs extends React.PureComponent
{
	state = _getInitialState.call(this);

	async componentDidMount()
	{
		this._isMounted = true;

    try {
      const userCaseList = await _getKycCaseList.call(this);
      if (userCaseList) {
        this.setState({
          isLoading: false,
          userCaseList: userCaseList.batch,
          totalCases: parseInt(userCaseList.total)
        });
      }
    }
    catch (error) {
      this.setState({
        error: true,
        isLoading: false
      });
      console.error(error);
    }
	}

	componentWillUnmount()
	{
		this._isMounted = false;
	}

  async componentDidUpdate(prevProps, prevState)
	{
    if (this.props.caseFilter.kyc !== prevProps.caseFilter.kyc) {
      this.setState({
        isLoadingCases: true
      });
      const userCaseList = await _getKycCaseList.call(this);
      this.setState({
        isLoadingCases: false,
        userCaseList: userCaseList.batch,
        totalCases: parseInt(userCaseList.total)
      });
    }
	}

	render()
	{
		const {props, state} = this;
		const {user, advisor, partner, showHeader} = props;

		return (
			<>
				<ContentBox
         style={
          props.type === "customerModal" ? 
            {
              minHeight: "66vh",
              margin: 0,
              padding: 0,
              overflow: "auto",
              boxShadow: "none"
            }
          :
            {
              width: "98.5%",
              margin: 0,
              marginBottom: "22.5px"
            }
          }
        >
          {showHeader && (
            <h2>
              {i18n("dashboard_selector", "kyc")}
            </h2>
          )}
					{state.isLoading && (
						<div style={{padding: "91px 0"}}>
							<ActivityIndicator color="#4cb7d5" busy/>
						</div>
					)}
          {state.error && (
						<div style={{padding: "91px 0"}}>Ett fel uppstod när data laddades, kontakta supporten.
						</div>
					)}
					{!state.error && !state.isLoading && (
            <>
              <div 
                className="dashboard-cases"             
                style={
                  props.type === "customerModal" ? 
                    { 
                      minHeight: "unset"
                    }
                  :
                    {}
                }
              >
                <KycTable
                  user={user}
                  partner={partner}
                  advisor={advisor}
                  data={state.userCaseList}
                  totalCases={state.totalCases}
                  isLoadingCases={state.isLoadingCases || false}
                  currentAction={state.currentAction}
                  modalWidget={props.type === "customerModal" ? true : false}
                  onAction={this._onRowAction}
                  nextStepOptions={props.nextStepOptions}
                  caseFilter={props.caseFilter}
                  caseFilterContext={props.caseFilterContext}
                  setCaseFilter={props.setCaseFilter}
                  setCustomerState={props.setCustomerState}
                  getCases={_getKycCaseList.bind(this)}
                />
                {state.isDisabled && (
                  <div className="dashboard-cases-disabled">
                    <ActivityIndicator color="#4cb7d5" busy/>
                  </div>
                )}
              </div>
            </>
					)}
				</ContentBox>
			</>
		);
	}

	_onRowAction = async (action, clientId) =>
	{
    this.setState({
      currentAction: action
    });

		if (action === "download")
		{
			let {caseId} = await api("/case/kyc-status", {
				ssn: formatSSN(clientId, {withDash: false}),
				wl: this.props.partner._name
			});

			const rawDataInBase64 = await api("/case/downloadReceipt", {
				caseId
			}, {}, true);

			if (rawDataInBase64 !== "")
			{
				console.log("Downloading case data...");
				// It is necessary to create a new blob object with mime-type explicitly set
				// otherwise only Chrome works like it should
				//blob = atob(blob);

				// For other browsers:
				// Create a link pointing to the ObjectURL containing the blob.
				const link = document.createElement('a');
				link.href = "data:application/pdf;base64," + rawDataInBase64;
				link.download = caseId + ".pdf";
				link.click();
			}
			else
			{
				console.log("Could not download case data");
			}      

      this.setState({
        currentAction: 'none',
			});
		}
	};
}

function _getInitialState()
{
	return {
		tabSelected: "attention",
		isLoading: true,
    isLoadingCases: false,
    currentAction: 'none',
		isDisabled: false,
		isMounted: false,
		kycCaseList: []
	};
}

function _getKycCaseList(page, searchString, order, orderBy)
{
	const ssn = this.props.user.ssn;

  // check if we have any options saved..
  const cobOptions = JSON.parse(getCookie("cobOptions")) || null;
  // .. and set rowsPerPage
  const rowsPerPage= cobOptions?.rowsPerPage["kyc"] || 10;

	return new Promise(async (resolve, reject) =>
	{
		try
		{
			const cases = await api("/kyc/list", {
        ssn: ssn,
        customerSsn:
          this.props?.client?.id ? // if a client was passed as a prop (customer modal)
            formatSSN(this.props.client.id, {withDash: true}) // Note the dash true on this one!
          : this.props?.client?.id === "" ? // handle client was passed as a prop but with no ssn set
              ""
            : null, // no ssn passed means it's a main dashboard widget not filtered by customer
        filter: this.props.caseFilter.kyc,
        page: page || 0,
        order: order || "asc",
        orderBy: orderBy || "kyc_date",
        searchString: searchString || "",
        limit: rowsPerPage === -1 ? 100 : rowsPerPage
      });
			if (this._isMounted)
			{
				resolve(cases);
			}
		}
		catch (e)
		{
			reject(e);
		}
	});
}