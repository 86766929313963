// HELPERS
import Validator from "@classes/Validator";
import {validateEmail} from "@helpers/utils";

// OTHER
import {mergeViewStateForFulfillment} from "../../Cob.helpers";


export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;
	const {isCompany, companyReps, companyRepsActiveAmount} = customerState;

	let atLeastOneSignatorySelected;
	if (!isCompany)
	{
		atLeastOneSignatorySelected = true;
	}
	else
	{
		atLeastOneSignatorySelected = _companyRepIsSignatory(companyReps[0]) ||
			_companyRepIsSignatory(companyReps[1]) ||
			_companyRepIsSignatory(companyReps[2]) ||
			_companyRepIsSignatory(companyReps[3]) ||
			_companyRepIsSignatory(companyReps[4]) ||
			_companyRepIsSignatory(companyReps[5]);
	}

	if (customerState.phoneNumber.length > 0
		&& customerState.phoneNumber !== "-"
		&& !Validator.validatePhoneNum(customerState.phoneNumber))
	{
		return false;
	}

	/**
	 * Om man fyllt i en LEI-kod så måste den vara korrekt
	 */
	if (isCompany)
	{
		if (!Validator.validateLeiCode(customerState.leiCode))
		{
			return false;
		}
	}

	/**
	 * Om rådgivningen gäller företag företag så måste alla företagsrepresentanter validera
	 */
	if (isCompany)
	{
		if (!_validateCompanyRep(companyReps[0]) ||
			!_validateCompanyRep(companyReps[1], companyRepsActiveAmount < 2) ||
			!_validateCompanyRep(companyReps[2], companyRepsActiveAmount < 3) ||
			!_validateCompanyRep(companyReps[3], companyRepsActiveAmount < 4) ||
			!_validateCompanyRep(companyReps[4], companyRepsActiveAmount < 5) ||
			!_validateCompanyRep(companyReps[5], companyRepsActiveAmount < 6))
		{
			return false;
		}
	}

	return !!(
		atLeastOneSignatorySelected &&
		customerState.ssnOrg.length > 0
		&& customerState.firstName.length > 0
		&& customerState.street.length > 0
		&& customerState.zipCode.length > 0
		&& customerState.city.length > 0
		&& validateEmail(customerState.email)
		&& customerState.isUsPerson === "no"
	);
}

function _companyRepIsSignatory(rep)
{
	return rep.types.length > 0 && rep.types.includes("signatory");
}

function _validateCompanyRep(rep, fallbackValue = false)
{

	if (fallbackValue)
	{
		return true;
	}

	/**
	 * For Swedish citizens we validate the SSN. Not selected citizenship will default to SE.
	 */
	if (rep.citizenship.length === 0 || rep.citizenship === "SE")
	{
		if (!Validator.validateSsn(rep.ssn))
		{
			return false;
		}
	}

	return !!(rep.types.length > 0 && (
		rep.firstName.length > 0
		&& rep.lastName.length > 0
		&& rep.email.length > 0
		&& rep.ssn.length > 0
	));
}