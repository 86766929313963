import { useState, useRef } from "react";

// CLASSES
import Settings from "@classes/Settings";

// HELPERS
import {createB64Context, stringifyQueryParams} from "@helpers/utils";

const useSearchBar = props => {  
  const { advisor, partner, customers, cobForm, nextStepOptions, setCustomerState } = props;

  const [customerList, setCustomerList] = useState([]);
  const searchInput = useRef(null);

  const requestSearch = searched => {
    const regex = /^\d{6}/;
    const isId = regex.test(searched);
    let searchString = isId ? searched : searched.toLowerCase();
    
    if (isId) {
      // if searchString doesn't start with 16 or 19
      if (searchString.substring(0, 2) !== "16" && searchString.substring(0, 2) !== "19"){
        // add prefix to searchString if missing
        const isCompany = (searchString[2] >= 2);
        searchString = isCompany ? "16" + searchString : "19" + searchString;
      }

      // for id lengths of 8 chars and above if theres no dash after the 8th char add it
      if (searchString.length >= 8 && searchString.charAt(8) !== "-") {
        searchString = searchString.slice(0, 8) + "-" + searchString.slice(8);
      }
    }

    setCustomerList(
      searchString.length > 2 ? customers.filter(
        customer =>
          (customer.first_name + " " + customer.last_name)
            .toLowerCase()
            .includes(searchString) ||
          customer.id?.toLowerCase().includes(searchString) ||
          customer.name?.toLowerCase().includes(searchString)
      ) : []
    );
  };

  const handleOptionSelection = async (customer, optionIndex) => {
    const ssnOrg = (customer.id).replace("-", "");
    if (optionIndex === 0)
		{
      const newStateProps = {
        isCompany: false,
        ssnOrg,
        needsNewDocumentation: false,
        kycCaseId: null,
        kycInfoLoading: true,
        overrideNewDocumentation: false,
        casesLoading: false,
        cases: null,
        selectedCaseId: null,
        selectedCaseIndex: -1,
        isNewCase: optionIndex === 0,
        standaloneApplicationsChecked: []
      };

			newStateProps.selectedCaseId = null;
			newStateProps.selectedCaseIndex = -1;      

      return setCustomerState(newStateProps, () =>
      {
        cobForm.current._onSubmit();
        // handleClose();
      });
		}
		else if (optionIndex > 1) // Standalone applications
		{
			const applicationIndex = optionIndex - 2;
			const applications = partner.standaloneApplications;
			const app = applications[applicationIndex];

      if (app === null)
      {
        console.error("Invalid app configuration", app.key);
        return;
      }

      // Create a checksum that the cob odin client will check to make sure that the request
      // originated in a correct way.
      const encodedContext = createB64Context("fairclient", advisor, ssnOrg, null);
      return window.location.href = app.url + stringifyQueryParams({
        context: encodedContext,
        token: Settings.get("AUTH_TOKEN")
      });
		}
  };

  return {
    searchInput,
    requestSearch,
    customerList,
    nextStepOptions,
    handleOptionSelection
  };
};


export default useSearchBar;
