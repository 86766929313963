import React from "react";

import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import fulfillment from "./Recommendation.fulfillment";
import TextView from "../../../../../components/TextView/TextView";
import OptionsList from "../../../components/OptionsList/OptionsList";
import Select from "../../../../../components/Select/Select";
import NumberField from "../../../components/NumberField/NumberField";
import Button from "../../../../../components/Button/Button";
import imgRemove from "@assets/img/ic_remove.svg";
import {localeNumber} from "@helpers/utils";
import ActivityIndicator from "../../../../../components/ActivityIndicator/ActivityIndicator";
import api from "../../../../../helpers/api";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import TextField from "../../../../../components/TextField/TextField";
import i18n from "@helpers/i18n";

export default class Recommendation extends React.PureComponent
{
	static ALLOWED_PRODUCTS_COUNT = 20;
	state = _getInitialState.call(this);
	fields = Recommendation.getFields();

	decisions = [
		{label: "Följer givet råd enligt ovan", value: "f"},
		{label: "Önskar avvakta, rådgivaren skall återkomma", value: "wa"},
		{label: "Önskar avvakta, kunden återkommer", value: "wc"},
		{label: "Följer ej givet råd", value: "nf"},
		{label: "Annat", value: "o"}
	];

	productOptions = null;

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	async componentDidMount()
	{
		if (this.productOptions == null || this.productOptions.length === 0)
		{
			console.log("Fetching available products...");
			this.productOptions = await _fetchProducts.call(this);
		}

		// Signal to parent whether this component has been fulfilled or not
		this.setState({isLoading: false}, () =>
		{
			this.props.onUpdated(this.state.isFulfilled);
		});
	}

	setCustomerState(customerState)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.onUpdated(this.state.isFulfilled);
		});
	}

	render()
	{
		const {state, props} = this;
		const {customer} = props;
		const {customerState} = state;

		if (state.isLoading)
		{
			return <ActivityIndicator color="#4cb7d5" busy/>;
		}

		let totalAmount = Recommendation.getTotalInvested(this.state.customerState.productsAdded);
		let totalSellAmount = Recommendation.getTotalSellAmount(this.state.customerState.productsAdded);
		let instrumentFeeWeighted = "0.00%";
		let instrumentFeeAmount = "0 kr";
		let instrumentFeeAmountRaw = 0;
		let transactionFeesTotal = 0;
		let totalFinancing = 0;
		let financingRequirements = 0;
		for (let i = 0; i < this.state.customerState.productsAdded.length; i++)
		{
			const p = this.state.customerState.productsAdded[i];
			instrumentFeeAmountRaw += p.fee_yearly_raw;
			transactionFeesTotal += p.transaction_fee_amount;

			const prodAmount = parseInt(p.amount.replaceAll(" ", "").replaceAll("kr", ""), 10);
			if (p.type === 'Sälj')
			{
				totalFinancing += isNaN(prodAmount) ? 0 : prodAmount;
			}
			else if (p.type === 'Köp')
			{
				financingRequirements += isNaN(prodAmount) ? 0 : prodAmount;
			}
		}
		let transactionFeesAmounts = localeNumber(transactionFeesTotal, 0) + " kr";

		if (instrumentFeeAmountRaw > 0 && totalAmount > 0)
		{
			instrumentFeeAmount = localeNumber(instrumentFeeAmountRaw, 0) + " kr";
			instrumentFeeWeighted = ((instrumentFeeAmountRaw / totalAmount) * 100).toFixed(2) + "%";
		}

		const advisorFee = parseFloat(customerState.recommendation_costs_advisor_fee.replaceAll(",", ".").replaceAll(" ", ""));
		const isFixedAdvisorFee = customerState.recommendation_costs_advisor_fee_is_fixed === "yes";
		let advisorFeeForDisplay = isFixedAdvisorFee ? "-" : (isNaN(advisorFee) ? "0.00%" : advisorFee.toFixed(2) + "%");

		let advisorFeeAmount = "0 kr";
		let totalFeeAmountRaw = instrumentFeeAmountRaw;

		if (isFixedAdvisorFee)
		{
			totalFeeAmountRaw += (isNaN(advisorFee) ? 0 : advisorFee);
			advisorFeeAmount = localeNumber(advisorFee, 0) + " kr";
			advisorFeeForDisplay = isNaN(advisorFee) ? "0.00%" : totalAmount > 0 ? (((advisorFee / totalAmount) * 100).toFixed(2) + "%") : "0.00%";
		}
		else if (customerState.recommendation_costs_advisor_fee.length > 0 && totalAmount > 0)
		{
			let advisorFeeAmountRaw = Math.round(totalAmount * (advisorFee / 100));
			totalFeeAmountRaw += advisorFeeAmountRaw;
			advisorFeeAmount = localeNumber(advisorFeeAmountRaw, 0) + " kr";
		}
		let totalFeeAmount = localeNumber(totalFeeAmountRaw, 0) + " kr";
		let totalFeeWeighted = totalAmount > 0 ? (totalFeeAmountRaw <= 0 ? "0.00%" : ((totalFeeAmountRaw / totalAmount) * 100).toFixed(2) + "%") : "0.00%";

		for (let i = 0; i < this.state.customerState.financing.length; i++)
		{
			const p = this.state.customerState.financing[i];
			let amount = parseInt(p.amount.replaceAll(" ", "").replaceAll("kr", ""), 10);
			totalFinancing += isNaN(amount) ? 0 : amount;
		}

		const today = new Date();
		let dayDate = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
		let monthDate = (today.getMonth() + 1) < 10 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
		const adviseDate = today.getFullYear() + '-' + monthDate + '-' + dayDate;

		const usingFixedAdvisorFee = this.state.customerState.recommendation_costs_advisor_fee_is_fixed === "yes";

		let productRowBuyIndex = -1;
		let productRowSellIndex = -1;

		return (
			<div style={{width: "100%"}}>
				<DefaultFields fields={this.fields}/>

				<div className="Cob-view2-fields">
					<h3>Rekommendation</h3>

					<input type={"hidden"} name={"customer_name"}
						   value={customer.isCompany ? customer.getData("customer_firstname") : (customer.getData("customer_firstname") + " " + customer.getData("customer_lastname"))}/>
					<input type={"hidden"} name={"date"} value={adviseDate}/>
					<input type={"hidden"} name={"advisor_name"}
						   value={this.props.user.firstName + " " + this.props.user.lastName}/>

					<h4>
						Rådgivarens förslag till förändring*
					</h4>
					<TextView
						name="recommendation_advisor_suggestion"
						label={`Fritext (${1000 - customerState.recommendation_advisor_suggestion.length} tecken kvar)`}
						value={customerState.recommendation_advisor_suggestion}
						maxLength={1000}
						validation={value => value.length > 0}
						resize="vertical"
						onChange={({target}) => this.setCustomerState({recommendation_advisor_suggestion: target.value})}
						fullWidth
						minHeight={120}
						required={true}
					/>

					<h4>
						Motivering till givet råd utifrån kundens önskemål och behov*
					</h4>
					<TextView
						name="recommendation_motivation_needs"
						label={`Fritext (${2000 - customerState.recommendation_motivation_needs.length} tecken kvar)`}
						value={customerState.recommendation_motivation_needs}
						maxLength={2000}
						validation={value => value.length > 0}
						resize="vertical"
						onChange={({target}) => this.setCustomerState({recommendation_motivation_needs: target.value})}
						fullWidth
						minHeight={200}
						required={true}
					/>

					<h4>
						Motivering till givet råd utifrån kundens ekonomiska situation*
					</h4>
					<TextView
						name="recommendation_motivation_finances"
						label={`Fritext (${2000 - customerState.recommendation_motivation_finances.length} tecken kvar)`}
						value={customerState.recommendation_motivation_finances}
						maxLength={2000}
						validation={value => value.length > 0}
						resize="vertical"
						onChange={({target}) => this.setCustomerState({recommendation_motivation_finances: target.value})}
						fullWidth
						minHeight={200}
						required={true}
					/>

					<OptionsList
						style={{marginTop: "10px"}}
						type="radio"
						name="recommendation_customer_has_buffer"
						description={"Anser rådgivaren att kunden har tillräckligt buffert även efter det att kunden följt rådet?*"}
						options={[
							{
								label: "Nej",
								value: "no",
								checked: customerState.recommendation_customer_has_buffer === "no"
							},
							{
								label: "Ja",
								value: "yes",
								checked: customerState.recommendation_customer_has_buffer === "yes"
							}
						]}
						onChange={(checked, recommendation_customer_has_buffer) => this.setCustomerState({recommendation_customer_has_buffer})}
					/>

					{customerState.recommendation_customer_has_buffer === "no" && (
						<div style={{
							width: "100%",
							padding: "15px",
							background: "#fff6f6",
							borderRadius: "6px",
							display: "block"
						}}>
							<strong style={{marginBottom: "15px", display: "block"}}>Motivera*:</strong>
							<TextView
								name="recommendation_customer_has_buffer_motivation"
								label={`Fritext (${1000 - customerState.recommendation_customer_has_buffer_motivation.length} tecken kvar)`}
								value={customerState.recommendation_customer_has_buffer_motivation}
								maxLength={1000}
								validation={value => value.length > 0}
								resize="vertical"
								onChange={({target}) => this.setCustomerState({recommendation_customer_has_buffer_motivation: target.value})}
								fullWidth
								minHeight={120}
								required={true}
							/>
						</div>
					)}

					<OptionsList
						style={{marginTop: "10px"}}
						type="radio"
						name="recommendation_customer_suits_risk"
						description={"Anser rådgivaren att rekommendationen är lämplig med hänsyn till kundens riskaptit, riskkapacitet samt placeringshorisont?*"}
						options={[
							{
								label: "Nej",
								value: "no",
								checked: customerState.recommendation_customer_suits_risk === "no"
							},
							{
								label: "Ja",
								value: "yes",
								checked: customerState.recommendation_customer_suits_risk === "yes"
							}
						]}
						onChange={(checked, recommendation_customer_suits_risk) => this.setCustomerState({recommendation_customer_suits_risk})}
					/>

					{customerState.recommendation_customer_suits_risk === "no" && (
						<div style={{
							width: "100%",
							padding: "15px",
							background: "#fff6f6",
							borderRadius: "6px",
							display: "block"
						}}>
							<strong style={{marginBottom: "15px", display: "block"}}>Motivera*:</strong>
							<TextView
								name="recommendation_customer_suits_risk_motivation"
								label={`Fritext (${1000 - customerState.recommendation_customer_suits_risk_motivation.length} tecken kvar)`}
								value={customerState.recommendation_customer_suits_risk_motivation}
								maxLength={1000}
								validation={value => value.length > 0}
								resize="vertical"
								onChange={({target}) => this.setCustomerState({recommendation_customer_suits_risk_motivation: target.value})}
								fullWidth
								minHeight={120}
								required={true}
							/>
						</div>
					)}

					<h4 style={{marginTop: "20px"}}>
						Lämplighet utifrån kundens kunskap och erfarenhet*
					</h4>

					<OptionsList
						type="radio"
						name="recommendation_customer_suitability"
						style={{maxWidth: "none"}}
						orientation="vertical"
						options={
							[
								{
									label: "Rekommendationen är lämplig med bakgrunden av kundens tidigare kunskap och erfarenhet",
									value: "Rekommendationen är lämplig med bakgrunden av kundens tidigare kunskap och erfarenhet",
									checked: customerState.recommendation_customer_suitability === "Rekommendationen är lämplig med bakgrunden av kundens tidigare kunskap och erfarenhet"
								},
								{
									label: "Rekommendationen är lämplig med bakgrunden av kundens tidigare kunskap och erfarenhet, efter det att kunden har utbildats i mötet",
									value: "Rekommendationen är lämplig med bakgrunden av kundens tidigare kunskap och erfarenhet, efter det att kunden har utbildats i mötet",
									checked: customerState.recommendation_customer_suitability === "Rekommendationen är lämplig med bakgrunden av kundens tidigare kunskap och erfarenhet, efter det att kunden har utbildats i mötet"
								},
								{
									label: "Rekommendationen är inte lämplig utifrån kundens tidigare kunskap och erfarenhet",
									value: "Rekommendationen är inte lämplig utifrån kundens tidigare kunskap och erfarenhet",
									checked: customerState.recommendation_customer_suitability === "Rekommendationen är inte lämplig utifrån kundens tidigare kunskap och erfarenhet"
								},
							]}
						onChange={(checked, recommendation_customer_suitability) =>
						{
							this.setCustomerState({recommendation_customer_suitability});
						}}
					/>

					<h4>
						Utbildning av kunden under mötet
					</h4>
					<TextView
						name="recommendation_customer_information"
						label={`Fritext (${1000 - customerState.recommendation_customer_information.length} tecken kvar)`}
						value={customerState.recommendation_customer_information}
						maxLength={1000}
						validation={value => value.length > 0}
						resize="vertical"
						onChange={({target}) => this.setCustomerState({recommendation_customer_information: target.value})}
						fullWidth
						minHeight={120}
					/>

					<div className="h-line spacing" style={{marginTop: "25px"}}/>

					<h4 style={{paddingBottom: "10px"}}>
						Finansiering av placeringsrekommendation
					</h4>

					{state.customerState.financing.map((financingSource, i) => (
						<div key={i} style={{flexDirection: "row", width: "100%"}}>
							<div style={{width: "420px", marginRight: "10px"}}>
								<TextField
									name={"recommendation_financing_description_" + i}
									label="Beskrivning"
									value={state.customerState.financing[i].description}
									validation={value => value.length > 0}
									onChange={({target}) => this._onFinancingChanged("description", i, target.value)}
								/>
							</div>
							<div style={{width: "160px"}}>
								<NumberField
									name={"recommendation_financing_amount_" + i}
									label={i18n("general", "provide_amount")}
									unit={" kr"}
									showSeparators={true}
									value={state.customerState.financing[i].amount}
									onChange={({target}) => this._onFinancingChanged("amount", i, target.value)}
								/>
							</div>
							<div style={{flex: "1", alignItems: "flex-end"}} className={"remove"}>
								{i > 0 && (
									<img alt="Remove" src={imgRemove} onClick={() => this._onFinancingRemoved(i)}/>
								)}
							</div>
						</div>
					))}

					{state.customerState.financing.length < 7 && (
						<Button
							size="small"
							label="Lägg till finansieringskälla"
							appearance={"primary"}
							onClick={this._addFinancingSource}
							filled
						/>
					)}

					<h4 style={{marginTop: "25px"}}>
						Rekommenderade köp och sälj av värdepapper
					</h4>

					{state.customerState.productsAdded.map((product, i) =>
					{
						if (product.type === "Köp")
						{
							productRowBuyIndex++;
						}
						else if (product.type === "Sälj")
						{
							productRowSellIndex++;
						}

						return (
							<ProductRow key={"prod" + i}
										index={i}
										buyIndex={productRowBuyIndex}
										sellIndex={productRowSellIndex}
										type={product.type}
										fee={product.fee}
										feeYearly={product.fee_yearly}
										amount={product.amount}
										share={product.share}
										family={product.family}
										name={product.name}
										isin={product.isin}
										ticker={product.ticker}
										transactionFee={product.transaction_fee}
										transactionFeeMin={product.transaction_fee_minimum}
										onRemove={this._removeProduct}
										onSelectType={this._onSelectType}
										onSelectProduct={this._onSelectProduct}
										onProductChanged={this._onProductChanged}
										productOptions={this.productOptions}
							/>
						);
					})}

					{state.customerState.productsAdded.length < Recommendation.ALLOWED_PRODUCTS_COUNT && (
						<Button
							size="small"
							label="Lägg till köp / sälj av värdepapper"
							appearance={"primary"}
							onClick={this._addProduct}
							filled
						/>
					)}

					{financingRequirements > totalFinancing && (
						<div>
							<p style={{color: "red"}}>
								Underskott i finansiering av
								köp: {localeNumber((totalFinancing - financingRequirements), 0)} kr.
							</p>
						</div>
					)}

					<h4 style={{marginTop: "25px"}}>
						Kommentar: rekommenderade köp och sälj av värdepapper
					</h4>
					<TextView
						name="recommendation_comment_trades"
						label={`Fritext (${1000 - customerState.recommendation_comment_trades.length} tecken kvar)`}
						value={customerState.recommendation_comment_trades}
						maxLength={1000}
						validation={value => value.length > 0}
						resize="vertical"
						onChange={({target}) => this.setCustomerState({recommendation_comment_trades: target.value})}
						fullWidth
						minHeight={120}
					/>

					<div className="h-line spacing" style={{marginTop: "25px"}}/>

					<h3>Kostnader och avgifter</h3>

					<h4 style={{marginTop: "20px"}}>
						Ange ersättning för rådgivning/portföljförvaltning (inkl. depåavgift)*
					</h4>
					<div style={{width: "200px"}}>
						<NumberField
							name="recommendation_costs_advisor_fee"
							label="Ange"
							unit={usingFixedAdvisorFee ? " kr" : "%"}
							showSeparators={usingFixedAdvisorFee}
							value={customerState.recommendation_costs_advisor_fee}
							onChange={({target}) => this.setCustomerState({recommendation_costs_advisor_fee: target.value})}
						/>
					</div>
					<Checkbox
						label="Ange ersättning för rådgivare i kr"
						name={"recommendation_costs_advisor_fee_is_fixed"}
						checked={usingFixedAdvisorFee}
						value={"yes"}
						onChange={(checked) =>
						{
							this.setCustomerState({recommendation_costs_advisor_fee_is_fixed: checked ? "yes" : "no"});
						}}
					/>

					<h4 style={{marginTop: "20px"}}>
						Summering
					</h4>

					<div
						style={{background: "#F7F7F7", borderRadius: "6px", padding: "20px", alignItems: "flex-start"}}>
						<div style={{width: "100%", maxWidth: "640px", flexDirection: "row"}}>
							<div style={{width: "50%", alignItems: "flex-start"}}>
								<strong style={{display: "block", paddingBottom: "7px", color: "#4cb7d5"}}>
									Investerat belopp
								</strong>
								{localeNumber(totalAmount, 0)} kr
								<input type={"hidden"} name={"recommendation_product_sum_total"}
									   value={localeNumber(totalAmount, 0) + " kr"}/>
								<input type={"hidden"} name={"recommendation_product_buy_sum_total"}
									   value={localeNumber(totalAmount, 0) + " kr"}/>
								<input type={"hidden"} name={"recommendation_product_buy_sum_amount"}
									   value={localeNumber(totalAmount, 0) + " kr"}/>
								<input type={"hidden"} name={"recommendation_product_sell_sum_total"}
									   value={localeNumber(totalSellAmount, 0) + " kr"}/>
								<input type={"hidden"} name={"recommendation_product_sell_sum_amount"}
									   value={localeNumber(totalSellAmount, 0) + " kr"}/>
							</div>

							<div style={{width: "50%", alignItems: "flex-end"}}>
								<strong style={{
									display: "block",
									paddingBottom: "7px",
									color: "#4cb7d5"
								}}>Transaktionskostnader</strong>
								{transactionFeesAmounts}
								<input type={"hidden"} name={"recommendation_transaction_fees"}
									   value={transactionFeesAmounts}/>
							</div>
						</div>

						<strong style={{display: "block", paddingTop: "16px", color: "#4cb7d5"}}>Avgifter</strong>

						<div className={"citroneer-costs-summary"}>
							<div className={"label"}>&nbsp;</div>
							<div className={"value header"}>Avgift (%) per år</div>
							<div className={"value header"}>Avgift (kr) per år</div>

							<div className={"label"}>Underliggande instrument</div>
							<div className={"value"}>{instrumentFeeWeighted}</div>
							<div className={"value"}>{instrumentFeeAmount}</div>

							<div className={"label"}>Ersättning rådgivning/portföljförvaltning</div>
							<div className={"value"}>{advisorFeeForDisplay}</div>
							<div className={"value"}>{advisorFeeAmount}</div>

							<div className={"label header final"}>Summa</div>
							<div className={"value header final"}>{totalFeeWeighted}</div>
							<div className={"value header final"}>{totalFeeAmount}</div>

							<input type={"hidden"} name={"recommendation_fees_pct"} value={instrumentFeeWeighted}/>
							<input type={"hidden"} name={"recommendation_fees_total"} value={instrumentFeeAmount}/>
							<input type={"hidden"} name={"recommendation_product_buy_sum_fee"} value={instrumentFeeWeighted}/>
							<input type={"hidden"} name={"recommendation_product_buy_sum_fee_yearly"} value={instrumentFeeAmount}/>

							<input type={"hidden"} name={"recommendation_costs_advisor_fee"}
								   value={advisorFeeForDisplay}/>
							<input type={"hidden"} name={"recommendation_costs_advisor_fee_total"}
								   value={advisorFeeAmount}/>

							<input type={"hidden"} name={"recommendation_costs_pct"} value={totalFeeWeighted}/>
							<input type={"hidden"} name={"recommendation_costs_total"} value={totalFeeAmount}/>
						</div>
					</div>

					<h4 style={{marginTop: "20px"}}>
						Vilka kostnader finns förknippade med givet råd?
					</h4>
					<TextView
						name="recommendation_costs_associated"
						label={`Fritext (${1000 - customerState.recommendation_costs_associated.length} tecken kvar)`}
						value={customerState.recommendation_costs_associated}
						maxLength={1000}
						validation={value => value.length > 0}
						resize="vertical"
						onChange={({target}) => this.setCustomerState({recommendation_costs_associated: target.value})}
						fullWidth
						minHeight={120}
					/>

					<div className="h-line spacing" style={{marginTop: "25px"}}/>

					<h3>Beslut</h3>

					<h4 style={{marginTop: "20px"}}>Kundens beslut*</h4>
					<Select
						name="recommendation_decision_taken"
						label="Ange"
						selectedIndex={this.decisions.map(c => c.value).indexOf(customerState.recommendation_decision_taken)}
						options={this.decisions}
						onChange={(option) => this.setCustomerState({recommendation_decision_taken: option.value})}
					/>

					{customerState.recommendation_decision_taken === "o" && (
						<div style={{
							width: "100%",
							padding: "15px",
							background: "#fff6f6",
							borderRadius: "6px",
							display: "block"
						}}>
							<strong style={{marginBottom: "15px", display: "block"}}>Ange annat beslut*:</strong>
							<TextView
								name="recommendation_decision_taken_other"
								label={`Fritext (${1000 - customerState.recommendation_decision_taken_other.length} tecken kvar)`}
								value={customerState.recommendation_decision_taken_other}
								maxLength={1000}
								validation={value => value.length > 0}
								resize="vertical"
								onChange={({target}) => this.setCustomerState({recommendation_decision_taken_other: target.value})}
								fullWidth
								minHeight={120}
								required={true}
							/>
						</div>
					)}

					<h4 style={{marginTop: "20px"}}>
						Kommentar till beslut samt eventuell avrådan
					</h4>
					<TextView
						name="recommendation_decision_comment"
						label={`Fritext (${1000 - customerState.recommendation_decision_comment.length} tecken kvar)`}
						value={customerState.recommendation_decision_comment}
						maxLength={1000}
						validation={value => value.length > 0}
						resize="vertical"
						onChange={({target}) => this.setCustomerState({recommendation_decision_comment: target.value})}
						fullWidth
						minHeight={120}
					/>
				</div>
			</div>
		);
	}

	_addFinancingSource = () =>
	{
		const financingSources = [...this.state.customerState.financing];
		financingSources.push(
			{
				description: "",
				amount: ""
			}
		);

		this.setCustomerState({financing: financingSources});
	}

	_addProduct = () =>
	{
		const products = [...this.state.customerState.productsAdded];
		products.push(
			{
				type: "",
				name: "",
				isin: "",
				ticker: "",
				amount: "",
				fee: "",
				fee_yearly: "-",
				fee_yearly_raw: 0,
				transaction_fee: 0,
				transaction_fee_amount: 0,
				share: "-",
				family: ""
			}
		);

		this.setCustomerState({productsAdded: products});
	}

	_removeProduct = (index) =>
	{
		if (this.state.customerState.productsAdded.length <= 1)
		{
			// At least one product needs to be included
			return;
		}

		const products = [...this.state.customerState.productsAdded];
		products.splice(index, 1);
		Recommendation.recalculateProductValues(products);
		this.setCustomerState({productsAdded: products});
	}

	_onSelectProduct = (index, selected) =>
	{
		const products = [...this.state.customerState.productsAdded];
		products[index].name = selected.Name;
		products[index].isin = selected.Isin;
		products[index].ticker = selected.Ticker;
		products[index].transaction_fee = selected.TransactionFee / 100;
		products[index].transaction_fee_minimum = selected.TransactionFeeMinimum;
		products[index].fee = selected.Fee;
		products[index].family = selected.Type;
		Recommendation.recalculateProductValues(products);
		this.setCustomerState({productsAdded: products});
	}

	_onSelectType = (index, selected) =>
	{
		const products = [...this.state.customerState.productsAdded];
		products[index].type = selected.value;
		Recommendation.recalculateProductValues(products);
		this.setCustomerState({productsAdded: products});
	}

	_onProductChanged = (field, index, value) =>
	{
		const products = [...this.state.customerState.productsAdded];
		if (field === "amount")
		{
			products[index].amount = value;
		}
		Recommendation.recalculateProductValues(products);
		this.setCustomerState({productsAdded: products});
	}

	_onFinancingChanged = (field, index, value) =>
	{
		const financing = [...this.state.customerState.financing];
		if (field === "amount")
		{
			financing[index].amount = value;
		}
		else if (field === "description")
		{
			financing[index].description = value;
		}
		this.setCustomerState({financing: financing});
	}

	_onFinancingRemoved = (index) =>
	{
		let financing = [...this.state.customerState.financing];
		financing.splice(index, 1);
		this.setCustomerState({financing: financing});
	}

	static getTotalInvested = (products) =>
	{
		let totalAmount = 0;
		for (let i = 0; i < products.length; i++)
		{
			if (products[i].type !== 'Köp')
			{
				continue;
			}

			let amount = products[i].amount;
			if (amount.length <= 0)
			{
				continue;
			}
			totalAmount += parseInt(amount.replaceAll(" ", ""), 10);
		}
		return totalAmount;
	}

	static getTotalSellAmount = (products) =>
	{
		let totalAmount = 0;
		for (let i = 0; i < products.length; i++)
		{
			if (products[i].type !== 'Sälj')
			{
				continue;
			}

			let amount = products[i].amount;
			if (amount.length <= 0)
			{
				continue;
			}
			totalAmount += parseInt(amount.replaceAll(" ", ""), 10);
		}
		return totalAmount;
	}

	static recalculateProductValues = (products) =>
	{
		let totalAmount = Recommendation.getTotalInvested(products);
		for (let i = 0; i < products.length; i++)
		{
			const p = products[i];
			const amountAsInt = parseInt(p.amount.replaceAll(" ", "").replaceAll(",", "."), 10);

			if (p.type === 'Sälj')
			{
				p.share = "-";
				p.fee_yearly = "-";
				p.fee_yearly_raw = 0;
			}
			else
			{
				const feeAsFloat = parseFloat((p.fee + "").replaceAll(",", "."));
				if (feeAsFloat <= 100 && feeAsFloat > 0)
				{
					p.fee_yearly = Math.round((feeAsFloat / 100) * amountAsInt);
					p.fee_yearly_raw = p.fee_yearly;
					p.fee_yearly = isNaN(p.fee_yearly) ? "-" : localeNumber(p.fee_yearly, 0) + " kr";
				}
				else
				{
					p.fee_yearly = "-";
					p.fee_yearly_raw = 0;
				}

				p.share = ((amountAsInt / totalAmount) * 100).toFixed(2);
				p.share = isNaN(p.share) ? "-" : p.share + "%";
			}

			p.transaction_fee_amount = amountAsInt * p.transaction_fee;

			// Enforce minimum transaction fee
			if (p.transaction_fee_amount < parseFloat(p.transaction_fee_minimum))
			{
				p.transaction_fee_amount = parseFloat(p.transaction_fee_minimum);
			}
		}
	};

	static getViewInitialStateForCustomer(customer)
	{
		let initialState = {
			isFulfilled: false,
			isLoading: true,
			customerState: {
				financing: [],
				productsAdded: [],
				recommendation_advisor_suggestion: customer.getData("recommendation_advisor_suggestion"),
				recommendation_motivation_needs: customer.getData("recommendation_motivation_needs"),
				recommendation_motivation_finances: customer.getData("recommendation_motivation_finances"),
				recommendation_customer_information: customer.getData("recommendation_customer_information"),

				recommendation_customer_has_buffer: customer.getData("recommendation_customer_has_buffer"),
				recommendation_customer_has_buffer_motivation: customer.getData("recommendation_customer_has_buffer_motivation"),

				recommendation_customer_suits_risk: customer.getData("recommendation_customer_suits_risk"),
				recommendation_customer_suits_risk_motivation: customer.getData("recommendation_customer_suits_risk_motivation"),

				recommendation_customer_suitability: customer.getData("recommendation_customer_suitability"),

				recommendation_costs_associated: customer.getData("recommendation_costs_associated"),
				recommendation_costs_advisor_fee: customer.getData("recommendation_costs_advisor_fee"),

				recommendation_decision_taken: customer.getData("recommendation_decision_taken"),
				recommendation_decision_taken_other: customer.getData("recommendation_decision_taken_other"),
				recommendation_decision_comment: customer.getData("recommendation_decision_comment"),

				recommendation_comment_trades: customer.getData("recommendation_comment_trades"),
				recommendation_costs_advisor_fee_is_fixed: customer.getData("recommendation_costs_advisor_fee_is_fixed"),
			}
		};

		if (initialState.customerState.recommendation_costs_advisor_fee_is_fixed === "yes")
		{
			initialState.customerState.recommendation_costs_advisor_fee = initialState.customerState.recommendation_costs_advisor_fee.replaceAll(" ", "").replaceAll("kr", "");
		}

		for (let i = 0; i < Recommendation.ALLOWED_PRODUCTS_COUNT; i++)
		{
			const prodIsin = customer.getData("recommendation_product_isin_" + i);
			if (prodIsin.length > 0)
			{
				initialState.customerState.productsAdded.push({
					type: customer.getData("recommendation_product_type_" + i),
					name: customer.getData("recommendation_product_name_" + i),
					isin: prodIsin,
					ticker: customer.getData("recommendation_product_ticker_" + i),
					amount: customer.getData("recommendation_product_amount_" + i),
					fee: customer.getData("recommendation_product_fee_" + i),
					fee_yearly: "-",
					fee_yearly_raw: 0,
					transaction_fee: customer.getData("recommendation_product_transaction_fee_" + i),
					transaction_fee_minimum: customer.getData("recommendation_product_transaction_fee_min_" + i),
					transaction_fee_amount: 0,
					share: "-",
					family: customer.getData("recommendation_product_family_" + i),
				});
			}
		}

		// Add a default product if list is empty
		if (initialState.customerState.productsAdded.length === 0)
		{
			initialState.customerState.productsAdded.push({
				type: "",
				name: "",
				isin: "",
				ticker: "",
				amount: "",
				fee: "",
				fee_yearly: "-",
				fee_yearly_raw: 0,
				transaction_fee: 0,
				transaction_fee_minimum: 0,
				transaction_fee_amount: 0,
				share: "-",
				family: ""
			});
		}

		for (let i = 0; i < 7; i++)
		{
			const desc = customer.getData("recommendation_financing_description_" + i);
			if (desc.length && desc.length > 0)
			{
				initialState.customerState.financing.push({
					description: desc,
					amount: customer.getData("recommendation_financing_amount_" + i)
				});
			}
		}

		// Show one source of financing
		if (initialState.customerState.financing.length === 0)
		{
			initialState.customerState.financing.push({
				description: "",
				amount: ""
			});
		}

		Recommendation.recalculateProductValues(initialState.customerState.productsAdded);
		return initialState;
	}

	static getFields()
	{
		let fields = [
			"recommendation_advisor_suggestion",
			"recommendation_motivation_needs",
			"recommendation_motivation_finances",
			"recommendation_customer_information",

			"recommendation_customer_has_buffer",
			"recommendation_customer_has_buffer_motivation",

			"recommendation_customer_suits_risk",
			"recommendation_customer_suits_risk_motivation",
			"recommendation_customer_suitability",

			"recommendation_comment_trades",

			"recommendation_costs_associated",
			"recommendation_costs_advisor_fee",

			"recommendation_decision_taken",
			"recommendation_decision_taken_other",
			"recommendation_decision_comment",

			"recommendation_fees_total",
			"recommendation_fees_pct",
			"recommendation_product_sum_total",
			"recommendation_costs_advisor_fee_total",
			"recommendation_costs_pct",
			"recommendation_costs_total",
			"recommendation_transaction_fees",

			"recommendation_costs_advisor_fee_is_fixed",
		];

		for (let i = 0; i < Recommendation.ALLOWED_PRODUCTS_COUNT; i++)
		{
			fields.push("recommendation_product_type_" + i);
			fields.push("recommendation_product_name_" + i);
			fields.push("recommendation_product_isin_" + i);
			fields.push("recommendation_product_ticker_" + i);
			fields.push("recommendation_product_amount_" + i);
			fields.push("recommendation_product_share_" + i);
			fields.push("recommendation_product_fee_" + i);
			fields.push("recommendation_product_family_" + i);
			fields.push("recommendation_product_fee_yearly_" + i);

			fields.push("recommendation_product_buy_name_" + i);
			fields.push("recommendation_product_buy_isin_" + i);
			fields.push("recommendation_product_buy_ticker_" + i);
			fields.push("recommendation_product_buy_amount_" + i);
			fields.push("recommendation_product_buy_share_" + i);
			fields.push("recommendation_product_buy_fee_" + i);
			fields.push("recommendation_product_buy_family_" + i);
			fields.push("recommendation_product_buy_fee_yearly_" + i);

			fields.push("recommendation_product_sell_name_" + i);
			fields.push("recommendation_product_sell_isin_" + i);
			fields.push("recommendation_product_sell_ticker_" + i);
			fields.push("recommendation_product_sell_amount_" + i);
			fields.push("recommendation_product_sell_share_" + i);
			fields.push("recommendation_product_sell_fee_" + i);
			fields.push("recommendation_product_sell_family_" + i);
			fields.push("recommendation_product_sell_fee_yearly_" + i);
		}

		for (let i = 0; i < 7; i++)
		{
			fields.push("recommendation_financing_description_" + i);
			fields.push("recommendation_financing_amount_" + i);
		}

		return fields;
	}
}

function _fetchProducts()
{
	return new Promise(async (resolve, reject) =>
	{
		try
		{
			const result = await api("/instruments");
			resolve(result);
		}
		catch (e)
		{
			console.error(e.message);
			reject();
		}
	});
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer} = this.props;
	let newState = Recommendation.getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

class ProductRow extends React.PureComponent
{
	typeOptions = [
		{label: "Köp", value: "Köp"},
		{label: "Sälj", value: "Sälj"}
	];

	render()
	{
		if (!this.props.productOptions)
		{
			return null;
		}

		const fulfilled = false;
		const index = this.props.index;

		let className = fulfilled ? "fulfilled " : "";
		className += "citroneer-product";

		let selectedIndex = -1;
		if (this.props.isin !== "")
		{
			for (let i = 0; i < this.props.productOptions.length; i++)
			{
				if (this.props.isin === this.props.productOptions[i].Isin)
				{
					selectedIndex = i;
					break;
				}
			}
		}

		const fieldNamePrefix = this.props.type === "Sälj" ? "sell" : "buy";
		const fieldIndex = this.props.type === "Sälj" ? this.props.sellIndex : this.props.buyIndex;

		return (
			<div className={className}>
				<div className={"transtype"}>
					<Select
						label="Typ"
						options={this.typeOptions}
						selectedIndex={this.typeOptions.map(c => c.value).indexOf(this.props.type)}
						onChange={this._onTypeChange}
					/>
				</div>
				<div className={"product"}>
					<Select
						className="NameIsinSelect"
						label="Produktnamn & ISIN"
						inputPlaceholder="Sök på namn eller ISIN"
						options={this.props.productOptions}
						selectedIndex={selectedIndex}
						customFilterer={this._nameIsinFilterer}
						renderOption={this._renderNameIsinOption}
						renderSelectedOption={this._renderNameIsinOption}
						onChange={this._onNameIsinChange}
					/>

					<input type={"hidden"} name={"recommendation_product_type_" + index} value={this.props.type}/>
					<input type={"hidden"} name={"recommendation_product_name_" + index} value={this.props.name}/>
					<input type={"hidden"} name={"recommendation_product_family_" + index} value={this.props.family}/>
					<input type={"hidden"} name={"recommendation_product_isin_" + index} value={this.props.isin}/>
					<input type={"hidden"} name={"recommendation_product_ticker_" + index} value={this.props.ticker}/>
					<input type={"hidden"} name={"recommendation_product_transaction_fee_" + index}
						   value={this.props.transactionFee}/>
					<input type={"hidden"} name={"recommendation_product_transaction_fee_min_" + index}
						   value={this.props.transactionFeeMin}/>

					<input type={"hidden"} name={"recommendation_product_" + fieldNamePrefix + "_name_" + fieldIndex}
						   value={this.props.name}/>
					<input type={"hidden"} name={"recommendation_product_" + fieldNamePrefix + "_family_" + fieldIndex}
						   value={this.props.family}/>
					<input type={"hidden"} name={"recommendation_product_" + fieldNamePrefix + "_isin_" + fieldIndex}
						   value={this.props.isin}/>
					<input type={"hidden"} name={"recommendation_product_" + fieldNamePrefix + "_ticker_" + fieldIndex}
						   value={this.props.ticker}/>
					<input type={"hidden"}
						   name={"recommendation_product_" + fieldNamePrefix + "_transaction_fee_" + fieldIndex}
						   value={this.props.transactionFee}/>
				</div>
				<div className={"amount"}>
					<NumberField label={i18n("general", "provide_amount")}
								 name={"recommendation_product_amount_" + index}
								 unit=" kr"
								 value={this.props.amount}
								 showSeparators={true}
								 validation={value => (value.length > 0)}
								 onChange={({target}) => this.props.onProductChanged("amount", index, target.value)}/>

					<input type={"hidden"} name={"recommendation_product_" + fieldNamePrefix + "_amount_" + fieldIndex}
						   value={localeNumber(this.props.amount.replaceAll(" ", "").replaceAll("kr", ""), 0) + " kr"}/>
				</div>
				<div className={"fees"}>
					<strong>Avgift</strong>
					{(this.props.fee * 1).toFixed(2)}%
					<input type={"hidden"} name={"recommendation_product_fee_" + index}
						   value={(this.props.fee * 1).toFixed(2)}/>
					<input type={"hidden"} name={"recommendation_product_" + fieldNamePrefix + "_fee_" + fieldIndex}
						   value={(this.props.fee * 1).toFixed(2)}/>
				</div>
				<div className={"remove"}>
					{this.props.index > 0 && (
						<img alt="Remove" src={imgRemove} onClick={this._onRemove}/>
					)}
				</div>
				<div className={"transtype"}></div>
				<div className={"product"}></div>
				<div className={"amount info"}>
					{this.props.share !== "-" && (
						<React.Fragment>
							{this.props.share}
							<input type={"hidden"} name={"recommendation_product_share_" + index}
								   value={this.props.share}/>
							<input type={"hidden"}
								   name={"recommendation_product_" + fieldNamePrefix + "_share_" + fieldIndex}
								   value={this.props.share}/>
						</React.Fragment>
					)}

					{this.props.share === "-" && (
						<React.Fragment>
							&nbsp;
						</React.Fragment>
					)}

				</div>
				<div className={"fees info"}>
					{this.props.type === 'Köp' && this.props.feeYearly !== "-" && (
						<React.Fragment>
							{this.props.feeYearly} per år
							<input type={"hidden"} name={"recommendation_product_fee_yearly_" + index}
								   value={this.props.feeYearly}/>
							<input type={"hidden"}
								   name={"recommendation_product_" + fieldNamePrefix + "_fee_yearly_" + fieldIndex}
								   value={this.props.feeYearly}/>
						</React.Fragment>
					)}
				</div>
				<div className={"remove"}></div>
			</div>
		);
	}

	_onNameIsinChange = (option) =>
	{
		this.props.onSelectProduct(this.props.index, option);
	};

	_onTypeChange = (option) =>
	{
		this.props.onSelectType(this.props.index, option);
	};

	_onRemove = () =>
	{
		this.props.onRemove(this.props.index);
	};

	_renderNameIsinOption = ({Name, Isin, Type}) => (
		<React.Fragment>
			<div className="name">{Name}</div>
			<div className="type">{Type}</div>
			<div className="isin">{Isin}</div>
		</React.Fragment>
	);

	_nameIsinFilterer = ({option}, input) =>
	{
		input = input.toLowerCase();

		let matchesIsinNumber = false;
		if (option.Isin)
		{
			const isin = option.Isin.toLowerCase();
			matchesIsinNumber = isin.indexOf(input) === 0;
		}

		const name = option.Name.toLowerCase();
		const type = option.Type.toLowerCase();
		return name.indexOf(input) >= 0 || matchesIsinNumber || type.indexOf(input) >= 0;
	};
}