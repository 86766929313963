import React from "react";

import i18n from "@helpers/i18n";
import {createClassName, localeNumber} from "@helpers/utils";

export class DocsList extends React.PureComponent {

	render() {
		const {props} = this;
		const {items, totalSize, maxSize, onDocRemove, alignBottom, filterKey, required} = props;
		const classes = createClassName("Cob-view14-DocsList", {});
		const alignStyle = alignBottom ? {marginTop: "7px"} : {};

		// To support multiple different docs lists we can filter on an optional filterKey
		const itemsFiltered = [];
		for (let i = 0; i < items.length; i++) {
			if (filterKey === undefined || items[i].filterKey === filterKey) {
				itemsFiltered.push(items[i]);
			}
		}

		const overMaxSizeClassName = totalSize > maxSize ? "max-size over-size" : "max-size";

		return (
			<div className={classes}>
				{!alignBottom && (
					<div className="Cob-view14-DocsList-info">
						<div className="total-docs">
							<strong>{i18n("docs_list", "num_docs")}:</strong>
							<span>{itemsFiltered.length}</span>
						</div>
						<div className="max-size">
							<strong>{i18n("docs_list", "max_upload_size")}:</strong>
							<span>{`${ localeNumber(totalSize) } KB / ${ localeNumber(maxSize) } KB`}</span>
						</div>
					</div>
				)}

				<div className="Cob-view14-DocsList-items" style={alignStyle}>
					{itemsFiltered.length === 0 ? (
						<div className="Cob-view14-DocsList-empty">
							{required ? (
								<strong>{i18n("docs_list", "at_least_one_doc")}</strong>
							) : (
								<span>{i18n("docs_list", "no_docs_added")}.</span>
							)}
						</div>
					) : itemsFiltered.map((item, index) => (
						<DocsListItem key={index} item={item} index={index} onRemove={onDocRemove} />
					))}
				</div>

				{alignBottom && (
					<div className="Cob-view14-DocsList-info">
						<div className="total-docs">
							<strong>{i18n("docs_list", "num_docs")}:</strong>
							<span>{itemsFiltered.length}</span>
						</div>
						<div className={overMaxSizeClassName}>
							<strong>{i18n("docs_list", "max_upload_size")}:</strong>
							<span>{`${ localeNumber(totalSize) } KB / ${ localeNumber(maxSize) } KB`}</span>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export class DocsListItem extends React.PureComponent {

	render() {
		const {item, onRemove} = this.props;
		const size = Math.round(item.size * 100) / 100000;

		return (
			<div className="Cob-view14-DocsList-Item">
				<div className="left">
					<div className="name">{item.name}</div>
					<div className="size">{localeNumber(size)} KB</div>
				</div>
				<div className="remove-button material-icons"
					 onClick={onRemove ? this._onRemove : undefined}>remove_circle
				</div>
			</div>
		);
	}

	// Internal methods
	_onRemove = () => {
		const {onRemove, item} = this.props;
		onRemove(item);
	}
}