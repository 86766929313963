import {createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import {routerMiddleware} from "react-router-redux";
import {composeWithDevTools} from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';

import getRootReducer from "./reducers";


export const history = createBrowserHistory();

const routerHistory = routerMiddleware(history);
let store = null;

export default () => store = createStore(
	getRootReducer(),
	composeWithDevTools(
		applyMiddleware(routerHistory, thunk)
	)
);

export const getStore = () => store;