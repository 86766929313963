import { useState, useEffect } from "react";

// HELPERS
import api from "@helpers/api";
import { formatSSN } from "@helpers/utils";

const usePoaCard = props => {
  const { client, advisor } = props;

  const [loaded, setLoaded] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [fmk, setFmk] = useState({
    id: null,
    status: "not_sent",
    signedDate: null,
    validTo: null
  });

  const getFmkStatus = async () => {
    try {
      const data = await api("/fmk/status", {
        advisor: advisor.ssn,
        ssn: formatSSN(client.id, {withDash: false}),
        isCardWidget: true
      });
      const { id, status, signedDate, validTo, companies } = data;
      setFmk({ id, status, signedDate, validTo, companies});
      setLoaded(true);
    }
    catch (e) {
      setFmk({ id: null, status: "error" });
      console.error(e.message);
    }
  }

  const onActionClick = (type) => {
    setConfirmDialogOpen(type);
  }

  const OnModalClose = () => {
    setConfirmDialogOpen(false);
  }

  const handleRevoke = async () => {
    setFetching(true);
    try {
      const data = await api("/fmk/revoke", {
        advisor: advisor.ssn,
        poaId: fmk.id
      });
      setFetching(false);
      setConfirmDialogOpen(false);
      setFmk({ status: "not_sent" });
    }
    catch (e) {
      setFetching(false);
      setConfirmDialogOpen(false);
      setFmk({ status: "error" });
      console.error(e.message);
    }
  }

  const handleRemove = async () => {
    setFetching(true);
    try {
      const data = await api("/fmk/remove", {
        advisor: advisor.ssn,
        ssn: formatSSN(client.id, {withDash: false})
      });
      setFetching(false);
      setConfirmDialogOpen(false);
      setFmk({ status: "not_sent" });
    }
    catch (e) {
      setFetching(false);
      setConfirmDialogOpen(false);
      setFmk({ status: "error" });
      console.error(e.message);
    }
  }

  const handleRemind = async () => {
    setFetching(true);
    try {
      const data = await api("/fmk/remind", {
        advisor: advisor.ssn,
        ssn: formatSSN(client.id, {withDash: false})
      });
      setFetching(false);
      setConfirmDialogOpen(false);
      setFmk({ id: null, status: "reminder_sent" });
    }
    catch (e) {
      setFetching(false);
      setConfirmDialogOpen(false);
      setFmk({ status: "error" });
      console.error(e.message);
    }
  }

  useEffect(() => {
    if (client.id && !loaded) {
      getFmkStatus();
    }
    else {
      setLoaded(true);
    }
  }, [getFmkStatus])  

  return {
    loaded,
    fetching,
    fmk,
    confirmDialogOpen,
    onActionClick,
    OnModalClose,
    handleRevoke,
    handleRemove,
    handleRemind
  };
};

export default usePoaCard;