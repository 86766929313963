import {mergeViewStateForFulfillment} from "../../Cob.helpers";
import Validator from "../../../../classes/Validator";

export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);

	if (!state.leiCodeRequired && state.leiCode.length === 0)
	{
		return true;
	}

	// If a LEI code has been entered it needs to be correct
	if (!Validator.validateLeiCode(state.leiCode) || state.leiCodeLoading)
	{
		return false;
	}

	return true;
}