import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState) {
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") return true;
	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	// Om kunden är företag måste minst en signatär anges
	if (customerState.isCompany) {
		for (let i = 0; i < customerState.company_active_signatories; i++) {

			const email = customerState['company_signatory_email_' + i];
			const ssn = customerState['company_signatory_sec_num_' + i];

			if (customerState['company_signatory_firstname_' + i].length === 0 ||
				customerState['company_signatory_lastname_' + i].length === 0 ||
				email.length === 0 ||
				ssn.length === 0) {
				return false;
			}

			// kolla att email är OK
			if (!validateEmail(email)) {
				return false;
			}

			// kolla att personnr är rätt format
			if (isNaN(ssn) || !(ssn.length === 12 && (ssn.substring(0, 2) === "19" || ssn.substring(0, 2) === "20"))) {
				return false;
			}
		}
	}

	/**
	 * Kolla att alla rutor är kryssade
	 */
	return !!(
		customerState.advice_sign_row_1 === "Ja" &&
		customerState.advice_sign_row_2 === "Ja" &&
		customerState.advice_sign_row_3 === "Ja"
	);
}

function validateEmail(email) {
	// eslint-disable-next-line
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}