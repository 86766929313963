import React from "react";
// HELPERS
import {localeNumber} from "@helpers/utils";


export const calcSum = function (prefix, count, calcFn, newState) {
	const fields = [];
	for (let i = 0; i < count; i++) {
		fields.push(prefix + (i + 1));
	}
	return fields.reduceRight((a, b) => a + calcFn(b, newState), 0);
};

export const calcRisk = function (prefix, count, calcFn, newState) {
	const fields = [];
	for (let i = 0; i < count; i++) {
		fields.push(prefix + (i + 1));
	}
	const sum1 = fields.reduceRight((a, b) => a + calcFn(b, newState), 0); // Same as calcSum()
	const sum2 = fields.reduceRight((a, b, i) => a + (calcFn(b, newState) * (i + 1)), 0);
	return sum1 === 0 || sum2 === 0 ? 0 : (Math.round((sum2 / sum1) * 10) / 10);
};

export const onTableFieldChange = function (prefix, n, {target}) {
	this.setTableData({
		[prefix + n]: target.value,
		[prefix + "sum"]: calcSum(prefix, 7, this._getTableData, {[prefix + n]: target.value}),
		[prefix + "sum_risk"]: calcRisk(prefix, 7, this._getTableData, {[prefix + n]: target.value})
	});
};

export function TotalValue(props) {
	const {value, name, namePrefix, unit = "", format = true, decimals, delimiter1, delimiter2} = props;
	const valueLocale = format === true ? localeNumber(value, decimals, delimiter1, delimiter2) : value;

	return (
		<React.Fragment>
			<div className="Cob-table-total-value">{valueLocale + unit}</div>
			<input type="hidden" name={namePrefix + name} value={valueLocale ? valueLocale + unit : ""}/>
		</React.Fragment>
	);
}

export function mergeViewStateForFulfillment(state, prevState) {
	state = state ? {...state} : {};
	for (const key in prevState) {
		if (typeof state[key] === "undefined") {
			state[key] = prevState[key];
		}
	}
	return state;
}

export function iterateData(data, name, count, startIndex = 0) {
	const result = {};

	for (let i = 0; i < count; i++) {
		const realName = name.replace("#", i + startIndex);
		result[realName] = data[realName];
	}
	return result;
}

export function roundWithDecimals(value, decimals = 1) {
	decimals = Math.pow(10, decimals);
	return Math.round(value * decimals) / decimals;
}

export function numberIsBetweenRange(n, a, b) {
	if (isNaN(n)) {
		n = n.replace(" SEK", "");
	}
	return n >= a && n <= b;
}

export function splitValue(value, customSeparator) {
	if (customSeparator) {
		return value.split(customSeparator).map(v => v.trim());
	}
	else {
		const match = /\((.*?)\)/.exec(value);
		return match ? [value.replace(`(${match[1]})`, "").trim(), match[1]] : [value];
	}
}

export function appendCurrency(value, currencyCode) {
	if (typeof value === "undefined" || value === null) {
		return null;
	}

	value = value.toString();
	if (!value) {
		value = "0";
	}

	/**
	 * Don't show ,00 for SEK values, only show decimals if they are not ,00
	 */
	if (currencyCode === 'SEK' && value.indexOf(",00") > -1) {
		value = value.substring(0, value.indexOf(","));
	}

	return value.indexOf("+") !== -1 ? value.replace(" +", ` ${currencyCode} +`) : `${value} ${currencyCode}`;
}