import React from "react";
import countries from "@helpers/countries";
import Select from "../../../../components/Select/Select";

export default class CountrySelection extends React.PureComponent {

	countries = countries.map(({label, code}) => ({label, value: label, code: code}));

	render() {
		const {field, label, onChange, mandatory, selected, highlightErrors} = this.props;

		let selectedIndex = -1;
		if (selected) {
			let index = 0;
			this.countries.forEach(function (country) {
				if (country.value === selected) {
					selectedIndex = index;
				}
				index++;
			});
		}

		return (
			<React.Fragment>
				<Select
					name={field}
					label={label + (mandatory ? "*" : "")}
					selectedIndex={selectedIndex}
					options={this.countries}
					onChange={onChange}
          highlightErrors={highlightErrors}
				/>
				<input type={"hidden"} name={field + "_code"} value={selectedIndex > -1 ? this.countries[selectedIndex].code : ""}/>
			</React.Fragment>
		);
	}
}