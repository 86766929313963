// CLASSES
import {mergeViewStateForFulfillment} from "../../../Cob.helpers";

export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	if (customerState.planning_liqudate_quickly.length <= 0)
	{
		return false;
	}

	if (customerState.planning_savings_horizon.length <= 0)
	{
		return false;
	}

	if (state.workflow !== "futur")
	{
		if (customerState.planning_buffer_amount.length <= 0)
		{
			return false;
		}

		if (customerState.numberOfSavingGoals > 0)
		{
			for (let i = 1; i <= customerState.numberOfSavingGoals; i++)
			{
				if (customerState[`planning_savings_goal_name_${i}`].length <= 0 ||
					customerState[`planning_savings_goal_name_${i}`].length > 30 ||
					customerState[`planning_savings_goal_target_years_${i}`].length <= 0 ||
					customerState[`planning_savings_goal_amount_${i}`].length <= 0)
				{
					return false;
				}
			}
		}
	}

	return true;
}