import CustomerInformation, {getViewInitialStateForCustomer as GetViewInitialStateForCustomer_CustomerInformation} from "./BasicInfo/CustomerInformation";
import CustomerInformationFulfillment from "./BasicInfo/CustomerInformation.fulfillment";
import ActionSelection, {getViewInitialStateForCustomer as GetViewInitialStateForCustomer_ActionSelection} from "./SelectedAction/ActionSelection";
import ActionSelectionFulfillment from "./SelectedAction/ActionSelection.fulfillment";
import FinancialSituation, {getViewInitialStateForCustomer as GetViewInitialStateForCustomer_FinancialSituation} from "./FinancialSituation/FinancialSituation";
import FinancialSituationFulfillment from "./FinancialSituation/FinancialSituation.fulfillment";
import FinancialSituation2, {getViewInitialStateForCustomer as GetViewInitialStateForCustomer_FinancialSituation2} from "./FinancialSituation/FinancialSituation2";
import FinancialSituation2Fulfillment from "./FinancialSituation/FinancialSituation2.fulfillment";
import FinancialSituation3, {getViewInitialStateForCustomer as GetViewInitialStateForCustomer_FinancialSituation3} from "./FinancialSituation/FinancialSituation3";
import FinancialSituation3Fulfillment from "./FinancialSituation/FinancialSituation3.fulfillment";
import FinancialPlanning, {getViewInitialStateForCustomer as GetViewInitialStateForCustomer_FinancialPlanning} from "./FinancialPlanning/FinancialPlanning";
import FinancialPlanningFulfillment from "./FinancialPlanning/FinancialPlanning.fulfillment";
import Purpose, {getViewInitialStateForCustomer as GetViewInitialStateForCustomer_Purpose} from "./Purpose/Purpose";
import PurposeFulfillment from "./Purpose/Purpose.fulfillment";
import KYC, {getViewInitialStateForCustomer as GetViewInitialStateForCustomer_KYC} from "./KYC/KYC";
import KYCFulfillment from "./KYC/KYC.fulfillment";
import KYCCompany, {getViewInitialStateForCustomer as GetViewInitialStateForCustomer_KYCCompany} from "./KYC/KYCCompany";
import KYCCompanyFulfillment from "./KYC/KYCCompany.fulfillment";
import Risk, {getViewInitialStateForCustomer as GetViewInitialStateForCustomer_Risk} from "./Risk/Risk";
import RiskFulfillment from "./Risk/Risk.fulfillment";
import RiskProfil, {getViewInitialStateForCustomer as GetViewInitialStateForCustomer_RiskProfil} from "./RiskProfil/RiskProfil";
import RiskProfilFulfillment from "./RiskProfil/RiskProfil.fulfillment";
import Experience, {getViewInitialStateForCustomer as GetViewInitialStateForCustomer_Experience} from "./Experience/Experience";
import ExperienceFulfillment from "./Experience/Experience.fulfillment";
import Uppdragsavtal, {getViewInitialStateForCustomer as GetViewInitialStateForCustomer_Uppdragsavtal} from "./Uppdragsavtal/Uppdragsavtal";
import UppdragsavtalFulfillment from "./Uppdragsavtal/Uppdragsavtal.fulfillment";
import SignAgreement from "./SignAgreement/SignAgreement";
import SignAgreementFulfillment from "./SignAgreement/SignAgreement.fulfillment";

export default class VMDemoClient {
  static availableViews = [
    {
      id: "DemoClient-CustomerInformation",
      View: CustomerInformation,
      getInitialState: GetViewInitialStateForCustomer_CustomerInformation,
      isViewFulfilled: CustomerInformationFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "DemoClient-SelectedAction",
      View: ActionSelection,
      getInitialState: GetViewInitialStateForCustomer_ActionSelection,
      isViewFulfilled: ActionSelectionFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "DemoClient-FinancialSituation",
      View: FinancialSituation,
      getInitialState: GetViewInitialStateForCustomer_FinancialSituation,
      isViewFulfilled: FinancialSituationFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "DemoClient-FinancialSituation-2",
      View: FinancialSituation2,
      getInitialState: GetViewInitialStateForCustomer_FinancialSituation2,
      isViewFulfilled: FinancialSituation2Fulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "DemoClient-FinancialSituation-3",
      View: FinancialSituation3,
      getInitialState: GetViewInitialStateForCustomer_FinancialSituation3,
      isViewFulfilled: FinancialSituation3Fulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "DemoClient-FinancialPlanning",
      View: FinancialPlanning,
      getInitialState: GetViewInitialStateForCustomer_FinancialPlanning,
      isViewFulfilled: FinancialPlanningFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "DemoClient-Purpose",
      View: Purpose,
      getInitialState: GetViewInitialStateForCustomer_Purpose,
      isViewFulfilled: PurposeFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "DemoClient-KYC",
      View: KYC,
      getInitialState: GetViewInitialStateForCustomer_KYC,
      isViewFulfilled: KYCFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: true
    },
    {
      id: "DemoClient-KYCCompany",
      View: KYCCompany,
      getInitialState: GetViewInitialStateForCustomer_KYCCompany,
      isViewFulfilled: KYCCompanyFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: true,
      isDisabledForCompanies: false
    },
    {
      id: "DemoClient-Risk",
      View: Risk,
      getInitialState: GetViewInitialStateForCustomer_Risk,
      isViewFulfilled: RiskFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "DemoClient-RiskProfil",
      View: RiskProfil,
      getInitialState: GetViewInitialStateForCustomer_RiskProfil,
      isViewFulfilled: RiskProfilFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "DemoClient-Experience",
      View: Experience,
      getInitialState: GetViewInitialStateForCustomer_Experience,
      isViewFulfilled: ExperienceFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "DemoClient-Uppdragsavtal",
      View: Uppdragsavtal,
      getInitialState: GetViewInitialStateForCustomer_Uppdragsavtal,
      isViewFulfilled: UppdragsavtalFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "DemoClient-SignAgreement",
      View: SignAgreement,
      // getInitialState: GetViewInitialStateForCustomer_SignAgreement,
      isViewFulfilled: SignAgreementFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    }
  ];
}