import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import Icon from "@components/Icon/Icon";
import {connectRedux, createClassName} from "@helpers/utils";
import {actions as appActions} from "@reducers/app";


export default connectRedux(state => ({
	app: state.app
}), {
	setAppState: appActions.setState
}, class PartnerErrorRoute extends React.PureComponent {

	componentDidMount() {

	}

	render() {
		const classes = createClassName("PartnerError", {});

		return (
			<div className={classes}>
				<ContentBox>

					<div className="icon-circle">
						<Icon value="error" size="xlarge"/>
					</div>

					<h2>Partner kunde inte hittas</h2>

                    <p>
                        Var god kontakta teknisk support.
                    </p>

				</ContentBox>
			</div>
		);
	}
});