import {mergeViewStateForFulfillment} from "../../../Cob.helpers";
import Purpose from "./Purpose";


export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	/**
	 * Syfte needs to be given
	 */
	if (customerState.purpose_investment.length <= 0)
	{
		return false;
	}

	/**
	 * Vilket påstående nedan... needs to be given
	 */
	if (customerState.rdo_purpose_invcsr.length <= 0)
	{
		return false;
	}

	let purposeSelected = false;
	for (let i = 0; i < Purpose.purposeOptions.length; i++)
	{
		if (customerState.rdo_purpose_invcsr === Purpose.purposeOptions[i].value)
		{
			purposeSelected = true;
			break;
		}
	}

	if (!purposeSelected)
	{
		return false;
	}

	return true;
}