import {isDate} from "@helpers/utils";

export default class Validator
{
	static validateEmail(email)
	{
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	static validateSsn(ssn, {personsOnly = false, companiesOnly = false} = {})
	{ 
    if (ssn === null)
		{
			return false;
		}

    // trim spaces
    ssn = ssn.replace(/\s/g, "");

    // check if 5th character from the end is a dash and trim it
    if (ssn[ssn.length - 5] === "-") {
      ssn = ssn.replace(/-/g, "");
    }

    // check length is valid
    if (ssn.length !== 10 && ssn.length !== 12)
		{
      return false;
		}

    // if ssn starts with 16 or 19 assume we're expecting a 12 digit ssn (might fail if older that 105?..)
    if (ssn.length === 10 && (ssn.substring(0, 2) === "16" || ssn.substring(0, 2) === "19")){
      return false;
    }

    const isCompany = ssn.length === 10 ? ssn[2] >= 2 : ssn[4] >= 2;

    if ((personsOnly && isCompany) || (companiesOnly && !isCompany)) {
      return false;
    }

    // add prefix to ssn if missing
    ssn = ssn.length === 10 ? isCompany ? "16" + ssn : "19" + ssn : ssn;

    // check if all characters are numbers
    const numbers = ssn.match(/^(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)$/);
    if (!numbers) {
      return false;
    }

    if (isCompany) { // skip checksum for companies
      return true
    }

		let checkSum = 0;
		if (!isDate(ssn.substring(0, 4), ssn.substring(4, 6), ssn.substring(6, 8)))
		{
      return false;
		}

		let n;
		for (let i = 3; i <= 12; i++)
		{
			n = parseInt(numbers[i], 10);
			checkSum += i % 2 === 0 ? n : (n * 2) % 9 + Math.floor(n / 9) * 9;
		}

		return checkSum % 10 === 0;
	}

	static validateOrgNum(orgNum)
	{
		orgNum = orgNum.replace("-", "");

		if (!orgNum.startsWith("16"))
		{
			orgNum = "16" + orgNum;
		}

		if (orgNum.length === 12 && orgNum.substring(0, 2) === "16")
		{
			return orgNum[2] >= 2 && Validator.validateChecksum(orgNum.substring(2));
		}
		else if (orgNum.length === 10)
		{
			return orgNum[0] >= 2 && Validator.validateChecksum(orgNum);
		}

		return false;
	}

	static validateChecksum(number)
	{
		const array = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
		let length = number.length;
		let bit = 1;
		let sum = 0;
		let value;

		while (length)
		{
			value = parseInt(number.charAt(--length), 10);
			bit ^= 1;
			sum += bit ? array[value] : value;
		}

		return sum % 10 === 0;
	}

	static validatePhoneNum(phoneNum)
	{
		phoneNum = phoneNum.replace(/[- ]/g, "");
		const isNumbersOnly = (v => parseInt(v, 10).toString() === v)(phoneNum.substr(1, phoneNum.length));
		return phoneNum[0] === "+" && isNumbersOnly && phoneNum.length >= 8;
	}

	static validatePhoneNumSweden(phoneNum)
	{
		if (phoneNum === undefined || phoneNum === null || phoneNum.length <= 4)
		{
			return false;
		}

		phoneNum = phoneNum.replace(/[- ]/g, "");
		const isNumbersOnly = (v => parseInt(v, 10).toString() === v)(phoneNum.substr(1, phoneNum.length));

		const isSwedenCountryCode = phoneNum.length >= 3 && phoneNum.substr(0, 3) === "+46";

		return phoneNum[0] === "+" && isNumbersOnly && isSwedenCountryCode;
	}

	static validatePhoneForSmsSigning(value)
	{
		let phone = value.replace(/[- ]/g, "");
		return /(\+?467)\d{8}/.test(phone);
	}

	static validateLeiCode(leiCode)
	{
		if (leiCode.length !== 20)
		{
			return false;
		}

		const FORMAT_ISVALID = /^[0-9A-Z]{18}[0-9]{2}$/;
		const lei = {
			isValid(rawValue)
			{
				const value = stringifyInput(rawValue);

				if (!value.match(FORMAT_ISVALID))
				{
					throw new Error('Exception value of format \'' + FORMAT_ISVALID + '\', found: \'' + value + '\'');
				}

				return mod97(value) === 1;
			}
		};

		try
		{
			return lei.isValid(leiCode);
		}
		catch (e)
		{
			return false;
		}
	}

	static validateClearingNumber(number, bank, availableBanks)
	{
		if (isNaN(number) || number.length !== 4)
		{
			return false;
		}

		/**
		 * If there's a bank selected we check the accepted clearing number ranges
		 */
		if (bank && bank.length > 0)
		{
			let selectedBank = null;
			for (const bankName in availableBanks)
			{
				if (bankName === 'length' || !availableBanks.hasOwnProperty(bankName))
				{
					continue;
				}
				if (bank === bankName)
				{
					selectedBank = availableBanks[bankName];
					break;
				}
			}

			if (selectedBank === null)
			{ // Övrig bank - return true
				return true;
			}

			let clearingNoIsInRange = false;
			for (let i = 0; i < selectedBank.length; i++)
			{
				const clearingNoRange = selectedBank[i];
				if (number >= clearingNoRange["start"] && number <= clearingNoRange["end"])
				{
					clearingNoIsInRange = true;
					break;
				}
			}
			return clearingNoIsInRange;
		}

		// No bank selected, clearing number is invalid
		return false;
	}
}

/**
 * Below code used for LEI calculation
 */
const CHARCODE_A = 'A'.charCodeAt(0);
const CHARCODE_0 = '0'.charCodeAt(0);

function mod97(value)
{
	let buffer = 0;
	let charCode;
	for (let i = 0; i < value.length; ++i)
	{
		charCode = value.charCodeAt(i);
		buffer = charCode + (charCode >= CHARCODE_A ? buffer * 100 - CHARCODE_A + 10 : buffer * 10 - CHARCODE_0);
		if (buffer > 1000000)
		{
			buffer %= 97;
		}
	}

	return buffer % 97;
}

function stringifyInput(rawValue)
{
	if (rawValue !== null && rawValue !== undefined)
	{
		switch (typeof rawValue)
		{
			case 'string':
				return rawValue.toUpperCase();
			default:
				throw new Error('Expecting value of type \'string\', found: \'' + (typeof rawValue) + '\'');
		}
	}

	throw new Error('Expecting value of type \'string\', found: \'' + rawValue + '\'');
}