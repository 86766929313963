import React from "react";
import PropTypes from "prop-types";

import { isEmptyObject } from "@helpers/utils";

import {
  Modal as MuiModal,
  Box,
  Typography,
} from "@mui/material";

// import Button from "@components/Button/Button";
import ModalContentBox from "@mui-theme/styled/ModalContentBox";
import CloseModalBox from "@mui-theme/styled/CloseModalBox";
import ThemedButton from "@mui-theme/styled/ThemedButton";

import useDeleteNoteModal from "./useDeleteNoteModal";

const EditNoteModal = props => {
  const {
    open,
    fetching,
    toggle,
    handleSubmit
  } = useDeleteNoteModal(props);

  return (
    <MuiModal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          toggle();
        }
      }}
      sx={{
        overflow: "scroll"
      }}
    >
      <ModalContentBox
        sx={{
          maxHeight: "96%"
        }}
      >
        <Box
          sx={{
            width: "100%",
            px: 2,
            alignItems: "flex-start"
          }}
        >
          <Typography variant="h4" color="common.black">
            Ta bort anteckning
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            alignItems: "flex-start",
            display: "flex",
            flexFlow: "column",
            px: 2
          }}
        >
          <Typography variant="h6" sx={{ color: "primary.main", mb: "5px" }}>
            Är du säker på att du vill ta bort denna anteckning?
          </Typography>
        </Box>
        <CloseModalBox
          sx={{
            px: 2
          }}
        >
          <ThemedButton
            variant="outlined"
            onClick={toggle}
            sx={{
              minWidth: "unset",
              width: 100
            }}
          >
            Avbryt
          </ThemedButton>
          <ThemedButton
            variant="contained"
            loading={fetching}
            onClick={handleSubmit}
            sx={{
              minWidth: "unset",
              width: 150
            }}
          >
            Radera
          </ThemedButton>
        </CloseModalBox>
      </ModalContentBox>
    </MuiModal>
  );
};

EditNoteModal.propTypes = {
  open: PropTypes.bool
};

export default EditNoteModal;
