import React from "react";
import PropTypes from "prop-types";

// HELPERS
import { createClassName } from "@helpers/utils";


export default class FieldsCollection extends React.PureComponent {
    static propTypes = {
        fields: PropTypes.array.isRequired,
        fieldsPerRow: PropTypes.number,
        align: PropTypes.string,
    }
    static defaultProps = {
        fieldsPerRow: 1,
        align: "left",
    }

    render() {
        const { props } = this;
        const { fields, fieldsPerRow, align } = props;
        const classes = createClassName(props.className, {
            "FieldsCollection": true,
            [align]: !!align,
        });

        return (
            <div className={ classes }>
                <div className="FieldsCollection-container">
                    { fields.map((field, index) => (
                        <FieldsCollectionFieldContainer { ...field } key={ field.key || index } index={ index } fieldsPerRow={ fieldsPerRow } />
                    ))}
                </div>
            </div>
        );
    }
}

class FieldsCollectionFieldContainer extends React.PureComponent {
    static propTypes = {
        render: PropTypes.any.isRequired,
        index: PropTypes.number.isRequired,
        fieldsPerRow: PropTypes.number.isRequired,
    }
    static defaultProps = {
        display: true,
    }

    render() {
        const { props } = this;
        const { render, fieldsPerRow, display } = props;
        const classes = createClassName(props.className, {
            "FieldsCollection-FieldContainer": true,
        });

        if(!display) return null;

        return (
            <div className={ classes } style={{ width: (Math.round((100 / fieldsPerRow) * 1000) / 1000) + "%" }}>
                { render }
            </div>
        );
    }
}