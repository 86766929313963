import React from "react";
import PropTypes from "prop-types";

/**
 * Hidden input fields containing partner data and information about the advisor that should be printed on the
 * case forms (if it has fields for this).
 *
 * If the case is shared we will show the main advisor details here, otherwise we'll show the details of the logged in
 * user.
 */
export default class PartnerHiddenFields extends React.PureComponent
{
	static propTypes = {
		customer: PropTypes.object.isRequired,
		user: PropTypes.object.isRequired
	}

	render()
	{
		const {user, customer} = this.props;

		let partnerCompany, partnerCompanyOrgNo, companyIsPartner;
		let partnerFirstName, partnerLastName, partnerSsn, partnerNumber;
		let partnerPhone, partnerEmail, partnerCity, partnerZip, partnerStreet;

		/**
		 * We primarily use the _caseAdvisor data (which shows the main advisor, if the case is shared), as long as
		 * this information is available.
		 */
		if (customer._caseAdvisor)
		{
			partnerFirstName = customer._caseAdvisor.first_name;
			partnerLastName = customer._caseAdvisor.last_name;
			partnerSsn = customer._caseAdvisor.ssn;
			partnerNumber = customer._caseAdvisor.number;
			partnerEmail = customer._caseAdvisor.email;
			partnerPhone = customer._caseAdvisor.phone;
			partnerCompany = customer._caseAdvisor.company_name;
			partnerCompanyOrgNo = customer._caseAdvisor.company_org_number;
			partnerCity = customer._caseAdvisor.company_city;
			partnerZip = customer._caseAdvisor.company_zip;
			partnerStreet = customer._caseAdvisor.company_street;
			companyIsPartner = customer._caseAdvisor.company_is_partner;
		}
		else // Take info from logged in user
		{
			partnerFirstName = user.firstName;
			partnerLastName = user.lastName;
			partnerSsn = user.ssn;
			partnerNumber = user.customerNumber;
			partnerEmail = user.email;
			partnerPhone = user.phoneNumber;
			partnerCompany = user.companyName;
			partnerCompanyOrgNo = user.orgNumber;
			partnerCity = user.city;
			partnerZip = user.zipCode;
			partnerStreet = user.street;
			companyIsPartner = user.companyIsPartner === "1";
		}

		// Add a dash to the ssn, if this is written without
		if (partnerSsn && partnerSsn.length === 12)
		{
			partnerSsn = partnerSsn.substring(0, 8) + "-" + partnerSsn.substring(8);
		}

		return (
			<React.Fragment>
				<input type="hidden" name="partner_company" value={partnerCompany || ""}/>
				<input type="hidden" name="partner_orgno" value={partnerCompanyOrgNo || ""}/>

				<input type="hidden" name="partner_firstname" value={partnerFirstName || ""}/>
				<input type="hidden" name="partner_lastname" value={partnerLastName || ""}/>
				<input type="hidden" name="partner_firstlastname" value={partnerFirstName + " " + partnerLastName}/>
				<input type="hidden" name="partner_fullname" value={partnerFirstName + " " + partnerLastName}/>
				<input type="hidden" name="partner_ssn" value={partnerSsn || ""}/>
				<input type="hidden" name="partner_number" value={partnerNumber || ""}/>
				<input type="hidden" name="sent_by_partner" value={companyIsPartner ? "1" : "0"}/>

				<input type="hidden" name="partner_phonenumber" value={partnerPhone || ""}/>
				<input type="hidden" name="partner_email" value={partnerEmail || ""}/>
				<input type="hidden" name="partner_company_address" value={partnerStreet || ""}/>
				<input type="hidden" name="partner_address_zipcode" value={partnerZip || ""}/>
				<input type="hidden" name="partner_address_city" value={partnerCity || ""}/>
				<input type="hidden" name="partner_address_zipandcity" value={partnerZip + " " + partnerCity}/>
			</React.Fragment>
		);
	}
}