import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";

const SectionHeaderPaper = props => (
  <Paper
    square
    sx={{
      // do not use shorthand on overrides, props need to match exactly!
      position: "absolute",
      top: { xs: 75, md: 60 },
      left: { xs: 0, md: 250, lg: 270 },
      width: "100%",
      height: { xs: 40, md: 60 },
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      paddingLeft: 3,
      boxShadow: "none",
      borderBottom: "1px solid #DDD",
      zIndex: 999,
      ...props.sx
    }}
    {...props}
  >
    {props.children}
  </Paper>
);

export default SectionHeaderPaper;

SectionHeaderPaper.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object
};
