import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./ActionSelection.fulfillment";
import AccountSelection from "./AccountSelection";
import AccountSelectionFulfillment from "./AccountSelection.fulfillment";
import Recommendation from "./Recommendation";
import RecommendationFulfillment from "./Recommendation.fulfillment";
import ActivityIndicator from "../../../../../components/ActivityIndicator/ActivityIndicator";
import api from "../../../../../helpers/api";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import i18n from "../../../../../helpers/i18n";

export default class ActionSelection extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState, isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	async componentDidMount()
	{
		console.log("Checking status of onboarding... ");
		let onboardingInfo = await _hasClientOnboarded.call(this, this.props.customer.ssnOrg);

		// Set navigation events
		let newCustomerState = {...this.state.customerState};

		if (onboardingInfo && !onboardingInfo.has_onboarded)
		{
			newCustomerState.checkbox_advice_onboarding = "yes";
		}

		this.setState({isLoading: false, onboardingInfo: onboardingInfo, customerState: newCustomerState}, () =>
		{
			this.props.setAppState({
				viewFulfilled: this.isFulfilled(), navigationButtons: {
					sharing: {},
					back: {},
					next: {}
				}
			});
		});
	}

	render()
	{
		const classes = createClassName("Cob-view Cob-view2", {});

		if (this.state.isLoading)
		{
			return (<div className={classes}>
					<ContentBox>
						<ActivityIndicator color="#4cb7d5" busy/>
					</ContentBox>
				</div>);
		}

		return (<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>{i18n("account_selection", "type_of_consultation")}</h2>

					<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
						<div style={{alignSelf: "flex-start"}}>
							<Checkbox
								label={this.state.onboardingInfo.has_onboarded ? i18n("action_selection", "documentation") : i18n("action_selection", "updating_documentation")}
								name={"checkbox_advice_basicinfo"}
								checked={true}
								value={"yes"}
							/>
						</div>
						<div style={{alignSelf: "flex-start"}}>
							<input type={"hidden"} name={"client_has_onboarded"}
								   value={this.state.onboardingInfo.has_onboarded ? "yes" : "no"}/>

							<Checkbox
								label={i18n("action_selection", "account_opening")}
								name={"checkbox_advice_onboarding"}
								checked={!this.state.onboardingInfo.has_onboarded || this.state.customerState.checkbox_advice_onboarding === "yes"}
								value={"yes"}
								onChange={(checked) =>
								{
									this.setCustomerState({checkbox_advice_onboarding: (!this.state.onboardingInfo.has_onboarded || checked) ? "yes" : "no"});
								}}
							/>

							{this.state.workflow !== "futur" && !this.state.onboardingInfo.has_onboarded && (
								<React.Fragment>
									<strong style={{margin: "10px 0 20px 0"}}>
								    {i18n("action_selection", "mandatory_not_onboarded_before")}
									</strong>
								</React.Fragment>)}
						</div>
						{this.state.workflow !== "futur" && (<div style={{alignSelf: "flex-start"}}>
								<Checkbox
								  label={i18n("action_selection", "recommendation")}
									name={"checkbox_advice_recommendation"}
									checked={this.state.customerState.checkbox_advice_recommendation === "yes"}
									value={"yes"}
									onChange={(checked) =>
									{
										this.setCustomerState({checkbox_advice_recommendation: checked ? "yes" : "no"});
									}}
								/>
							</div>)}
					</StackLayout>

					{(!this.state.onboardingInfo.has_onboarded || this.state.customerState.checkbox_advice_onboarding === "yes") && (
						<React.Fragment>
							<div className="h-line spacing" style={{marginTop: "25px"}}/>
							<AccountSelection customer={this.props.customer}
											  onUpdated={this._accountSelectionFulfilled}
											  onboardingInfo={this.state.onboardingInfo}
											  currentWorkflow={this.state.workflow}/>
							<input type={"hidden"} name={"client_has_onboarded"} value={"yes"}/>
						</React.Fragment>)}

					{this.state.customerState.checkbox_advice_recommendation === "yes" && (<React.Fragment>
							<div className="h-line spacing" style={{marginTop: "25px"}}/>

							<Recommendation customer={this.props.customer}
											user={this.props.user}
											onUpdated={this._recommendationFulfilled}
											currentWorkflow={this.state.workflow}/>
						</React.Fragment>)}
				</ContentBox>
			</div>);
	}

	_accountSelectionFulfilled = (fulfilled) =>
	{
		const newState = this.state;
		newState.isAccountSelectionFulfilled = fulfilled;
		this._modulesFulfilled(newState);
	};

	_recommendationFulfilled = (fulfilled) =>
	{
		const newState = this.state;
		newState.isPlacementRecommendationFulfilled = fulfilled;
		this._modulesFulfilled(newState);
	};

	_modulesFulfilled(newState)
	{
		newState.isFulfilled = this.isFulfilled(newState);
		this.setState(newState, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
		});
	}
}

function _hasClientOnboarded(customerSsn)
{
	return new Promise(async (resolve, reject) =>
	{
		try
		{
			const result = await api("/citroneer/onboarding", {"ssn": customerSsn});
			resolve(result);
		}
		catch (e)
		{
			console.error(e.message);
			reject();
		}
	});
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, currentWorkflow} = this.props;
	let newState = getViewInitialStateForCustomer(customer, currentWorkflow);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer, workflow)
{
	workflow = workflow == null ? "default" : workflow;

	let initialState = {
		workflow: workflow,
		isFulfilled: false,
		isLoading: true,
		onboardingInfo: {
			has_onboarded: customer.getData("client_has_onboarded") ? customer.getData("client_has_onboarded") === "yes" : false,
			has_naked_account: false
		},
		isAccountSelectionFulfilled: AccountSelectionFulfillment.call(this, AccountSelection.getViewInitialStateForCustomer(customer), null),
		isPlacementRecommendationFulfilled: RecommendationFulfillment.call(this, Recommendation.getViewInitialStateForCustomer(customer), null),
		customerState: {
			checkbox_advice_onboarding: customer.getData("checkbox_advice_onboarding"),
			checkbox_advice_recommendation: customer.getData("checkbox_advice_recommendation"),
		}
	};

	if (!initialState.onboardingInfo.has_onboarded)
	{
		initialState.customerState.checkbox_advice_onboarding = "yes";
	}

	return initialState;
}

function _getFields()
{
	let fields = ["client_has_onboarded", "checkbox_advice_onboarding", "checkbox_advice_recommendation"];
	fields = fields.concat(AccountSelection.getFields());
	fields = fields.concat(Recommendation.getFields());
	return fields;
}