import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function ExperienceFulfillment(state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	if (customerState.exp_is_professional.length <= 0)
	{
		return false;
	}

	if (customerState.exp_years_traded.length <= 0 || customerState.exp_years_traded < 0 || customerState.exp_years_traded > 99)
	{
		return false;
	}

	if (customerState.exp_understands_total_loss !== "Ja")
	{
		return false;
	}

	// All table fields need to be entered
	// eslint-disable-next-line
	for (const [key, value] of Object.entries(customerState.tableData))
	{
		if (value.length <= 0)
		{
			return false; // Not mandatory for now
		}
	}

	return true;
}