import React from "react";
import moment from "moment";

// CLASSES
import Settings from "@classes/Settings";

// COMPONENTS
import Checkbox from "@components/Checkbox/Checkbox";
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../components/DefaultFields/DefaultFields";
import SignModal from "./subcomponents/SignModal/SignModal";

// HELPERS
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";
import api from "@helpers/api";

// OTHER
import fulfillment from "./SignAgreement.fulfillment";
import AssistantModal from "./subcomponents/AssistantModal/AssistantModal";


export default class SignAgreement extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state)
	}

	setCustomerState = (customerState, callbackFn) => {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) callbackFn();
		});
	};

	componentDidMount() {
		const {setAppState, user} = this.props;
		const signButtonText = user.role === "assistant" ? i18n("cob", "sign_agreement_assistant") : i18n("cob", "sign_agreement");

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {label: signButtonText, appearance: "success"}
			},
		});
	}

	render() {
		const {props, state} = this;
		const {signModalVisible, signModalBusy, signModalData, onRestartSession, user, advisor, customer} = props;
		const {customerState} = state;
		const classes = createClassName("Cob-view Cob-SignAgreement", {});
		const signDate = moment();

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				{signModalVisible && user.role !== "assistant"  && (
					<SignModal
						busy={signModalBusy}
						visible={signModalVisible}
						data={signModalData}
						status={state.signStatus}
						errorMessage={state.signErrorMessage}
						signUrls={state.signUrls}
						user={user}
						customer={customer}
						onSign={this._onSignModalSign}
						onClose={this._onSignModalClose}
						onRetry={this._onSignModalRetry}
						onRestart={onRestartSession}
					/>
				)}



				{signModalVisible && user.role === "assistant" && (
					<AssistantModal
						busy={signModalBusy}
						visible={signModalVisible}
						user={user}
						advisor={advisor}
						onSend={this._onSignModalSign}
						onClose={this._onSignModalClose}
						onRestart={onRestartSession}
					/>
				)}

				<input type="hidden" name="advice_sign_date" value={signDate.format("YYYY-MM-DD")}/>

				<ContentBox>
					<h2>Signering och kundens underskrift</h2>

					<div className="Cob-SignAgreement-fields">
						<Checkbox
							name="checkbox_customer_kyc_gdpr"
							label="Undertecknad medger härmed att Bolaget behandlar personuppgifter som lämnas i samband med ingåendet av detta avtal. Personuppgifterna används av Bolaget för öppnande av depå och i övrigt vid händelser förenade med vad som framgår av detta avtal."
							value="yes"
							checked={customerState.checkbox_customer_kyc_gdpr === "yes"}
							onChange={checked => this.setCustomerState({checkbox_customer_kyc_gdpr: checked ? "yes" : "no"})}
							alignTop
						/>

						<Checkbox
							name="advice_sign_row_1"
							label="Jag är medveten om att det/de placeringsråd jag erhållit och accepterat bygger på de uppgifter som jag lämnat. Jag godkänner informationen jag själv/rådgivaren nedtecknat i detta dokument."
							value="yes"
							checked={customerState.advice_sign_row_1 === "yes"}
							onChange={checked => this.setCustomerState({advice_sign_row_1: checked ? "yes" : "no"})}
							alignTop
						/>

						<Checkbox
							name="advice_sign_row_2"
							label="Jag har tagit del av relevant informationsmaterial och faktablad."
							value="yes"
							checked={customerState.advice_sign_row_2 === "yes"}
							onChange={checked => this.setCustomerState({advice_sign_row_2: checked ? "yes" : "no"})}
						/>

						<Checkbox
							name="advice_sign_row_3"
							label="Jag godkänner att mina personuppgifter lagras och hanteras hos Nord och dess anknutna ombud för administration."
							value="yes"
							checked={customerState.advice_sign_row_3 === "yes"}
							onChange={checked => this.setCustomerState({advice_sign_row_3: checked ? "yes" : "no"})}
							alignTop
						/>

						<Checkbox
							name="advice_sign_row_4"
							label="Jag har förstått att jag i undantagsfall riskerar att förlora det insatta kapitalet helt eller delvis."
							value="yes"
							checked={customerState.advice_sign_row_4 === "yes"}
							onChange={checked => this.setCustomerState({advice_sign_row_4: checked ? "yes" : "no"})}
							alignTop
						/>

						<Checkbox
							name="advice_sign_row_5"
							label="I förslaget kan det förekomma inslag från andra risknivåer, men totalt sett utgör helheten den överenskomna risknivån."
							value="yes"
							checked={customerState.advice_sign_row_5 === "yes"}
							onChange={checked => this.setCustomerState({advice_sign_row_5: checked ? "yes" : "no"})}
							alignTop
						/>

						<Checkbox
							name="advice_sign_row_6"
							label="Kopia på rådgivningsdokumentationen"
							value="yes"
							checked={customerState.advice_sign_row_6 === "yes"}
							onChange={checked => this.setCustomerState({advice_sign_row_6: checked ? "yes" : "no"})}
						/>
					</div>
				</ContentBox>
			</div>
		);
	}

	// Internal methods
	_onSignModalClose = async () => {
		const {setAppState} = this.props;
		await setAppState({signModalVisible: false});
	};

	_onSignModalSign = ({signatories, signOnScreen}) => {
		return new Promise(async resolve => {
			const {props} = this;
			const {customer, user, setAppState} = props;
			const newStateProps = {};

			await setAppState({signModalBusy: true});

			try {
				// Fetch datasheet URLs by ISINs (if any)
				const datasheetUrls = [];

				/**
				 * Build array of docs that should not be included in the final documentation
				 */
				const excludedDocuments = [];
				if (!customer.getData("customer_account_broker_2")) {
					excludedDocuments.push(1); // Remove kontoöppningsavtal
				}

				// Sign the case and receive its sign URL
				const {signUrls} = await api("/case/sign", {
					caseId: Settings.get("CASE_ID"),
					signatories,
					signOnScreen,
					partner: user.getStakeholderData(),
					excludeDocs: excludedDocuments,
					datasheetUrls,
				});

				newStateProps.signStatus = "success";
				newStateProps.signUrls = signUrls;
			} catch (e) {
				console.error(e.message);
				newStateProps.signStatus = "fail";
				newStateProps.signErrorMessage = e.message;
			}

			this.setState(newStateProps, async () => {
				await setAppState({signModalBusy: false});
				resolve();
			});
		});
	};

	_onSignModalRetry = () => {
		this.setState({
			signStatus: null,
			signUrls: null,
			signErrorMessage: null,
		});
	}
}


// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer} = this.props;
	const newState = {
		signStatus: null,
		signUrls: null,
		signErrorMessage: null,

		customerState: {
			checkbox_customer_kyc_gdpr: customer.getData("checkbox_customer_kyc_gdpr") || "yes",
			advice_sign_row_1: customer.getData("advice_sign_row_1"),
			advice_sign_row_2: customer.getData("advice_sign_row_2"),
			advice_sign_row_3: customer.getData("advice_sign_row_3"),
			advice_sign_row_4: customer.getData("advice_sign_row_4"),
			advice_sign_row_5: customer.getData("advice_sign_row_5"),
			advice_sign_row_6: customer.getData("advice_sign_row_6"),
		}
	};

	newState.isFulfilled = this.isFulfilled(newState);

	return newState;
}

function _getFields() {
	return [
		"checkbox_customer_kyc_gdpr",
		"advice_sign_row_1",
		"advice_sign_row_2",
		"advice_sign_row_3",
		"advice_sign_row_4",
		"advice_sign_row_5",
		"advice_sign_row_6",
	];
}