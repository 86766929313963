// MODULES
import React from "react";
import {Link} from "react-router-dom";

// CLASSES
import Assently from "@classes/Assently";
import Settings from "@classes/Settings";
import User from "@classes/User";

// COMPONENTS
import Button from "@components/Button/Button";
import Form from "@components/Form/Form";
import GlofitechBankIDModal from "@classes/GlofitechBankIDLogin";

// HELPERS
import {connectRedux, createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";
import api from "@helpers/api";

// REDUCERS
import {actions as appActions} from "@reducers/app";

export default connectRedux(state => ({
	app: state.app
}), {
	setAppState: appActions.setState,
	setUser: appActions.setUser,
	setAdvisor: appActions.setAdvisor,
}, class HomeRoute extends React.PureComponent {
	state = {
		hide: true,
		bankIDLoginVisible: false,
		animatingOut: false
	};

	componentDidMount() {
		const {reducers} = this.props;
		const {partner} = reducers.app;

    if (partner && !this.logoSrc) {
      import(`@assets/${partner.name}/${partner.logo}.png`).then( logo  => {
        this.logoSrc = logo.default;
      });
    }

		this._unhideTimeout = setTimeout(async () => {
			const {history} = this.props;
			const authToken = Settings.get("AUTH_TOKEN");

			try {
				if (!authToken) {
					throw new Error("AUTH_TOKEN_UNDEFINED");
				}

				const lastView = Settings.get("LAST_VIEW") || 1;
				this.setState({hide: false}, () => history.push("/cob/" + lastView));
			}
			catch (e) {
				this.setState({hide: false});
			}
		}, 100);
	}

	componentWillUnmount() {
		clearTimeout(this._unhideTimeout);
	}

	render() {
		const {props, state} = this;
		const classes = createClassName("Home", {
			"hide": state.hide,
			"animate-out": state.animatingOut
		});

		return (
			<div className={classes}>
        <div className="Home-content">
          {this.logoSrc && (
            <Link to={"/"}>
              <img
                src={this.logoSrc}
                // alt={partner.title}
                style={{
                  maxHeight: 86,
                  zIndex: 1000
                }}
              />
            </Link>
          )}
          <h1>{i18n("general", "welcome")}!</h1>
          <p>{i18n("home", "please_authenticate_below")}</p>
          <Form onSubmit={_onAuth.bind(this)} autoComplete={false} submitOnEnter>
            <Button
              type="submit"
              label={i18n("general", "authenticate")}
              appearance="secondary"
              busy={props.reducers.app.busy}
              filled
            />
          </Form>
        </div>
        <GlofitechBankIDModal
          actions={this.props.actions}
          history={this.props.history}
          onCancel={this._cancelLoginGlofitech.bind(this)}
          visible={state.bankIDLoginVisible}/>
			</div>
		);
	}

	_cancelLoginGlofitech() {
		this.setState({bankIDLoginVisible: false}, () => {
			this.props.actions.setAppState({busy: false});
		});
	}
});

// PRIVATE FUNCTIONS
function _animateOutAsync() {
	return new Promise(resolve => {
		this.setState({animatingOut: true}, () => {
			setTimeout(() => this.setState({animatingOut: false}, resolve), 300);
		});
	});
}

async function _onAuth() {
	const {actions} = this.props;

	// Set app to busy state
	actions.setAppState({busy: true});

	// Get user data and save to Settings
	try {
		const {userData, advisorData} = await _fetchUserDataAsync.call(this);

		// Set app to non-busy state
		actions.setAppState({busy: false});

		// Create user and set in app reducer
		actions.setUser(new User(userData));
		actions.setAdvisor(new User(advisorData));

		// Animate out
		await _animateOutAsync.call(this);

		// Redirect
		this.props.history.push("/cob");
	}
	catch (e) {
		// Clear Settings and redirect
		console.error(e.message);
		Settings.clear();
		this.props.history.push("/partner-not-found");
	}
}

function _fetchUserDataAsync() {
	return new Promise(async (resolve, reject) => {
		const {actions, history, reducers} = this.props;

		if (reducers.app.partner.authenticationProvider === 'glofitech') {
			this.setState({bankIDLoginVisible: true});
		}
		else {
			const {token: coreIdAuthToken} = await api("/auth/coreid");
			const callback = async response => {
				if (response.type === "authenticated") {

					try {
						const {token: authToken} = await api("/auth/token", {coreIdToken: response.token});

						// Save token to settings
						Settings.set("AUTH_TOKEN", authToken);

						// Set userData (partner)
						const {partner, advisor} = await api("/auth/data");
						Assently.close();

						if (partner) {
							actions.setAppState({busy: false});
							resolve({token: authToken, userData: partner, advisorData: advisor});
						}
						else {
							Settings.clear();
							history.push("/partner-not-found");
							return;
						}
					}
					catch (e) {
						Assently.close();
						actions.setAppState({busy: false});
						reject(e);
					}
				}
				else if (response.type === "cancelled") {
					Assently.close();
					actions.setAppState({busy: false});
				}
			};

			// Initialize Assently client and start it
			Assently.init(coreIdAuthToken, callback);
			Assently.start();
		}
	});
}